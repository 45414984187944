import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'antd';
import moment from 'moment';
import { ConversationDataType } from '../../pages/Camo/HelpDesk';
import closeIcon from '../../assets/close-icon-mobile.svg';
import styles from './TicketDrawer.module.less';
import DisplayAttachments from './DisplayAttachments';
import ReplyForm from './ReplyForm';
import ConversationCard from './ConversationCard';
import CloseTicketModal from './CloseTicketModal';

const TicketDrawer = ({ ticket }): ReactElement => {
  const {
    status,
    title,
    date,
    refNum,
    type,
    content,
    conversation,
    attachments,
  }: {
    status: string;
    title: string;
    date: Date;
    refNum: string;
    type: string;
    content: string;
    conversation: Array<ConversationDataType>;
    attachments: Array<any>;
  } = ticket;

  const [replyVisible, setReplyVisible] = useState(false);
  const [sortDirection, setSortDirection] = useState('Ascending');
  const [modalVisible, setModalVisible] = useState(false);

  const handleSortChange = (): void => {
    if (sortDirection === 'Ascending') {
      setSortDirection('Descending');
    } else {
      setSortDirection('Ascending');
    }
  };

  const sortConversation: Array<ConversationDataType> = conversation.sort(
    (a: ConversationDataType, b: ConversationDataType): number => {
      if (a.date < b.date) {
        if (sortDirection === 'Ascending') {
          return -1;
        }
        if (sortDirection !== 'Ascending') {
          return 1;
        }
      }
      if (a.date > b.date) {
        if (sortDirection === 'Ascending') {
          return 1;
        }
        if (sortDirection !== 'Ascending') {
          return -1;
        }
      }
      return 0;
    },
  );

  const handleCancel = (): void => {
    setModalVisible(false);
  };

  const handleCloseTicket = (): void => {
    setModalVisible(false);
  };

  useEffect(() => {
    setReplyVisible(false);
  }, [conversation]);

  let statusStyling = styles.okStatus;
  if (status === 'warning') statusStyling = styles.warningStatus;
  if (status === 'overdue') statusStyling = styles.overdueStatus;

  return (
    <div className={styles.drawerWrapper}>
      <div className={styles.ticketWrapper}>
        <div className={styles.ticketHeader}>
          <div className={styles.titleWrapper}>
            <div className={`${styles.statusDiv} ${statusStyling}`} />
            <div>{title}</div>
          </div>
          <span>{refNum}</span>
        </div>
        <div className={styles.ticketBody}>
          <div className={styles.firstRow}>
            <div>
              <div className={styles.label}>RAISED ON:</div>
              <div className={styles.data}>{moment(date).format('DD/MM/YYYY')}</div>
            </div>
            {/* <div>
              <div className={styles.label}>RAISED ON:</div>
              <div className={styles.data}>{moment(date).format('DD/MM/YYYY')}</div>
            </div> */}
          </div>
          <div>
            <div className={styles.label}>AREA:</div>
            <div className={styles.data}>{type}</div>
          </div>
          <div>
            <div className={styles.label}>DESCRIPTION:</div>
            <div className={styles.data}>{content}</div>
          </div>
          <div>
            <div className={styles.label}>ATTACHMENTS:</div>
            <div>
              <DisplayAttachments attachments={attachments} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headerRow}>
        <span className={styles.conversationTitle}>
          Conversation History <span className={styles.conversationCount}>(10)</span>
        </span>
        <button type="button" className={styles.sortByButton} onClick={(): void => handleSortChange()}>
          <span className={styles.sortBy}>
            <span className={styles.sortType}>{`Days ${sortDirection}`}</span>
            <Icon
              type="caret-down"
              className={sortDirection === 'Ascending' ? styles.sortArrow : styles.sortArrowDesc}
            />
          </span>
        </button>
      </div>
      <div className={styles.conversationWrapper}>
        {!replyVisible &&
          sortConversation.map((convo, index) => (
            <div className={styles.conversationCardWrapper}>
              <ConversationCard
                key={convo.id}
                author={convo.author}
                company={convo.company}
                date={convo.date}
                content={convo.content}
                attachments={convo.attachments}
                index={index}
                setReplyVisible={setReplyVisible}
              />
            </div>
          ))}
        {replyVisible && <ReplyForm ticketNumber={refNum} setReplyVisible={setReplyVisible} />}
      </div>
      <div className={styles.buttonWrapper}>
        <Button type="primary" className={styles.closeTicketButton}>
          Close Ticket
        </Button>
      </div>
      <Modal
        title="Close Ticket"
        visible={modalVisible}
        onOk={handleCloseTicket}
        onCancel={handleCancel}
        className={styles.closeTicketModal}
        okText="Close Ticket"
        closeIcon={<img src={closeIcon} alt="close" className={styles.closeIcon} />}
      >
        <CloseTicketModal ticket={ticket} />
      </Modal>
    </div>
  );
};

export default TicketDrawer;
