import React, { ReactElement } from 'react';
import { Button, Input, Radio, Form, Card } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import iconResponse from '../../assets/icon-response.svg';
import { submitDocumentApproval } from '../../services/apiNew';
import styles from './DocumentPage.module.less';

const { TextArea } = Input;

class DocumentPageForm extends React.Component<DocumentPageFormProps, { error: string; loading: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
    };
  }

  handleChange = () => {
    if (this.state.error !== null) {
      this.setState({ error: null });
    }
  };

  onSubmit = (e: any): void => {
    const { form, history, approvalRequestId } = this.props;
    this.setState({ loading: true });
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) return null;
      // TODO: api call with form values
      const res = await submitDocumentApproval(
        approvalRequestId,
        form.getFieldsValue().approvalState,
        form.getFieldsValue().remarks,
      );
      if (res && res.status === 200) {
        this.setState({ loading: false });
        history.push(`/public/document/${res.data.documentApprovalId}/complete`);
      } else if (res && res.response.status === 400) {
        this.setState({ loading: false, error: 'Something went wrong, please try again' });
      }
    });
  };

  render(): ReactElement {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { error, loading } = this.state;
    return (
      <>
        <Card className={styles.document__card__internal}>
          <h2>
            <img src={iconResponse} className={styles.icon} alt="Response icon" /> Response
          </h2>
          <div className={styles.paddingWrapper}>
            <Form id="doc-form" onSubmit={this.onSubmit}>
              <Form.Item id="test" label="Please enter any remarks">
                {getFieldDecorator('remarks')(
                  <TextArea
                    maxLength={250}
                    autoSize={{ maxRows: 4, minRows: 4 }}
                    className={styles.textArea}
                    onKeyDown={this.handleChange}
                  />,
                )}
              </Form.Item>

              <Form.Item id="radio-group" label="Please select review action:" className={styles.radioGroup}>
                {getFieldDecorator('approvalState', { initialValue: 'approve' })(
                  <Radio.Group className={styles.documentFormRadioGroup} onChange={this.handleChange}>
                    <Radio className={styles.radioButtonWrapper} value="approve">
                      Adopt document
                    </Radio>
                    <Radio value="reject">Reject document</Radio>
                    <Radio value="feedback">Revise assessment</Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Form>
          </div>
        </Card>
        <div className={styles.buttonWrapper}>
          <Button
            htmlType="submit"
            type="primary"
            disabled={loading}
            className={styles.saveFormButton}
            onClick={this.onSubmit}
          >
            Submit your review
          </Button>
        </div>
        {error && <p className={styles.formError}>{error}</p>}
      </>
    );
  }
}

const WrapperDocumentPageForm = Form.create<DocumentPageFormProps>()(DocumentPageForm);

export default WrapperDocumentPageForm;

export interface DocumentPageFormProps extends FormComponentProps {
  history: any;
  approvalRequestId: string;
}
