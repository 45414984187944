import React, { useState, useEffect } from 'react';
import { useIntl, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import { Table } from 'antd';
import SearchInput from '../SearchInput';
import defaults from '../../utils/defaults';
import TableTitle from '../TableTitle/TableTitle';
import TechLogEmptyState from '../../assets/empty-state-documents-v2.svg';
import tripArrowIcon from '../../assets/trip-arrow.svg';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import TableFilter from './TableFilter';
import styles from './TechLogPageTable.module.less';

const DummyDataMulti = [
  {
    registration: 'G-DHLE',
    techlogs: [
      {
        id: 1,
        srp: '64698',
        srpDate: moment('2020-09-12', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-13', 'YYYY/MM/DD'),
        fromAirport: 'BAH',
        toAirport: 'LEJ',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 2,
        srp: '64699',
        srpDate: moment('2020-09-12', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-13', 'YYYY/MM/DD'),
        fromAirport: 'LEJ',
        toAirport: 'JFK',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 3,
        srp: '64700',
        srpDate: moment('2020-09-13', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-14', 'YYYY/MM/DD'),
        fromAirport: 'JFK',
        toAirport: 'EMA',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 4,
        srp: '64701',
        srpDate: moment('2020-09-13', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-14', 'YYYY/MM/DD'),
        fromAirport: 'EMA',
        toAirport: 'LEJ ',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 5,
        srp: '64702',
        srpDate: moment('2020-09-13', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-14', 'YYYY/MM/DD'),
        fromAirport: 'LEJ',
        toAirport: 'BAH ',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 6,
        srp: '64704',
        srpDate: moment('2020-09-14', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-15', 'YYYY/MM/DD'),
        fromAirport: 'BAH',
        toAirport: 'NBO',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 7,
        srp: '64704',
        srpDate: moment('2020-09-14', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-16', 'YYYY/MM/DD'),
        fromAirport: 'NBO',
        toAirport: 'RUH',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 8,
        srp: '64706',
        srpDate: moment('2020-09-15', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
        fromAirport: 'RUH',
        toAirport: 'BAH',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 9,
        srp: '64707',
        srpDate: moment('2020-09-15', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-16', 'YYYY/MM/DD'),
        fromAirport: 'BAH',
        toAirport: 'NBO',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 10,
        srp: '64708',
        srpDate: moment('2020-09-15', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
        fromAirport: 'NBO',
        toAirport: 'BAH',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 11,
        srp: '64709',
        srpDate: moment('2020-09-16', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
        fromAirport: 'BAH',
        toAirport: 'KWI',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 11,
        srp: '64709',
        srpDate: moment('2020-09-16', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
        fromAirport: 'BAH',
        toAirport: 'KWI',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 12,
        srp: '',
        srpDate: moment('2020-09-16', 'YYYY/MM/DD'),
        entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
        fromAirport: 'KWI',
        toAirport: 'BAH',
        age: '1 day',
        errorLink: '/',
        type: 'awaiting corrections',
      },
    ],
  },
  {
    registration: 'G-DHLF',
    techlogs: [
      {
        id: 13,
        srp: '60354',
        srpDate: moment('2020/09/12', 'YYYY/MM/DD'),
        entryDate: moment('2020/09/13', 'YYYY/MM/DD'),
        fromAirport: 'EMA',
        toAirport: 'LEJ',
        age: '2 days',
        errorLink: '/',
        type: 'awaiting corrections',
      },
      {
        id: 14,
        srp: '60355',
        srpDate: moment('2020/09/12', 'YYYY/MM/DD'),
        entryDate: moment('2020/09/13', 'YYYY/MM/DD'),
        fromAirport: 'LEJ',
        toAirport: 'MED',
        age: '2 days',
        errorLink: '/',
        type: 'missing',
      },
    ],
  },
  {
    registration: 'G-DHLG',
    techlogs: [
      {
        id: 24,
        srp: '63948',
        srpDate: moment('2020/09/11', 'YYYY/MM/DD'),
        entryDate: moment('2020/09/13', 'YYYY/MM/DD'),
        fromAirport: 'NBO',
        toAirport: 'BAH',
        age: '2 days',
        errorLink: '/',
        type: 'last entries',
      },
      {
        id: 25,
        srp: '63950',
        srpDate: moment('2020/09/12', 'YYYY/MM/DD'),
        entryDate: moment('2020/09/13', 'YYYY/MM/DD'),
        fromAirport: 'BAH',
        toAirport: 'NBO',
        age: '3 days',
        errorLink: '/',
        type: 'last entries',
      },
    ],
  },
];
const DummyData = [
  {
    id: 1,
    srp: '64698',
    srpDate: moment('2020-09-12', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-13', 'YYYY/MM/DD'),
    fromAirport: 'BAH',
    toAirport: 'LEJ',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 2,
    srp: '64699',
    srpDate: moment('2020-09-12', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-13', 'YYYY/MM/DD'),
    fromAirport: 'LEJ',
    toAirport: 'JFK',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 3,
    srp: '64700',
    srpDate: moment('2020-09-13', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-14', 'YYYY/MM/DD'),
    fromAirport: 'JFK',
    toAirport: 'EMA',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 4,
    srp: '64701',
    srpDate: moment('2020-09-13', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-14', 'YYYY/MM/DD'),
    fromAirport: 'EMA',
    toAirport: 'LEJ ',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 5,
    srp: '64702',
    srpDate: moment('2020-09-13', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-14', 'YYYY/MM/DD'),
    fromAirport: 'LEJ',
    toAirport: 'BAH ',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 6,
    srp: '64704',
    srpDate: moment('2020-09-14', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-15', 'YYYY/MM/DD'),
    fromAirport: 'BAH',
    toAirport: 'NBO',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 7,
    srp: '64704',
    srpDate: moment('2020-09-14', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-16', 'YYYY/MM/DD'),
    fromAirport: 'NBO',
    toAirport: 'RUH',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 8,
    srp: '64706',
    srpDate: moment('2020-09-15', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
    fromAirport: 'RUH',
    toAirport: 'BAH',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 9,
    srp: '64707',
    srpDate: moment('2020-09-15', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-16', 'YYYY/MM/DD'),
    fromAirport: 'BAH',
    toAirport: 'NBO',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 10,
    srp: '64708',
    srpDate: moment('2020-09-15', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
    fromAirport: 'NBO',
    toAirport: 'BAH',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 11,
    srp: '64709',
    srpDate: moment('2020-09-16', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
    fromAirport: 'BAH',
    toAirport: 'KWI',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 11,
    srp: '64709',
    srpDate: moment('2020-09-16', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
    fromAirport: 'BAH',
    toAirport: 'KWI',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
  {
    id: 12,
    srp: '',
    srpDate: moment('2020-09-16', 'YYYY/MM/DD'),
    entryDate: moment('2020-09-17', 'YYYY/MM/DD'),
    fromAirport: 'KWI',
    toAirport: 'BAH',
    age: '1 day',
    errorLink: '/',
    type: 'awaiting corrections',
  },
];

const TechLogPageTable = ({ userSettings, multiAircraft }) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { formatMessage } = useIntl();
  const [filterValue, setFilterValue] = useState('All');
  const [filteredData, setFilteredData] = useState([]);
  const [multiTechlogs, setMultiTechlogs] = useState([]);
  const [dataMessage, setDataMessage] = useState('');

  const columns = [
    ...(multiAircraft
      ? [
          {
            title: (props) => (
              <TableTitle props={props} title={formatMessage({ id: 'title.registration' })} dataIndex="registration" />
            ),
            dataIndex: 'registration',
            sortName: 'registration',
            width: 120,
            sorter: (a, b) => a.registration.localeCompare(b.registration),
            sortDirections: ['descend', 'ascend'],
            render(val) {
              return (
                <div>
                  <span className={`${tableStyles.statusText}`}>{val}</span>
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.srp' })} dataIndex="srp" />,
      dataIndex: 'srp',
      align: 'center',
      sorter: (a, b) => a.srp - b.srp,
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return (
          <div>
            <span className={tableStyles.numberText}>{`${val ? `#${val}` : ''}`}</span>
          </div>
        );
      },
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.srpDate' })} dataIndex="srpDate" />,
      dataIndex: 'srpDate',
      sorter: (a, b) => moment(a.srpDate, 'DD-MM-YYYY') - moment(b.srpDate, 'DD-MM-YYYY'),
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return userSettings && userSettings.dateFormat
          ? moment(val).format(userSettings.dateFormat)
          : moment(val).format(defaults.defaultDateFormat);
      },
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.entryDate' })} dataIndex="entryDate" />
      ),
      dataIndex: 'entryDate',
      sorter: (a, b) => moment(a.entryDate, 'DD-MM-YYYY') - moment(b.entryDate, 'DD-MM-YYYY'),
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return userSettings && userSettings.dateFormat
          ? moment(val).format(userSettings.dateFormat)
          : moment(val).format(defaults.defaultDateFormat);
      },
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.from' })} dataIndex="fromAirport" />
      ),
      dataIndex: 'fromAirport',
      width: 70,
      sorter: (a, b) => a.fromAirport.localeCompare(b.fromAirport),
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return (
          <div>
            <span className={tableStyles.airportText}>{val}</span>
          </div>
        );
      },
    },
    {
      width: 30,
      render() {
        return (
          <div className={tableStyles.iconWrapper}>
            <img src={tripArrowIcon} alt="tipIcon" />
          </div>
        );
      },
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.to' })} dataIndex="toAirport" />,
      dataIndex: 'toAirport',
      sorter: (a, b) => a.toAirport.localeCompare(b.toAirport),
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return (
          <div>
            <span className={tableStyles.airportText}>{val}</span>
          </div>
        );
      },
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.age' })} dataIndex="age" />,
      dataIndex: 'age',
      sorter: (a, b) => a.age - b.age,
      sortDirections: ['descend', 'ascend'],
      render(val, record) {
        const difference = Math.abs(record.entryDate.diff(moment(), 'd'));
        return (
          <div>
            <span className={tableStyles.ageText}>{`${difference} ${difference === 1 ? 'day' : 'days'}`}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.errorDetails' })} dataIndex="errorLink" />
      ),
      dataIndex: 'errorLink',
      render(val) {
        return (
          <div>
            <Link to={`${val}`}>{formatMessage({ id: 'form.button.link' })}</Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setFilteredData(DummyData);
    setDataMessage(
      DummyData.length > 0 ? formatMessage({ id: 'text.noSearchData' }) : formatMessage({ id: 'text.noData' }),
    );
  }, [formatMessage]);

  useEffect(() => {
    let techlogArray = [];
    if (multiAircraft) {
      DummyDataMulti.forEach((plane) => {
        const techlogs = plane.techlogs
          ? plane.techlogs.map((techlog) => ({ ...techlog, registration: plane.registration }))
          : [];
        techlogArray = [...techlogArray, ...techlogs];
      });
      setMultiTechlogs(techlogArray);
      setFilteredData(techlogArray);
      setDataMessage(
        techlogArray.length > 0 ? formatMessage({ id: 'text.noSearchData' }) : formatMessage({ id: 'text.noData' }),
      );
    }
  }, [multiAircraft, formatMessage]);

  return (
    <>
      <div className={styles.listFilterRow}>
        {DummyData.length > 0 && (
          <>
            <SearchInput
              placeholder={formatMessage({ id: 'form.placeholder.searchMX' })}
              value={searchValue}
              visible={searchVisible}
              onToggle={() => setSearchVisible(!searchVisible)}
              onBlur={(value) => {
                if (!value) {
                  setSearchVisible(false);
                }
              }}
              onChange={(value) => {
                setSearchValue(value);
                if (!value) {
                  if (multiAircraft) {
                    setFilteredData(multiTechlogs);
                  } else {
                    setFilteredData(DummyData);
                  }
                } else if (multiAircraft) {
                  setFilteredData(
                    multiTechlogs.filter((techlog) => techlog.id.toString().indexOf(value.toUpperCase()) > -1),
                  );
                } else {
                  setFilteredData(
                    DummyData.filter((techlog) => techlog.id.toString().indexOf(value.toUpperCase()) > -1),
                  );
                }
              }}
              data-test="searchInput"
              onClear={() => {
                setSearchValue(null);
                if (multiAircraft) {
                  setFilteredData(multiTechlogs);
                } else {
                  setFilteredData(DummyData);
                }
              }}
              inputRef="techlog"
            />
            <div className={styles.filterContainer}>
              <TableFilter
                buttons={['All', 'Awaiting Corrections', 'Missing', 'Last Entries']}
                handleRadioSelection={(selection) => setFilterValue(selection)}
                initialSelection={filterValue}
              />
            </div>
          </>
        )}
      </div>
      <div className={tableStyles.tableContainer}>
        {filteredData.length === 0 ? (
          <div className={tableStyles.emptyStateWrapper}>
            <img src={TechLogEmptyState} alt="no data" className={tableStyles.emptyState} />
            <span>{dataMessage}</span>
          </div>
        ) : (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={filteredData}
            bordered={false}
            rowClassName={tableStyles.tableRow}
            pagination={false}
            scroll={{ x: 800 }}
          />
        )}
      </div>
    </>
  );
};

TechLogPageTable.propTypes = {
  multiAircraft: PropTypes.bool,
};

TechLogPageTable.defaultProps = {
  multiAircraft: false,
};

export default compose(
  withRouter,
  injectIntl,
  connect(({ userSettings }) => ({
    userSettings,
  })),
)(TechLogPageTable);
