import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AircraftActionTypes } from '../../models/aircraft';
import FleetDefectsTable from './FleetDefectsTable';
import styles from './FleetDefectsContainer.module.less';

const FleetDefectsContainer = ({ aircraftMap }) => {
  const [loading, setLoading] = useState(false);
  const [defectsData, setDefectsData] = useState(false);
  const aircraftArray = Array.from(aircraftMap.values());

  useEffect(() => {
    const aircraftDefects = [];
    if (aircraftArray && (!defectsData || defectsData === [])) {
      setLoading(true);
      aircraftArray.forEach((aircraft) => {
        const registration = aircraft.id;
        if (aircraft.defects) {
          aircraft.defects.forEach((defect) => {
            const defectObj = defect;
            defectObj.registration = registration;
            defectObj.status = defect.togo > 0 ? 'open' : 'overdue';
            defectObj.number = defect.id;
            aircraftDefects.push(defectObj);
          });
        }
        setDefectsData(aircraftDefects);
        setLoading(false);
      });
    }
  }, [aircraftArray, defectsData]);

  return (
    <>
      <div className={styles.fleetTableContainer}>
        <FleetDefectsTable shortList defectsData={defectsData} loading={loading} />
      </div>
    </>
  );
};

FleetDefectsContainer.propTypes = {
  aircraftMap: PropTypes.instanceOf(Map),
};

FleetDefectsContainer.defaultProps = {
  aircraftMap: new Map(),
};

const FleetDefectsContainerWithRedux = connect(
  ({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  }),
  (dispatch) => ({
    updateAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.UPDATE,
        payload,
      }),
  }),
)(FleetDefectsContainer);

export default FleetDefectsContainerWithRedux;
