import { ReliabilityReportsActionTypes } from '.';

export const saveFleetDelays = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.FLEETDELAYS,
    payload,
  });
};

export const saveFleetAMP = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.FLEETAMP,
    payload,
  });
};

export const saveFleetUtilisation = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.FLEETUTILISATION,
    payload,
  });
};

export const saveAircraftDelays = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.AIRCRAFTDELAYS,
    payload,
  });
};

export const saveAircraftAMP = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.AIRCRAFTAMP,
    payload,
  });
};

export const saveAircraftUtilisation = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.AIRCRAFTUTILISATION,
    payload,
  });
};

export const saveAircraftTypeDelays = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.AIRCRAFTTYPEDELAYS,
    payload,
  });
};

export const saveAircraftTypeAMP = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.AIRCRAFTTYPEAMP,
    payload,
  });
};

export const saveAircraftTypeUtilisation = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsActionTypes.AIRCRAFTTYPEUTILISATION,
    payload,
  });
};
