import React, { FunctionComponent } from 'react';
import styles from './ReportProgressBar.module.less';

const ReportProgressBar: FunctionComponent<ReportProgressBarProps> = ({
  completedValueNumber,
  completedValueText,
  totalValueLabel,
  completedProgress,
  totalProgress,
  pogressBarColor,
  noMargin,
}) => {
  const completedPercentage = (completedProgress / totalProgress) * 100;
  return (
    <>
      <div className={styles.summaryCardLabels}>
        <span>
          <span className={styles.completedValueNumber}>{completedValueNumber}</span>
          {completedValueText}
        </span>
        <span>{totalValueLabel}</span>
      </div>
      <div className={noMargin ? styles.totalProgressNoMargin : styles.totalProgress}>
        <div
          className={styles.completedProgress}
          style={{ width: `${completedPercentage}%`, backgroundColor: `${pogressBarColor}` }}
        />
      </div>
    </>
  );
};

type ReportProgressBarProps = {
  completedValueNumber?: number;
  completedValueText?: string;
  totalValueLabel?: string;
  completedProgress: number;
  totalProgress: number;
  pogressBarColor: string;
  noMargin?: boolean;
};

export default ReportProgressBar;
