import React, { ReactElement, useState, useEffect } from 'react';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Col, Row } from 'antd';
import moment from 'moment';
import LegendItem from '../../Charts/Legend/LegendItem';
import SummaryCard from '../SummaryCard';
import { CustomizedYAxisTick, CustomizedXAxisTick, formatDate, filterDataByTimeScale } from './utils';
import styles from './ReliabilityReportGraph.module.less';

const GenericAreaGraph = ({
  title,
  data,
  axisKey,
  dataKey,
  stroke,
  fill,
  id,
  referenceLine = null,
  label = null,
  unit = null,
  format,
  selectedTimeScale,
}): ReactElement => {
  const [ticks, setTicks] = useState([]);

  // configure graph ticks if using time format
  useEffect(() => {
    if (format !== 'time') return;
    const ticksArray = [];
    if (selectedTimeScale.text === 'Custom') {
      const lengthOfTime = moment
        .duration(
          moment()
            .add(selectedTimeScale.value.from, 'months')
            .diff(moment().add(selectedTimeScale.value.to, 'months')),
        )
        .as('months');
      const roundedTime = Math.round(Math.abs(lengthOfTime));
      for (let i = 0; i < roundedTime + 1; i += 1) {
        const timeStamp = moment()
          .add(selectedTimeScale.value.to, 'months')
          .startOf('month')
          .subtract(i, 'months')
          .unix();
        ticksArray.push(timeStamp);
      }
      setTicks(ticksArray);
    } else {
      for (let i = 0; i < selectedTimeScale.value; i += 1) {
        const timeStamp = moment()
          .startOf('month')
          .subtract(i, 'months')
          .unix();
        ticksArray.push(timeStamp);
      }
      // add the next month so that the current month is displayed in full on the axis
      ticksArray.unshift(
        moment()
          .startOf('month')
          .add(1, 'months')
          .unix(),
      );
      setTicks(ticksArray);
    }
  }, [format, selectedTimeScale.text, selectedTimeScale.value]);

  let formattedData = [];
  if (data && data[0] && Object.getOwnPropertyNames(data[0]).includes('date')) {
    formattedData = filterDataByTimeScale(data, selectedTimeScale, true);
  }
  return (
    <div className={styles.graphWrapper}>
      <div className={styles.headerRow}>
        <h2>{title}</h2>
      </div>
      <Row className={styles.graphLabelWrapper}>
        <Col span={label ? 18 : 24}>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart data={formattedData} margin={{ top: 10, bottom: 20, right: 20, left: 10 }}>
              <CartesianGrid strokeDasharray="2 2" stroke="#8097b1" opacity={0.2} />
              <XAxis
                dataKey={axisKey}
                axisLine={{ stroke: '#E0E7FF' }}
                ticks={ticks}
                tick={{ fill: '#b0bac9' }}
                domain={[ticks[ticks.length - 1], ticks[0]]}
                interval={0}
                tickLine={false}
                type="number"
                tickFormatter={(t): string => moment.unix(t).format('MMM')}
              />
              <YAxis
                axisLine={{ stroke: '#E0E7FF' }}
                tickLine={false}
                tick={<CustomizedYAxisTick unit={unit} />}
                domain={formattedData.length > 0 ? [0, 'auto'] : [0, 1]}
              />
              {referenceLine &&
                formattedData.length > 1 &&
                referenceLine.map((item) => (
                  <ReferenceLine
                    y={item.value}
                    strokeDasharray="4 4"
                    stroke="red"
                    // @ts-ignore
                    label={{ value: item.text, position: 'insideTopLeft' }}
                  />
                ))}
              <Area
                dataKey={dataKey}
                strokeWidth={2}
                stroke="url(#legend1)"
                fill="url(#legend1)"
                dot={{ fill: '#ffffff' }}
                isAnimationActive={data && data.length > 1}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={styles.legend}>
            <LegendItem label={dataKey} index={1} button={false} />
          </div>
        </Col>
        {label ? (
          <Col span={6}>
            <SummaryCard title={label.title1} summaryCount={label.value1} countColor="#3f98ff" small engine>
              <span className={styles.summaryLabel}>{label.year}</span>
            </SummaryCard>
            <br />
            <SummaryCard
              title={label.title2}
              summaryCount={Number.isNaN(label.value2) ? 0 : label.value2}
              countColor="#3f98ff"
              small
              engine
            >
              <span className={styles.summaryLabel}>{label.year}</span>
            </SummaryCard>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default GenericAreaGraph;
