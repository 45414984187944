import { ReliabilityReportsAircraftActionTypes } from '.';

export const SaveAircraftData = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsAircraftActionTypes.AIRCRAFT,
    payload,
  });
};

export const SaveAircraftType = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsAircraftActionTypes.AIRCRAFTTYPE,
    payload,
  });
};

export const SaveAircraftRegistrations = ({ payload }) => (dispatch): any => {
  dispatch({
    type: ReliabilityReportsAircraftActionTypes.REGISTRATIONS,
    payload,
  });
};
