import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Col, Row, Card } from 'antd';
import PropTypes from 'prop-types';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import { queryAllWorkpacks } from '../../services/apiNew';
import { AircraftActionTypes } from '../../models/aircraft';
import WorkpackStatusTable from '../../components/AircraftServiceOverview/WorkpackStatusTable';
import workpacksIcon from '../../assets/pageHeaderIcons/title-workpacks.svg';
import styles from './FleetWorkpack.module.less';

class FleetWorkpack extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    saveAllAircraft: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.getAllWorkpacks();
  }

  getAllWorkpacks = async () => {
    const { saveAllAircraft } = this.props;
    const res = await queryAllWorkpacks();
    saveAllAircraft(res.data.data.allAircraft);
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <PageHeaderWrapper>
        <PageHeaderWrapper
          secondaryHeader
          layout="modern"
          title={
            <span>
              <img src={workpacksIcon} alt="reports" className={styles.headerIcon} />{' '}
              {formatMessage({ id: 'text.fleetWorkpack' })}
            </span>
          }
        />
        <Row gutter={20} className={styles.statusOverviewRow}>
          <Col>
            <Card className={cardStyles.pageCard} bordered={false}>
              <Col span={24}>
                <WorkpackStatusTable multiAircraft />
              </Col>
            </Card>
          </Col>
        </Row>
      </PageHeaderWrapper>
    );
  }
}

const fleetWorkpackWithRedux = connect(
  () => ({}),
  (dispatch) => ({
    saveAllAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.SAVE_ALL,
        payload,
      }),
  }),
)(FleetWorkpack);
export default withRouter(injectIntl(fleetWorkpackWithRedux));
