import React, { ReactElement, useState } from 'react';
import { Upload, Button, message, Row, Col, Spin, Modal } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { RcFile } from 'antd/lib/upload';
import { useIntl } from 'react-intl';
import UploadIcon from '../../assets/empty-state-upload-image.svg';
import servers from '../../utils/servers';
import deleteIcon from './assets/delete-red.svg';
import styles from './AttachmentUpload.module.less';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Dragger } = Upload;

const AttachmentUpload = (): ReactElement => {
  const [localFileList, setLocalFileList] = useState([]);
  const [alreadyLoadedIDs, setAlreadyLoadedIDs] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const { formatMessage } = useIntl();
  const checkFileSize = (file: RcFile): boolean => Math.round(file.size / 1024 / 1024) > 5;

  const getBase64 = (file: RcFile): Promise<any> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => resolve(reader.result);
      reader.onerror = (error): void => reject(error);
    });
  };

  const handlePreview = async (file): Promise<void> => {
    const newFile = file;
    if (!file.url && !file.preview) {
      newFile.url = false;
    }
    if (!file.url) newFile.url = await getBase64(file.originFileObj);
    setPreviewImage(newFile.url || newFile.preview);
  };

  const handleFileChange = ({ fileList }): void => {
    const newFileList = fileList.map(async (file) => {
      const newFile = file;

      if (!file.url && !file.preview) {
        newFile.preview = await getBase64(file.originFileObj);
      }

      return newFile;
    });

    Promise.all(newFileList).then((list) => {
      const filteredList = list.filter((item: RcFile) => !alreadyLoadedIDs.includes(item.uid) && !checkFileSize(item));
      const filteredListIDs = filteredList.map((item: RcFile) => item.uid);
      setAlreadyLoadedIDs(alreadyLoadedIDs.concat(filteredListIDs));
      setLocalFileList(localFileList.concat(filteredList));
      if (filteredList.length > 0) {
        // handleAttachmentsUpload(filteredList);
      }
    });
  };

  const handleCancel = (): void => {
    setPreviewVisible(false);
    setTimeout(() => {
      setPreviewImage('');
    }, 200);
  };

  const handleAttachmentDelete = (index: number): void => {
    localFileList.splice(index, 1);
    setLocalFileList([...localFileList]);
  };

  const displayAttachment = (): ReactElement[] => {
    const images = localFileList.map((file, index) => {
      if (file.url || file.preview) {
        return (
          <div className={styles.attachmentWrapper} key={file.name}>
            <Button
              type="link"
              onClick={(): void => {
                handlePreview(file);
                setPreviewVisible(true);
              }}
              className={styles.imageButton}
            >
              {file.type === 'application/pdf' ? (
                <Document renderMode="svg" className={styles.pdfImage} file={file.preview}>
                  <Page width={88} pageNumber={1} />
                </Document>
              ) : (
                <img
                  src={file.preview || `${servers.api}/${file.thumbnail_url}`}
                  alt="preview"
                  className={styles.attachmentPreviewImage}
                />
              )}
            </Button>
            <div className={styles.fileName}>
              <span>{file.name}</span>
              <Button
                type="default"
                className={styles.deleteAttachment}
                onClick={(): void => {
                  handleAttachmentDelete(index);
                }}
              >
                <img src={deleteIcon} alt="delete" className={styles.deleteIcon} />
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className={styles.attachmentSpinner}>
          <Spin size="small" />
        </div>
      );
    });
    return images;
  };

  const displayPreviewImage = (image): ReactElement => {
    let displayImage;
    if (image) {
      if (image.startsWith('data:application/pdf')) {
        displayImage = (
          <embed src={image || `${servers.api}/${image}`} width="100%" height="650" type="application/pdf" />
        );
      } else {
        displayImage = (
          <div className={styles.fullsizeImagePreview}>
            <img
              src={image || `${servers.api}/${image}`}
              alt="preview"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </div>
        );
      }
    }
    return displayImage;
  };

  return (
    <div className={styles.uploadWrapper}>
      <Row>
        <Col span={12}>
          <Dragger
            showUploadList={false}
            className={styles.dragger}
            beforeUpload={(file): boolean => {
              if (checkFileSize(file)) {
                message.error(formatMessage({ id: 'message.melFileTooLarge' }));
                return false;
              }
              Promise.resolve(file).then(() => setLocalFileList([...localFileList, file]));
              return false;
            }}
            onChange={handleFileChange}
          >
            <button type="button" className={styles.uploadButton}>
              <img src={UploadIcon} alt="upload icon" className={styles.uploadIcon} />
              <span className={styles.fileTypes}>{formatMessage({ id: 'text.pdfFiles' })}</span>
              <span className={styles.instructions}>
                {formatMessage({ id: 'text.dragDropFile' })}{' '}
                <span className={styles.highlightBrowseText}>{formatMessage({ id: 'text.browse' })}</span>
              </span>
            </button>
          </Dragger>
        </Col>
        <Col span={12} className={styles.attachmentCol}>
          {displayAttachment()}
        </Col>
      </Row>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel} closable={false}>
        {displayPreviewImage(previewImage)}
      </Modal>
    </div>
  );
};

export default AttachmentUpload;
