import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';
import { queryUtilisation } from '../../services/apiNew';
import { AircraftActionTypes } from '../../models/aircraft';
import AircraftOverviewCamo from '../AircraftOverviewCamo';
import defaults from '../../utils/defaults';
import styles from './AircraftSummary.module.less';

const AircraftSummary = ({ aircraft, updateAircraft }) => {
  const { formatMessage } = useIntl();
  const [aircraftMxArray, setAircraftMxArray] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    params: { id },
  } = useRouteMatch();

  useEffect(() => {
    const getUtilisation = async () => {
      setLoading(true);
      const res = await queryUtilisation(id);
      updateAircraft(res.data.data.aircraft);
      setLoading(false);
    };
    if (!loading && aircraft && !aircraft.utilisationTotals) {
      getUtilisation();
    }
    const aircraftData = {
      maintenance: {
        // TODO: replace dummy data
        flight_seconds_to_next_check: 28800,
        cycles_to_next_check: 9,
        days_to_next_check: 13,
      },
      mx_thresholds: {},
      current_values: {
        // TODO: replace dummy data
        flight_seconds: 0,
        apu_seconds: 1800,
        cycles: 1,
      },
      ...aircraft,
    };
    const cyclesObj = {
      remaining: aircraft.utilisationRemaining ? aircraft.utilisationRemaining.flightCyclesRemaining : 0,
      threshold: aircraftData.mx_thresholds.cycles
        ? aircraftData.mx_thresholds.cycles
        : defaults.defaultMXThresholds.cycles,
      total: aircraft.utilisationTotals ? Math.floor(aircraft.utilisationTotals.flightCycles) : 0,
      nextDue: aircraft.utilisationRemaining ? aircraft.utilisationRemaining.flightCyclesNextDue : 0,
    };
    const daysObj = {
      remaining: aircraft.utilisationRemaining ? aircraft.utilisationRemaining.aircraftDaysRemaining / 24 : 0,
      threshold: aircraftData.mx_thresholds.days ? aircraftData.mx_thresholds.days : defaults.defaultMXThresholds.days,
      total: aircraft.utilisationTotals ? Math.floor(aircraft.utilisationTotals.aircraftDays) : 0,
      nextDue: aircraft.utilisationRemaining ? aircraft.utilisationRemaining.aircraftDaysNextDue : 0,
    };

    const hoursObj = {
      remaining: aircraft.utilisationRemaining ? Math.round(aircraft.utilisationRemaining.flightHoursRemaining) : 0,
      threshold: aircraftData.mx_thresholds.flights_seconds
        ? aircraftData.mx_thresholds.flights_seconds
        : defaults.defaultMXThresholds.flight_seconds,
      total: aircraft.utilisationTotals ? Math.floor(aircraft.utilisationTotals.flightHours) : 0,
      nextDue: aircraft.utilisationRemaining ? aircraft.utilisationRemaining.flightHoursNextDue : 0,
    };

    setAircraftMxArray([
      {
        mxObj: cyclesObj,
        circleUnit: formatMessage({ id: 'title.cyclesRem' }),
        format: 'number',
        showTotal: true,
        showNextDue: true,
        active: !!aircraftData.maintenance.cycles_to_next_check,
      },
      {
        mxObj: daysObj,
        circleUnit: formatMessage({ id: 'title.daysRem' }),
        format: 'number',
        showTotal: true,
        showNextDue: true,
        active: !!aircraftData.maintenance.days_to_next_check,
      },
      {
        mxObj: hoursObj,
        circleUnit: formatMessage({ id: 'title.hoursRem' }),
        showTotal: true,
        showNextDue: true,
        active: !!aircraftData.maintenance.flight_seconds_to_next_check,
      },
    ]);
  }, [aircraft, formatMessage, id, loading, updateAircraft]);
  if (loading) {
    return (
      <div className={styles.loadingDiv}>
        <Spin size="small" />
      </div>
    );
  }
  return <AircraftOverviewCamo aircraft={aircraft} widgets={aircraftMxArray} />;
};

AircraftSummary.propTypes = {
  aircraft: PropTypes.object,
  updateAircraft: PropTypes.func.isRequired,
};

AircraftSummary.defaultProps = {
  aircraft: {},
};

const AircraftSummaryWithRedux = connect(
  ({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  }),
  (dispatch) => ({
    updateAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.UPDATE,
        payload,
      }),
  }),
)(AircraftSummary);
export default AircraftSummaryWithRedux;
