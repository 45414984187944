import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { PieChart, Pie, Cell } from 'recharts';
import PropTypes from 'prop-types';
import LegendItem from './LegendItem';
import styles from './DefectsSummary.module.less';

const data = [
  {
    id: 'mel',
    name: 'MEL',
    value: 7,
    colour: '#b4b1f2',
    gradient: 'linear-gradient(to bottom, #b4b1f2, #799fe0)',
    startColour: '#b4b1f2',
    stopColour: '#799fe0',
  },
  {
    id: 'cabin',
    name: 'Cabin Items',
    value: 9,
    colour: '#024574',
    gradient: 'linear-gradient(to bottom, #024574, #012140)',
    startColour: '#024574',
    stopColour: '#012140',
  },
  {
    id: 'other',
    name: 'Other',
    value: 3,
    colour: '#b1f2ec',
    gradient: 'linear-gradient(to bottom, #b1f2ec, #79e0d4)',
    startColour: '#b1f2ec',
    stopColour: '#79e0d4',
  },
];

const DefectsSummary = ({ defectsData }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  let melCount = 0;
  let cabinCount = 0;
  let otherCount = 0;

  defectsData.forEach((object) => {
    if (object.type === 'MEL') melCount += 1;
    if (object.type === 'Cabin') cabinCount += 1;
    if (object.type === 'OTHER') otherCount += 1;
  });

  data[0].value = melCount;
  data[1].value = cabinCount;
  data[2].value = otherCount;

  const valueCount = defectsData.length;

  return (
    <Row>
      <Col span={14}>
        <PieChart width={180} height={200} className={styles.pieOuter}>
          <foreignObject x={28} y={38} className={styles.foreignObject}>
            <div className={styles.dropCircle} />
          </foreignObject>
          <defs>
            <linearGradient id="mel" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#72c9ff" />
              <stop offset="100%" stopColor="#3f98ff" />
            </linearGradient>
            <linearGradient id="cabin" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#024574" />
              <stop offset="100%" stopColor="#012140" />
            </linearGradient>
            <linearGradient id="other" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#b1f2ec" />
              <stop offset="100%" stopColor="#79e0d4" />
            </linearGradient>
          </defs>

          <text x={90} y={90} className={`${styles.text} ${styles.bold}`} textAnchor="middle" dominantBaseline="middle">
            {valueCount}
          </text>
          <text x={90} y={110} className={styles.text} textAnchor="middle" dominantBaseline="middle">
            defects
          </text>
          <Pie
            className={styles.pieInner}
            activeIndex={activeIndex}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={78}
            outerRadius={85}
            animationBegin={200}
          >
            {data.map((entry, index) => (
              <Cell key={entry.name} fill={entry.colour} />
            ))}
          </Pie>
        </PieChart>
      </Col>
      <Col span={10} className={styles.graphLegend}>
        {data.map((d) => (
          <LegendItem key={d.name} name={d.name} colour={d.gradient} />
        ))}
      </Col>
    </Row>
  );
};

DefectsSummary.propTypes = {
  defectsData: PropTypes.array,
};

DefectsSummary.defaultProps = {
  defectsData: [],
};

export default DefectsSummary;
