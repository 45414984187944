import React, { ReactElement } from 'react';
import LegendCircle from './LegendCircle';
import styles from './LegendItem.module.less';

const LegendItem = ({
  label,
  index,
  onButtonClick,
  button,
  isActive,
  staticColour,
  dataKeys,
}: LegendItemProps): ReactElement => {
  return button ? (
    <button
      type="button"
      className={`${isActive ? styles.legendButton : styles.legendButtonOff} ${styles.legendItem}`}
      onClick={(): void => onButtonClick()}
    >
      <LegendCircle index={index} staticColour={staticColour} dataKeys={dataKeys} />
      <span className={styles.legendLabel}>{label}</span>
    </button>
  ) : (
    <div className={styles.legendItem}>
      <LegendCircle index={index} staticColour={staticColour} dataKeys={dataKeys} />
      <span className={styles.legendLabel}>{label}</span>
    </div>
  );
};

type LegendItemProps = {
  label: string;
  index: number;
  onButtonClick?: Function;
  button: boolean;
  isActive?: boolean;
  staticColour?: boolean;
  dataKeys?: any[];
};

export default LegendItem;
