import { Card } from 'antd';
import React, { ReactElement } from 'react';
import AircraftReliabilityReports from '../../pages/Camo/AircraftReliabilityReports';
import styles from './ReportsCard.module.less';

const ReportsCard = (): ReactElement => {
  return (
    <Card className={styles.reportCard}>
      <AircraftReliabilityReports />
    </Card>
  );
};

export default ReportsCard;
