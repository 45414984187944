import { Col, Row, Spin } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { usePrevious } from '../../hooks/usePrevious';
import {
  queryReportAtaDefects,
  queryReportDefectTrends,
  queryReportUnscheduledRemovals,
  queryReportTimeBetweenDefects,
  queryReportDefectSummary,
} from '../../services/apiNew';
import {
  saveFleetDefectTrends,
  saveFleetDefectSummary,
  saveFleetUnscheduledRemovals,
  saveFleetATADefects,
  saveFleetTimeBetweenDefects,
  saveAircraftDefectTrends,
  saveAircraftDefectSummary,
  saveAircraftUnscheduledRemovals,
  saveAircraftATADefects,
  saveAircraftTimeBetweenDefects,
  saveAircraftTypeDefectTrends,
  saveAircraftTypeDefectSummary,
  saveAircraftTypeUnscheduledRemovals,
  saveAircraftTypeATADefects,
  saveAircraftTypeTimeBetweenDefects,
} from '../../models/performanceReports/action';
import { ReportsFilter } from '../../pages/Camo/AircraftReliabilityReports';
import { PerformanceReportsState } from '../../models/performanceReports';
import { ReliabilityReportsAircraftState } from '../../models/reliabilityReportsAircraft';
import graphStyles from './Graphs/ReliabilityReportGraph.module.less';
import FiltersColumn from './FiltersColumn';
import GenericAreaGraph from './Graphs/GenericAreaGraph';
import GenericBarGraph from './Graphs/GenericBarGraph';
import GenericBarGraphDynamic from './Graphs/GenericBarGraphDynamic';
import StackedBarGraph from './Graphs/StackedBarGraph';
import ReportProgressBar from './ReportProgressBar';
import SummaryCard from './SummaryCard';
import TabHeader from './TabHeader';
import styles from './TabStyles.module.less';
import GenericLineGraph from './Graphs/GenericLineGraph';
import { filterDataByTimeScale } from './Graphs/utils';

const PerformanceTab = ({
  filter,
  selectedTimeScale,
  setSelectedTimeScale,
  handleFilterChange,
  activeTab,
  performanceReports,
  reliabilityReportsAircraft,
  saveFleetDefectTrendsLocal,
  saveFleetUnscheduledRemovalsLocal,
  saveFleetATADefectsLocal,
  saveFleetDefectSummaryLocal,
  saveFleetTimeBetweenDefectsLocal,
  saveAircraftDefectTrendsLocal,
  saveAircraftDefectSummaryLocal,
  saveAircraftUnscheduledRemovalsLocal,
  saveAircraftATADefectsLocal,
  saveAircraftTimeBetweenDefectsLocal,
  saveAircraftTypeDefectTrendsLocal,
  saveAircraftTypeDefectSummaryLocal,
  saveAircraftTypeUnscheduledRemovalsLocal,
  saveAircraftTypeATADefectsLocal,
  saveAircraftTypeTimeBetweenDefectsLocal,
}): ReactElement => {
  const { formatMessage } = useIntl();
  const [totalMAREP, setTotalMAREP] = useState(0);
  const [totalPIREP, setTotalPIREP] = useState(0);
  const [unscheduledRemovalsMonthlyTotals, setUnscheduledRemovalsMonthlyTotals] = useState([]);
  const [defectTrendsLoading, setDefectTrendsLoading] = useState(false);
  const [unscheduledRemovalsLoading, setUnscheduledRemovalsLoading] = useState(false);
  const [unscheduledRemovalsPartFilter, setUnscheduledRemovalsPartFilter] = useState({
    text: 'All Parts',
    value: null,
  });
  const [unscheduledRemovalsPartDropdownOptions, setUnscheduledRemovalsPartDropdownOptions] = useState([]);
  const [sortedRemovedTotals, setSortedRemovedTotals] = useState([]);
  const [ATADefectsLoading, setATADefectsLoading] = useState(false);
  const [timeBetweenDefectsLoading, setTimeBetweenDefectsLoading] = useState(false);
  const [ATADefectsOverTime, setATADefectsOverTime] = useState([]);
  const [defectSummaryLoading, setDefectSummaryLoading] = useState(false);
  const [ATAChapters, setATAChapters] = useState([]);
  const [ATADataKeys, setATADataKeys] = useState(['A', 'B', 'C', 'D']);
  const [dataSource, setDataSource] = useState<any>(new Map());
  const [firstRun, setFirstRun] = useState(true);
  const { registrations, types } = reliabilityReportsAircraft;
  const { fleet, aircraft, aircraftType, ttl } = performanceReports;
  const { text, value } = selectedTimeScale;
  const prevPropsFilter = usePrevious(filter);
  const prevPropsSelectedTimeScale = usePrevious(value);
  let fromDate = null;
  let toDate = null;
  if (text === 'Custom') {
    fromDate = moment()
      .add(value.from, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    toDate = moment()
      .add(value.to + 1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
  } else {
    fromDate = moment()
      .subtract(value, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    toDate = moment()
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  useEffect(() => {
    switch (filter.type) {
      case ReportsFilter.FLEET:
        setDataSource(fleet.dataMap);
        break;
      case ReportsFilter.AIRCRAFT:
        setDataSource(aircraft.dataMap.get(filter.value) || new Map());
        break;
      case ReportsFilter.AIRCRAFTTYPE:
        setDataSource(aircraftType.dataMap.get(filter.value) || new Map());
        break;
      default:
        setDataSource(fleet.dataMap);
    }
  }, [filter.type, filter.value, aircraft, aircraftType, fleet]);

  useEffect(() => {
    const getLastFetched = () => {
      switch (filter.type) {
        case ReportsFilter.FLEET:
          return fleet.lastFetched;
          break;
        case ReportsFilter.AIRCRAFT:
          return aircraft.lastFetched;
          break;
        case ReportsFilter.AIRCRAFTTYPE:
          return aircraftType.lastFetched;
          break;
        default:
          break;
      }
    };
    const saveToRedux = (data, type): any => {
      switch (filter.type) {
        case ReportsFilter.FLEET:
          if (type === 'trends') {
            saveFleetDefectTrendsLocal(data);
          } else if (type === 'removals') {
            saveFleetUnscheduledRemovalsLocal(data);
          } else if (type === 'ata') {
            saveFleetATADefectsLocal(data);
          } else if (type === 'summary') {
            saveFleetDefectSummaryLocal(data);
          } else {
            saveFleetTimeBetweenDefectsLocal(data);
          }
          break;
        case ReportsFilter.AIRCRAFT:
          if (type === 'trends') {
            saveAircraftDefectTrendsLocal({ data, type: filter.value });
          } else if (type === 'removals') {
            saveAircraftUnscheduledRemovalsLocal({ data, type: filter.value });
          } else if (type === 'ata') {
            saveAircraftATADefectsLocal({ data, type: filter.value });
          } else if (type === 'summary') {
            saveAircraftDefectSummaryLocal({ data, type: filter.value });
          } else {
            saveAircraftTimeBetweenDefectsLocal({ data, type: filter.value });
          }
          break;
        case ReportsFilter.AIRCRAFTTYPE:
          if (type === 'trends') {
            saveAircraftTypeDefectTrendsLocal({ data, type: filter.value });
          } else if (type === 'removals') {
            saveAircraftTypeUnscheduledRemovalsLocal({ data, type: filter.value });
          } else if (type === 'ata') {
            saveAircraftTypeATADefectsLocal({ data, type: filter.value });
          } else if (type === 'summary') {
            saveAircraftTypeDefectSummaryLocal({ data, type: filter.value });
          } else {
            saveAircraftTypeTimeBetweenDefectsLocal({ data, type: filter.value });
          }
          break;
        default:
          break;
      }
    };
    const buildQueryString = (): string => {
      if (filter.type === ReportsFilter.AIRCRAFT) {
        return JSON.stringify({ registration: filter.value, from_date: fromDate, to_date: toDate });
      }
      if (filter.type === ReportsFilter.AIRCRAFTTYPE) {
        return JSON.stringify({ type: filter.value, from_date: fromDate, to_date: toDate });
      }
      return JSON.stringify({ from_date: fromDate, to_date: toDate });
    };
    if (
      activeTab === 'performance' &&
      !firstRun &&
      (Date.now() - getLastFetched() > ttl ||
        (prevPropsFilter && filter.value !== prevPropsFilter.value) ||
        (prevPropsSelectedTimeScale && selectedTimeScale.value !== prevPropsSelectedTimeScale))
    ) {
      const getDefectTrendsData = async (): Promise<any> => {
        setDefectTrendsLoading(true);
        const res = await queryReportDefectTrends(buildQueryString());
        saveToRedux(res.data, 'trends');
        setDefectTrendsLoading(false);
      };
      const getUnscheduledRemovalsData = async (): Promise<any> => {
        setUnscheduledRemovalsLoading(true);
        const res = await queryReportUnscheduledRemovals(buildQueryString());
        saveToRedux(res.data, 'removals');
        setUnscheduledRemovalsLoading(false);
      };
      const getATADefectsData = async (): Promise<any> => {
        setATADefectsLoading(true);
        const res = await queryReportAtaDefects(buildQueryString());
        saveToRedux(res.data, 'ata');
        setATADefectsLoading(false);
      };
      const getTimeBetweenDefectsData = async (): Promise<any> => {
        setTimeBetweenDefectsLoading(true);
        const res = await queryReportTimeBetweenDefects(buildQueryString());
        saveToRedux(res.data, 'time');
        setTimeBetweenDefectsLoading(false);
      };
      const getDefectSummary = async (): Promise<any> => {
        setDefectSummaryLoading(true);
        const res = await queryReportDefectSummary(buildQueryString());
        saveToRedux(res.data, 'summary');
        setDefectSummaryLoading(false);
      };
      getDefectTrendsData();
      getUnscheduledRemovalsData();
      getATADefectsData();
      getTimeBetweenDefectsData();
      getDefectSummary();
    }
    setFirstRun(false);
  }, [
    selectedTimeScale,
    activeTab,
    aircraft.lastFetched,
    aircraftType.lastFetched,
    filter.type,
    filter.value,
    fleet.lastFetched,
    fromDate,
    toDate,
    ttl,
    prevPropsFilter,
    saveFleetATADefectsLocal,
    saveFleetDefectTrendsLocal,
    saveFleetDefectSummaryLocal,
    saveFleetTimeBetweenDefectsLocal,
    saveFleetUnscheduledRemovalsLocal,
    saveAircraftATADefectsLocal,
    saveAircraftDefectTrendsLocal,
    saveAircraftDefectSummaryLocal,
    saveAircraftTimeBetweenDefectsLocal,
    saveAircraftTypeATADefectsLocal,
    saveAircraftTypeDefectTrendsLocal,
    saveAircraftTypeDefectSummaryLocal,
    saveAircraftTypeTimeBetweenDefectsLocal,
    saveAircraftTypeUnscheduledRemovalsLocal,
    saveAircraftUnscheduledRemovalsLocal,
  ]);

  useEffect(() => {
    let newMAREP = 0;
    let newPIREP = 0;
    if (dataSource && dataSource.get('defectSummary'))
      dataSource.get('defectSummary').forEach((item) => {
        newMAREP += parseInt(item.MAREP, 10);
        newPIREP += parseInt(item.PIREP, 10);
      });
    setTotalMAREP(newMAREP);
    setTotalPIREP(newPIREP);
  }, [dataSource]);

  useEffect(() => {
    if (dataSource && dataSource.get('unscheduledRemovals')) {
      const monthlyRemovedParts = dataSource.get('unscheduledRemovals').reduce((result, item) => {
        const updatedResult = result;
        const existingIndex = updatedResult.findIndex((i) => i.value === item.part_number);
        if (existingIndex === -1) {
          updatedResult.push({ text: item.part_number, value: item.part_number });
        }
        return updatedResult;
      }, []);
      monthlyRemovedParts.unshift({ text: 'All Parts', value: null });
      setUnscheduledRemovalsPartDropdownOptions(monthlyRemovedParts);
    }
  }, [dataSource]);

  useEffect(() => {
    if (dataSource && dataSource.get('unscheduledRemovals')) {
      let unscheduledRemovals = dataSource.get('unscheduledRemovals');
      if (unscheduledRemovalsPartFilter.value !== null) {
        unscheduledRemovals = unscheduledRemovals.filter(
          (item) => item.part_number === unscheduledRemovalsPartFilter.value,
        );
      }
      const monthlyRemovedTotals = unscheduledRemovals.reduce((result, item) => {
        const updatedResult = result;
        const existingIndex = updatedResult.findIndex((i) => i.date === item.date);
        if (existingIndex > -1) {
          updatedResult[existingIndex].count += item.count;
        } else {
          updatedResult.push({ date: item.date, count: item.count });
        }
        return updatedResult;
      }, []);
      const sortedMonthlyRemovedTotals = monthlyRemovedTotals.sort((a: any, b: any) => b.date - a.date);
      setUnscheduledRemovalsMonthlyTotals([...sortedMonthlyRemovedTotals]);
    }
  }, [dataSource, unscheduledRemovalsPartFilter]);

  useEffect(() => {
    if (dataSource && dataSource.get('unscheduledRemovals')) {
      const monthlyRemovedTotals = dataSource.get('unscheduledRemovals').reduce((result, item) => {
        const updatedResult = result;
        const existingIndex = updatedResult.findIndex((i) => i.part_number === item.part_number);
        if (existingIndex > -1) {
          updatedResult[existingIndex].count += item.count;
        } else {
          updatedResult.push({
            partName: item.partName,
            count: item.count,
            part_number: item.part_number,
            date: item.date,
          });
        }
        return updatedResult;
      }, []);
      const sortedTotals = monthlyRemovedTotals.sort((a: any, b: any) => b.count - a.count);
      setSortedRemovedTotals(sortedTotals);
    }
  }, [dataSource]);

  useEffect(() => {
    if (dataSource && dataSource.get('ATADefects')) {
      const timeFilteredATAs = filterDataByTimeScale(dataSource.get('ATADefects'), selectedTimeScale, true);
      const ATADefects = timeFilteredATAs.map((item) => {
        const object = {
          date: item.date,
          [item.chapter]: item.total,
        };
        return object;
      });
      const uniqueChapters = timeFilteredATAs.reduce((result, item) => {
        const updatedResult = result;
        const existingIndex = updatedResult.findIndex((i) => i === item.chapter);
        if (existingIndex === -1) {
          updatedResult.push(item.chapter);
        }
        return updatedResult;
      }, []);
      setATAChapters(uniqueChapters);
      setATADefectsOverTime(ATADefects);
    }
  }, [dataSource, selectedTimeScale]);

  useEffect(() => {
    let DataKeys = [];
    if (dataSource && dataSource.get('ATADefects')) {
      const ATADefectClone: { key: unknown; total: number; date: number; chapter: string } = {
        ...dataSource.get('ATADefects')[0],
      };
      delete ATADefectClone.total;
      delete ATADefectClone.chapter;
      delete ATADefectClone.date;
      DataKeys = Object.keys(ATADefectClone);
    }
    setATADataKeys(DataKeys.sort());
  }, [dataSource]);

  return (
    <div className={styles.layoutDiv}>
      <div className={styles.graphsWrapper}>
        <TabHeader selectedTimeScale={selectedTimeScale} setSelectedTimeScale={setSelectedTimeScale} filter={filter} />
        <Spin spinning={defectTrendsLoading}>
          <GenericBarGraphDynamic
            data={dataSource.get('defectTrends')}
            title={formatMessage({ id: 'title.defectReportTrends' })}
            axisKey="date"
            selectedTimeScale={selectedTimeScale}
            format="time"
            button
            filter={filter}
            dataKeys={['total', 'MAREP', 'PIREP']}
            firstTickPadding
          />
        </Spin>
        <Row gutter={24} style={{ margin: 0 }} className={graphStyles.graphWrapper}>
          <div className={graphStyles.headerRow}>
            <h2>{formatMessage({ id: 'title.marepSummary' })}</h2>
          </div>
          <Spin spinning={defectSummaryLoading}>
            <Col span={12}>
              <SummaryCard
                title={formatMessage({ id: 'title.totalReportedDefects' })}
                summaryCount={totalPIREP + totalMAREP}
                countColor="#35b96d"
              >
                <ReportProgressBar
                  completedValueNumber={dataSource.get('defectSummary') && dataSource.get('defectSummary')[0].total}
                  completedValueText="Total Defects"
                  totalValueLabel={
                    dataSource.get('defectSummary') &&
                    moment.unix(dataSource.get('defectSummary')[0].date).format('YYYY')
                  }
                  completedProgress={dataSource.get('defectSummary') && dataSource.get('defectSummary')[0].total}
                  totalProgress={totalMAREP + totalPIREP}
                  pogressBarColor="#024574"
                />
                <ReportProgressBar
                  completedValueNumber={
                    (dataSource.get('defectSummary') && dataSource.get('defectSummary')[1].total) || 0
                  }
                  completedValueText="Total Defects"
                  totalValueLabel={
                    dataSource.get('defectSummary') &&
                    moment.unix(dataSource.get('defectSummary')[1].date).format('YYYY')
                  }
                  completedProgress={(dataSource.get('defectSummary') && dataSource.get('defectSummary')[1].total) || 0}
                  totalProgress={totalMAREP + totalPIREP}
                  pogressBarColor="#3f98ff"
                  noMargin
                />
              </SummaryCard>
            </Col>
            <Col span={12} style={{ height: '75px' }}>
              <SummaryCard
                title={formatMessage({ id: 'title.marepDefects' })}
                summaryCount={dataSource.get('defectSummary') && dataSource.get('defectSummary')[1].MAREP}
                countColor="#3f98ff"
                small
              >
                <span className={styles.summaryLabel}>
                  Total{' '}
                  {dataSource.get('defectSummary') &&
                    moment.unix(dataSource.get('defectSummary')[1].date).format('YYYY')}
                </span>
              </SummaryCard>
              <br />
              <SummaryCard
                title={formatMessage({ id: 'title.pirepDefects' })}
                summaryCount={dataSource.get('defectSummary') && dataSource.get('defectSummary')[1].PIREP}
                countColor="#024574"
                small
              >
                <span className={styles.summaryLabel}>
                  Total{' '}
                  {dataSource.get('defectSummary') &&
                    moment.unix(dataSource.get('defectSummary')[1].date).format('YYYY')}
                </span>
              </SummaryCard>
            </Col>
          </Spin>
        </Row>
        <Spin spinning={timeBetweenDefectsLoading}>
          <Row gutter={24}>
            <Col xxl={12} xl={24} lg={24} md={24}>
              <GenericAreaGraph
                data={dataSource.get('timeBetweenDefects')}
                title={formatMessage({ id: 'title.defectReportTimeBetween' })}
                dataKey="hours"
                axisKey="date"
                stroke="#024574"
                format="time"
                unit=" hours"
                fill="#024574"
                selectedTimeScale={selectedTimeScale}
                id={345}
              />
            </Col>
            <Col xxl={12} xl={24} lg={24} md={24}>
              <GenericAreaGraph
                data={dataSource.get('timeBetweenDefects')}
                title={formatMessage({ id: 'title.defectReportCyclesBetween' })}
                dataKey="cycles"
                axisKey="date"
                stroke="#1790ff"
                fill="#1791ff"
                selectedTimeScale={selectedTimeScale}
                id={234}
                unit=""
                format="time"
              />
            </Col>
          </Row>
        </Spin>
        <Row>
          <Spin spinning={ATADefectsLoading}>
            <Col span={24}>
              <StackedBarGraph
                data={dataSource.get('ATADefects')}
                selectedTimeScale={selectedTimeScale}
                title={formatMessage({ id: 'title.ataDefects' })}
                axisKey="chapter"
                dataKeys={ATADataKeys}
                layout="vertical"
                stackId="ataDefects"
                button
              />
            </Col>
            <Col span={24}>
              <GenericBarGraphDynamic
                data={ATADefectsOverTime}
                title={formatMessage({ id: 'title.ataDefectsOverTime' })}
                selectedTimeScale={selectedTimeScale}
                format="time"
                dataKeys={ATAChapters}
                filter={filter}
                axisKey="date"
                button
                firstTickPadding
              />
            </Col>
          </Spin>
        </Row>
        <Spin spinning={unscheduledRemovalsLoading}>
          <Row>
            <Col span={24}>
              <GenericLineGraph
                data={unscheduledRemovalsMonthlyTotals && unscheduledRemovalsMonthlyTotals}
                title={formatMessage({ id: 'title.unscheduledRemovals' })}
                selectedTimeScale={selectedTimeScale}
                format="time"
                button={false}
                dataKeys={['count']}
                dropdown
                dropdownOptions={unscheduledRemovalsPartDropdownOptions}
                dropdownSelectedOption={unscheduledRemovalsPartFilter}
                onDropdownSelect={(item) => setUnscheduledRemovalsPartFilter(item)}
                isAnimationActive={false}
              />
            </Col>
          </Row>
        </Spin>
        <Spin spinning={unscheduledRemovalsLoading}>
          <GenericBarGraph
            data={sortedRemovedTotals}
            title={formatMessage({ id: 'title.topRemovalsByPart' })}
            axisKey="part_number"
            line1={{
              dataKey: 'count',
              startColour: '#1790ff',
              stopColour: '#1790ff',
            }}
            barSize={7}
            selectedTimeScale={selectedTimeScale}
            layout="vertical"
            format="time"
            id="removalPart"
            button={false}
          />
        </Spin>
      </div>
      <div className={styles.filtersWrapper}>
        <FiltersColumn
          handleFilterChange={(value) => {
            handleFilterChange(value);
          }}
          registrations={registrations}
          types={types}
          filter={filter}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  performanceReports,
  reliabilityReportsAircraft,
}: {
  performanceReports: PerformanceReportsState;
  reliabilityReportsAircraft: ReliabilityReportsAircraftState;
}): {
  performanceReports: {};
  reliabilityReportsAircraft: {};
} => {
  return {
    performanceReports,
    reliabilityReportsAircraft,
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  saveFleetDefectTrendsLocal: (payload: any) => Promise<void>;
  saveFleetDefectSummaryLocal: (payload: any) => Promise<void>;
  saveFleetUnscheduledRemovalsLocal: (payload: any) => Promise<void>;
  saveFleetATADefectsLocal: (payload: any) => Promise<void>;
  saveFleetTimeBetweenDefectsLocal: (payload: any) => Promise<void>;
  saveAircraftDefectTrendsLocal: (payload: any) => Promise<void>;
  saveAircraftDefectSummaryLocal: (payload: any) => Promise<void>;
  saveAircraftUnscheduledRemovalsLocal: (payload: any) => Promise<void>;
  saveAircraftATADefectsLocal: (payload: any) => Promise<void>;
  saveAircraftTypeDefectSummaryLocal: (payload: any) => Promise<void>;
  saveAircraftTimeBetweenDefectsLocal: (payload: any) => Promise<void>;
  saveAircraftTypeDefectTrendsLocal: (payload: any) => Promise<void>;
  saveAircraftTypeUnscheduledRemovalsLocal: (payload: any) => Promise<void>;
  saveAircraftTypeATADefectsLocal: (payload: any) => Promise<void>;
  saveAircraftTypeTimeBetweenDefectsLocal: (payload: any) => Promise<void>;
} => ({
  saveFleetDefectTrendsLocal: (payload): Promise<void> => {
    return dispatch(saveFleetDefectTrends({ payload }));
  },
  saveFleetDefectSummaryLocal: (payload): Promise<void> => {
    return dispatch(saveFleetDefectSummary({ payload }));
  },
  saveFleetUnscheduledRemovalsLocal: (payload): Promise<void> => {
    return dispatch(saveFleetUnscheduledRemovals({ payload }));
  },
  saveFleetATADefectsLocal: (payload): Promise<void> => {
    return dispatch(saveFleetATADefects({ payload }));
  },
  saveFleetTimeBetweenDefectsLocal: (payload): Promise<void> => {
    return dispatch(saveFleetTimeBetweenDefects({ payload }));
  },
  saveAircraftDefectTrendsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftDefectTrends({ payload }));
  },
  saveAircraftDefectSummaryLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftDefectSummary({ payload }));
  },
  saveAircraftUnscheduledRemovalsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftUnscheduledRemovals({ payload }));
  },
  saveAircraftATADefectsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftATADefects({ payload }));
  },
  saveAircraftTimeBetweenDefectsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTimeBetweenDefects({ payload }));
  },
  saveAircraftTypeDefectTrendsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeDefectTrends({ payload }));
  },
  saveAircraftTypeDefectSummaryLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeDefectSummary({ payload }));
  },
  saveAircraftTypeUnscheduledRemovalsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeUnscheduledRemovals({ payload }));
  },
  saveAircraftTypeATADefectsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeATADefects({ payload }));
  },
  saveAircraftTypeTimeBetweenDefectsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeTimeBetweenDefects({ payload }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceTab);
