/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { injectIntl } from 'react-intl';
import { pathToRegexp } from 'path-to-regexp';
import { connect } from 'react-redux';
import { urlToList } from '../_utils/pathTools';
import styles from './index.module.less';

const { SubMenu } = Menu;

const getIcon = (icon) => {
  if (typeof icon === 'string') {
    return <img src={icon} alt="icon" className={styles.icon} />;
  }
  return icon;
};

export const getMenuMatches = memoizeOne(
  (flatMenuKeys, path) => flatMenuKeys.filter((item) => item && pathToRegexp(item).test(path)),
  isEqual,
);

class BaseMenu extends PureComponent {
  static propTypes = {
    menuData: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
    onCollapse: PropTypes.func,
    Authorized: PropTypes.object,
    openKeys: PropTypes.object,
    theme: PropTypes.string,
    mode: PropTypes.string,
    style: PropTypes.object,
    handleOpenChange: PropTypes.func,
  };

  static defaultProps = {
    Authorized: undefined,
    openKeys: undefined,
    style: undefined,
    isMobile: false,
    onCollapse: undefined,
    theme: '',
    mode: '',
    handleOpenChange: undefined,
  };

  constructor(props) {
    super(props);
    this.getSelectedMenuKeys = memoizeOne(this.getSelectedMenuKeys, isEqual);
    this.flatMenuKeys = this.getFlatMenuKeys(props.menuData);
  }

  /**
   * Recursively flatten the data
   * [{path:string},{path:string}] => {path,path2}
   * @param  menus
   */
  getFlatMenuKeys(menus) {
    let keys = [];
    menus.forEach((item) => {
      if (item.children) {
        keys = keys.concat(this.getFlatMenuKeys(item.children));
      }
      keys.push(item.path);
    });
    return keys;
  }

  /**
   * @memberof SiderMenu
   */
  getNavMenuItems = (menusData, parent) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .filter((item) => item.name && !item.hideInMenu)
      .map((item) => {
        // make dom
        const ItemDom = this.getSubMenuOrItem(item, parent);
        return this.checkPermissionItem(item.authority, ItemDom);
      })
      .filter((item) => item);
  };

  // Get the currently selected menu
  getSelectedMenuKeys = (pathname) =>
    urlToList(pathname).map((itemPath) => getMenuMatches(this.flatMenuKeys, itemPath).pop());

  /**
   * get SubMenu or Item
   */
  getSubMenuOrItem = (item) => {
    const {
      intl: { formatMessage },
    } = this.props;
    // doc: add hideChildrenInMenu
    if (item.children && !item.hideChildrenInMenu && item.children.some((child) => child.name)) {
      const name = item.locale ? formatMessage({ id: item.locale }) : item.name;
      return (
        <SubMenu
          title={
            item.icon ? (
              <span>
                {getIcon(item.icon)}
                <span>{name}</span>
              </span>
            ) : (
              name
            )
          }
          key={item.path}
          className="sidebar-menu-item"
          data-testid="BaseMenu--SubMenuIcon"
        >
          {this.getNavMenuItems(item.children)}
        </SubMenu>
      );
    }
    return (
      <Menu.Item className="sidebar-menu-item" key={item.path}>
        {this.getMenuItemPath(item)}
      </Menu.Item>
    );
  };

  /**
   * http.Link a
   * Judge whether it is http link.return a or Link
   * @memberof SiderMenu
   */
  getMenuItemPath = (item) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const name = item.locale ? formatMessage({ id: item.locale }) : item.name;
    const itemPath = this.conversionPath(item.path);
    const icon = getIcon(item.icon);
    const { target } = item;
    // Is it a http link
    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}>
          {icon}
          <span>{name}</span>
        </a>
      );
    }
    const { location, isMobile, onCollapse } = this.props;
    return (
      <Link
        to={itemPath}
        target={target}
        replace={itemPath === location.pathname}
        onClick={
          isMobile
            ? () => {
                onCollapse(true);
              }
            : undefined
        }
        data-testid="BaseMenu--IconLink"
      >
        {icon}
        <span>{name}</span>
      </Link>
    );
  };

  // permission to check
  checkPermissionItem = (authority, ItemDom) => {
    const { Authorized } = this.props;
    if (Authorized && Authorized.check) {
      const { check } = Authorized;
      return check(authority, ItemDom);
    }
    return ItemDom;
  };

  conversionPath = (path) => {
    if (path && path.indexOf('http') === 0) {
      return path;
    }
    return `/${path || ''}`.replace(/\/+/g, '/');
  };

  render() {
    const {
      openKeys,
      theme,
      mode,
      location: { pathname },
      menuData,
    } = this.props;
    // if pathname can't match, use the nearest parent's key
    let selectedKeys = this.getSelectedMenuKeys(pathname);
    if (!selectedKeys.length && openKeys) {
      selectedKeys = [openKeys[openKeys.length - 1]];
    }
    let props = {};
    if (openKeys) {
      props = {
        openKeys,
      };
    }
    const { handleOpenChange, style } = this.props;
    return (
      <Menu
        key="Menu"
        mode={mode}
        theme={theme}
        onOpenChange={handleOpenChange}
        selectedKeys={selectedKeys}
        style={style}
        data-testid="BaseMenu--Menu"
        className={mode === 'horizontal' ? 'top-nav-menu' : ''}
        {...props}
      >
        {this.getNavMenuItems(menuData)}
      </Menu>
    );
  }
}

export default injectIntl(
  connect(({ menu }) => ({
    collapsed: menu.collapsed,
  }))(BaseMenu),
);
