import React, { ReactElement, useState, useEffect } from 'react';
import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { Loading } from '@arcflight/tf-component-library';
import { queryDocumentApproval } from '../../services/apiNew';
import iconResponse from '../../assets/icon-response.svg';
import documentApproved from '../../assets/email-tf-document-approved.svg';
import documentRejected from '../../assets/email-tf-document-rejected.svg';
import documentFeedback from '../../assets/email-tf-document-feedback.svg';
import logo from '../../assets/trustflight-logo-white.svg';
import bgOverlay from '../../assets/public-mx-bg-grid.svg';
import styles from './DocumentPage.module.less';

const DocumentPageApprovalState = ({ match }): ReactElement => {
  const { documentId } = match().params;
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    const getDocumentApproval = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await queryDocumentApproval(documentId);
        setDocument(res.data);
      } catch (err) {
        console.error(err);
        history.push('/public/exception/404');
      }
      setLoading(false);
    };
    getDocumentApproval();
  }, [documentId, history]);

  const renderStatusFeedback = (): any => {
    const { approved, awaitingFeedback } = document;
    let src = documentRejected;
    let alt = 'Document rejected';
    let text = 'has been rejected';
    if (awaitingFeedback) {
      src = documentFeedback;
      alt = 'document awaiting feedback';
      text = 'has been sent back to document issuer with the following feedback';
    }
    if (approved) {
      src = documentApproved;
      alt = 'document approved';
      text = 'has been approved';
    }
    return (
      <>
        <img src={src} className={styles.approval} alt={alt} />
        <h1>{`Document ${document.publicationDocumentType} ${document.publicationDocumentNumber} ${text}`}</h1>
      </>
    );
  };

  return (
    <div className={`${styles.backgroundWrapper} ${styles.backgroundWrapper__approval}`}>
      <img src={bgOverlay} className={styles.bgOverlay} alt="bg" />
      <div className={styles.padding}>
        <img src={logo} className={styles.logo} alt="bg" />
        <Card className={styles.document__card}>
          {loading ? <Loading loading={loading} contain /> : renderStatusFeedback()}

          <Card className={styles.document__card__internal}>
            {!loading && (
              <div className={styles.marginWrapper}>
                <h2>
                  <img src={iconResponse} className={styles.icon} alt="Response icon" /> Response
                </h2>
                <p>{document.remarks}</p>
              </div>
            )}
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default DocumentPageApprovalState;
