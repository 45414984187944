import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Card, Dropdown, Menu, Icon, Table, Badge, Tag } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import defaults from '../../utils/defaults';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import GraphRadioButton from '../Graphs/GraphRadioButton';
import SearchInput from '../SearchInput';
import config from '../../utils/config';
import AircraftEmptyState from '../../assets/empty-state-aircraft-search.svg';
import ModernPagination from '../ModernPagination';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import styles from './FleetTable.module.less';

const FleetTable = ({ aircraftMap, history }) => {
  const getAircraftStatus = (aircraft) => {
    if (!aircraft.status) {
      return 'Serviceable';
    }
    if (aircraft.status === 'Serviceable') {
      return 'Zero Deferrals';
    }
    if (aircraft.status === 'Overdue') {
      return 'Overdue';
    }
    if (aircraft.status === 'MEL/CDL') {
      return 'MEL/CDL';
    }
    if (aircraft.status === 'AOG') {
      return 'AOG';
    }
    return null;
  };

  const getStatusStyles = (percentage) => {
    if (percentage === 'disabled') {
      const strokeColor = '#f5f5f5';
      const textColor = null;
      return {
        strokeColor,
        textColor,
      };
    }

    // Red/Default Stroke Color
    const defaultStrokeColor = {
      '0%': '#ff7474',
      '100%': '#ff4040',
    };

    // Warning Stroke Color
    const warningStrokeColor = {
      '0%': '#fad288',
      '100%': '#f2a650',
    };

    // Green stroke Color
    const greenStrokeColor = {
      '0%': '#7bc88d',
      '100%': '#4aa91c',
    };

    let strokeColor = defaultStrokeColor;
    let textColor = styles.success;
    if (percentage > 25) {
      strokeColor = greenStrokeColor;
      textColor = styles.success;
    } else if (percentage <= 25 && percentage > 0) {
      strokeColor = warningStrokeColor;
      textColor = styles.warn;
    } else if (percentage <= 0) {
      strokeColor = defaultStrokeColor;
      textColor = styles.error;
    }

    return {
      strokeColor,
      textColor,
    };
  };
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [radioValue, setRadioValue] = useState('All');

  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredAircraft, setFilteredAircraft] = useState();
  const intl = useIntl();
  const aircraft = Array.from(aircraftMap.values());
  const aircraftTypes = aircraft
    .map((item) => item.model)
    .filter((value, index, self) => self.indexOf(value) === index);
  aircraftTypes.unshift('All Aircraft');
  const [filterValue, setFilterValue] = useState(aircraftTypes[0]);
  const [dataMessage, setDataMessage] = useState(intl.formatMessage({ id: 'text.noData' }));

  const status = {
    open: {
      badge: 'warning',
    },
    Serviceable: {
      badge: 'success',
    },
    'Zero Deferrals': {
      badge: 'success',
    },
    Unserviceable: {
      badge: 'error',
    },
    Critical: {
      badge: 'warning',
    },
    draft: {
      badge: 'default',
    },
    error: {
      badge: 'error',
    },
    AOG: {
      badge: 'error',
    },
    Overdue: {
      badge: 'warning',
    },
    'MEL/CDL': {
      badge: 'warning',
    },
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousRadioValue = usePrevious(radioValue);
  const previousFilterValue = usePrevious(filterValue);
  const previousSearchValue = usePrevious(searchValue);

  useEffect(() => {
    setFilteredAircraft(null);
  }, [aircraftMap]);

  useEffect(() => {
    if (aircraft.length > 0 && !filteredAircraft) {
      const sortedAircraft = aircraft.sort((a, b) => {
        if (a.status === 'AOG') return -1;
        if (a.status === 'MEL/CDL' && b.status === 'Overdue') return -1;
        if (a.status === 'MEL/CDL' && b.status === 'Serviceable') return -1;
        if (a.status === 'Overdue' && b.status === 'Serviceable') return -1;
        return 0;
      });
      setFilteredAircraft(sortedAircraft);
      setDataMessage(intl.formatMessage({ id: 'text.noSearchData' }));
    }
    const filterChanged = filterValue !== previousFilterValue;
    const radioChanged = radioValue !== previousRadioValue;
    const searchChanged = searchValue !== previousSearchValue;
    if (filteredAircraft && (filterChanged || radioChanged || searchChanged)) {
      let newFilter = aircraft;
      if (filterValue !== 'All Aircraft') {
        newFilter = newFilter.filter((a) => {
          return filterValue === a.model;
        });
      }
      if (radioValue !== 'All') {
        newFilter = newFilter.filter((a) => {
          return radioValue === getAircraftStatus(a);
        });
      }
      if (searchValue) {
        newFilter = newFilter.filter((a) => {
          return a.registration.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      setFilteredAircraft(newFilter);
    }
  }, [
    radioValue,
    filterValue,
    filteredAircraft,
    aircraft,
    searchValue,
    previousFilterValue,
    previousRadioValue,
    previousSearchValue,
    intl,
  ]);

  const format = (item) => {
    let itemPercentage = (item.remaining * 25) / item.threshold;
    let itemProgressStyles = getStatusStyles(itemPercentage);
    let itemRemainingDisplay = item.remaining;
    let displayUnitSingular = item.unitSingular;
    let displayUnitPlural = item.unitPlural;

    if (item.remaining <= 0) {
      itemPercentage = 100;
      itemProgressStyles = getStatusStyles(0);
      itemRemainingDisplay = 0;
    }
    if (item.format === 'seconds') {
      itemRemainingDisplay = Math.round(itemRemainingDisplay / 3600);
    }
    if (item.format === 'days' && itemRemainingDisplay > 30) {
      // Switch days to months
      itemRemainingDisplay = Math.round(itemRemainingDisplay / 30);
      displayUnitSingular = intl.formatMessage({ id: 'units.monthLeftShort' });
      displayUnitPlural = intl.formatMessage({ id: 'units.monthsLeftShort' });
    }
    return {
      displayUnitSingular,
      displayUnitPlural,
      itemRemainingDisplay,
      itemProgressStyles,
    };
  };

  const formatDaysRemaining = (item) => {
    let itemProgressStyles = getStatusStyles(item.remaining > 6 ? 100 : 10);
    let days = null;
    let hours = null;
    let minutes = null;
    if (item.remaining <= 0) {
      itemProgressStyles = getStatusStyles(0);
      days = 0;
    } else {
      const isAnHourAccurateCheck = item.remaining % 24 > 0;
      const hasOver24HoursLeft = item.remaining / 24 > 1;
      const hasLessThan1HourLeft = item.remaining < 1 && item.remaining > 0;
      if (!isAnHourAccurateCheck) {
        days = item.remaining / 24;
      } else if (hasOver24HoursLeft) {
        days = Math.floor(item.remaining / 24);
        const hasLessThanOneHour = item.remaining % 24 < 1 && item.remaining % 24 > 0;
        hours = !hasLessThanOneHour ? Math.floor(item.remaining % 24) : '';
      } else if (!hasLessThan1HourLeft) {
        hours = item.remaining;
      } else {
        minutes = item.remaining * 60;
      }
    }

    return {
      days,
      hours,
      minutes,
      itemProgressStyles,
    };
  };
  const columns = [
    {
      title: 'Registration',
      dataIndex: 'registration',
      className: styles.fleetTableHeader,
      render(val, record) {
        return (
          <div>
            <Badge status={status[getAircraftStatus(record)].badge} />
            <span className={styles.regText}>{`${val} `}</span>
            <span>{`(${record.model})`}</span>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'aircraftImgUrl',
      className: styles.fleetTableHeader,
      render(val, record) {
        return (
          <div
            className={styles.aircraftImg}
            style={{ backgroundImage: `url(${config.get('api')}${val})` }}
            alt={record.registration}
          />
        );
      },
    },
    {
      title: 'Hours Remaining',
      dataIndex: 'utilisationRemaining.flightHoursRemaining',
      className: styles.fleetTableHeader,
      render(val, record) {
        const hoursItem = {
          remaining: val,
          threshold: record.mx_thresholds
            ? record.mx_thresholds.flight_seconds
            : defaults.defaultMXThresholds.flight_seconds,
          format: 'seconds',
          unitSingular: intl.formatMessage({ id: 'units.hour' }),
          unitPlural: intl.formatMessage({ id: 'units.hours' }),
        };
        const formattedStatus = format(hoursItem);
        return (
          <span>
            <span className={styles.success}>{`${Math.round(val)} `}</span>
            <span className={styles.statusText}>{val === 1 ? hoursItem.unitSingular : hoursItem.unitPlural}</span>
          </span>
        );
      },
    },
    {
      title: 'Cycles Remaining',
      dataIndex: 'utilisationRemaining.flightCyclesRemaining',
      className: styles.fleetTableHeader,
      render(val, record) {
        const cyclesItem = {
          remaining: val,
          threshold: record.mx_thresholds ? record.mx_thresholds.cycles : defaults.defaultMXThresholds.cycles,
          format: 'number',
          unitSingular: intl.formatMessage({ id: 'units.cycle' }),
          unitPlural: intl.formatMessage({ id: 'units.cycles' }),
        };
        const formattedStatus = format(cyclesItem);
        return (
          <span>
            <span className={formattedStatus.itemProgressStyles.textColor}>
              {`${formattedStatus.itemRemainingDisplay} `}
            </span>
            <span className={styles.statusText}>
              {formattedStatus.itemRemainingDisplay === 1
                ? formattedStatus.displayUnitSingular
                : formattedStatus.displayUnitPlural}
            </span>
          </span>
        );
      },
    },
    {
      title: 'Days Remaining',
      dataIndex: 'utilisationRemaining.aircraftDaysRemaining',
      className: styles.fleetTableHeader,
      render(val) {
        const daysItem = {
          remaining: val,
          threshold: 6,
          format: 'days',
          unitSingular: intl.formatMessage({ id: 'units.day' }),
          unitPlural: intl.formatMessage({ id: 'units.days' }),
        };
        const formattedStatus = formatDaysRemaining(daysItem);
        return (
          <span>
            <span className={formattedStatus.itemProgressStyles.textColor}>
              {formattedStatus.days !== null && `${Math.floor(formattedStatus.days)} `}
            </span>
            {formattedStatus.days !== null && (
              <span className={styles.statusText}>
                {formattedStatus.days === 1
                  ? intl.formatMessage({ id: 'units.day' })
                  : intl.formatMessage({ id: 'units.days' })}
              </span>
            )}
            {formattedStatus.hours && (
              <span>
                <span className={formattedStatus.itemProgressStyles.textColor}>
                  {` ${Math.floor(formattedStatus.hours)} `}
                </span>
                <span className={styles.statusText}>
                  {parseInt(formattedStatus.hours, 10) === 1
                    ? intl.formatMessage({ id: 'units.hour' })
                    : intl.formatMessage({ id: 'units.hours' })}
                </span>
              </span>
            )}
            {formattedStatus.minutes && (
              <span>
                <span className={formattedStatus.itemProgressStyles.textColor}>
                  {` ${Math.floor(formattedStatus.minutes)} `}
                </span>
                <span className={styles.statusText}>
                  {parseInt(formattedStatus.minutes, 10) === 1
                    ? intl.formatMessage({ id: 'units.minute' })
                    : intl.formatMessage({ id: 'units.minutes' })}
                </span>
              </span>
            )}
          </span>
        );
      },
    },
    {
      title: 'Defects Raised',
      dataIndex: 'defectCount',
      className: styles.fleetTableHeader,
      render(val) {
        return (
          <span>
            <span className={val > 0 ? styles.error : styles.success}>{val}</span>
            <span className={styles.statusText}>{' defects'}</span>
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: styles.fleetTableHeader,
      render(val) {
        return (
          <span>
            {val === 'Serviceable' && <Tag color="green">{intl.formatMessage({ id: 'status.zeroDeferrals' })}</Tag>}
            {val === 'MEL/CDL' && <Tag color="orange">{intl.formatMessage({ id: 'status.openDefect' })}</Tag>}
            {val === 'Overdue' && <Tag color="orange">{intl.formatMessage({ id: 'status.overdue' })}</Tag>}
            {val === 'AOG' && <Tag color="red">{intl.formatMessage({ id: 'status.onGround' })}</Tag>}
          </span>
        );
      },
    },
  ];

  let filteredAircraftSubset =
    filteredAircraft && filteredAircraft.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  if (filteredAircraftSubset && filteredAircraftSubset.length === 0) filteredAircraftSubset = filteredAircraft;
  return (
    <Card className={cardStyles.pageCard} bordered={false}>
      <div className={styles.listFilterRow}>
        <SearchInput
          placeholder={intl.formatMessage({ id: 'form.placeholder.searchByAircraftReg' })}
          value={searchValue}
          visible={searchVisible}
          onToggle={() => setSearchVisible(!searchVisible)}
          onChange={(value) => setSearchValue(value)}
          handleBlur={(value) => {
            if (!value) {
              setSearchVisible(false);
            }
          }}
          data-test="searchInput"
          onClear={() => setSearchValue(null)}
          inputRef="fleetTable"
        />
        <div className={styles.filterContainer}>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={(key) => setFilterValue(key.key)}>
                {aircraftTypes.map((type) => {
                  return <Menu.Item key={`${type}`}>{type}</Menu.Item>;
                })}
              </Menu>
            }
          >
            <span className={styles.dropdownLink}>
              {intl.formatMessage({ id: 'form.labels.aircraftType' })}:<span>{filterValue}</span>{' '}
              <Icon type="caret-down" />
            </span>
          </Dropdown>
          <GraphRadioButton
            buttons={['All', 'Zero Deferrals', 'Overdue', 'MEL/CDL', 'AOG']}
            handleRadioSelection={(selection) => setRadioValue(selection)}
            initialSelection={radioValue}
          />
        </div>
      </div>
      <div className={styles.fleetTableContainer}>
        {!filteredAircraft || filteredAircraft.length === 0 ? (
          <div className={tableStyles.emptyStateWrapper}>
            <img src={AircraftEmptyState} alt="no data" className={tableStyles.emptyState} />
            <span>{dataMessage}</span>
          </div>
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={filteredAircraftSubset}
              bordered={false}
              rowClassName={styles.fleetTableRow}
              rowKey="id"
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => history.push(`/camo/aircraft/${record.id}/overview`),
                };
              }}
            />
            {filteredAircraft.length >= pageSize && (
              <ModernPagination
                key="modernPagination"
                pageSize={pageSize}
                current={currentPage}
                total={filteredAircraft && filteredAircraft.length}
                onPageNoChange={(page) => setCurrentPage(page)}
                onPageSizeChange={(size) => setPageSize(size)}
                data-test="modernPagination"
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

FleetTable.propTypes = {
  history: PropTypes.object.isRequired,
  aircraftMap: PropTypes.instanceOf(Map),
};

FleetTable.defaultProps = {
  aircraftMap: new Map(),
};

const fleetTableWithRedux = connect(({ aircraft }) => ({
  aircraftMap: aircraft.aircraftMap,
  lastFetched: aircraft.lastFetched,
}))(FleetTable);
export default compose(withRouter)(fleetTableWithRedux);
