const initialState: ReliabilityReportsState = {
  fleet: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraft: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraftType: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  ttl: 60000,
};

export enum ReliabilityReportsActionTypes {
  FLEETDELAYS = 'reliabilityReports/fleetDelays',
  FLEETAMP = 'reliabilityReports/fleetAMP',
  FLEETUTILISATION = 'reliabilityReports/fleetUtilisation',
  AIRCRAFTDELAYS = 'reliabilityReports/aircraftDelays',
  AIRCRAFTAMP = 'reliabilityReports/aircraftAMP',
  AIRCRAFTUTILISATION = 'reliabilityReports/aircraftUtilisation',
  AIRCRAFTTYPEDELAYS = 'reliabilityReports/aircraftTypeDelays',
  AIRCRAFTTYPEAMP = 'reliabilityReports/aircraftTypeAMP',
  AIRCRAFTTYPEUTILISATION = 'reliabilityReports/aircraftTypeUtilisation',
}

interface SaveFleetDelays {
  type: ReliabilityReportsActionTypes.FLEETDELAYS;
  payload: any;
}

interface SaveFleetAMP {
  type: ReliabilityReportsActionTypes.FLEETAMP;
  payload: any;
}

interface SaveFleetUtilisation {
  type: ReliabilityReportsActionTypes.FLEETUTILISATION;
  payload: any;
}

interface SaveAircraftDelays {
  type: ReliabilityReportsActionTypes.AIRCRAFTDELAYS;
  payload: any;
}

interface SaveAircraftAMP {
  type: ReliabilityReportsActionTypes.AIRCRAFTAMP;
  payload: any;
}

interface SaveAircraftUtilisation {
  type: ReliabilityReportsActionTypes.AIRCRAFTUTILISATION;
  payload: any;
}

interface SaveAircraftTypeDelays {
  type: ReliabilityReportsActionTypes.AIRCRAFTTYPEDELAYS;
  payload: any;
}

interface SaveAircraftTypeAMP {
  type: ReliabilityReportsActionTypes.AIRCRAFTTYPEAMP;
  payload: any;
}

interface SaveAircraftTypeUtilisation {
  type: ReliabilityReportsActionTypes.AIRCRAFTTYPEUTILISATION;
  payload: any;
}

type ReliabilityReportsAction =
  | SaveFleetDelays
  | SaveFleetAMP
  | SaveFleetUtilisation
  | SaveAircraftDelays
  | SaveAircraftAMP
  | SaveAircraftUtilisation
  | SaveAircraftTypeDelays
  | SaveAircraftTypeAMP
  | SaveAircraftTypeUtilisation;

export default function(state = initialState, action: ReliabilityReportsAction): ReliabilityReportsState {
  switch (action.type) {
    case ReliabilityReportsActionTypes.FLEETDELAYS: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('delays', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.FLEETAMP: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('AMP', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.FLEETUTILISATION: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('utilisation', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.AIRCRAFTDELAYS: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('delays', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.AIRCRAFTAMP: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('AMP', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.AIRCRAFTUTILISATION: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('utilisation', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.AIRCRAFTTYPEDELAYS: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('delays', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.AIRCRAFTTYPEAMP: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('AMP', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case ReliabilityReportsActionTypes.AIRCRAFTTYPEUTILISATION: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('utilisation', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    default:
      return state;
  }
}

export interface ReliabilityReportsState {
  fleet: FleetState;
  aircraft: AircraftState;
  aircraftType: AircraftTypeState;
  ttl: number;
}

export interface FleetState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftTypeState {
  dataMap: Map<string, any>;
  lastFetched: number;
}
