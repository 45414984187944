const initialState: EngineReportsState = {
  fleet: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraft: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraftType: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  ttl: 60000,
};

export enum EngineReportsActionTypes {
  FLEETENGINES = 'engineReports/fleetEngines',
  FLEETOIL = 'engineReports/fleetOil',
  AIRCRAFTENGINES = 'engineReports/aircraftEngines',
  AIRCRAFTOIL = 'engineReports/aircraftOil',
  AIRCRAFTTYPEENGINES = 'engineReports/aircraftTypeEngines',
  AIRCRAFTTYPEOIL = 'engineReports/aircraftTypeOil',
  FLEETUNSCHEDULEDENGINEREMOVALS = 'engineReports/fleetUnscheduledEngineRemovals',
  AIRCRAFTUNSCHEDULEDENGINEREMOVALS = 'engineReports/aircraftUnscheduledEngineRemovals',
  AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS = 'engineReports/aircraftTypeUnscheduledEngineRemovals',
}

interface SaveFleetEngines {
  type: EngineReportsActionTypes.FLEETENGINES;
  payload: any;
}

interface SaveFleetOil {
  type: EngineReportsActionTypes.FLEETOIL;
  payload: any;
}

interface SaveAircraftEngines {
  type: EngineReportsActionTypes.AIRCRAFTENGINES;
  payload: any;
}

interface SaveAircraftOil {
  type: EngineReportsActionTypes.AIRCRAFTOIL;
  payload: any;
}

interface SaveAircraftTypeEngines {
  type: EngineReportsActionTypes.AIRCRAFTTYPEENGINES;
  payload: any;
}

interface SaveAircraftTypeOil {
  type: EngineReportsActionTypes.AIRCRAFTTYPEOIL;
  payload: any;
}

interface SaveFleetUnscheduledEngineRemovals {
  type: EngineReportsActionTypes.FLEETUNSCHEDULEDENGINEREMOVALS;
  payload: any;
}

interface SaveAircraftUnscheduledEngineRemovals {
  type: EngineReportsActionTypes.AIRCRAFTUNSCHEDULEDENGINEREMOVALS;
  payload: any;
}

interface SaveAircraftTypeUnscheduledEngineRemovals {
  type: EngineReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS;
  payload: any;
}

type engineReportsAction =
  | SaveFleetEngines
  | SaveFleetOil
  | SaveAircraftEngines
  | SaveAircraftOil
  | SaveAircraftTypeEngines
  | SaveAircraftTypeOil
  | SaveFleetUnscheduledEngineRemovals
  | SaveAircraftUnscheduledEngineRemovals
  | SaveAircraftTypeUnscheduledEngineRemovals;

export default function(state = initialState, action: engineReportsAction): EngineReportsState {
  switch (action.type) {
    case EngineReportsActionTypes.FLEETENGINES: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('engines', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.FLEETOIL: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('oil', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.AIRCRAFTENGINES: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('engines', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.AIRCRAFTOIL: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('oil', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.AIRCRAFTTYPEENGINES: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('engines', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.AIRCRAFTTYPEOIL: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('oil', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.FLEETUNSCHEDULEDENGINEREMOVALS: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('unscheduledEngineRemovals', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.AIRCRAFTUNSCHEDULEDENGINEREMOVALS: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      dataMap.set('unscheduledEngineRemovals', action.payload);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case EngineReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      dataMap.set('unscheduledEngineRemovals', action.payload);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    default:
      return state;
  }
}

export interface EngineReportsState {
  fleet: FleetState;
  aircraft: AircraftState;
  aircraftType: AircraftTypeState;
  ttl: number;
}

export interface FleetState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftTypeState {
  dataMap: Map<string, any>;
  lastFetched: number;
}
