import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import servers from '../../utils/servers';
import styles from './AircraftDetails.module.less';

const AircraftDetails = ({ aircraft, intl: { formatMessage } }) => {
  const checkLength = aircraft ? Number(aircraft.designator.length) : 0;

  return aircraft ? (
    <div className={styles.parentAircraftDetails}>
      <div
        className={styles.aircraftImg}
        style={{
          backgroundImage: `url(${servers.api}${aircraft.aircraftImgUrl})`,
        }}
        alt={aircraft.registration}
        data-test="aircraftSilhouette"
      />
      <div className={styles.aircraftText}>
        <div className={styles.aircraftReg} data-test="aircraftRegistration">
          {aircraft.registration || '-'}
        </div>
        <div className={styles.aircraftType}>
          <span data-test="aircraftModel">{aircraft.description || '-'}</span>
          <span data-test="aircraftSerialNo">
            {aircraft.registration ? `${checkLength > 20 ? `\n` : ` | `}${aircraft.registration}` : '-'}
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.loading}>{formatMessage({ id: 'text.loading' })}</div>
  );
};

AircraftDetails.propTypes = {
  aircraft: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AircraftDetails);
