import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';
import styles from './SummaryPieChart.module.less';
import LegendItem from './LegendItem';

const data = [
  { name: 'Resolved', id: 'resolved', value: 7, gradient: 'linear-gradient(to bottom, #024574, #012140)' },
  {
    name: 'Awaiting Response',
    id: 'awaitingResponse',
    value: 5,
    gradient: 'linear-gradient(to bottom, #72c9ff, #3f98ff)',
  },
  { name: 'Open', id: 'open', value: 10, gradient: 'linear-gradient(to bottom, #b1f2ec, #79e0d4)' },
  { name: 'Other Status', id: 'otherStatus', value: 3, gradient: 'linear-gradient(to bottom, #D7EBFE, #B5C5D6)' },
];

const SummaryPieChart = () => {
  return (
    <>
      <PieChart width={180} height={200} className={styles.pieOuter}>
        <foreignObject x={28} y={38} className={styles.foreignObject}>
          <div className={styles.dropCircle} />
        </foreignObject>
        <defs>
          <linearGradient id="awaitingResponse" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#72c9ff" />
            <stop offset="100%" stopColor="#3f98ff" />
          </linearGradient>
          <linearGradient id="resolved" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#024574" />
            <stop offset="100%" stopColor="#012140" />
          </linearGradient>
          <linearGradient id="open" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#b1f2ec" />
            <stop offset="100%" stopColor="#79e0d4" />
          </linearGradient>
          <linearGradient id="otherStatus" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#D7EBFE" />
            <stop offset="100%" stopColor="#B5C5D6" />
          </linearGradient>
        </defs>

        <text x={90} y={90} className={`${styles.text} ${styles.bold}`} textAnchor="middle" dominantBaseline="middle">
          150
        </text>
        <text x={90} y={110} className={styles.text} textAnchor="middle" dominantBaseline="middle">
          tickets
        </text>
        <Pie
          className={styles.pieInner}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={78}
          outerRadius={85}
          animationBegin={200}
        >
          {data.map((entry, index) => (
            <Cell key={data[index].name} fill={`url(#${data[index].id})`} />
          ))}
        </Pie>
      </PieChart>
      <div className={styles.legendWrapper}>
        {data.map((dataObject) => (
          <LegendItem key={dataObject.name} name={dataObject.name} colour={dataObject.gradient} />
        ))}
      </div>
    </>
  );
};

export default SummaryPieChart;
