const initialState: ReliabilityReportsAircraftState = {
  ttl: 60000,
  registrations: [],
  types: [],
};

export enum ReliabilityReportsAircraftActionTypes {
  AIRCRAFT = 'reliabilityReportsAircraft/aircraft',
  AIRCRAFTTYPE = 'reliabilityReportsAircraft/aircraftType',
  REGISTRATIONS = 'reliabilityReportsAircraft/registrations',
}

interface SaveAircraftData {
  type: ReliabilityReportsAircraftActionTypes.AIRCRAFT;
  payload: any;
}

interface SaveAircraftTypeData {
  type: ReliabilityReportsAircraftActionTypes.AIRCRAFTTYPE;
  payload: any;
}

interface SaveRegistrations {
  type: ReliabilityReportsAircraftActionTypes.REGISTRATIONS;
  payload: any;
}

type ReliabilityReportsAircraftAction = SaveAircraftData | SaveAircraftTypeData | SaveRegistrations;

export default function(
  state = initialState,
  action: ReliabilityReportsAircraftAction,
): ReliabilityReportsAircraftState {
  switch (action.type) {
    case ReliabilityReportsAircraftActionTypes.AIRCRAFTTYPE: {
      return {
        ...state,
        types: action.payload,
      };
    }
    case ReliabilityReportsAircraftActionTypes.REGISTRATIONS: {
      return {
        ...state,
        registrations: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface ReliabilityReportsAircraftState {
  ttl: number;
  types: Array<string[]>;
  registrations: Array<string[]>;
}
