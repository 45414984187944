import React, { useState, useEffect } from 'react';
import { useIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import { Table, Spin } from 'antd';
import { queryWorkpacks } from '../../services/apiNew';
import { AircraftActionTypes } from '../../models/aircraft';
import SearchInput from '../SearchInput';
import ModernPagination from '../ModernPagination';
import TableTitle from '../TableTitle/TableTitle';

import WorkpackEmptyState from '../../assets/empty-state-workpacks.svg';
import defaults from '../../utils/defaults';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import styles from './WorkpackStatusTable.module.less';

const WorkpackStatusTable = ({ aircraft, userSettings, updateAircraft, aircraftMap, multiAircraft }) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [multiWorkpacks, setMultiWorkpacks] = useState([]);
  const [filteredWorkpacks, setFilteredWorkpacks] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataMessage, setDataMessage] = useState('');
  const {
    params: { id },
  } = useRouteMatch();

  const [currentColumn, setCurrentColumn] = useState('');
  const [currentDirection, setCurrentDirection] = useState('');

  useEffect(() => {
    const getWorkpacks = async () => {
      setLoading(true);
      const res = await queryWorkpacks(id);
      updateAircraft(res.data.data.aircraft);
      setLoading(false);
    };
    if (!multiAircraft && aircraft && !aircraft.workpacks) {
      getWorkpacks();
    }
    if (!multiAircraft && aircraft && aircraft.workpacks) {
      setFilteredWorkpacks(aircraft.workpacks);
      setDataMessage(
        aircraft.workpacks.length > 0
          ? formatMessage({ id: 'text.noSearchData' })
          : formatMessage({ id: 'text.noData' }),
      );
    }
  }, [aircraft, id, updateAircraft, multiAircraft, formatMessage]);

  useEffect(() => {
    let workpacksArray = [];
    if (multiAircraft && aircraftMap) {
      aircraftMap.forEach((plane) => {
        const workpacks = plane.workpacks
          ? plane.workpacks.map((workpack) => ({ ...workpack, registration: plane.registration }))
          : [];
        workpacksArray = [...workpacksArray, ...workpacks];
      });
      setMultiWorkpacks(workpacksArray);
      setFilteredWorkpacks(workpacksArray);
      setDataMessage(
        workpacksArray.length > 0 ? formatMessage({ id: 'text.noSearchData' }) : formatMessage({ id: 'text.noData' }),
      );
    }
  }, [multiAircraft, aircraftMap, formatMessage]);

  const getStatus = (workpack) => {
    const plannedEndDate = moment(workpack.planned_end_date, 'MMM DD YYYY').add(workpack.planned_end_time, 'minute');
    const plannedStartDate = moment(workpack.planned_start_date, 'MMM DD YYYY').add(
      workpack.planned_start_time,
      'minute',
    );
    if (workpack.closing_date) {
      return 'closed';
    }
    if (plannedEndDate.isBefore()) {
      return 'overdue';
    }
    if (plannedStartDate.isBefore()) {
      return 'maintenance';
    }
    return 'scheduled';
  };

  const status = {
    closed: {
      text: formatMessage({ id: 'status.closed' }),
      colour: 'linear-gradient(to bottom, #2fc595, #228d6b)',
      no: 0,
    },
    maintenance: {
      text: formatMessage({ id: 'status.inMaintenance' }),
      colour: 'linear-gradient(to bottom, #ffa174, #ffbc40)',
      no: 2,
    },
    scheduled: {
      text: formatMessage({ id: 'status.scheduled' }),
      colour: 'linear-gradient(to bottom, #72c9ff, #3f98ff)',
      no: 1,
    },
    overdue: {
      text: formatMessage({ id: 'status.overdue' }),
      colour: 'linear-gradient(to bottom, #ff7474, #ff4040)',
      no: 3,
    },
  };

  const sortData = (value, sortFunctionType) => {
    let direction;

    const getStatusOrder = (workpack) => {
      if (workpack.closing_date) {
        return 4;
      }
      if (moment(workpack.planned_end_date, 'MMM DD YYYY').isBefore()) {
        return 3;
      }
      if (moment(workpack.planned_start_date, 'MMM DD YYYY').isBefore()) {
        return 2;
      }
      return 1;
    };

    const compareNumber = (a, b) => {
      if (value === 'status') {
        if (direction === 'descending') {
          return getStatusOrder(a) - getStatusOrder(b);
        }
        return getStatusOrder(b) - getStatusOrder(a);
      }
      if (direction === 'descending') {
        return a[value] - b[value];
      }
      return b[value] - a[value];
    };

    const compareString = (a, b) => {
      if (direction === 'descending') {
        const bText = b[value].toUpperCase();
        const aText = a[value].toUpperCase();
        if (bText < aText) {
          return -1;
        }
        if (bText > aText) {
          return 1;
        }

        return 0;
      }

      const aText = a[value].toUpperCase();
      const bText = b[value].toUpperCase();
      if (aText < bText) {
        return -1;
      }
      if (aText > bText) {
        return 1;
      }

      return 0;
    };

    const compareTime = (a, b) => {
      if (direction === 'descending') {
        return moment(a[value], 'MMM DD YYYY').unix() - moment(b[value], 'MMM DD YYYY').unix();
      }
      return moment(b[value], 'MMM DD YYYY').unix() - moment(a[value], 'MMM DD YYYY').unix();
    };

    const filteredWorkpacksClone = [...filteredWorkpacks];
    if (currentColumn !== value) {
      setCurrentColumn(value);
      setCurrentDirection('ascending');
      direction = 'ascending';
      if (sortFunctionType === 'string') {
        filteredWorkpacksClone.sort(compareString);
      } else if (sortFunctionType === 'time') {
        filteredWorkpacksClone.sort(compareTime);
      } else {
        filteredWorkpacksClone.sort(compareNumber);
      }
    } else if (currentColumn === value && currentDirection === 'ascending') {
      setCurrentDirection('descending');
      direction = 'descending';
      if (sortFunctionType === 'string') {
        filteredWorkpacksClone.sort(compareString);
      } else if (sortFunctionType === 'time') {
        filteredWorkpacksClone.sort(compareTime);
      } else {
        filteredWorkpacksClone.sort(compareNumber);
      }
    } else if (currentColumn === value && currentDirection === 'descending') {
      setCurrentColumn('');
      setCurrentDirection('');

      filteredWorkpacksClone.sort((a, b) => {
        return a.id - b.id;
      });
    }

    setFilteredWorkpacks([...filteredWorkpacksClone]);
  };

  const columns = [
    {
      title: (props) => (
        <TableTitle
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          props={props}
          title={formatMessage({ id: 'title.status' })}
          dataIndex="status"
        />
      ),
      dataIndex: 'status',
      width: 140,
      render(val, record) {
        return (
          <div>
            <span className={tableStyles.statusIndicator} style={{ background: status[getStatus(record)].colour }} />
            <span className={tableStyles.statusText}>{status[getStatus(record)].text}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          props={props}
          title={formatMessage({ id: 'title.workpackTitle' })}
          dataIndex="description"
        />
      ),
      dataIndex: 'description',
      sortName: 'description',
      width: 160,
      render(val) {
        return (
          <div>
            {val.includes('CWR') ? (
              <span className={`${tableStyles.statusText}`}>{val}</span>
            ) : (
              <span className={`${tableStyles.statusText} ${tableStyles.capitalise}`}>{val.toLowerCase()}</span>
            )}
          </div>
        );
      },
    },
    ...(multiAircraft
      ? [
          {
            title: (props) => (
              <TableTitle
                sortData={sortData}
                sortOrdered={currentDirection}
                currentColumn={currentColumn}
                dataType="string"
                props={props}
                title={formatMessage({ id: 'title.registration' })}
                dataIndex="registration"
              />
            ),
            dataIndex: 'registration',
            sortName: 'registration',
            width: 120,
            render(val) {
              return (
                <div>
                  <span className={`${tableStyles.statusText}`}>{val}</span>
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: (props) => (
        <TableTitle
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          props={props}
          title={formatMessage({ id: 'title.workpackReference' })}
          dataIndex="workpack"
        />
      ),
      dataIndex: 'workpack',
      sortName: 'workpack',
      width: 200,
      render(val) {
        return (
          <div>
            <span className={tableStyles.numberText}>{val}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="time"
          props={props}
          title={formatMessage({ id: 'title.dateIssued' })}
          dataIndex="created_date"
        />
      ),
      dataIndex: 'created_date',
      width: 120,
      render(val) {
        return userSettings && userSettings.dateFormat
          ? moment(val, 'MMM DD YYYY').format(userSettings.dateFormat)
          : moment(val, 'MMM DD YYYY').format(defaults.defaultDateFormat);
      },
    },
    {
      title: (props) => (
        <TableTitle
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="time"
          props={props}
          title={formatMessage({ id: 'title.scheduledWorkDate' })}
          dataIndex="planned_start_date"
        />
      ),
      dataIndex: 'planned_start_date',
      width: 120,
      render(val) {
        return userSettings && userSettings.dateFormat
          ? moment(val, 'MMM DD YYYY').format(userSettings.dateFormat)
          : moment(val, 'MMM DD YYYY').format(defaults.defaultDateFormat);
      },
    },
    {
      title: (props) => (
        <TableTitle
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="time"
          props={props}
          title={formatMessage({ id: 'title.dateClosed' })}
          dataIndex="closing_date"
        />
      ),
      dataIndex: 'closing_date',
      width: 120,
      render(val) {
        if (val) {
          return userSettings && userSettings.dateFormat
            ? moment(val, 'MMM DD YYYY').format(userSettings.dateFormat)
            : moment(val, 'MMM DD YYYY').format(defaults.defaultDateFormat);
        }
        return '-';
      },
    },
  ];

  const filterWorkpacks = (filterValue, multi) => {
    let workpackRef = [];
    let workpackDescription = [];
    if (multi) {
      workpackRef = multiWorkpacks.filter((workpack) => workpack.workpack.indexOf(filterValue.toUpperCase()) > -1);
      workpackDescription = multiWorkpacks.filter(
        (workpack) => workpack.description.indexOf(filterValue.toUpperCase()) > -1,
      );
    } else {
      workpackRef = aircraft.workpacks.filter((workpack) => workpack.workpack.indexOf(filterValue.toUpperCase()) > -1);
      workpackDescription = aircraft.workpacks.filter(
        (workpack) => workpack.description.indexOf(filterValue.toUpperCase()) > -1,
      );
    }
    const filtered = Array.from(new Set(workpackRef.concat(workpackDescription)));
    setFilteredWorkpacks(filtered);
  };

  let filteredWorkpacksSubset =
    filteredWorkpacks && filteredWorkpacks.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  if (filteredWorkpacksSubset && filteredWorkpacksSubset.length === 0) filteredWorkpacksSubset = filteredWorkpacks;

  const showSearchInput = (aircraft && aircraft.workpacks) || (multiAircraft && multiWorkpacks.length > 0);

  return (
    <>
      <div className={styles.listFilterRow}>
        {showSearchInput && (
          <SearchInput
            placeholder={formatMessage({ id: 'form.placeholder.searchMX' })}
            value={searchValue}
            visible={searchVisible}
            onBlur={(value) => {
              if (!value) {
                setSearchVisible(false);
              }
            }}
            onToggle={() => {
              setSearchVisible(!searchVisible);
            }}
            onChange={(value) => {
              setSearchValue(value);
              if (!value) {
                if (multiAircraft) {
                  setFilteredWorkpacks(multiWorkpacks);
                } else {
                  setFilteredWorkpacks(aircraft.workpacks);
                }
              } else {
                filterWorkpacks(value, multiAircraft);
              }
            }}
            data-test="searchInput"
            onClear={() => {
              setSearchValue(null);
              if (multiAircraft) {
                setFilteredWorkpacks(multiWorkpacks);
              } else {
                setFilteredWorkpacks(aircraft.workpacks);
              }
            }}
            inputRef="workpack"
          />
        )}
      </div>
      <div className={tableStyles.tableContainer}>
        {loading ? (
          <div className={styles.loadingDiv}>
            <Spin size="small" />
          </div>
        ) : (
          <>
            {filteredWorkpacks.length === 0 ? (
              <div className={tableStyles.emptyStateWrapper}>
                <img src={WorkpackEmptyState} alt="no data" className={tableStyles.emptyState} />
                <span>{dataMessage}</span>
              </div>
            ) : (
              <Table
                rowKey="id"
                columns={columns}
                dataSource={filteredWorkpacksSubset}
                bordered={false}
                rowClassName={tableStyles.tableRow__noLink}
                pagination={false}
                scroll={{ x: 915 }}
              />
            )}
            {filteredWorkpacks.length >= pageSize && (
              <ModernPagination
                key="modernPagination"
                pageSize={pageSize}
                current={currentPage}
                total={filteredWorkpacks && filteredWorkpacks.length}
                onPageNoChange={(page) => setCurrentPage(page)}
                onPageSizeChange={(size) => setPageSize(size)}
                data-test="modernPagination"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

WorkpackStatusTable.propTypes = {
  aircraft: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
  updateAircraft: PropTypes.func.isRequired,
  aircraftMap: PropTypes.instanceOf(Map),
  multiAircraft: PropTypes.bool,
};

WorkpackStatusTable.defaultProps = {
  aircraftMap: new Map(),
  multiAircraft: false,
};

export default compose(
  withRouter,
  injectIntl,
  connect(({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  })),
  connect(
    ({ userSettings }) => ({
      userSettings,
    }),
    (dispatch) => ({
      updateAircraft: async (payload) =>
        dispatch({
          type: AircraftActionTypes.UPDATE,
          payload,
        }),
    }),
  ),
)(WorkpackStatusTable);
