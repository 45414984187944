import { Button, Col, Row } from 'antd';
import React, { ReactElement } from 'react';
import moment from 'moment';
import chevron from '../../assets/icon-chevron-blue.svg';
import styles from './ConversationCard.module.less';
import DisplayAttachments from './DisplayAttachments';

const isMobile = window.innerWidth < 769;

const ConversationCard = ({ author, company, date, content, attachments, index, setReplyVisible }): ReactElement => {
  return (
    <>
      {isMobile ? (
        <div className={styles.cardBody}>
          <div className={styles.cardHeaderRow}>
            <div className={styles.fromWrapper}>
              <div className={styles.avatarIcon}>G</div>
              <span className={styles.name}>{author.name}</span>
              <span className={styles.company}>({company})</span>
            </div>
            <div className={styles.replyWrapper}>
              <span className={styles.date}> {moment(date, 'YYYYMMDDHHSS').format('DD/MM/YYYY HH:SS')}</span>
            </div>
          </div>
          <div className={styles.cardContent}>
            <Row>
              <Col span={24}>
                <span className={styles.text}>{content}</span>
              </Col>
              <Col span={24}>
                <DisplayAttachments attachments={attachments} />
              </Col>
            </Row>
          </div>
          {index === 0 && (
            <Button type="link" className={styles.replyButton} onClick={(): void => setReplyVisible(true)}>
              Reply
            </Button>
          )}
        </div>
      ) : (
        <div className={styles.cardBody}>
          <div className={styles.cardHeaderRow}>
            <div className={styles.fromWrapper}>
              <div className={styles.avatarIcon}>G</div>
              <span className={styles.name}>{author.name}</span>
              <span className={styles.company}>({company})</span>
            </div>
            <div className={styles.replyWrapper}>
              <span className={styles.date}> {moment(date, 'YYYYMMDDHHSS').format('DD/MM/YYYY HH:SS')}</span>
              {index === 0 ? (
                <button type="button" className={styles.replyButton} onClick={(): void => setReplyVisible(true)}>
                  Reply <img src={chevron} alt="chevron" className={styles.chevron} />
                </button>
              ) : null}
            </div>
          </div>
          <div className={styles.cardContent}>
            <Row gutter={30}>
              <Col span={12}>
                <span className={styles.text}>{content}</span>
              </Col>
              <Col span={11} offset={1}>
                <DisplayAttachments attachments={attachments} />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default ConversationCard;
