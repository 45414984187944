import React, { ReactElement, useState, useEffect } from 'react';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Icon, Spin, Card } from 'antd';
import { TicketsActionTypes } from '../../models/tickets';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import HowCanWeHelp from '../../components/FleetStatusPage/HowCanWeHelp';
import AlertCard from '../../components/FleetStatusPage/AlertCard';
import SelectTicket from '../../components/FleetStatusPage/SelectTicket';
import TicketList from '../../components/FleetStatusPage/TicketList';
import helpdeskIcon from '../../assets/mainNav/icon-helpdesk.svg';
import { getTickets } from '../../services/apiNew';
import styles from './HelpDesk.module.less';

const isMobile = window.innerWidth < 769;

const LoadingSpinner = (): ReactElement => (
  <Spin style={{ width: '100%', margin: '40px 0' }} indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />
);

const Helpdesk = ({ saveAllTickets, ticketsMap, lastFetched }): ReactElement => {
  const { formatMessage } = useIntl();
  const [updateFetch, setUpdateFetch] = useState(true);
  const [activeTicket, setActiveTicket] = useState(null);
  const [latestTicket, setLatestTicket] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async (): Promise<void> => {
      setLoading(true);
      try {
        const response = await getTickets();
        let ticketList = response.data.data.tickets;

        ticketList = ticketList.map((ticket) => {
          let conversationArray = [];
          conversationArray = ticket.events.map((event) => {
            if (event.type === 'COMMENT') {
              return event;
            }
            return null;
          });
          conversationArray = conversationArray.filter((comment) => {
            return comment != null;
          });
          return { ...ticket, conversation: conversationArray };
        });

        const save = { list: ticketList, pagination: response.data.pages };

        saveAllTickets(save);
      } catch (err) {
        console.error(err.message);
      }
      setLoading(false);
    };

    if (updateFetch === true) {
      fetchTickets();
      setUpdateFetch(false);
      if (ticketsMap.size > 0 && !activeTicket) {
        setActiveTicket(Array.from(ticketsMap.values())[0]);
      }
      if (ticketsMap.size > 0 && !latestTicket) {
        setLatestTicket(Array.from(ticketsMap.values())[0]);
      }
    }
  }, [saveAllTickets, updateFetch, activeTicket, latestTicket, ticketsMap]);

  useEffect(() => {
    if (ticketsMap.size > 0 && !activeTicket) {
      setActiveTicket(Array.from(ticketsMap.values())[0]);
    }
    if (ticketsMap.size > 0 && !latestTicket) {
      setLatestTicket(Array.from(ticketsMap.values())[0]);
    }
  }, [ticketsMap, latestTicket, activeTicket]);

  const handleReviewClick = (): void => {
    document.getElementById('ticket-list').scrollIntoView();
  };

  const handleTicketChange = (ticket: TicketDataType): void => {
    document.getElementById('ticket-list').scrollIntoView();
    setActiveTicket(ticket);
  };

  const handleUpdate = (value): void => {
    setUpdateFetch(value);
  };

  return !loading ? (
    <PageHeaderWrapper
      title={
        <span>
          <img src={helpdeskIcon} alt="helpdesk" className={styles.helpdeskIcon} />{' '}
          {formatMessage({ id: 'text.helpDesk' })}
        </span>
      }
    >
      {latestTicket && (
        <AlertCard
          title={latestTicket.title}
          refNum={latestTicket.ticketNumber}
          conversation={latestTicket.conversation}
          onReviewClick={handleReviewClick}
        />
      )}
      <HowCanWeHelp onUpdate={handleUpdate} />
      {ticketsMap.size > 0 && (
        <Card className={styles.cardBody}>
          {!isMobile && <SelectTicket activeTicket={activeTicket} onUpdate={handleUpdate} />}
          <TicketList
            ticketData={Array.from(ticketsMap.values())}
            activeTicketId={activeTicket ? activeTicket.id : null}
            onTicketChange={handleTicketChange}
          />
        </Card>
      )}
    </PageHeaderWrapper>
  ) : (
    <LoadingSpinner />
  );
};

const mapStateToProps = ({ tickets }): { ticketsMap: Map<string, { id: string }>; lastFetched: number } => {
  return {
    ticketsMap: tickets ? tickets.ticketsMap : {},
    lastFetched: tickets ? tickets.lastFetched : {},
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  saveAllTickets: (payload) => Promise<void>;
} => ({
  saveAllTickets: async (payload): Promise<void> =>
    dispatch({
      type: TicketsActionTypes.SAVE_ALL,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Helpdesk);

export interface HelpdeskPageProps extends WrappedComponentProps<'intl'> {
  tickets: any;
}

export type HelpdeskPageState = {
  activeTicket: TicketDataType;
  latestTicket: TicketDataType;
  loading: boolean;
};

export interface TicketDataType {
  status: string;
  title: string;
  date: Date;
  refNum: string;
  type: string;
  content: string;
  id: string;
  conversation: Array<ConversationDataType>;
  attachments: Array<any>;
}

export interface ConversationDataType {
  author: { email: string; id: string; name: string };
  company: string;
  date: Date;
  content: string;
  attachments: Array<any>;
  id: string;
}
