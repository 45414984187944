import React, { ReactElement, useEffect, useState } from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import moment, { months } from 'moment';
import LegendItem from '../../Charts/Legend/LegendItem';
import styles from './ReliabilityReportGraph.module.less';
import { colourGradients, CustomizedYAxisTick, filterDataByTimeScale } from './utils';

const GenericLineGraph = ({
  data,
  title,
  selectedTimeScale,
  format,
  dataKeys,
  referenceLine = null,
  staticColour = false,
  filter = null,
  button = true,
  lineColourChange = null,
  dropdown = false,
  dropdownSelectedOption = null,
  dropdownOptions = [],
  onDropdownSelect = (item) => {
    // default func
  },
  isAnimationActive = true,
  unit = null,
  baseYAxisNumber = undefined,
}): ReactElement => {
  const [ticks, setTicks] = useState([]);
  const [legendItems, setLegendItems] = useState([]);

  // set the legend items based on data keys
  useEffect(() => {
    if (dataKeys) setLegendItems(dataKeys.map((key) => ({ name: key, show: true })));
  }, [dataKeys]);

  // configure graph ticks if using time format
  useEffect(() => {
    if (format !== 'time') return;
    const ticksArray = [];
    if (selectedTimeScale.text === 'Custom') {
      const lengthOfTime = moment
        .duration(
          moment()
            .add(selectedTimeScale.value.from, 'months')
            .diff(moment().add(selectedTimeScale.value.to, 'months')),
        )
        .as('months');
      const roundedTime = Math.round(Math.abs(lengthOfTime));
      for (let i = 0; i < roundedTime + 1; i += 1) {
        const timeStamp = moment()
          .add(selectedTimeScale.value.to, 'months')
          .startOf('month')
          .subtract(i, 'months')
          .unix();
        ticksArray.push(timeStamp);
      }
      setTicks(ticksArray);
    } else {
      for (let i = 0; i < selectedTimeScale.value; i += 1) {
        const timeStamp = moment()
          .startOf('month')
          .subtract(i, 'months')
          .unix();
        ticksArray.push(timeStamp);
      }
      // add the next month so that the current month is displayed in full on the axis
      ticksArray.unshift(
        moment()
          .startOf('month')
          .add(1, 'months')
          .unix(),
      );
      setTicks(ticksArray);
    }
  }, [format, selectedTimeScale]);

  const setLegend = (key: string, value: boolean): void => {
    const newLegendItems = legendItems;
    const index = newLegendItems.findIndex((i) => i.name === key);
    newLegendItems[index].show = value;
    if (legendItems.length === 2 && value === false) {
      if (index === 0) newLegendItems[1].show = true;
      if (index === 1) newLegendItems[0].show = true;
    }
    setLegendItems([...newLegendItems]);
  };

  let newData = [];
  if (data && data[0] && Object.getOwnPropertyNames(data[0]).includes('date')) {
    newData = filterDataByTimeScale(data, selectedTimeScale, true);
  }

  const menu = (
    <Menu>
      {dropdownOptions.map((item) => (
        <Menu.Item key={item.text} onClick={(): void => onDropdownSelect(item)}>
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  const coloursArray = ['#024574', '#72c9ff'];

  const getLegendColor = (index: number): string => {
    const legendColours = [
      '#3f98ff',
      '#012140',
      '#76aee4',
      '#b5c5d6',
      '#79e0d4',
      '#e6e8ed',
      '#4aa91c',
      '#e4c966',
      '#ff4040',
      '#3f98ff',
    ];
    return legendColours[index];
  };

  return (
    <div className={styles.graphWrapper}>
      <div className={styles.headerRow}>
        <span className={styles.filter}>{title}</span>
        {dropdown && (
          <Dropdown overlay={menu}>
            <div className={styles.filterWrapper}>
              <span>Showing: </span>
              <span className={styles.value}>
                {dropdownSelectedOption.text} <Icon type="caret-down" />
              </span>
            </div>
          </Dropdown>
        )}
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={newData} margin={{ top: 10, bottom: 20, right: 12, left: 10 }}>
          <CartesianGrid strokeDasharray="2 2" stroke="#8097b1" opacity={0.2} />
          <defs>
            <linearGradient id="lineChange" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#1890FF" opacity={0.5} />
              <stop offset={`${newData && newData[0] && newData[0].expected}`} stopColor="#1890FF" opacity={0.5} />
              <stop offset={`${newData && newData[0] && newData[0].expected}`} stopColor="#ff0000" opacity={0.5} />
              <stop offset="100%" stopColor="#ff0000" opacity={0.5} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            axisLine={{ stroke: '#e0e7ff' }}
            ticks={ticks}
            tick={{ fill: '#b0bac9' }}
            domain={[ticks[ticks.length - 1], ticks[0]]}
            interval={0}
            tickLine={false}
            type="number"
            tickFormatter={(t): string => moment.unix(t).format('MMM')}
          />
          <YAxis
            axisLine={{ stroke: '#e0e7ff' }}
            tickLine={false}
            tick={<CustomizedYAxisTick unit={unit} />}
            domain={newData.length > 0 ? [0, 'auto'] : [0, baseYAxisNumber || 0]}
          />
          {colourGradients(staticColour, dataKeys)}
          {referenceLine &&
            filter &&
            filter.type === 2 &&
            referenceLine.map((item, index) => {
              if (legendItems[index] && legendItems[index].show) {
                return (
                  <ReferenceLine
                    y={item.value}
                    strokeDasharray="4 4"
                    stroke={coloursArray[index]}
                    key={item.text}
                    // @ts-ignore
                    label={{ value: item.text, position: 'insideTopLeft', fill: '#b0bac9' }}
                  />
                );
              }
              return null;
            })}
          {legendItems.map((legend, index) => {
            if (legend.show) {
              return (
                <Line
                  key={legend.name}
                  type="monotone"
                  strokeWidth="2"
                  connectNulls
                  isAnimationActive={isAnimationActive}
                  dataKey={legend.name}
                  stroke={filter && filter.type === 2 && lineColourChange ? `url(#lineChange)` : getLegendColor(index)}
                />
              );
            }
            return null;
          })}
        </LineChart>
      </ResponsiveContainer>
      <div className={styles.legend}>
        {legendItems.map((legend, index) => (
          <LegendItem
            onButtonClick={(): void => setLegend(legend.name, !legend.show)}
            key={legend.name}
            isActive={legend.show}
            label={legend.name}
            index={index + 1}
            button={button}
          />
        ))}
      </div>
    </div>
  );
};

export default GenericLineGraph;

export interface LegendType {
  [x: string]: boolean;
}
