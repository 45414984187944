import { Icon } from 'antd';
import React, { ReactElement } from 'react';
import Sticky from 'react-stickynode';
import { ReportsFilter } from '../../pages/Camo/AircraftReliabilityReports';
import styles from './FiltersColumn.module.less';

const FiltersColumn = ({ handleFilterChange, types, registrations, filter }): ReactElement => {
  const filters = [
    {
      title: 'Aircraft Type',
      type: ReportsFilter.AIRCRAFTTYPE,
      list: types,
    },
    {
      title: 'Registration',
      type: ReportsFilter.AIRCRAFT,
      list: registrations,
    },
  ];

  const handleFilterClick = (value, type): void => {
    if (value === filter.value || value.registration === filter.value) {
      handleFilterChange({ value: 'Fleet', type: ReportsFilter.FLEET });
    } else {
      handleFilterChange({ value, type });
    }
  };

  const filterSection = (filterObject): ReactElement => {
    return (
      <div className={styles.filtersSection} key={filterObject.title}>
        <span className={styles.title}>{filterObject.title}</span>
        {filterObject.list.map((item, i) => (
          <button
            key={
              // eslint-disable-next-line no-nested-ternary
              item.registration ? item.registration : item.type ? item.type : item
            }
            type="button"
            onClick={(): void => handleFilterClick(item, filterObject.type)}
            className={
              filter.value === item.registration || filter.value === item.type || filter.value === item
                ? styles.activeFilter
                : styles.filterItem
            }
          >
            {((item.registration && filter.value === item.registration) || filter.value === item) && (
              <Icon type="close" className={styles.closeIcon} />
            )}
            {// eslint-disable-next-line no-nested-ternary
            item.registration ? item.registration : item.type ? item.type : item}
          </button>
        ))}
      </div>
    );
  };

  return (
    <Sticky top={20}>
      <div>{filters.map((item) => filterSection(item))}</div>
    </Sticky>
  );
};

export default FiltersColumn;
