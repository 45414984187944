import React from 'react';
import { Layout } from 'antd';
import DocumentTitle from 'react-document-title';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { ContainerQuery } from 'react-container-query';
import classNames from 'classnames';
import { pathToRegexp } from 'path-to-regexp';
import { enquireScreen, unenquireScreen } from 'enquire-js';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Bowser from 'bowser';
import Footer from './Footer';
import NotValidBrowser from './notValidBrowser';
import Context from './MenuContext';
import styles from './ExternalLayout.module.less';

const { Content } = Layout;

// Conversion router to menu.
function formatter(data, parentAuthority, parentName) {
  return data
    .map((item) => {
      let locale = 'menu';
      if (parentName && item.name) {
        locale = `${parentName}.${item.name}`;
      } else if (item.name) {
        locale = `menu.${item.name}`;
      } else if (parentName) {
        locale = parentName;
      }
      if (item.path) {
        const result = {
          ...item,
          locale,
          authority: item.authority || parentAuthority,
        };
        if (item.routes) {
          const children = formatter(item.routes, item.authority, locale);
          // Reduce memory usage
          result.children = children;
        }
        delete result.routes;
        return result;
      }

      return null;
    })
    .filter((item) => item);
}

const memoizeOneFormatter = memoizeOne(formatter, isEqual);

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
};

class ExternalLayout extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    collapsed: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    fixSiderbar: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    fixedHeader: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.getPageTitle = memoizeOne(this.getPageTitle);
    this.getBreadcrumbNameMap = memoizeOne(this.getBreadcrumbNameMap, isEqual);
    this.breadcrumbNameMap = this.getBreadcrumbNameMap();
    this.matchParamsPath = memoizeOne(this.matchParamsPath, isEqual);
  }

  state = {
    isMobile: false,
  };

  componentDidMount() {
    this.renderRef = requestAnimationFrame(() => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        rendering: false,
      });
    });
    this.enquireHandler = enquireScreen((mobile) => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });
  }

  componentDidUpdate(preProps) {
    // After changing to phone mode,
    // if collapsed is true, you need to click twice to display
    this.breadcrumbNameMap = this.getBreadcrumbNameMap();
    const { isMobile } = this.state;
    const { collapsed } = this.props;
    if (isMobile && !preProps.isMobile && !collapsed) {
      this.handleMenuCollapse(false);
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.renderRef);
    unenquireScreen(this.enquireHandler);
  }

  getContext() {
    const { location } = this.props;
    return {
      location,
      breadcrumbNameMap: this.breadcrumbNameMap,
    };
  }

  getMenuData = () => {
    const menu = memoizeOneFormatter([]);
    return menu;
  };

  /**
   * @param {Object} menuData
   */
  getBreadcrumbNameMap() {
    const routerMap = {};
    const mergeMenuAndRouter = (data) => {
      data.forEach((menuItem) => {
        if (menuItem.children) {
          mergeMenuAndRouter(menuItem.children);
        }
        // Reduce memory usage
        routerMap[menuItem.path] = menuItem;
      });
    };
    mergeMenuAndRouter(this.getMenuData());
    return routerMap;
  }

  getPageTitle = (pathname) => {
    const currRouterData = this.matchParamsPath(pathname);

    if (!currRouterData) {
      return 'TrustFlight';
    }
    const message = this.props.intl.formatMessage({
      id: currRouterData.locale || currRouterData.name,
      defaultMessage: currRouterData.name,
    });
    return `${message} - TrustFlight`;
  };

  getLayoutStyle = () => {
    const { isMobile } = this.state;
    const { fixSiderbar, collapsed, layout } = this.props;
    if (fixSiderbar && layout !== 'topmenu' && !isMobile) {
      return {
        paddingLeft: collapsed ? '80px' : '200px',
      };
    }
    return null;
  };

  getContentStyle = () => {
    const { fixedHeader } = this.props;
    return {
      margin: '24px 24px 0',
      paddingTop: fixedHeader ? 64 : 0,
    };
  };

  matchParamsPath = (pathname) => {
    const pathKey = Object.keys(this.breadcrumbNameMap).find((key) => pathToRegexp(key).test(pathname));
    return this.breadcrumbNameMap[pathKey];
  };

  handleMenuCollapse = (collapsed) => {
    const { dispatch } = this.props;
    dispatch({
      type: 'menu/changeLayoutCollapsed',
      payload: collapsed,
    });
  };

  render() {
    const {
      children,
      location: { pathname },
    } = this.props;
    const layout = (
      <Layout>
        <Layout
          style={{
            ...this.getLayoutStyle(),
            minHeight: '100vh',
          }}
          className={styles.externalLayout}
        >
          <Content style={this.getContentStyle()} data-test="externalContent">
            {children}
          </Content>
          <Footer lightText data-test="externalFooter" />
        </Layout>
      </Layout>
    );
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.parsedResult.browser.name !== 'Internet Explorer';
    return (
      <>
        <DocumentTitle title={this.getPageTitle(pathname)}>
          <ContainerQuery query={query}>
            {(params) => (
              <Context.Provider value={this.getContext()}>
                <div className={classNames(params)}>
                  {isValidBrowser ? (
                    layout
                  ) : (
                    <NotValidBrowser
                      browserName={`${browser.parsedResult.browser.name} ${browser.parsedResult.browser.version}`}
                    />
                  )}
                </div>
              </Context.Provider>
            )}
          </ContainerQuery>
        </DocumentTitle>
      </>
    );
  }
}

const externalLayoutWithRedux = connect(({ menu, userSettings }) => ({
  collapsed: menu.collapsed,
  ...userSettings.ui,
}))(ExternalLayout);
export default withRouter(injectIntl(externalLayoutWithRedux));
