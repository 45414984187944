import React, { useState, useEffect } from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import LegendItem from '../../Charts/Legend/LegendItem';
import styles from './ReliabilityReportGraph.module.less';
import { colourGradients, CustomizedXAxisTick, CustomizedYAxisTick, RoundedBar } from './utils';

const StackedBarGraph = ({
  data,
  title,
  axisKey,
  dataKeys,
  barSize,
  layout,
  stackId,
  button,
  selectedTimeScale,
  staticColour = false,
}) => {
  const [legendItems, setLegendItems] = useState([]);
  const [monthFilter, setMonthFilter] = useState({ text: moment().format('MMM'), value: moment() });
  const [timeOptions, setTimeOptions] = useState([]);

  // set the legend items based on data keys
  useEffect(() => {
    if (dataKeys) setLegendItems(dataKeys.map((key) => ({ name: key, show: true })));
  }, [dataKeys]);

  useEffect(() => {
    const options = [];
    if (typeof selectedTimeScale.value === 'object') {
      for (let i = selectedTimeScale.value.to; i >= selectedTimeScale.value.from; i -= 1) {
        options.push({
          text: moment()
            .subtract(Math.abs(i), 'months')
            .format('MMM'),
          value: moment().subtract(Math.abs(i), 'months'),
        });
      }
    } else {
      for (let i = 0; i < selectedTimeScale.value; i += 1) {
        options.push({
          text: moment()
            .subtract(i, 'months')
            .format('MMM'),
          value: moment().subtract(i, 'months'),
        });
      }
    }
    setTimeOptions(options);
  }, [selectedTimeScale]);

  const filteredData = data.filter((item) => moment.unix(item.date).month() === monthFilter.value.month());

  const longestAxisLabel = () => {
    let length = 0;
    if (data)
      data.forEach((item) => {
        if (item[`${axisKey}`].length > length) length = item[`${axisKey}`].length;
      });
    return length;
  };

  const setLegend = (key, value) => {
    const newLegendItems = legendItems;
    const index = newLegendItems.findIndex((i) => i.name === key);
    newLegendItems[index].show = value;
    setLegendItems([...newLegendItems]);
  };

  const menu = (
    <Menu>
      {timeOptions.map((item) => (
        <Menu.Item key={item.text} onClick={() => setMonthFilter(item)}>
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={styles.graphWrapper}>
      <div className={styles.headerRow}>
        <h2>{title}</h2>
        <Dropdown overlay={menu}>
          <div className={styles.filterWrapper}>
            <span>Showing: </span>
            <span className={styles.value}>
              {monthFilter.text} <Icon type="caret-down" />
            </span>
          </div>
        </Dropdown>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          layout={layout}
          data={filteredData}
          reverseStackOrder
          margin={{ top: 10, bottom: 20, right: 5, left: filteredData.length > 0 ? longestAxisLabel() * 5 : 5 }}
        >
          <CartesianGrid strokeDasharray="2 2" stroke="#8097b1" opacity={0.2} />
          {layout === 'vertical' ? (
            <XAxis
              type="number"
              axisLine={{ stroke: '#E0E7FF' }}
              tickLine={false}
              domain={[0, 8]}
              tick={<CustomizedXAxisTick />}
            />
          ) : (
            <XAxis dataKey={axisKey} axisLine={{ stroke: '#E0E7FF' }} tickLine={false} tick={<CustomizedXAxisTick />} />
          )}
          {layout === 'vertical' ? (
            <YAxis
              type="category"
              dataKey={axisKey}
              axisLine={{ stroke: '#E0E7FF' }}
              tickLine={false}
              domain={filteredData.length > 0 ? [0, 'auto'] : [0, 0]}
              tick={<CustomizedYAxisTick />}
            />
          ) : (
            <YAxis axisLine={{ stroke: '#E0E7FF' }} tickLine={false} tick={<CustomizedYAxisTick />} />
          )}
          {colourGradients(staticColour, dataKeys)}
          {legendItems.map((legend, index) => {
            if (legend.show) {
              return (
                <Bar
                  dataKey={legend.name}
                  stackId={stackId}
                  barSize={barSize}
                  fill={`url(#legend${index + 1})`}
                  shape={<RoundedBar radius={[0, 10, 10, 0]} />}
                />
              );
            }
            return null;
          })}
        </BarChart>
      </ResponsiveContainer>
      <div className={styles.legend}>
        {legendItems.map((legend, index) => (
          <LegendItem
            onButtonClick={() => setLegend(legend.name, !legend.show)}
            key={legend.name}
            isActive={legend.show}
            label={legend.name}
            index={index + 1}
            button={button}
          />
        ))}
      </div>
    </div>
  );
};

StackedBarGraph.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string.isRequired,
  axisKey: PropTypes.string.isRequired,
  dataKeys: PropTypes.array,
  barSize: PropTypes.number,
  layout: PropTypes.string,
  stackId: PropTypes.string,
  button: PropTypes.bool,
  selectedTimeScale: PropTypes.object,
  staticColour: PropTypes.bool,
};

StackedBarGraph.defaultProps = {
  data: [],
  dataKeys: [],
  barSize: 7,
  layout: 'horizontal',
  stackId: null,
  button: true,
  selectedTimeScale: {},
  staticColour: false,
};

export default StackedBarGraph;
