import React, { ReactElement } from 'react';
import styles from './LegendItem.module.less';

const LegendCircle = ({ index, staticColour, dataKeys }: LegendCircleProps): ReactElement => {
  return (
    <svg className={styles.circle} height="20" width="15">
      <circle fill={staticColour ? `url(#${dataKeys[index - 1]})` : `url(#legend${index})`} cx="5.5" cy="50%" r="5.5" />
    </svg>
  );
};

type LegendCircleProps = {
  index: number;
  staticColour?: boolean;
  dataKeys: any[];
};

export default LegendCircle;
