export const MeasureArray = [
  'Each',
  'Inch',
  'Feet',
  'Quart',
  'Square Inch',
  'Square Foot',
  'Millimeter',
  'Centimeter',
  'Meter',
  'Litre',
  'Pounds',
  'Ounce',
  'Grams',
  'Kilograms',
  'Gallon',
  'Fluid Ounces',
  'Square Centimeter',
  'Square Meter',
];

export const ATAArray = [
  '00-00: General',
  '05-00: Time Limits',
  '06-00: Dimensions and areas',
  '07-00: Lifting and shoring',
  '08-00: Leveling and weighing',
  '09-00: Towing and taxiing',
  '10-00: Parking and mooring',
  '11-00: Placards and markings',
  '12-00: Servicing',
  '20-00: Standard practices - Airframe',
  '21-00: Air conditioning',
  '22-00: Auto flight',
  '23-00: Communications',
  '24-00: Electrical power',
  '25-00: Equipment/Furnishings',
  '26-00: Fire Protection',
  '27-00: Flight controls',
  '28-00: Fuel',
  '29-00: Hydraulic power',
  '30-00: Ice & rain protection',
  '31-00: Instruments',
  '32-00: Landing gear',
  '33-00: Lights',
  '34-00: Navigation',
  '35-00: Oxygen',
  '36-00: Pneumatics',
  '37-00: Vacuum',
  '38-00: Water waste',
  '42-00: Integrated modular avionics',
  '44-00: Cabin system',
  '45-00: Central maintenance system (CIS)',
  '46-00: Information systems (ATIMS)',
  '47-00: Flammability reduction system',
  '49-00: Airborne auxiliary power',
  '50-00: Cargo and accessory compartments',
  '51-00: Standard practices - structures',
  '52-00: Doors',
  '53-00: Fuselage',
  '54-00: Nacelles/Pylons',
  '55-00: Stabilizers',
  '56-00: Windows',
  '57-00: Wings',
  '61-00: Propellors',
  '70-00: Standard practices - engines',
  '71-00: Power plant',
  '72-00: Engine',
  '73-00: Engine fuel and control',
  '74-00: Ignition',
  '75-00: Engine air',
  '76-00: Engine controls',
  '77-00: Engine indicating',
  '78-00: Exhaust',
  '79-00: Oil',
  '80-00: Starting',
  '91-00: Zoning/Charts',
];
