import { Spin, Row, Col } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { usePrevious } from '../../hooks/usePrevious';
import { EngineReportsState } from '../../models/engineReports';
import { ReliabilityReportsAircraftState } from '../../models/reliabilityReportsAircraft';
import { ReportsFilter } from '../../pages/Camo/AircraftReliabilityReports';
import { queryReportEngines, queryReportOil, queryReportUnscheduledEngineRemovals } from '../../services/apiNew';
import {
  saveFleetEngine,
  saveFleetOil,
  saveAircraftEngine,
  saveAircraftOil,
  saveAircraftTypeEngine,
  saveAircraftTypeOil,
  saveFleetUnscheduledEngineRemovals,
  saveAircraftUnscheduledEngineRemovals,
  saveAircraftTypeUnscheduledEngineRemovals,
} from '../../models/engineReports/action';
import FiltersColumn from './FiltersColumn';
import GenericAreaGraph from './Graphs/GenericAreaGraph';
import GenericLineGraph from './Graphs/GenericLineGraph';
import TabHeader from './TabHeader';
import styles from './TabStyles.module.less';

const EngineTab = ({
  filter,
  selectedTimeScale,
  setSelectedTimeScale,
  handleFilterChange,
  activeTab,
  engineReports,
  reliabilityReportsAircraft,
  saveFleetOilLocal,
  saveAircraftOilLocal,
  saveAircraftTypeOilLocal,
  saveFleetUnscheduledEngineRemovalsLocal,
  saveAircraftUnscheduledEngineRemovalsLocal,
  saveAircraftTypeUnscheduledEngineRemovalsLocal,
}): ReactElement => {
  const [oilLoading, setOilLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [dataSource, setDataSource] = useState<any>(new Map());
  const { registrations, types } = reliabilityReportsAircraft;
  const [unscheduledEngineRemovalsLoading, setUnscheduledEngineRemovalsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { fleet, aircraft, aircraftType, ttl } = engineReports;
  const { text, value } = selectedTimeScale;
  let fromDate = null;
  let toDate = null;

  if (text === 'Custom') {
    fromDate = moment()
      .add(value.from, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    toDate = moment()
      .add(value.to + 1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
  } else {
    fromDate = moment()
      .subtract(value, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    toDate = moment().format('YYYY-MM-DD');
  }

  const prevPropsSelectedTimeScale = usePrevious(value);
  const prevPropsFilter = usePrevious(filter);

  useEffect(() => {
    switch (filter.type) {
      case ReportsFilter.FLEET:
        setDataSource(fleet.dataMap);
        break;
      case ReportsFilter.AIRCRAFT:
        setDataSource(aircraft.dataMap.get(filter.value) || new Map());
        break;
      case ReportsFilter.AIRCRAFTTYPE:
        setDataSource(aircraftType.dataMap.get(filter.value) || new Map());
        break;
      default:
        setDataSource(fleet.dataMap);
    }
  }, [filter.type, filter.value, aircraft, aircraftType, fleet]);

  useEffect(() => {
    const getLastFetched = () => {
      switch (filter.type) {
        case ReportsFilter.FLEET:
          return fleet.lastFetched;
          break;
        case ReportsFilter.AIRCRAFT:
          return aircraft.lastFetched;
          break;
        case ReportsFilter.AIRCRAFTTYPE:
          return aircraftType.lastFetched;
          break;
        default:
          break;
      }
    };

    const saveToRedux = (data, type): any => {
      switch (filter.type) {
        case ReportsFilter.FLEET:
          if (type === 'oil') {
            saveFleetOilLocal(data);
          } else {
            saveFleetUnscheduledEngineRemovalsLocal(data);
          }
          break;
        case ReportsFilter.AIRCRAFT:
          if (type === 'oil') {
            saveAircraftOilLocal({ data, type: filter.value });
          } else {
            saveAircraftUnscheduledEngineRemovalsLocal({ data, type: filter.value });
          }
          break;
        case ReportsFilter.AIRCRAFTTYPE:
          if (type === 'oil') {
            saveAircraftTypeOilLocal({ data, type: filter.value });
          } else {
            saveAircraftTypeUnscheduledEngineRemovalsLocal({ data, type: filter.value });
          }
          break;
        default:
          break;
      }
    };

    const buildQueryString = (): string => {
      if (filter.type === ReportsFilter.AIRCRAFT) {
        return JSON.stringify({ registration: filter.value, from_date: fromDate, to_date: toDate });
      }
      if (filter.type === ReportsFilter.AIRCRAFTTYPE) {
        return JSON.stringify({ type: filter.value, from_date: fromDate, to_date: toDate });
      }
      return JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
      });
    };
    if (
      activeTab === 'engines' &&
      !firstRun &&
      (Date.now() - getLastFetched() > ttl ||
        (prevPropsFilter && filter.value !== prevPropsFilter.value) ||
        (prevPropsSelectedTimeScale && selectedTimeScale.value !== prevPropsSelectedTimeScale))
    ) {
      const getOilData = async (): Promise<any> => {
        setOilLoading(true);
        const res = await queryReportOil(buildQueryString());
        saveToRedux(res.data, 'oil');
        setOilLoading(false);
      };
      const getUnscheduledEngineRemovalsData = async (): Promise<any> => {
        setUnscheduledEngineRemovalsLoading(true);
        const res = await queryReportUnscheduledEngineRemovals(buildQueryString());
        saveToRedux(res.data, 'unscheduledEngineRemovals');
        setUnscheduledEngineRemovalsLoading(false);
      };

      getOilData();
      getUnscheduledEngineRemovalsData();
    }
    setFirstRun(false);
  }, [
    activeTab,
    aircraft.lastFetched,
    aircraftType.lastFetched,
    filter.type,
    filter.value,
    fleet.lastFetched,
    fromDate,
    prevPropsFilter,
    saveAircraftOilLocal,
    saveAircraftTypeOilLocal,
    selectedTimeScale,
    saveFleetOilLocal,
    saveAircraftUnscheduledEngineRemovalsLocal,
    saveAircraftTypeUnscheduledEngineRemovalsLocal,
    saveFleetUnscheduledEngineRemovalsLocal,
    toDate,
    ttl,
  ]);

  return (
    <div className={styles.layoutDiv}>
      <div className={styles.graphsWrapper}>
        <TabHeader selectedTimeScale={selectedTimeScale} setSelectedTimeScale={setSelectedTimeScale} filter={filter} />
        <Spin spinning={oilLoading}>
          <Row>
            <Col span={24}>
              <GenericAreaGraph
                title={formatMessage({ id: 'title.oilConsumption' })}
                data={dataSource.get('oil')}
                axisKey="date"
                dataKey="average"
                stroke="#3f98ff"
                referenceLine={[{ value: 0.04, text: formatMessage({ id: 'text.consumptionPerHourPerEngine' }) }]}
                fill="#3f98ff"
                id="o"
                format="time"
                unit=" qt"
                selectedTimeScale={selectedTimeScale}
              />
            </Col>
          </Row>
        </Spin>
        <Spin spinning={unscheduledEngineRemovalsLoading}>
          <Row>
            <Col span={24}>
              <GenericLineGraph
                data={dataSource && dataSource.get('unscheduledEngineRemovals')}
                baseYAxisNumber={6}
                title={formatMessage({ id: 'title.unscheduledEngineRemovals' })}
                selectedTimeScale={selectedTimeScale}
                format="time"
                button
                dataKeys={['count']}
              />
            </Col>
          </Row>
        </Spin>
      </div>
      <div className={styles.filtersWrapper}>
        <FiltersColumn
          handleFilterChange={(value) => {
            handleFilterChange(value);
          }}
          registrations={registrations}
          types={types}
          filter={filter}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  engineReports,
  reliabilityReportsAircraft,
}: {
  engineReports: EngineReportsState;
  reliabilityReportsAircraft: ReliabilityReportsAircraftState;
}): {
  engineReports: {};
  reliabilityReportsAircraft: {};
} => {
  return {
    engineReports,
    reliabilityReportsAircraft,
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  saveFleetOilLocal: (payload: any) => Promise<void>;
  saveAircraftOilLocal: (payload: any) => Promise<void>;
  saveAircraftTypeOilLocal: (payload: any) => Promise<void>;
  saveFleetUnscheduledEngineRemovalsLocal: (payload: any) => Promise<void>;
  saveAircraftUnscheduledEngineRemovalsLocal: (payload: any) => Promise<void>;
  saveAircraftTypeUnscheduledEngineRemovalsLocal: (payload: any) => Promise<void>;
} => ({
  saveFleetOilLocal: (payload): Promise<void> => {
    return dispatch(saveFleetOil({ payload }));
  },
  saveAircraftOilLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftOil({ payload }));
  },
  saveAircraftTypeOilLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeOil({ payload }));
  },
  saveFleetUnscheduledEngineRemovalsLocal: (payload): Promise<void> => {
    return dispatch(saveFleetUnscheduledEngineRemovals({ payload }));
  },
  saveAircraftUnscheduledEngineRemovalsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftUnscheduledEngineRemovals({ payload }));
  },
  saveAircraftTypeUnscheduledEngineRemovalsLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeUnscheduledEngineRemovals({ payload }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EngineTab);
