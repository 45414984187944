import React, { ReactElement, useState, useEffect } from 'react';
import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { Loading } from '@arcflight/tf-component-library';
import { queryDocumentApproval } from '../../services/apiNew';
import iconResponse from '../../assets/icon-response.svg';
import documentApproved from '../../assets/email-tf-document-approved.svg';
import documentRejected from '../../assets/email-tf-document-rejected.svg';
import documentFeedback from '../../assets/email-tf-document-feedback.svg';
import logo from '../../assets/trustflight-logo-white.svg';
import bgOverlay from '../../assets/public-mx-bg-grid.svg';
import styles from './DocumentPage.module.less';

const DocumentPageRevoked = ({ match }): ReactElement => {
  const { documentId } = match().params;
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    const getDocumentApproval = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await queryDocumentApproval(documentId);
        setDocument(res.data);
      } catch (err) {
        console.error(err);
        history.push('/public/exception/404');
      }
      setLoading(false);
    };
    getDocumentApproval();
  }, [documentId, history]);

  return (
    <div className={`${styles.backgroundWrapper} ${styles.backgroundWrapper__approval}`}>
      <img src={bgOverlay} className={styles.bgOverlay} alt="bg" />
      <div className={styles.padding}>
        <img src={logo} className={styles.logo} alt="bg" />
        <Card className={styles.document__card}>
          {loading ? (
            <Loading loading={loading} contain />
          ) : (
            <>
              <img src={documentRejected} className={styles.approval} alt="document revoked" />
              <h1>{`Document Assement for ${document.publicationDocumentType} ${document.publicationDocumentNumber} has been revoked. This assessment can no longer be approved or rejected`}</h1>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default DocumentPageRevoked;
