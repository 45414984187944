import { Card, Row, Col } from 'antd';
import React, { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import aircraftSearchIcon from './assets/empty-state-aircraft-search.svg';
import documentsIcon from './assets/empty-state-documents-v1.svg';
import maintenanceIcon from './assets/empty-state-maintenance.svg';
import noDataIcon from './assets/empty-state-no-data.svg';

import styles from './HowCanWeHelp.module.less';

import QueryForm from './QueryForm';

const HowCanWeHelp = ({ onUpdate }): ReactElement => {
  const { formatMessage } = useIntl();
  const [visibleForm, setVisibleForm] = useState(false);
  const [title, setTitle] = useState('');

  const handleButtonClick = (input: string): void => {
    setVisibleForm(true);
    switch (input) {
      case 'technical':
        setTitle('Report a Technical Support');
        break;
      case 'service':
        setTitle('Service Request');
        break;
      case 'question':
        setTitle('Question');
        break;
      case 'other':
        setTitle('Other');
        break;
      default:
        setTitle('');
        break;
    }
  };

  return (
    <Card className={styles.cardBody}>
      <div className={styles.cardTitle}>{formatMessage({ id: 'title.howCanWeHelp' })}</div>
      <div className={styles.cardSubTitle}>{formatMessage({ id: 'title.selectACatagory' })}</div>
      <Row gutter={24}>
        {!visibleForm ? (
          <>
            <Col md={6}>
              <Card className={styles.buttonCard} onClick={(): void => handleButtonClick('technical')}>
                <div className={styles.innerWrapper}>
                  <img src={maintenanceIcon} alt="Technical Support" className={styles.buttonIcon} />
                  <div className={styles.buttonName}>{formatMessage({ id: 'text.reportATechincalSupport' })}</div>
                </div>
              </Card>
            </Col>
            <Col md={6}>
              <Card className={styles.buttonCard} onClick={(): void => handleButtonClick('service')}>
                <div className={styles.innerWrapper}>
                  <img src={documentsIcon} alt="Service request" className={styles.buttonIcon} />
                  <div className={styles.buttonName}>{formatMessage({ id: 'text.serviceRequest' })}</div>
                </div>
              </Card>
            </Col>
            <Col md={6}>
              <Card className={styles.buttonCard} onClick={(): void => handleButtonClick('question')}>
                <div className={styles.innerWrapper}>
                  <img src={aircraftSearchIcon} alt="Question search" className={styles.buttonIcon} />
                  <div className={styles.buttonName}>{formatMessage({ id: 'text.question' })}</div>
                </div>
              </Card>
            </Col>
            <Col md={6}>
              <Card className={styles.buttonCard} onClick={(): void => handleButtonClick('other')}>
                <div className={styles.innerWrapper}>
                  <img src={noDataIcon} alt="other search" className={styles.buttonIcon} />
                  <div className={styles.buttonName}>{formatMessage({ id: 'text.other' })}</div>
                </div>
              </Card>
            </Col>
          </>
        ) : (
          <QueryForm title={title} setVisibleForm={setVisibleForm} onUpdate={onUpdate} />
        )}
      </Row>
    </Card>
  );
};

export default HowCanWeHelp;
