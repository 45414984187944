import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Card, Table, Dropdown, Menu, Icon } from 'antd';
import moment from 'moment';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import SearchInput from '../SearchInput';
import defaults from '../../utils/defaults';
import DocumentEmptyState from '../../assets/empty-state-documents-v2.svg';
import ModernPagination from '../ModernPagination';
import TableTitle from '../TableTitle/TableTitle';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import styles from './DocumentAssessmentTable.module.less';

const data = [
  {
    id: '1',
    status: 'reviewed',
    title: 'FLIGHT CONTROLS - TRIMMABLE HORIZONTAL STABILIZERS ACTUATOR (THSA) -',
    type: 'SB',
    area: 'flight controls',
    date_created: '2020-03-25',
    time_to_review: 44400,
    expected_review_time: 44400,
  },
  {
    id: '2',
    status: 'open',
    title: 'ENGINE-REPAIRED LOW PRESSURE TURBINE STAGE 1 NOZZLE SEGMENTS',
    type: 'AD',
    area: 'engine',
    date_created: '2020-03-25',
    time_to_review: 11100,
    expected_review_time: 44400,
  },
  {
    id: '3',
    status: 'overdue',
    title: 'NAVIGATION-ATC/MODE S-INSTALL HONEYWELL ATC TRANSPONDERS TRA-67A INCOR',
    type: 'SB',
    area: 'navigation',
    date_created: '2020-03-25',
    time_to_review: 44400,
    expected_review_time: 58800,
  },
  {
    id: '4',
    status: 'reviewed',
    title: 'AIRBORNE AUXILIARY POWER - APU MOUNTS - OPTIMIZE APU',
    type: 'SB',
    area: 'apu',
    date_created: '2020-03-25',
    time_to_review: 44400,
    expected_review_time: 44400,
  },
  {
    id: '5',
    status: 'reviewed',
    title: 'EMERGENCY LOCATOR TRANSMITTER',
    type: 'AD',
    area: 'engine',
    date_created: '2020-03-25',
    time_to_review: 44400,
    expected_review_time: 44400,
  },
  {
    id: '6',
    status: 'reviewed',
    title: 'POWERPLANT-ENGINE LT HARNESS, FANCASE INTRODUCTION OF HIGH INSERTION',
    type: 'SB',
    area: 'powerplant',
    date_created: '2020-03-25',
    time_to_review: 44400,
    expected_review_time: 44400,
  },
  {
    id: '7',
    status: 'reviewed',
    title: 'DETECT AND CORRECT CORROSION IN THE BORE OF EACH MLG UNIT BOGIE BEAM',
    type: 'AD',
    area: 'mlg',
    date_created: '2020-03-25',
    time_to_review: 44400,
    expected_review_time: 44400,
  },
];

const DocumentAssessmentTable = ({ userSettings }) => {
  const { formatMessage } = useIntl();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('all');
  const [statusArray, setStatusArray] = useState(['all']);
  const [typeFilter, setTypeFilter] = useState('all');
  const [typeArray, setTypeArray] = useState(['all']);
  const [filteredData, setFilteredData] = useState(data);
  const [dataMessage, setDataMessage] = useState('');

  const status = {
    open: {
      badge: 'warning',
      text: formatMessage({ id: 'status.open' }),
      colour: 'linear-gradient(to bottom, #ffa174, #ffbc40)',
    },
    reviewed: {
      badge: 'success',
      text: formatMessage({ id: 'status.reviewed' }),
      colour: 'linear-gradient(to bottom, #2fc595, #228d6b)',
    },
    overdue: {
      badge: 'error',
      text: formatMessage({ id: 'status.overdue' }),
      colour: 'linear-gradient(to bottom, #ff7474, #ff4040)',
    },
  };

  const columns = [
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.status' })} dataIndex="status" />,
      dataIndex: 'status',
      width: 130,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      className: tableStyles.tableHeader,
      render(val) {
        return (
          <div>
            <span className={tableStyles.statusIndicator} style={{ background: status[val].colour }} />
            <span className={tableStyles.statusText}>{status[val].text}</span>
          </div>
        );
      },
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.title' })} dataIndex="title" />,
      dataIndex: 'title',
      className: tableStyles.tableHeader,
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (text ? text.toLowerCase() : '-'),
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.type' })} dataIndex="type" />,
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['descend', 'ascend'],
      className: tableStyles.tableHeader,
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.dateCreated' })} dataIndex="date_created" />
      ),
      dataIndex: 'date_created',
      sorter: (a, b) => moment(a.date_created, 'DD-MM-YYYY') - moment(b.date_created, 'DD-MM-YYYY'),
      sortDirections: ['descend', 'ascend'],
      className: tableStyles.tableHeader,
      render(val) {
        return userSettings && userSettings.dateFormat
          ? moment(val, 'YYYY-MM-DD').format(userSettings.dateFormat)
          : moment(val, 'YYYY-MM-DD').format(defaults.defaultDateFormat);
      },
    },
    {
      title: 'time taken to review',
      dataIndex: 'time_to_review',
      sorter: (a, b) => a.time_to_review - b.time_to_review,
      sortDirections: ['descend', 'ascend'],
      render: (val, record) => {
        const hours = Math.round(val / 3600);
        const mins = (val % 3600) / 60;
        const timeDifference = record.expected_review_time - val;
        const diffHours = Math.round(timeDifference / 3600);
        const diffMins = (timeDifference % 3600) / 60;
        return (
          <div>
            {`${hours}hrs ${mins}mins`}
            <span className={timeDifference > 0 ? tableStyles.timeDiffShow : tableStyles.timeDiffHide}>
              {`(${diffHours}hrs ${diffMins}mins)`}
            </span>
          </div>
        );
      },
    },
  ];

  const filtersArray = [
    {
      filter: statusFilter,
      array: statusArray,
      func: setStatusFilter,
      text: 'status',
    },
    {
      filter: typeFilter,
      array: typeArray,
      func: setTypeFilter,
      text: 'type',
    },
  ];

  const dropdownFilter = (object) => {
    return (
      <div className={styles.dropdownWrapper}>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu onClick={(item) => object.func(item.key)}>
              {object.array.map((item) => {
                return (
                  <Menu.Item className={styles.capitalise} key={`${item}`}>
                    {item}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
        >
          <span className={styles.dropdownLink}>
            {formatMessage({ id: `text.${object.text}` })}: <span className={styles.capitalise}>{object.filter}</span>{' '}
            <Icon type="caret-down" />
          </span>
        </Dropdown>
      </div>
    );
  };

  const getArrayContents = (input, selection, array) => {
    return input.reduce((arr, object) => {
      if (!arr.includes(object[`${selection}`])) array.push(object[`${selection}`]);
      return array;
    }, []);
  };

  useEffect(() => {
    setStatusArray(getArrayContents(data, 'status', statusArray));
    setTypeArray(getArrayContents(data, 'type', typeArray));
  }, [statusArray, typeArray]);

  useEffect(() => {
    let newData = [];
    newData = data.filter(
      (object) =>
        (statusFilter === 'all' || object.status === statusFilter) &&
        (typeFilter === 'all' || object.type === typeFilter) &&
        (!searchValue ||
          searchValue === '' ||
          Object.values(object).some((value) =>
            value
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()),
          )),
    );
    setFilteredData(newData);
    setDataMessage(data.length > 0 ? formatMessage({ id: 'text.noSearchData' }) : formatMessage({ id: 'text.noData' }));
  }, [searchValue, statusFilter, typeFilter, formatMessage]);

  let dataSubset = filteredData && filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  if (dataSubset && dataSubset.length === 0) dataSubset = filteredData;

  return (
    <Card className={`${cardStyles.pageCard} ${styles.tableCard}`}>
      <div className={cardStyles.cardHeader}>{formatMessage({ id: 'title.documentAssessment' })}</div>
      <div className={styles.searchWrapper}>
        <SearchInput
          placeholder={formatMessage({ id: 'form.placeholder.searchByAircraftReg' })}
          value={searchValue}
          visible={searchVisible}
          onToggle={() => setSearchVisible(!searchVisible)}
          onChange={(value) => setSearchValue(value)}
          onBlur={(value) => {
            if (!value) {
              setSearchVisible(false);
            }
          }}
          data-test="searchInput"
          onClear={() => setSearchValue('')}
          inputRef="documentTable"
        />
        <div className={styles.filtersWrapper}>{filtersArray.map((object) => dropdownFilter(object))}</div>
      </div>
      <div className={tableStyles.tableContainer}>
        {dataSubset.length === 0 ? (
          <div className={tableStyles.emptyStateWrapper}>
            <img src={DocumentEmptyState} alt="no data" className={tableStyles.emptyState} />
            <span>{dataMessage}</span>
          </div>
        ) : (
          <>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={dataSubset}
              bordered={false}
              pagination={false}
              rowClassName={tableStyles.tableRow}
              scroll={{ x: 915 }}
            />
            <ModernPagination
              key="modernPagination"
              pageSize={pageSize}
              current={currentPage}
              total={data.length}
              onPageNoChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(size) => setPageSize(size)}
              data-test="modernPagination"
            />
          </>
        )}
      </div>
    </Card>
  );
};

DocumentAssessmentTable.propTypes = {
  intl: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  connect(
    ({ userSettings }) => ({
      userSettings,
    }),
    (dispatch) => ({}),
  ),
)(DocumentAssessmentTable);
