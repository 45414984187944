import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import downArrow from '../../../assets/icon-arrow-down.svg';

const BackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
`;

const StyledDropdown = styled.button`
  width: 210px;
  height: 100%;
  min-height: 34px;
  text-align: left;
  border-radius: 2px;
  // border: solid 1px rgba(36, 45, 65, 0.1);
  border ${({ error }): string => (error ? 'solid 1px #ca2322' : 'solid 1px rgba(36, 45, 65, 0.1)')};
  background-color: ${({ disabled }): string => (disabled ? 'rgba(36, 45, 65, 0.02)' : '#fff')};
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  height: 100%;
`;

const Arrow = styled.img`
  margin-left: 4px;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  width: ${({ longContent }): string => (longContent ? '310px' : '210px')};
  max-height: 340px;
  overflow-y: scroll;
  top: ${({ placement }): string => (placement === 'bottom' ? '42px' : 'unset')};
  bottom: ${({ placement }): string => (placement === 'top' ? '42px' : 'unset')};
  background-color: #fff;
  // border: solid 1px rgba(36, 45, 65, 0.1);
  border ${({ error }): string => (error ? 'solid 1px #ca2322' : 'solid 1px rgba(36, 45, 65, 0.1)')};
  z-index: 1;
`;

const StyledOption = styled.button`
  width: 100%;
  min-height: 34px;
  height: 100%;
  cursor: pointer;
  text-align: left;
  padding-left: 9px;
  border: 1px solid #fff;
  color: rgba(36, 45, 65, 0.9);
  &:hover {
    border-radius: 2px;
    border: solid 1px rgba(24, 144, 255, 0.2);
    background-color: rgba(24, 144, 255, 0.05);
  }
  &:focus {
    border-radius: 2px;
    border: solid 1px #1890ff;
    background-color: rgba(24, 144, 255, 0.1);
    color: #1890ff;
  }
  ${({ selected }): any =>
    selected &&
    css`
      color: #1890ff;
    `}
`;

interface DropdownProps {
  options: Array<string>;
  optionFunction: (input: string) => void;
  activeOption: string;
  marginBottom?: string;
  longContent?: boolean;
  placement?: string;
  disabled?: boolean;
  error?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  optionFunction,
  activeOption,
  marginBottom,
  longContent,
  placement,
  disabled = false,
  error,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleVisibleChange = (): void => {
    if (!disabled) setDropdownVisible(!dropdownVisible);
  };

  const displayOptions = options.map((option) => {
    return (
      <StyledOption
        key={option}
        onClick={(): void => {
          optionFunction(option);
          handleVisibleChange();
        }}
        selected={option === activeOption}
      >
        {option}
      </StyledOption>
    );
  });

  return (
    <>
      {dropdownVisible && <BackgroundDiv onClick={handleVisibleChange} />}
      <DropdownWrapper marginBottom={marginBottom}>
        <StyledDropdown error={error} disabled={disabled} onClick={handleVisibleChange}>
          <TextWrapper>
            {activeOption}
            <Arrow src={downArrow} alt="down arrow" />
          </TextWrapper>
        </StyledDropdown>
        {dropdownVisible ? (
          <OptionsWrapper longContent={longContent} placement={placement}>
            {displayOptions}
          </OptionsWrapper>
        ) : null}
      </DropdownWrapper>
    </>
  );
};

export default Dropdown;
