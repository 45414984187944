import React, { PureComponent } from 'react';
import { Row, Col, Card, Dropdown, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import { queryAircraftsWithUtilisation } from '../../services/apiNew';
import DetailedSummaryCard from '../../components/FleetPerformance/DetailedSummaryCard';
import { AircraftActionTypes } from '../../models/aircraft';
import SummaryPieChart from '../../components/AircraftServiceOverview/SummaryPieChart';
import DocumentAssessmentTable from '../../components/FleetPerformance/DocumentAssessmentTable';
import dropdownArrow from '../../assets/DropdownArrow.svg';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import styles from './Performance.module.less';

class Performance extends PureComponent {
  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    saveAllAircraft: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTimePeriod: 'Last 7 days',
    };
  }

  componentDidMount() {
    this.getAllAircraft();
  }

  getAllAircraft = async () => {
    const { saveAllAircraft } = this.props;
    const res = await queryAircraftsWithUtilisation();
    saveAllAircraft(res.data.data.allAircraft);
  };

  dateDropDown = () => {
    const { selectedTimePeriod } = this.state;
    const menu = (
      <Menu>
        <Menu.Item onClick={() => this.setState({ selectedTimePeriod: 'Last 7 Days' })}>
          <span className={styles.dropdownMenuItem}>Last 7 days</span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ selectedTimePeriod: 'Last 14 days' })}>
          <span className={styles.dropdownMenuItem}>Last 14 days</span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ selectedTimePeriod: 'Last month' })}>
          <span className={styles.dropdownMenuItem}>Last month</span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ selectedTimePeriod: 'Last 3 months' })}>
          <span className={styles.dropdownMenuItem}>Last 3 months</span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ selectedTimePeriod: 'Last 6 months' })}>
          <span className={styles.dropdownMenuItem}>Last 6 months</span>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className={styles.dropdownWrapper}>
        <Dropdown overlay={menu} placement="bottomRight">
          <div className={styles.dropdownLink}>
            Showing: <span>{selectedTimePeriod}</span> <img src={dropdownArrow} alt="arrow" />
          </div>
        </Dropdown>
      </div>
    );
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <PageHeaderWrapper>
        <PageHeaderWrapper
          layout="modern"
          title={formatMessage({ id: 'title.performance' })}
          action={this.dateDropDown()}
        >
          <Row gutter={30}>
            <Col span={6}>
              <Card className={`${cardStyles.pageCard} ${styles.summaryCard}`}>
                <div className={`${cardStyles.cardHeader} ${styles.topMargin}`}>Ticket Summary</div>
                <SummaryPieChart />
              </Card>
            </Col>
            <Col span={18}>
              <Card className={`${cardStyles.pageCard} ${styles.detailedSummaryCard}`}>
                <div className={cardStyles.cardHeader}>Detailed Ticket Summary</div>
                <DetailedSummaryCard />
              </Card>
            </Col>
          </Row>
        </PageHeaderWrapper>
      </PageHeaderWrapper>
    );
  }
}

const PerformanceWithRedux = connect(
  ({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  }),
  (dispatch) => ({
    saveAllAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.SAVE_ALL,
        payload,
      }),
  }),
)(Performance);

export default withRouter(injectIntl(PerformanceWithRedux));
