import { Col, Modal, Row } from 'antd';
import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import { closeTicket } from '../../services/apiNew';
import closeIcon from '../../assets/close-icon-mobile.svg';
import CloseTicketModal from './CloseTicketModal';
import styles from './CurrentTicket.module.less';

const CurrentTicket = ({ activeTicket, onUpdate }): ReactElement => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    status,
    title,
    department,
    date,
    ticketNumber,
    type,
    content,
  }: {
    status: string;
    title: string;
    department: { id: number; name: string };
    date: Date;
    ticketNumber: number;
    type: string;
    content: string;
  } = activeTicket;

  let statusStyling = styles.okStatus;
  if (status === 'warning') statusStyling = styles.warningStatus;
  if (status === 'overdue') statusStyling = styles.overdueStatus;

  const handleCancel = (): void => {
    setModalVisible(false);
  };

  const handleCloseTicket = async (): Promise<void> => {
    await closeTicket(ticketNumber);
    setModalVisible(false);
    onUpdate(true);
  };

  return (
    <>
      <div className={styles.currentTicketWrapper}>
        <div className={styles.ticketHeader}>
          <div className={styles.headerLayout}>
            <div className={`${styles.statusCircle} ${statusStyling}`} />
            <div className={styles.ticketTitle}>{title}</div>
            <div className={styles.referenceNumber}>{ticketNumber}</div>
          </div>
          <button type="button" className={styles.closeTicketButton} onClick={(): void => setModalVisible(true)}>
            Close Ticket
          </button>
        </div>
        <div className={styles.ticketBody}>
          <Row className={styles.topRow} gutter={30}>
            <Col span={6}>
              <div className={styles.label}>Type:</div>
              <div className={styles.detail}>{department.name}</div>
            </Col>
            <Col span={6}>
              <div className={styles.label}>Area:</div>
              <div className={styles.detail}>{type}</div>
            </Col>
            <Col span={6}>
              <div className={styles.label}>Raised on:</div>
              <div className={styles.detail}>{moment(date, 'YYYYMMDDHHSS').format('DD/MM/YYYY')}</div>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <div className={styles.label}>Description:</div>
              <div className={styles.detail}>
                <span>{content}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.label}>Attachments:</div>
              <div className={styles.detail}>
                <span>Attachments go here</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        title="Close Ticket"
        visible={modalVisible}
        onOk={handleCloseTicket}
        onCancel={handleCancel}
        className={styles.closeTicketModal}
        okText="Close Ticket"
        closeIcon={<img src={closeIcon} alt="close" className={styles.closeIcon} />}
      >
        <CloseTicketModal ticket={activeTicket} />
      </Modal>
    </>
  );
};

export default CurrentTicket;
