import { Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import { DashboardState } from '../models';
import { User, UserRoles, UserOrganisationPermissions, CamoResource, UserPermission } from '../models/userSettings';
import { updateUserDetails } from '../models/userSettings/actions';
import { getMe } from '../services/apiNew';
import { getOauthUrl } from '../utils/oauth';
import menuDataGenerator from '../utils/menuData';

const cookies = new Cookies();

export const hasServiceDeskPermission = (
  userOrganisationPermissions: UserOrganisationPermissions[],
  requiredResource: CamoResource,
  requiredPermissionLevel: UserPermission,
): boolean => {
  if (!userOrganisationPermissions) {
    return false;
  }
  const hasPermission = userOrganisationPermissions.find((orgPermissions) => {
    const permission = orgPermissions.permissions.find((p) => p.resource === requiredResource);
    return permission && permission[requiredPermissionLevel];
  });
  return !!hasPermission;
};

export const useAuthenticationValidation = (
  requiredRole?: UserRoles,
  resourceRequired?: CamoResource,
  permissionRequired?: UserPermission,
): AuthenticationValidation => {
  const { pathname } = useLocation();
  const redirectToLogin = useCallback(() => {
    const url = getOauthUrl(pathname);
    window.location.href = url;
  }, [pathname]);

  const [authenticationObject, setAuthenticationObject] = useState<AuthenticationValidation>({
    loading: true,
    authenticated: false,
    lastChecked: Date.now(),
  });
  const {
    id,
    dashboard_permissions: dashboardPermissions,
    service_desk_permissions: serviceDeskPermissions,
  } = useSelector<
    DashboardState,
    { id: string; dashboard_permissions: UserRoles[]; service_desk_permissions: UserOrganisationPermissions[] }
  >(({ userSettings }) => ({
    id: userSettings.details?.id,
    dashboard_permissions: userSettings.details?.dashboard_permissions,
    service_desk_permissions: userSettings.details?.service_desk_permissions,
  }));
  const dispatch = useDispatch();
  const { auth, userId } = cookies.getAll();
  useEffect(() => {
    if (authenticationObject.loading && id === userId && auth) {
      // Got valid user in the Redux State, let's update the authenticated object and call it a day
      setAuthenticationObject({
        loading: false,
        authenticated: requiredRole
          ? dashboardPermissions.includes(requiredRole)
          : hasServiceDeskPermission(serviceDeskPermissions, resourceRequired, permissionRequired),
        lastChecked: Date.now(),
      });
    } else if (authenticationObject.loading && auth) {
      // Got auth token but no user in state, let's go get one and dispatch it to Redux once that's done
      getMe()
        .then(({ data }: { data: User }) => {
          dispatch(updateUserDetails({ details: data }));
        })
        .catch(() => {
          redirectToLogin();
        });
    } else if (!auth) {
      // No login data found on the client, let's send them to CoreAPI for authentication
      redirectToLogin();
    }
  }, [
    authenticationObject.loading,
    userId,
    auth,
    requiredRole,
    dispatch,
    id,
    dashboardPermissions,
    redirectToLogin,
    serviceDeskPermissions,
    resourceRequired,
    permissionRequired,
  ]);

  return authenticationObject;
};

const LoadingScreen: React.FC = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      minWidth: '100vw',
    }}
    data-testid="AuthLoadingScreen"
  >
    <Spin />
  </div>
);

const AuthProvider: React.FC<{
  roleRequired?: UserRoles;
  resourceRequired?: CamoResource;
  permissionRequired?: UserPermission;
}> = ({ children, roleRequired, resourceRequired, permissionRequired }) => {
  const { formatMessage } = useIntl();
  const { authenticated, loading } = useAuthenticationValidation(roleRequired, resourceRequired, permissionRequired);
  const { dashboard_permissions: dashboardPermissions, service_desk_permissions: serviceDeskPermissions } = useSelector<
    DashboardState,
    { dashboard_permissions: UserRoles[]; service_desk_permissions: UserOrganisationPermissions[] }
  >(({ userSettings }) => ({
    dashboard_permissions: userSettings.details?.dashboard_permissions,
    service_desk_permissions: userSettings.details?.service_desk_permissions,
  }));
  const { pathname } = useLocation();
  const redirectToLogin = useCallback(() => {
    const url = getOauthUrl(pathname);
    window.location.href = url;
  }, [pathname]);
  if (loading) {
    return <LoadingScreen />;
  }
  if (authenticated) {
    return <>{children}</>;
  }

  // user has valid auth token but no permission for requested route.
  // redirect to first allowed page or login if no pages allowed
  const menuData = menuDataGenerator(formatMessage, dashboardPermissions, serviceDeskPermissions);
  if (menuData.length) {
    return <Redirect to={menuData[0].path} />;
  }
  redirectToLogin();
};

export default AuthProvider;

export interface AuthenticationValidation {
  loading: boolean;
  authenticated: boolean;
  lastChecked: number;
}
