import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Dropdown, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import CamoMenuLayout from '../../layouts/CamoMenuLayout';
import { AircraftActionTypes } from '../../models/aircraft';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import SearchInput from '../../components/SearchInput';
import MxTimeline from '../../components/MXTimeline/MxTimeline';
import MaintenanceEmptyState from '../../assets/empty-state-maintenance.svg';
import LegendItem from '../../components/AircraftServiceOverview/LegendItem';
import globalStyles from '../../utils/globalStyles.module.less';
import styles from './AircraftMaintenanceForecast.module.less';

const baseMx = [
  {
    name: 'Engine 1',
    color: 'linear-gradient(to bottom, #024574, #012140)',
  },
  {
    name: 'Engine 2',
    color: 'linear-gradient(to bottom, #72c9ff, #3f98ff)',
  },
  {
    name: 'Landing Gear Overhaul',
    color: 'linear-gradient(to bottom, #b1f2ec, #79e0d4)',
  },
  {
    name: 'C Check',
    color: 'linear-gradient(to bottom, #b6dbff, #76aee4)',
  },
];
const lineMaintenance = [
  {
    name: 'LLP',
    color: 'linear-gradient(to bottom, #baa3ff, #baa2ff)',
  },
  {
    name: 'OOP',
    color: 'linear-gradient(to left, #8342ff, #ae86f9 0%)',
  },
  {
    name: 'Scheduled',
    color: 'linear-gradient(to bottom, #5400ff, #4e00c7)',
  },
  {
    name: 'Defects',
    color: 'linear-gradient(to top, #2058a4, #3f79b5)',
  },
  {
    name: 'Packages',
    color: 'linear-gradient(to bottom, #0085ff, #0065c2)',
  },
  {
    name: 'SB',
    color: 'linear-gradient(to bottom, #b1f2ec, #79e0d4)',
  },
  {
    name: 'AD',
    color: 'linear-gradient(to bottom, #b1f2ec, #79e0d4)',
  },
];
const area = [{ name: 'Airframe' }, { name: 'Engine' }, { name: 'Propeller' }, { name: 'APU' }];

const AircraftMaintenanceForecast = ({ aircraftMap }) => {
  const {
    params: { id },
  } = useRouteMatch();
  const selectedAircraft = aircraftMap.get(id);
  const { formatMessage } = useIntl();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dates = ['October 2020 - June 2021'];
  const [dateValue, setDateFilter] = useState(dates[0]);
  const [groups, setGroups] = useState([
    { id: 'base', title: 'Base / Overhauls', stackItems: true },
    { id: 'line', title: 'Line', stackItems: true },
  ]);
  const [items, setItems] = useState([{ id: 1, group: 'line' }]);
  const [startTime, setStartTime] = useState(moment().add(-6, 'month'));
  const [endTime, setEndTime] = useState(moment().add(6, 'month'));
  const [activeBaseMX, setActiveBaseMX] = useState([]);
  const [activeLineMaintenance, setActiveLineMaintenance] = useState([]);
  const [activeLineMaintenanceArea, setActiveLineMaintenanceArea] = useState([]);

  const handleFilterClick = (name, func, array) => {
    if (array.includes(name)) {
      const index = array.indexOf(name);
      array.splice(index, 1);
      func([...array]);
    } else {
      array.push(name);
      func([...array]);
    }
  };

  useEffect(() => {
    const getMXItems = () => {
      const aircraftChecks =
        selectedAircraft &&
        selectedAircraft.maintenanceChecks &&
        selectedAircraft.maintenanceChecks.map((check) => {
          return {
            id: `${check.event}-${selectedAircraft.registration}-${check.planned_start_date}`,
            group: selectedAircraft.id,
            title: check.description,
            start_time: moment(
              check.planned_start_date === 'MMM DD YYYY' ? check.planned_end_date : check.planned_start_date,
              'MMM DD YYYY',
            ),
            location: check.location,
            end_time: moment(check.planned_end_date, 'MMM DD YYYY'),
          };
        });
      setItems(aircraftChecks);
    };
    getMXItems();
  }, [selectedAircraft]);

  return (
    <CamoMenuLayout>
      <PageHeaderWrapper layout="modern" title={formatMessage({ id: 'title.maintenanceForecast' })}>
        <Row>
          <Card className={`${styles.pageCard} ${globalStyles.pageCard}`}>
            <Col lg={19} md={19} sm={19} className={styles.maintenanceCol}>
              <div className={styles.cardHeader}>Upcoming Maintenance Items</div>
              <div className={styles.searchRow}>
                <SearchInput
                  placeholder={formatMessage({ id: 'form.placeholder.searchMXType' })}
                  value={searchValue}
                  visible={searchVisible}
                  onToggle={() => setSearchVisible(!searchVisible)}
                  onBlur={(value) => {
                    if (!value) {
                      setSearchVisible(false);
                    }
                  }}
                  onChange={(value) => setSearchValue(value)}
                  data-test="searchInput"
                  onClear={() => setSearchValue(null)}
                  inputRef="aircraftMaintenance"
                />
                <div className={styles.dropdownContainer}>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu onClick={(key) => setDateFilter(key.key)}>
                        {dates.map((date) => {
                          return <Menu.Item key={`${date}`}>{date}</Menu.Item>;
                        })}
                      </Menu>
                    }
                  >
                    <span className={styles.dropdownLink}>
                      {formatMessage({ id: 'text.show' })}: <span>{dateValue}</span> <Icon type="caret-down" />
                    </span>
                  </Dropdown>
                </div>
              </div>
              {items && items.length !== 0 ? (
                <MxTimeline
                  groups={groups}
                  items={items}
                  startTime={startTime}
                  endTime={endTime}
                  timeSteps={{
                    second: 1,
                    minute: 1,
                    hour: 1,
                    day: 1,
                    month: 1,
                    year: 1,
                  }}
                />
              ) : (
                <div className={styles.emptyStateWrapper}>
                  <img src={MaintenanceEmptyState} alt="no data" className={styles.emptyState} />
                  <span>{formatMessage({ id: 'text.noData' })}</span>
                </div>
              )}
            </Col>
            <Col lg={5} md={5} sm={5} className={styles.filtersCol}>
              <div className={styles.filterHeader}>{formatMessage({ id: 'text.baseMxOverHauls' })}</div>
              {baseMx.map((b) => (
                <LegendItem
                  key={b.name}
                  name={b.name}
                  colour={b.color}
                  onClick={handleFilterClick}
                  func={setActiveBaseMX}
                  active={activeBaseMX}
                />
              ))}
              <div className={`${styles.filterHeader} ${styles.gap}`}>
                {formatMessage({ id: 'text.lineMaintenance' })}
              </div>
              {lineMaintenance.map((b) => (
                <LegendItem
                  key={b.name}
                  name={b.name}
                  colour={b.color}
                  onClick={handleFilterClick}
                  func={setActiveLineMaintenance}
                  active={activeLineMaintenance}
                />
              ))}
              <div className={`${styles.filterHeader} ${styles.gap}`}>
                {formatMessage({ id: 'text.lineMaintenanceArea' })}
              </div>
              {area.map((a) => (
                <div className={activeLineMaintenanceArea.includes(a.name) ? styles.areaNameActive : styles.areaName}>
                  <button
                    type="button"
                    key={a.name}
                    className={styles.areaNameButton}
                    onClick={() => handleFilterClick(a.name, setActiveLineMaintenanceArea, activeLineMaintenanceArea)}
                  >
                    {a.name}
                  </button>
                </div>
              ))}
            </Col>
          </Card>
        </Row>
      </PageHeaderWrapper>
    </CamoMenuLayout>
  );
};

AircraftMaintenanceForecast.propTypes = {
  aircraftMap: PropTypes.instanceOf(Map).isRequired,
};

const AircraftMaintenanceForecastWithRedux = connect(
  ({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  }),
  (dispatch) => ({
    saveAllAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.SAVE_ALL,
        payload,
      }),
  }),
)(AircraftMaintenanceForecast);

export default AircraftMaintenanceForecastWithRedux;
