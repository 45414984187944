import React from 'react';
import { ResponsiveContainer, ComposedChart, CartesianGrid, Line, Area, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';

const CostGraph = ({ data, isLnfpForecastVisisble, isFleetAverageForecastVisible, isXForecastVisible }) => {
  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#b0bac9">
          {payload.value}
        </text>
      </g>
    );
  };

  CustomizedAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object,
  };

  CustomizedAxisTick.defaultProps = {
    x: 0,
    y: 0,
    payload: {},
  };

  const formatter = (value) => `£${value}k`;
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={data} width={600} height={300} margin={{ top: 0, right: 0, left: 25, bottom: 0 }}>
        <defs>
          <linearGradient id="colourLnfp" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(23, 144, 255, 0.1" />
            <stop offset="100%" stopColor="rgba(23, 144, 255, 0.01" />
          </linearGradient>
        </defs>
        <XAxis stroke="#b0bac9" dataKey="year" tick={<CustomizedAxisTick />} />
        <YAxis stroke="#b0bac9" tickFormatter={formatter} />
        <CartesianGrid stroke="#b0bac9" strokeDasharray="2 2" />
        {isLnfpForecastVisisble ? (
          <Area
            dataKey="lnfpForecast"
            stroke="#1790ff"
            fillOpacity={1}
            fill="url(#colourLnfp)"
            strokeWidth={2}
            dot={{ fill: '#ffffff' }}
          />
        ) : null}
        {isFleetAverageForecastVisible ? (
          <Line dataKey="fleetForecast" stroke="#012140" strokeDasharray="3 3" dot={false} strokeWidth={2} />
        ) : null}
        {isXForecastVisible ? (
          <Line dataKey="estimatedForecast" stroke="#f5222d" strokeDasharray="3 3" dot={false} strokeWidth={2} />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

CostGraph.propTypes = {
  data: PropTypes.array,
  isLnfpForecastVisisble: PropTypes.bool,
  isFleetAverageForecastVisible: PropTypes.bool,
  isXForecastVisible: PropTypes.bool,
};

CostGraph.defaultProps = {
  data: [],
  isLnfpForecastVisisble: true,
  isFleetAverageForecastVisible: true,
  isXForecastVisible: true,
};

export default CostGraph;
