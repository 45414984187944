import React, { ReactElement } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Rectangle } from 'recharts';

export const CustomizedYAxisTick = (props): ReactElement => {
  const { x, y, payload, unit } = props;

  return (
    <g transform={`translate(${x - 5},${y - 10})`}>
      <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(0)" fill="#b0bac9">
        {payload.value}
        {unit || null}
      </text>
    </g>
  );
};

export const CustomizedXAxisTick = (props): ReactElement => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" transform="rotate(0)" fill="#b0bac9">
        {payload.value}
      </text>
    </g>
  );
};

export const RoundedBar = ({ fill, x, y, width, height, radius }): ReactElement => {
  return <Rectangle width={width} height={height} x={x} y={y} radius={radius} fill={fill} />;
};

export const formatDate = (data, format: string): Array<any> => {
  const clonedData = _.cloneDeep(data);
  const newData = clonedData.map((entry) => {
    const newEntry = entry;
    newEntry.date = moment(newEntry.date).format(format);
    return newEntry;
  });
  return newData;
};

export const filterDataByTimeScale = (data, timeScale, unix?, addBlankDate?): Array<any> => {
  if (timeScale.text === 'Custom') {
    const filteredData = data.filter((item) => {
      return moment.unix(item.date).isBetween(
        moment().add(timeScale.value.from, 'month'),
        moment()
          .startOf('month')
          .add(timeScale.value.to, 'month'),
        'month',
        '[]',
      );
    });
    if (addBlankDate) {
      const timescaleFrom = parseInt(timeScale.value.from, 10) - 1;
      const timescaleTo = parseInt(timeScale.value.to, 10) + 1;
      const blankDateStart = moment()
        .startOf('month')
        .add(timescaleFrom, 'months');

      const blankDateEnd = moment()
        .startOf('month')
        .add(timescaleTo, 'months');
      filteredData.unshift({ date: blankDateStart.unix() });
      filteredData.push({ date: blankDateEnd.unix() });
    }
    return filteredData;
  }

  const timescale = parseInt(timeScale.value, 10) - 1;
  const blankDate = moment(
    moment()
      .startOf('month')
      .subtract(parseInt(timeScale.value, 10), 'months'),
  ).unix();

  let filteredData;
  if (unix) {
    filteredData = data.filter(
      (item) =>
        moment.unix(item.date).isSameOrAfter(
          moment()
            .startOf('month')
            .subtract(timescale, 'months'),
        ),
      'month',
    );
    if (addBlankDate) {
      // add a blank value with no tick for graph padding
      filteredData.unshift({ date: blankDate });
    }
    return filteredData;
  }

  return data.filter(
    (item) =>
      moment(item.date, 'DD/MM/YYYY').isSameOrAfter(
        moment()
          .startOf('month')
          .subtract(timescale, 'months'),
      ),
    'month',
  );
};

export const removeSpaces = (input: string): string => {
  return input.replace(/ /g, '');
};

export const colourGradients = (staticColour, dataKeys): any => {
  const flattenedDataKeys = dataKeys.map((item) => removeSpaces(item));
  return (
    <defs>
      <linearGradient id={staticColour ? `${flattenedDataKeys[0]}` : 'legend1'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#72c9ff" />
        <stop offset="100%" stopColor="#3f98ff" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[1]}` : 'legend2'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#024574" />
        <stop offset="100%" stopColor="#012140" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[2]}` : 'legend3'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#b6dbff" />
        <stop offset="100%" stopColor="#76aee4" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[3]}` : 'legend4'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#d7ebfe" />
        <stop offset="100%" stopColor="#b5c5d6" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[4]}` : 'legend5'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#b1f2ec" />
        <stop offset="100%" stopColor="#79e0d4" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[5]}` : 'legend6'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#f4f5f7" />
        <stop offset="100%" stopColor="#e6e8ed" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[6]}` : 'legend7'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#7bc88d" />
        <stop offset="100%" stopColor="#4aa91c" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[7]}` : 'legend8'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#f4d996" />
        <stop offset="100%" stopColor="#e4c966" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[8]}` : 'legend9'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#ff7474" />
        <stop offset="100%" stopColor="#ff4040" />
      </linearGradient>
      <linearGradient id={staticColour ? `${flattenedDataKeys[9]}` : 'legend10'} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#72c9ff" />
        <stop offset="100%" stopColor="#3f98ff" />
      </linearGradient>
    </defs>
  );
};
