import React, { useState, useEffect } from 'react';
import { Popover, Badge } from 'antd';
import Timeline, { TimelineHeaders, DateHeader } from 'react-calendar-timeline';
import moment from 'moment';
import styles from './MxTimeline.module.less';

export default ({
  groups,
  items,
  onViewChange,
  startTime,
  endTime,
  hideSidebar,
  timeSteps,
  singleAircraft,
  handleTimeChange,
}) => {
  const visibleStartTime = startTime || moment().add(-1, 'year');
  const visibleEndTime = endTime || moment().add(1, 'year');
  const [monthStep, setMonthStep] = useState(3);

  const gradientStyles = {
    cCheck: 'linear-gradient(to bottom, #b4b1f2, #799fe0)',
    engine1: 'linear-gradient(to bottom, #024574, #012140)',
    engine2: 'linear-gradient(to bottom, #72c9ff, #3f98ff)',
    landingGear: 'linear-gradient(to bottom, #b1f2ec, #79e0d4)',
  };

  const getBackgroundStyle = (item) => {
    let backgroundColor = '';
    if (item.location) {
      backgroundColor = item.location === '01' ? gradientStyles.engine1 : gradientStyles.engine2;
    }
    if (item.title.toLowerCase().includes('check')) {
      backgroundColor = gradientStyles.cCheck;
    } else {
      backgroundColor = gradientStyles.landingGear;
    }
    return backgroundColor;
  };

  const itemRenderer = ({ item, getItemProps }) => {
    return (
      <div style={{ padding: '20px' }}>
        <Popover content={<div>{item.description}</div>} title={item.title} trigger="hover">
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getItemProps({
              style: {
                background: getBackgroundStyle(item),
                border: 'none',
                borderRadius: '25px',
                textAlign: 'center',
                minWidth: '20px',
                opacity: 1,
                maxHeight: '18px',
                display: 'flex',
                justifyContent: 'center',
              },
            })}
          >
            <p
              style={{
                color: '#646464',
                textAlign: 'center',
                paddingTop: '20px',
                fontSize: '9px',
                letterSpacing: '.5',
                lineHeight: '1',
                whiteSpace: 'nowrap',
              }}
            >
              {item.title}
            </p>
          </div>
        </Popover>
      </div>
    );
  };

  const verticalLineClassNamesForTime = (timeStart) => {
    // const currentTimeStart = moment(timeStart).format('YYYY');
    const currentTimeStart = moment(timeStart).format('MM');
    let classes = [];
    if (currentTimeStart % 2 === 0) {
      classes = ['', styles.evenYearCol, styles.dash];
    } else {
      classes = ['', styles.oddYearCol, styles.dash];
    }

    return classes;
  };

  const getWeekOfMonth = (dateObj) => {
    const date = moment(dateObj);
    const weekInYear = date.isoWeek();
    const result = weekInYear - date.startOf('month').isoWeek();
    return result < 0 ? weekInYear : result + 1;
  };

  return (
    <div className={styles.timelineWrapper}>
      <Timeline
        stackItems
        defaultTimeStart={moment()}
        defaultTimeEnd={visibleEndTime}
        canMove={false}
        canResize={false}
        canChangeGroup={false}
        itemRenderer={itemRenderer}
        verticalLineClassNamesForTime={verticalLineClassNamesForTime}
        horizontalLineClassNamesForGroup={() => [` ${styles.horizontalLines}`]}
        groups={groups}
        items={items}
        timeSteps={timeSteps}
        sidebarWidth={hideSidebar ? 0 : undefined}
        lineHeight={singleAircraft ? 100 : 50}
        itemHeightRatio={0.5}
      >
        <TimelineHeaders
          calendarHeaderStyle={{ border: 'none' }}
          style={{ backgroundColor: 'white', borderBottom: '1px solid #e6e8ed' }}
        >
          <DateHeader
            unit={timeSteps.day === 7 ? 'month' : 'primaryHeader'}
            labelFormat={timeSteps.day === 7 ? 'MMM' : undefined}
            intervalRenderer={({ getIntervalProps, intervalContext }) => {
              return (
                <div
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getIntervalProps()}
                  className={styles.primaryHeader}
                >
                  <span className={styles.primaryText}>{intervalContext.intervalText}</span>
                </div>
              );
            }}
          />
          {timeSteps.day === 7 || timeSteps.month === 3 ? (
            <DateHeader
              unit={timeSteps.day === 7 ? 'day' : 'month'}
              height={50}
              className={styles.headerQuarter}
              intervalRenderer={({ getIntervalProps, intervalContext }) => {
                return (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getIntervalProps()}
                    onClick={(e) => {
                      onViewChange();
                      getIntervalProps().onClick(e);
                    }}
                  >
                    {timeSteps.day === 7 ? (
                      <span className={styles.test}>{`W${getWeekOfMonth(intervalContext.interval.startTime)}`}</span>
                    ) : (
                      <span className={styles.test}>{moment(intervalContext.interval.startTime).format('[Q]Q')}</span>
                    )}
                  </div>
                );
              }}
            />
          ) : (
            <DateHeader height={50} />
          )}
        </TimelineHeaders>
      </Timeline>
    </div>
  );
};
