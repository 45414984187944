import { getOauthUrl } from './oauth';

/**
 * Redirects the user to a suitable error page using the supplied redirecting function.
 * @param {number} httpCode
 * @param {(url: string) => void} redirectFunc
 */
export const redirectFromError = (httpCode: number, redirectFunc: (url: string) => void): void => {
  if (httpCode === 401) {
    redirectFunc(getOauthUrl(window.location.pathname));
  } else if (httpCode === 403) {
    redirectFunc('/exception/403');
  } else if (httpCode <= 499) {
    redirectFunc('/exception/404');
  } else {
    redirectFunc('/exception/500');
  }
};

/**
 * Redirects the user to an error page.
 * @param {string} url The URL we want to request
 */
export default function error({ statusCode }: { statusCode: number }): void {
  if (process.env.NODE_ENV === 'development') {
    console.error('This redirection should be updated');
  }
  if (statusCode === 401) {
    window.location.href = getOauthUrl(window.location.pathname);
    return;
  }
  const redirect = (pathname): void => {
    if (process.env.NODE_ENV !== 'development') {
      // @FIXME Decide if this is the best approach
      window.location.pathname = pathname;
    }
  };
  redirectFromError(statusCode, redirect);
}
