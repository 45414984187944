import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { submitPartSubmission } from '../../services/apiNew';
import TooltipIcon from '../../assets/icon-tooltip.svg';

import { ATAArray, MeasureArray } from './DropdownLists';

import Dropdown from './Components/Dropdown';

const FormCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  padding: 28px;
  box-shadow: 0 0 10px 0 #dbe3ed;
  border: solid 2px #fff;
  background-color: #fafbfd;
`;

const Label = styled.div`
  color: #242d41;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
  display: flex;
`;

const TextDiv = styled.div`
  margin-bottom: 32px;
  color: rgba(36, 45, 65, 0.7);
`;

const StyledInput = styled.input`
  height: 34px;
  width: ${({ width }): string => (width ? `${width}` : '400px')};
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  border ${({ error }): string => (error ? 'solid 1px #ca2322' : 'solid 1px rgba(36, 45, 65, 0.1)')};
  background-color: #fff;
  color: rgba(36, 45, 65, 0.8);
  margin-bottom: 24px;
  margin-right: ${({ marginright }): string => (marginright ? `${marginright}` : '0')};
  padding-left: 10px;
  ::placeholder {
    color: rgba(36, 45, 65, 0.3);
  }
`;

const normalButtonStyle = css`
  border: solid 1px rgba(36, 45, 65, 0.1);
  background-color: rgba(36, 45, 65, 0.02);
  color: rgba(36, 45, 65, 0.7);
`;

const activeButtonStyle = css`
  border: solid 1px rgba(24, 144, 255, 0.3);
  background-color: rgba(24, 144, 255, 0.1);
  color: #3f98ff;
`;

const StyledButton = styled.button`
  min-width: 60px;
  height: 32px;
  border-radius: 2px;
  margin-bottom: 24px;
  padding: 0 10px;
  cursor: pointer;
  margin-right: ${({ marginRight }): string => `${marginRight}` || '0'};
  ${({ active }): string => (active ? `${activeButtonStyle}` : `${normalButtonStyle}`)};
`;

const StyledSubmit = styled.button`
  background-color: #1890ff;
  color: #fff;
  height: 40px;
  margin-top: 16px;
  border-radius: 20px;
  padding: 0 40px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-top: -2px;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  left: 26px;
  top: -34px;
  padding: 10px;
  border: solid 2px #fff;
  background-color: #fafbfd;
  border-radius: 2px;
  width: 475px;
  font-size: 12px;
  box-shadow: 0 0 10px 0 rgba(36, 45, 65, 0.2);
  z-index: 10;
`;

const TooltipDiv = styled.div`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledSpan = styled.span`
  font-weight: 400;
`;

const ErrorMessage = styled.p`
  color: #ca2322;
  margin-top: -24px;
  margin-bottom: 6px;
  font-size: 12px;
`;

const StyledSubmitWrapper = styled.div`
  margin-top: ${(props) => {
    if (props.status) {
      return '0px';
    }
    return '63px';
  }};
`;

const AlertBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: ${({ type }): string => (type === 'success' ? '1px solid  #b7eb8f' : '1px solid  #ffa39e')};
  background-color: ${({ type }): string => (type === 'success' ? '#f6ffed' : '#fff1f0')};
  padding: 8px 12px;
`;

const validateString = (fieldName, value, charLimit) => {
  if (value.trim() === '') {
    return `${fieldName} is required`;
  }
  if (value.trim().length > charLimit) {
    return `${fieldName} needs to be ${charLimit + 1} characters or fewer`;
  }
  return null;
};

const NewPartForm: React.FC = () => {
  const [partType, setPartType] = useState('part');
  const [aircraftType, setAircraftType] = useState('all');
  const [chosenClass, setChosenClass] = useState('Rotable');
  const [choseMeasure, setChoseMeasure] = useState('Each');
  const [chosenATA, setChosenATA] = useState('00-00: General');
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const partRef = useRef(null);
  const descriptionRef = useRef(null);

  const { formatMessage } = useIntl();

  const [errors, setErrors] = useState({ partNumber: null, description: null });
  const [touched, setTouched] = useState({ partNumber: null, description: null });
  const [status, setStatus] = useState(null);

  const resetStatus = () => {
    if (status !== null) {
      setStatus(null);
    }
  };
  const handleChange = (evt) => {
    const { name, value, id } = evt.target;
    resetStatus();
    setTouched({
      ...touched,
      [name]: true,
    });

    const charLim = id === 'description' ? 36 : 32;
    const error = validateString(name, value, charLim);
    const errorObj = { ...errors };
    errorObj[id] = error;

    setErrors(errorObj);
  };

  const handleFormSubmit = async (): Promise<void> => {
    const payload = {
      partNumber: partRef.current.value,
      description: descriptionRef.current.value,
      class: chosenClass,
      measure: choseMeasure,
      partType,
      aircraftType,
      ata: chosenATA,
    };

    setErrors({
      partNumber: validateString('Part number', payload.partNumber, 32),
      description: validateString('Description', payload.description, 36),
    });

    const filtered = Object.values(errors).filter((el) => {
      return el != null;
    });
    const filteredTouched = Object.values(touched).filter((el) => {
      return el != null;
    });

    if (filtered.length < 1 && filteredTouched.length > 0) {
      // submit the form
      const reply = await submitPartSubmission(payload);
      setStatus(reply && reply.status ? reply.status : reply);
    }
  };

  const setClass = (option): void => {
    resetStatus();
    setChosenClass(option);
  };

  const setMeasure = (option): void => {
    resetStatus();
    setChoseMeasure(option);
  };

  const setATA = (option): void => {
    resetStatus();
    setChosenATA(option);
  };

  useEffect(() => {
    if (chosenClass === 'Rotable') {
      setChoseMeasure('Each');
    }
  }, [chosenClass]);

  useEffect(() => {
    setTimeout(() => {
      resetStatus();
    }, 4000);
  });

  return (
    <FormCard>
      <Label marginBottom="12px">{formatMessage({ id: 'title.addNewPart' })}</Label>
      <TextDiv>{formatMessage({ id: 'text.toAddNewPart' })}</TextDiv>
      <Label marginBottom="4px">{formatMessage({ id: 'title.partNumber' })}</Label>
      <StyledInput
        type="text"
        name="Part Number"
        id="partNumber"
        ref={partRef}
        placeholder="123"
        error={errors.partNumber}
        onChange={handleChange}
      />
      {errors.partNumber && <ErrorMessage>{errors.partNumber}</ErrorMessage>}
      <Label marginBottom="4px">{formatMessage({ id: 'text.description' })}</Label>
      <StyledInput
        type="text"
        name="description"
        id="description"
        ref={descriptionRef}
        placeholder="Enter a description"
        error={errors.description}
        onChange={handleChange}
      />
      {errors.description && <ErrorMessage>{errors.description}</ErrorMessage>}
      <Label marginBottom="4px">
        {formatMessage({ id: 'text.class' })}{' '}
        <IconWrapper>
          <img
            src={TooltipIcon}
            alt="tooltip icon"
            onMouseEnter={(): void => setTooltipVisible(true)}
            onMouseLeave={(): void => setTooltipVisible(false)}
          />
          {tooltipVisible ? (
            <TooltipWrapper>
              <TooltipDiv>
                Rotable <StyledSpan>- Serialised Part</StyledSpan>
              </TooltipDiv>
              <TooltipDiv>
                Consumable <StyledSpan>- Expendable non-serialised part</StyledSpan>
              </TooltipDiv>
              <TooltipDiv>
                Consumable repairable <StyledSpan>- High cost or repairable part that has no serial number</StyledSpan>
              </TooltipDiv>
            </TooltipWrapper>
          ) : null}
        </IconWrapper>
      </Label>
      <Dropdown
        options={['Rotable', 'Consumable', 'Consumable Repairable']}
        optionFunction={setClass}
        activeOption={chosenClass}
        marginBottom="24px"
        placement="bottom"
      />
      <Label marginBottom="4px">{formatMessage({ id: 'text.measure' })}</Label>
      <Dropdown
        options={MeasureArray}
        optionFunction={setMeasure}
        activeOption={choseMeasure}
        marginBottom="24px"
        placement="bottom"
        disabled={chosenClass === 'Rotable'}
      />
      <Label marginBottom="4px">{formatMessage({ id: 'text.partType' })}</Label>
      <StyledButton
        active={partType === 'part'}
        marginRight="4px"
        onClick={(): void => {
          resetStatus();
          setPartType('part');
        }}
      >
        {formatMessage({ id: 'title.part' })}
      </StyledButton>
      <StyledButton
        active={partType === 'tool'}
        onClick={(): void => {
          resetStatus();
          setPartType('tool');
        }}
      >
        {formatMessage({ id: 'text.tool' })}
      </StyledButton>
      <Label marginBottom="4px">{formatMessage({ id: 'text.acType' })}</Label>
      <StyledButton
        active={aircraftType === 'all'}
        marginRight="4px"
        onClick={(): void => {
          resetStatus();
          setAircraftType('all');
        }}
      >
        {formatMessage({ id: 'form.button.all' })}
      </StyledButton>
      <StyledButton
        active={aircraftType === '737'}
        marginRight="4px"
        onClick={(): void => {
          resetStatus();
          setAircraftType('737');
        }}
      >
        {formatMessage({ id: 'text.737' })}
      </StyledButton>
      <StyledButton
        active={aircraftType === '737NG'}
        marginRight="4px"
        onClick={(): void => {
          resetStatus();
          setAircraftType('737NG');
        }}
      >
        {formatMessage({ id: 'text.737NG' })}
      </StyledButton>
      <StyledButton
        active={aircraftType === '737MAX'}
        marginRight="4px"
        onClick={(): void => {
          resetStatus();
          setAircraftType('737MAX');
        }}
      >
        {formatMessage({ id: 'text.737MAX' })}
      </StyledButton>
      <Label marginBottom="4px">{formatMessage({ id: 'text.ata' })}</Label>
      <Dropdown
        activeOption={chosenATA}
        options={ATAArray}
        optionFunction={setATA}
        marginBottom="24px"
        longContent
        placement="top"
      />
      <StyledSubmitWrapper status={status}>
        {status && (
          <>
            <AlertBox type={status === 200 ? 'success' : 'warning'}>
              {status && status === 200 ? 'Success: New part added' : `Warning: Part number already exists`}
            </AlertBox>
          </>
        )}
        <StyledSubmit onClick={handleFormSubmit}>{formatMessage({ id: 'form.button.addNewPart' })}</StyledSubmit>
      </StyledSubmitWrapper>
    </FormCard>
  );
};

export default NewPartForm;
