import React, { ReactElement, useEffect, useState } from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import LegendItem from '../../Charts/Legend/LegendItem';
import { colourGradients, CustomizedYAxisTick, filterDataByTimeScale, removeSpaces } from './utils';
import styles from './ReliabilityReportGraph.module.less';

const GenericBarGraph = ({
  data,
  title,
  axisKey,
  format,
  selectedTimeScale,
  button,
  dataKeys,
  filter = null,
  staticColour = null,
  padding = { left: 0, right: 0 },
  firstTickPadding = false,
}): ReactElement => {
  const [legendItems, setLegendItems] = useState([]);
  const [ticks, setTicks] = useState([]);
  const [colours, setColours] = useState();

  // set the legend items based on data keys
  useEffect(() => {
    if (dataKeys) setLegendItems(dataKeys.map((key) => ({ name: key, show: true })));
  }, [dataKeys]);

  // configure graph ticks if using time format
  useEffect(() => {
    if (format !== 'time') return;
    const ticksArray = [];
    if (selectedTimeScale.text === 'Custom') {
      const lengthOfTime = moment
        .duration(
          moment()
            .add(selectedTimeScale.value.from, 'months')
            .diff(moment().add(selectedTimeScale.value.to, 'months')),
        )
        .as('months');
      const roundedTime = Math.round(Math.abs(lengthOfTime));
      for (let i = 0; i < roundedTime + 1; i += 1) {
        const timeStamp = moment()
          .add(selectedTimeScale.value.to, 'months')
          .startOf('month')
          .subtract(i, 'months')
          .unix();
        ticksArray.push(timeStamp);
      }
      setTicks(ticksArray);
    } else {
      for (let i = 0; i < selectedTimeScale.value; i += 1) {
        const timeStamp = moment()
          .startOf('month')
          .subtract(i, 'months')
          .unix();
        ticksArray.push(timeStamp);
      }
      // add the next month so that the current month is displayed in full on the axis
      ticksArray.unshift(
        moment()
          .startOf('month')
          .add(1, 'months')
          .unix(),
      );
      setTicks(ticksArray);
    }
  }, [format, selectedTimeScale.text, selectedTimeScale.value]);

  useEffect(() => {
    if (filter && filter.value === 'Fleet') {
      setColours(colourGradients(staticColour, dataKeys));
    }
  }, [dataKeys, filter, staticColour]);

  const setLegend = (key: string, value: boolean): void => {
    const newLegendItems = legendItems;
    const index = newLegendItems.findIndex((i) => i.name === key);
    newLegendItems[index].show = value;
    if (legendItems.length > 2) {
      if (newLegendItems.every((item) => item.show === false)) newLegendItems[index].show = true;
      setLegendItems([...newLegendItems]);
    } else {
      if (legendItems.length === 2 && value === false) {
        if (index === 0) newLegendItems[1].show = true;
        if (index === 1) newLegendItems[0].show = true;
      }
      setLegendItems([...newLegendItems]);
    }
  };

  let newData = [];
  if (data && data[0] && Object.getOwnPropertyNames(data[0]).includes('date')) {
    newData = filterDataByTimeScale(data, selectedTimeScale, true, firstTickPadding);
  }

  const addRadiusToData =
    newData.length > 0 &&
    newData.map((item) => {
      return {
        ...item,
        radius: 10,
      };
    });

  const longestAxisLabel = (): number => {
    let length = 0;
    if (data)
      data.forEach((item) => {
        if (item[`${axisKey}`].length > length) length = item[`${axisKey}`].length;
      });
    return length;
  };
  const flattenedDataKeys = dataKeys.map((item) => removeSpaces(item));
  return (
    <div className={styles.graphWrapper}>
      <div className={styles.headerRow}>
        <h2>{title}</h2>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={addRadiusToData} margin={{ top: 10, bottom: 20, right: 12, left: longestAxisLabel() * 2 }}>
          <CartesianGrid strokeDasharray="2 2" stroke="#8097b1" opacity={0.2} />
          <XAxis
            dataKey="date"
            axisLine={{ stroke: '#E0E7FF' }}
            ticks={ticks}
            domain={[ticks[ticks.length - 1], ticks[0]]}
            interval={0}
            type="number"
            tick={{ fill: '#b0bac9' }}
            tickLine={false}
            tickFormatter={(t): string => moment.unix(t).format('MMM')}
            padding={padding}
          />
          <YAxis
            axisLine={{ stroke: '#E0E7FF' }}
            tickLine={false}
            tick={<CustomizedYAxisTick />}
            domain={addRadiusToData.length > 0 ? [0, 'auto'] : [0, 1]}
          />
          {colours}
          {legendItems.map((legend, index) => {
            if (legend.show) {
              return (
                <Bar
                  key={legend.name}
                  type="monotone"
                  dataKey={legend.name}
                  fill={staticColour ? `url(#${flattenedDataKeys[index]})` : `url(#legend${index + 1})`}
                  barSize={7}
                />
              );
            }
            return null;
          })}
        </BarChart>
      </ResponsiveContainer>
      <div className={styles.legend}>
        {legendItems.map((legend, index) => (
          <LegendItem
            onButtonClick={(): void => setLegend(legend.name, !legend.show)}
            key={legend.name}
            isActive={legend.show}
            label={legend.name}
            index={index + 1}
            staticColour={staticColour}
            dataKeys={flattenedDataKeys}
            button={button}
          />
        ))}
      </div>
    </div>
  );
};

export default GenericBarGraph;

export interface LineType {
  dataKey: string;
  startColour: string;
  stopColour: string;
}
