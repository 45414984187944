import React, { FunctionComponent } from 'react';
import styles from './SummaryCard.module.less';

const SummaryCard: FunctionComponent<SummaryCardProps> = ({
  title,
  children,
  summaryCount,
  countColor,
  small,
  engine,
}) => {
  return (
    <div className={small ? styles.summaryCardSmall : styles.summaryCard}>
      <span style={{ color: `${countColor}` }} className={engine ? styles.engineCount : styles.count}>
        {summaryCount}
      </span>
      <div className={engine ? styles.engineValue : styles.value}>
        <h3 className={`${styles.summaryCardHeader}`}>{title}</h3>
        {children}
      </div>
    </div>
  );
};

type SummaryCardProps = {
  title: string;
  summaryCount: number;
  countColor: string;
  children?: JSX.Element | JSX.Element[];
  small?: boolean;
  engine?: boolean;
};

export default SummaryCard;
