import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Col, Row, Card } from 'antd';
import PropTypes from 'prop-types';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import { queryAircraftsWithUtilisation } from '../../services/apiNew';
import { AircraftActionTypes } from '../../models/aircraft';
import FleetDefectsContainer from '../../components/FleetDefects/FleetDefectsContainer';
import defectsIcon from '../../assets/pageHeaderIcons/title-fleet-defects.svg';
import styles from './FleetDefects.module.less';

class FleetDefects extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    saveAllAircraft: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.getAllAircraft();
  }

  getAllAircraft = async () => {
    const { saveAllAircraft } = this.props;
    const res = await queryAircraftsWithUtilisation();
    saveAllAircraft(res.data.data.allAircraft);
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <PageHeaderWrapper>
        <PageHeaderWrapper
          layout="modern"
          title={
            <span>
              <img src={defectsIcon} alt="reports" className={styles.headerIcon} />{' '}
              {formatMessage({ id: 'text.fleetDefects' })}
            </span>
          }
        />
        <Row gutter={20} className={styles.fleetTableRow}>
          <Col>
            <Card className={cardStyles.pageCard} bordered={false}>
              <Col span={24}>
                <FleetDefectsContainer />
              </Col>
            </Card>
          </Col>
        </Row>
      </PageHeaderWrapper>
    );
  }
}

const fleetDefectsWithRedux = connect(
  ({ aircraft }) => ({
    lastFetched: aircraft.lastFetched,
  }),
  (dispatch) => ({
    saveAllAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.SAVE_ALL,
        payload,
      }),
  }),
)(FleetDefects);
export default withRouter(injectIntl(fleetDefectsWithRedux));
