import React, { useState, useEffect } from 'react';
import { useIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import { Table, Spin, Button } from 'antd';
import { AircraftActionTypes } from '../../models/aircraft';
import SearchInput from '../SearchInput';
import TableTitle from '../TableTitle/TableTitle';
import defaults from '../../utils/defaults';
import ModernPagination from '../ModernPagination';
import DefectsEmptyState from '../../assets/empty-state-defects.svg';
import ShowAllIcon from '../../assets/continue-arrow.svg';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import styles from './DefectsTable.module.less';

const DefectsTable = ({ aircraft, userSettings, shortList, defectsData, hideShowAll }) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filteredDefects, setFilteredDefects] = useState([]);
  const { formatMessage } = useIntl();
  const [dataMessage, setDataMessage] = useState('');
  const [currentColumn, setCurrentColumn] = useState('');
  const [currentDirection, setCurrentDirection] = useState('');

  useEffect(() => {
    setFilteredDefects(defectsData);
    setDataMessage(
      defectsData.length > 0 ? formatMessage({ id: 'text.noSearchData' }) : formatMessage({ id: 'text.noData' }),
    );
  }, [defectsData, formatMessage]);
  const status = {
    open: {
      badge: 'warning',
      text: formatMessage({ id: 'status.open' }),
      colour: 'linear-gradient(to bottom, #ffa174, #ffbc40)',
    },
    resolved: {
      badge: 'success',
      text: formatMessage({ id: 'status.resolved' }),
      colour: 'linear-gradient(to bottom, #2fc595, #228d6b)',
    },
    overdue: {
      badge: 'error',
      text: formatMessage({ id: 'status.overdue' }),
      colour: 'linear-gradient(to bottom, #ff7474, #ff4040)',
    },
  };

  const sortData = (value, sortFunctionType) => {
    //TODO: sort all data, apply filter, apply pagination - done
    //different compare functions
    //determine if already selected, direction
    let direction;

    const getStatusOrder = (workpack) => {
      if (workpack.closing_date) {
        return 4;
      }
      if (moment(workpack.planned_end_date, 'MMM DD YYYY').isBefore()) {
        return 3;
      }
      if (moment(workpack.planned_start_date, 'MMM DD YYYY').isBefore()) {
        return 2;
      }
      return 1;
    };

    const compareNumber = (a, b) => {
      if (value === 'status') {
        if (direction === 'descending') {
          return getStatusOrder(a) - getStatusOrder(b);
        }
        return getStatusOrder(b) - getStatusOrder(a);
      }
      if (direction === 'descending') {
        return a[value] - b[value];
      }
      return b[value] - a[value];
    };

    const compareString = (a, b) => {
      if (direction === 'descending') {
        const bText = b[value].toUpperCase();
        const aText = a[value].toUpperCase();
        if (bText < aText) {
          return -1;
        }
        if (bText > aText) {
          return 1;
        }

        return 0;
      }

      const aText = a[value].toUpperCase();
      const bText = b[value].toUpperCase();
      if (aText < bText) {
        return -1;
      }
      if (aText > bText) {
        return 1;
      }

      return 0;
    };

    const compareTime = (a, b) => {
      if (direction === 'descending') {
        return moment(a[value], 'MMM DD YYYY').unix() - moment(b[value], 'MMM DD YYYY').unix();
      }
      return moment(b[value], 'MMM DD YYYY').unix() - moment(a[value], 'MMM DD YYYY').unix();
    };

    const filteredDefectsClone = [...filteredDefects];
    if (currentColumn !== value) {
      //set value, sort by ascending
      setCurrentColumn(value);
      setCurrentDirection('ascending');
      direction = 'ascending';
      if (sortFunctionType === 'string') {
        filteredDefectsClone.sort(compareString);
      } else if (sortFunctionType === 'time') {
        filteredDefectsClone.sort(compareTime);
      } else {
        filteredDefectsClone.sort(compareNumber);
      }
    } else if (currentColumn === value && currentDirection === 'ascending') {
      //set value, sort by descending
      setCurrentDirection('descending');
      direction = 'descending';
      if (sortFunctionType === 'string') {
        filteredDefectsClone.sort(compareString);
      } else if (sortFunctionType === 'time') {
        filteredDefectsClone.sort(compareTime);
      } else {
        filteredDefectsClone.sort(compareNumber);
      }
    } else if (currentColumn === value && currentDirection === 'descending') {
      setCurrentColumn('');
      setCurrentDirection('');

      filteredDefectsClone.sort((a, b) => {
        return moment(a.issueDate, 'MMM DD YYYY').unix() - moment(b.issueDate, 'MMM DD YYYY').unix();
      });
    }

    setFilteredDefects([...filteredDefectsClone]);
  };

  const renderToGo = (togo, dimension) => {
    const isNegative = togo < 0;
    if (dimension === 'D') {
      const toGoDays = togo / 24;
      const formattedToGoDays = isNegative ? Math.ceil(toGoDays) : Math.floor(toGoDays);
      const remainderHours = togo % 24;
      const isHourAccurate = togo % 24 !== 0;
      const dayText = toGoDays >= -1 && toGoDays <= 1 ? 'day' : 'days';
      const hourText = remainderHours >= -1 && remainderHours <= 1 ? 'hour' : 'hours';

      if (!isHourAccurate) {
        return `${formattedToGoDays} ${dayText}`;
      }
      const days = formattedToGoDays !== 0 ? `${formattedToGoDays} ${dayText}` : '';
      const hours = `${remainderHours} ${hourText}`;
      return `${days} ${hours}`;
    }
    if (dimension === 'H') {
      if (isNegative) {
        return `${Math.ceil(togo / 60)} ${Math.ceil(togo / 60) < 0 ? 'hours' : 'hour'}`;
      }
      return `${Math.floor(togo / 60)} ${Math.floor(togo / 60) > 1 ? 'hours' : 'hour'}`;
    }
    if (dimension === 'AH') {
      if (isNegative) {
        return `${Math.ceil(togo)} ${Math.ceil(togo) < 0 ? 'hours' : 'hour'}`;
      }
      return `${Math.floor(togo)} ${Math.floor(togo) > 1 ? 'hours' : 'hour'}`;
    }
    if (dimension === 'C') {
      return `${togo} cycles`;
    }
    return togo;
  };

  const columns = [
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.status' })}
          dataIndex="status"
        />
      ),
      dataIndex: 'status',
      width: 100,
      sortName: 'status',
      className: tableStyles.tableHeader,
      render(val) {
        return (
          <div>
            <span className={tableStyles.statusIndicator} style={{ background: status[val].colour }} />
            <span className={tableStyles.statusText}>{status[val].text}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          title={formatMessage({ id: 'title.number' })}
          dataIndex="number"
        />
      ),
      dataIndex: 'number',
      className: tableStyles.tableHeader,
      sortName: 'number',
      width: 110,
      render(val) {
        return (
          <div>
            <span className={tableStyles.numberText}>{`WO${val}`}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="time"
          title={formatMessage({ id: 'title.date' })}
          dataIndex="issueDate"
        />
      ),
      dataIndex: 'issueDate',
      className: tableStyles.dateColumn,
      width: 125,
      render(val) {
        return userSettings && userSettings.dateFormat
          ? moment(val, 'MMM DD YYYY').format(userSettings.dateFormat)
          : moment(val, 'MMM DD YYYY').format(defaults.defaultDateFormat);
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.ata' })}
          dataIndex="ataChapter"
        />
      ),
      dataIndex: 'ataChapter',
      className: tableStyles.ataColumn,
      width: 75,
      render(val) {
        return <span className={tableStyles.numberText}>{val}</span>;
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.melCode' })}
          dataIndex="melCode"
        />
      ),
      dataIndex: 'melCode',
      className: tableStyles.melCode,
      width: 75,
      render(val) {
        return <span className={tableStyles.dateText}>{val}</span>;
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.item' })}
          dataIndex="description"
        />
      ),
      dataIndex: 'description',
      className: tableStyles.tableHeader,

      render: (text) => (text ? text.toLowerCase() : '-'),
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          title={formatMessage({ id: 'title.remaining' })}
          dataIndex="togo"
        />
      ),
      dataIndex: 'togo',
      className: tableStyles.tableHeader,
      width: 100,
      render(val, object) {
        return renderToGo(val, object.dimension);
      },
    },
  ];

  if (loading) {
    return (
      <div className={styles.loadingDiv}>
        <Spin size="small" />
      </div>
    );
  }

  let filteredDefectsSubset =
    filteredDefects && filteredDefects.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  if (filteredDefectsSubset && filteredDefectsSubset.length === 0) filteredDefectsSubset = filteredDefects;

  return aircraft && defectsData ? (
    <>
      <div className={styles.listFilterRow}>
        {defectsData.length > 0 && (
          <SearchInput
            placeholder={formatMessage({ id: 'form.placeholder.searchMX' })}
            value={searchValue}
            visible={searchVisible}
            onToggle={() => setSearchVisible(!searchVisible)}
            onBlur={(value) => {
              if (!value) {
                setSearchVisible(false);
              }
            }}
            onChange={(value) => {
              setSearchValue(value);
              if (!value) {
                setFilteredDefects(defectsData);
              } else {
                const defectNumber = value.replace(/W|w|O|o/g, '');
                setFilteredDefects(defectsData.filter((defect) => defect.id.toString().indexOf(defectNumber) > -1));
              }
            }}
            onClear={() => {
              setSearchValue(null);
              setFilteredDefects(defectsData);
            }}
            data-test="searchInput"
            inputRef="defects"
          />
        )}
        {hideShowAll ? null : (
          <div className={styles.actionContainer}>
            <Link to={`/camo/aircraft/${aircraft.id}/defects`}>
              <Button type="default" className={styles.showAllButton}>
                <div className={styles.buttonText}>{formatMessage({ id: 'text.showAll' })}</div>
                <div className={styles.iconWrapper}>
                  <img src={ShowAllIcon} className={styles.showAllIcon} alt="show all" />
                </div>
              </Button>
            </Link>
          </div>
        )}
      </div>
      {filteredDefectsSubset.length === 0 ? (
        <div className={tableStyles.emptyStateWrapper}>
          <img src={DefectsEmptyState} alt="no data" className={tableStyles.emptyState} />
          <span>{dataMessage}</span>
        </div>
      ) : (
        <div className={tableStyles.tableContainer}>
          <Table
            rowKey="index"
            columns={columns}
            dataSource={filteredDefectsSubset}
            bordered={false}
            rowClassName={tableStyles.tableRow__noLink}
            pagination={false}
            scroll={{ x: 915 }}
          />
          {!shortList && filteredDefectsSubset.length > 15 && (
            <ModernPagination
              key="modernPagination"
              pageSize={pageSize}
              current={currentPage}
              total={defectsData.length}
              onPageNoChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(size) => setPageSize(size)}
              data-test="modernPagination"
            />
          )}
        </div>
      )}
    </>
  ) : null;
};

DefectsTable.propTypes = {
  onSearchChange: PropTypes.func,
  aircraft: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
  shortList: PropTypes.bool,
  defectsData: PropTypes.array,
  hideShowAll: PropTypes.bool,
};

DefectsTable.defaultProps = {
  onSearchChange: () => {
    // default func
  },
  shortList: true,
  defectsData: [],
  hideShowAll: false,
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    ({ userSettings }) => ({
      userSettings,
    }),
    (dispatch) => ({
      updateAircraft: async (payload) =>
        dispatch({
          type: AircraftActionTypes.UPDATE,
          payload,
        }),
    }),
  ),
)(DefectsTable);
