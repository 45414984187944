import { BaseModel } from '..';

const initialState: AircraftState = {
  aircraftMap: new Map(),
  newAircraftId: null,
  lastFetched: 0,
  ttl: 30000,
  data: {
    list: [],
  },
};

export enum AircraftActionTypes {
  SAVE = 'aircraft/save',
  UPDATE = 'aircraft/update',
  SAVE_ALL = 'aircraft/saveAll',
  SAVE_NEW = 'aircraft/saveNew',
  REMOVE = 'aircraft/removePerson',
}

export interface SaveAircraftAction {
  type: AircraftActionTypes.SAVE;
  payload: Aircraft;
}

export interface SaveAllAircraftAction {
  type: AircraftActionTypes.SAVE_ALL;
  payload: Aircraft[];
}

export interface SaveNewAircraftAction {
  type: AircraftActionTypes.SAVE_NEW;
  payload: Aircraft;
}

export interface RemoveAircraftAction {
  type: AircraftActionTypes.REMOVE;
  payload: Aircraft;
}

export interface UpdateAircraftAction {
  type: AircraftActionTypes.UPDATE;
  payload: Aircraft;
}

type AircraftAction =
  | SaveAircraftAction
  | SaveAllAircraftAction
  | SaveNewAircraftAction
  | RemoveAircraftAction
  | UpdateAircraftAction;

export const isCompleteAircraftRecord = (record: BaseAircraft | Aircraft): record is Aircraft =>
  record &&
  'maintenance' in record &&
  'departure_airport' in record &&
  'destination_airport' in record &&
  'last_airport' in record &&
  'totals' in record &&
  'tiles' in record;

export const getAircraftImage = (aircraft): string => {
  let image = '/assets/aircraft/B788.png';
  const aircraftType = `${aircraft.operator}${aircraft.acModel}`;
  switch (aircraftType) {
    case 'FLE737MAX':
      image = '/assets/aircraft/B737-MAX-FLAIR.png';
      break;
    case 'FLE737NG':
      image = '/assets/aircraft/B737-800-FLAIR.png';
      break;
    default:
      image = '/assets/aircraft/B788.png';
      break;
  }
  return image;
};

export default function(state = initialState, action: AircraftAction): AircraftState {
  switch (action.type) {
    case AircraftActionTypes.SAVE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      aircraftMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        aircraftMap,
      };
    }
    case AircraftActionTypes.UPDATE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      aircraftMap.set(action.payload.registration, {
        ...aircraftMap.get(action.payload.registration),
        ...action.payload,
        id: action.payload.registration,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        aircraftMap,
      };
    }
    case AircraftActionTypes.SAVE_NEW: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      const newAircraftId = action.payload.id;
      aircraftMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        newAircraftId,
        aircraftMap,
      };
    }
    case AircraftActionTypes.SAVE_ALL: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      action.payload.forEach((aircraft: Aircraft | BaseAircraft) => {
        const stateAircraft = aircraftMap.get(aircraft.registration);
        const lastFetched = isCompleteAircraftRecord(aircraft) ? Date.now() : stateAircraft?.lastFetched || 0;
        // calc mx status
        // if utilisation is zero then unserviceable
        aircraftMap.set(aircraft.registration, {
          ...stateAircraft,
          ...aircraft,
          id: aircraft.registration,
          aircraftImgUrl: getAircraftImage(aircraft),
          lastFetched,
        });
      });
      return {
        ...state,
        aircraftMap,
        lastFetched: Date.now(),
      };
    }
    case AircraftActionTypes.REMOVE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      aircraftMap.delete(action.payload.id);
      return {
        ...state,
        aircraftMap,
      };
    }
    default:
      return state;
  }
}

export interface AircraftState {
  aircraftMap: Map<string, Aircraft | BaseAircraft>;
  newAircraftId?: string;
  lastFetched: number;
  ttl: number;
  data: {
    list: Aircraft[];
  };
}

export interface AircraftType {
  id: string;
  manufacturer: string;
  model: string;
  designator: string;
  category: string;
  engine_count: number;
  engine_type: string;
  weight_category: string;
  aircraft_image_url: string;
}

export interface Defect {
  togo: number;
  type: string;
}
export interface BaseAircraft extends BaseModel {
  id: string;
  serial_number: string;
  registration: string;
  year: number;
  simulator: false;
  apu_installed: false;
  operator_id: string;
  max_fuel_value: number;
  fuel_unit: string;
  oil_unit: string;
  maintenance_status: string;
  flight_status: string;
  mx_display_days: null;
  mx_thresholds: {};
  aircraft_type: AircraftType;
  aircraftImgUrl: string;
  status: string;
  utilisationTotals: UtilisationTotals;
  utilisationRemaining: UtilisationRemaining;
  defectCount: number;
  defects: [Defect];
}

export interface Aircraft extends BaseAircraft {
  last_airport: string;
  departure_airport: {
    ident: string;
    latitude: string;
    longitude: string;
  };
  destination_airport: {
    ident: string;
    latitude: string;
    longitude: string;
  };
  days_remaining: number;
  flight_seconds_remaining: number;
  apu_seconds_remaining: number;
  cycles_remaining: number;
  limitations: string[];
  estimated_next_due_date: string;
}

interface UtilisationTotals {
  flightHours: number;
  flightCycles: number;
  aircraftDays: number;
}
interface UtilisationRemaining {
  flightHoursRemaining: number;
  flightCyclesRemaining: number;
  aircraftDaysRemaining: number;
  flightHoursNextDue: number;
  flightCyclesNextDue: number;
  aircraftDaysNextDue: string;
}
