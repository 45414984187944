import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { CamoResource, UserPermission } from '../../models/userSettings';
import { hasServiceDeskPermission } from '../../providers/AuthProvider';
import overviewIcon from '../assets/context-menu-icons/overview.svg';
import defectsIcon from '../assets/context-menu-icons/defects.svg';
import maintenanceForecastIcon from '../assets/context-menu-icons/maintenance-forecast.svg';
import costAnalysisIcon from '../assets/context-menu-icons/cost-analysis.svg';
import moreIcon from '../assets/more-icon.svg';
import styles from './ContextMenu.module.less';

const devices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

const deviceIsIOS = () => navigator && devices.includes(navigator.platform);

const fixIOSHover = () => {
  if (deviceIsIOS()) {
    return styles.noHover;
  }
  return null;
};

const hoverClass = fixIOSHover();

const ContextMenu = ({
  selectedKeys,
  onClick,
  isMobile,
  intl: { formatMessage },
  match,
  showMxForecast,
  userSettings,
}) => {
  const {
    params: { id },
  } = match;
  return (
    <Menu
      mode="vertical"
      selectedKeys={selectedKeys}
      onClick={onClick}
      className={`${styles.contextMenu} ${isMobile ? styles.contextMobileHeader : undefined}`}
    >
      <Menu.Item key="overview" className={hoverClass}>
        <Link to={`/camo/aircraft/${id}/overview`}>
          <div className={styles.iconWrapper}>
            <img src={overviewIcon} className={styles.menuIcon} alt="menuIcon" />
          </div>
          {formatMessage({ id: 'menu.camo.overview' })}
        </Link>
      </Menu.Item>
      {showMxForecast && (
        <Menu.Item key="forecast" className={hoverClass}>
          <Link to={`/camo/aircraft/${id}/forecast`}>
            <div className={styles.iconWrapper}>
              <img src={maintenanceForecastIcon} className={styles.menuIcon} alt="menuIcon" />
            </div>
            {formatMessage({ id: 'menu.camo.maintenanceForecast' })}
          </Link>
        </Menu.Item>
      )}
      {hasServiceDeskPermission(
        userSettings?.details?.service_desk_permissions,
        CamoResource.COST,
        UserPermission.READ,
      ) && (
        <Menu.Item key="cost" className={hoverClass}>
          <Link to={`/camo/aircraft/${id}/cost`}>
            <div className={styles.iconWrapper}>
              <img src={costAnalysisIcon} className={styles.menuIcon} alt="menuIcon" />
            </div>
            {formatMessage({ id: 'menu.camo.costAnalysis' })}
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="defects" className={hoverClass}>
        <Link to={`/camo/aircraft/${id}/defects`}>
          <div className={styles.iconWrapper}>
            <img src={defectsIcon} className={styles.menuIcon} alt="menuIcon" />
          </div>
          {formatMessage({ id: 'menu.camo.defects' })}
        </Link>
      </Menu.Item>
      <Menu.Item key="more" className="ant-menu-item-more">
        <img src={moreIcon} alt="more" />
      </Menu.Item>
    </Menu>
  );
};

ContextMenu.propTypes = {
  selectedKeys: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  match: PropTypes.object.isRequired,
  showMxForecast: PropTypes.bool,
  userSettings: PropTypes.object.isRequired,
};

ContextMenu.defaultProps = {
  isMobile: false,
  showMxForecast: false,
  onClick: () => {
    // default fun
  },
};

const ContextMenuWithRedux = connect(({ aircraft, userSettings }) => ({
  aircraftMap: aircraft.aircraftMap,
  userSettings,
}))(ContextMenu);

export default injectIntl(withRouter(ContextMenuWithRedux));
