/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from '../PageHeader';
import ModernPageHeader from '../PageHeader/modernPageHeader';
import GridContent from './GridContent';
import styles from './index.module.less';

const PageHeaderWrapper = ({
  children,
  contentWidth,
  wrapperClassName,
  top,
  layout,
  secondaryHeader,
  ...restProps
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className={`${styles.pageHeaderWrapper} ${wrapperClassName}`}>
      {top}
      {layout === 'modern' ? (
        <ModernPageHeader {...restProps} />
      ) : (
        <PageHeader
          wide={contentWidth === 'Fixed'}
          home={formatMessage({ id: 'menu.home', defaultMessage: 'Home' })}
          key="pageheader"
          {...restProps}
          linkElement={Link}
          itemRender={(item) => {
            if (item.locale) {
              return formatMessage({ id: item.locale, defaultMessage: item.name });
            }
            return item.name;
          }}
        />
      )}
      {children ? (
        <div className={styles.content}>
          <GridContent>{children}</GridContent>
        </div>
      ) : null}
    </div>
  );
};

PageHeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, null]),
  contentWidth: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.object,
  secondaryHeader: PropTypes.bool,
};

PageHeaderWrapper.defaultProps = {
  children: null,
  top: undefined,
  wrapperClassName: undefined,
  layout: null,
  secondaryHeader: false,
};

export default connect(({ userSettings }) => ({
  contentWidth: userSettings.ui.contentWidth,
}))(PageHeaderWrapper);
