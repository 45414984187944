import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Timeline, { TimelineHeaders, DateHeader } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { Card, Popover, Dropdown, Menu, Icon, Badge } from 'antd';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import MaintenanceEmptyState from '../../assets/empty-state-maintenance.svg';
import SearchInput from '../../components/SearchInput';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import styles from './LongTermMxStatus.module.less';

const LongTermMxStatus = ({ aircraft }) => {
  const aircraftTypes = aircraft.map((a) => a.registration);
  aircraftTypes.unshift('All Aircraft');
  const intl = useIntl();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState(aircraftTypes[0]);
  const [mxItems, setMxItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredMxItems, setFilteredMxItems] = useState([]);
  const [monthStep, setMonthStep] = useState(3);
  const [activeFilters, setActiveFilters] = useState(['Check', 'ENG 1', 'ENG 2', 'Landing Gear Overhaul']);
  const [dataMessage, setDataMessage] = useState(intl.formatMessage({ id: 'text.noData' }));

  const gradientStyles = {
    cCheck: 'linear-gradient(to bottom, #b4b1f2, #799fe0)',
    engine1: 'linear-gradient(to bottom, #024574, #012140)',
    engine2: 'linear-gradient(to bottom, #72c9ff, #3f98ff)',
    landingGear: 'linear-gradient(to bottom, #b1f2ec, #79e0d4)',
  };

  const getMxItems = () => {
    const newMxItems = [];
    aircraft.forEach((a) => {
      if (Array.isArray(a.heavyMaintenanceForecast)) {
        const aircraftChecks = a.heavyMaintenanceForecast.map((workpack, index) => {
          let startTime;
          if (workpack.expected) {
            startTime =
              workpack.expected === 'DEC 12 1971'
                ? moment.unix(workpack.expected / 1000).subtract(1, 'week')
                : moment.unix(workpack.expected / 1000);
          } else {
            startTime = moment.unix(workpack.expected / 1000);
          }
          return {
            id: `${a.id}-${index}`,
            group: a.id,
            title: workpack.event,
            start_time: startTime,
            location: workpack.location,
            item_type: workpack.item_type,
            description: workpack.event,
            event: workpack.event,
            end_time: workpack.planned_end
              ? moment.unix(workpack.planned_end / 1000)
              : moment.unix(workpack.expected / 1000).add(1, 'day'),
          };
        });
        newMxItems.push(...aircraftChecks);
      }
    });
    setMxItems(newMxItems);
    setFilteredMxItems(newMxItems);
    setDataMessage(
      newMxItems.length > 0
        ? intl.formatMessage({ id: 'text.noSearchData' })
        : intl.formatMessage({ id: 'text.noData' }),
    );
  };

  const getGroups = () => {
    const g = aircraft.map((a) => ({
      id: a.id,
      title: a.registration,
      stackItems: true,
    }));
    setGroups(g);
    setFilteredGroups(g);
  };

  useEffect(() => {
    getMxItems();
    getGroups();
  }, [aircraft]);

  useEffect(() => {
    let filteredGroup = groups;
    if (filterValue !== 'All Aircraft') {
      filteredGroup = groups.filter((g) => g.id === filterValue);
    }
    setFilteredGroups(filteredGroup);
  }, [filterValue, groups]);

  useEffect(() => {
    const newFilteredMxItems = mxItems.filter((mx) => mx.title.toLowerCase().includes(searchValue.toLowerCase()));
    let newFilteredGroups;
    if (searchValue) {
      const filteredGroupIds = newFilteredMxItems.reduce((allGroups, mxItem) => {
        const existingIndex = allGroups.findIndex((group) => group === mxItem.group);
        const allItems = allGroups;
        if (existingIndex < 0) {
          allItems.push(mxItem.group);
        }
        return allItems;
      }, []);
      newFilteredGroups = groups.filter((g) => filteredGroupIds.includes(g.id));
    } else {
      newFilteredGroups = aircraft.map((a) => ({
        id: a.id,
        title: a.registration,
        stackItems: true,
      }));
    }
    setFilteredMxItems(newFilteredMxItems);
    setFilteredGroups(newFilteredGroups);
  }, [aircraft, groups, mxItems, searchValue]);

  const filterMxItems = () => {
    const newFilteredItems = mxItems.filter((object) => {
      const location = activeFilters.includes(object.location);
      const itemType = activeFilters.includes(object.item_type);
      const landingGear =
        activeFilters.includes('Landing Gear Overhaul') &&
        object.item_type === 'Replacement' &&
        object.location !== 'ENG 1' &&
        object.location !== 'ENG 2';
      return location || itemType || landingGear;
    });
    setFilteredMxItems(newFilteredItems);
  };

  const onFilterChange = (filter) => {
    if (activeFilters.includes(filter)) {
      const index = activeFilters.indexOf(filter);
      activeFilters.splice(index, 1);
      setActiveFilters([...activeFilters]);
    } else {
      activeFilters.push(filter);
      setActiveFilters([...activeFilters]);
    }
    filterMxItems();
  };

  const getBackgroundStyle = (item) => {
    let backgroundColor = '';
    if (item.item_type === 'Check') {
      backgroundColor = gradientStyles.cCheck;
    } else if (item.location && item.location.includes('ENG')) {
      backgroundColor = item.location.includes('1') ? gradientStyles.engine1 : gradientStyles.engine2;
    } else {
      backgroundColor = gradientStyles.landingGear;
    }
    return backgroundColor;
  };

  const itemRenderer = ({ item, getItemProps }) => {
    return (
      <div style={{ padding: '20px' }}>
        <Popover content={<div>{item.description}</div>} title={item.title} trigger="hover">
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getItemProps({
              style: {
                background: getBackgroundStyle(item),
                border: 'none',
                borderRadius: '25px',
                textAlign: 'center',
                minWidth: '20px',
                opacity: 1,
                maxHeight: '18px',
                display: 'flex',
                justifyContent: 'center',
              },
            })}
          >
            <p
              style={{
                color: '#646464',
                textAlign: 'center',
                paddingTop: '20px',
                fontSize: '9px',
                letterSpacing: '.5',
                lineHeight: '1',
                whiteSpace: 'nowrap',
              }}
            >
              {item.title}
            </p>
          </div>
        </Popover>
      </div>
    );
  };

  itemRenderer.propTypes = {
    item: PropTypes.object.isRequired,
    getItemProps: PropTypes.object.isRequired,
  };

  const verticalLineClassNamesForTime = (timeStart) => {
    // const currentTimeStart = moment(timeStart).format('YYYY');
    const currentTimeStart = moment(timeStart).format('MM');
    let classes = [];
    if (currentTimeStart % 2 === 0) {
      classes = ['', styles.evenYearCol, styles.dash];
    } else {
      classes = ['', styles.oddYearCol, styles.dash];
    }

    return classes;
  };

  return (
    <Card className={`${styles.timelineWrapper} ${cardStyles.pageCard}`}>
      <div className={cardStyles.cardHeader}>Long Term Maintenance Status</div>
      <div className={styles.listFilterRow}>
        <SearchInput
          placeholder={intl.formatMessage({ id: 'form.placeholder.searchByTitle' })}
          value={searchValue}
          visible={searchVisible}
          onToggle={() => setSearchVisible(!searchVisible)}
          onBlur={(value) => {
            if (!value) {
              setSearchVisible(false);
            }
          }}
          onChange={(value) => setSearchValue(value)}
          data-test="searchInput"
          onClear={() => setSearchValue('')}
          inputRef="longTermMx"
        />
        <div className={styles.filterContainer}>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={(key) => setFilterValue(key.key)}>
                {aircraftTypes.map((type) => {
                  return <Menu.Item key={`${type}`}>{type}</Menu.Item>;
                })}
              </Menu>
            }
          >
            <span className={styles.dropdownLink}>
              {intl.formatMessage({ id: 'form.labels.aircraftType' })}:<span>{filterValue}</span>{' '}
              <Icon type="caret-down" />
            </span>
          </Dropdown>
          <div className={styles.buttonContainer}>
            <button
              className={activeFilters.includes('ENG 1') ? styles.buttonToggle : styles.buttonToggleOff}
              type="button"
              onClick={() => onFilterChange('ENG 1')}
            >
              <Badge color={gradientStyles.engine1} />
              <span>Engine 1</span>
            </button>
            <button
              className={activeFilters.includes('ENG 2') ? styles.buttonToggle : styles.buttonToggleOff}
              type="button"
              onClick={() => onFilterChange('ENG 2')}
            >
              <Badge color={gradientStyles.engine2} />
              <span>Engine 2</span>
            </button>
            <button
              className={activeFilters.includes('Landing Gear Overhaul') ? styles.buttonToggle : styles.buttonToggleOff}
              type="button"
              onClick={() => onFilterChange('Landing Gear Overhaul')}
            >
              <Badge color={gradientStyles.landingGear} />
              <span>Landing Gear Overhaul</span>
            </button>
            <button
              className={activeFilters.includes('Check') ? styles.buttonToggle : styles.buttonToggleOff}
              type="button"
              onClick={() => onFilterChange('Check')}
            >
              <Badge color={gradientStyles.cCheck} />
              <span>Check</span>
            </button>
          </div>
        </div>
      </div>
      {filteredMxItems.length > 0 && filteredGroups.length > 0 ? (
        <Timeline
          groups={filteredGroups}
          items={filteredMxItems}
          stackItems
          lineHeight={50}
          defaultTimeStart={moment()}
          defaultTimeEnd={moment().add(6, 'month')}
          canMove={false}
          canResize={false}
          canChangeGroup={false}
          timeSteps={{
            second: 1,
            minute: 1,
            hour: 1,
            day: 1,
            month: monthStep,
            year: 1,
          }}
          itemRenderer={itemRenderer}
          verticalLineClassNamesForTime={verticalLineClassNamesForTime}
          horizontalLineClassNamesForGroup={() => [` ${styles.horizontalLines}`]}
        >
          <TimelineHeaders calendarHeaderStyle={{ border: 'none' }} style={{ borderBottom: '1px solid #e6e8ed' }}>
            <DateHeader
              unit="primaryHeader"
              intervalRenderer={({ getIntervalProps, intervalContext }) => {
                return (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getIntervalProps()}
                    className={styles.primaryHeader}
                  >
                    <span className={styles.primaryText}>{intervalContext.intervalText}</span>
                  </div>
                );
              }}
            />
            {monthStep === 3 ? (
              <DateHeader
                unit="month"
                labelFormat="[Q]Q"
                className={styles.headerQuarter}
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  return (
                    <div
                      role="button"
                      tabIndex={0}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getIntervalProps()}
                      onClick={(e) => {
                        setMonthStep(1);
                        getIntervalProps().onClick(e);
                      }}
                      onKeyPress={(e) => {
                        setMonthStep(1);
                        getIntervalProps().onClick(e);
                      }}
                    >
                      <span className={styles.test}>{moment(intervalContext.interval.startTime).format('[Q]Q')}</span>
                    </div>
                  );
                }}
              />
            ) : (
              <DateHeader />
            )}
          </TimelineHeaders>
        </Timeline>
      ) : (
        <div className={tableStyles.emptyStateWrapper}>
          <img src={MaintenanceEmptyState} alt="no data" className={tableStyles.emptyState} />
          <span>{dataMessage}</span>
        </div>
      )}
    </Card>
  );
};

LongTermMxStatus.propTypes = {
  aircraft: PropTypes.array.isRequired,
};

export default LongTermMxStatus;
