import React, { useState, useEffect } from 'react';
import { useIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import { Table, Spin } from 'antd';
import SearchInput from '../SearchInput';
import TableTitle from '../TableTitle/TableTitle';
import ModernPagination from '../ModernPagination';
import DefectsEmptyState from '../../assets/empty-state-defects.svg';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import styles from './MaintenanceTable.module.less';

const MaintenanceTable = ({ aircraft, data, userSettings, loading }) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setfilteredData] = useState([]);
  const { formatMessage } = useIntl();
  const [dataMessage, setDataMessage] = useState('');
  const [currentColumn, setCurrentColumn] = useState('');
  const [currentDirection, setCurrentDirection] = useState('');

  useEffect(() => {
    setfilteredData(data);
    setDataMessage(data.length > 0 ? formatMessage({ id: 'text.noSearchData' }) : formatMessage({ id: 'text.noData' }));
  }, [data, formatMessage]);

  const renderToGo = (togo, dimension) => {
    const isNegative = togo < 0;
    if (dimension === 'D') {
      const toGoDays = togo / 24;
      const formattedToGoDays = isNegative ? Math.ceil(toGoDays) : Math.floor(toGoDays);
      const remainderHours = togo % 24;
      const isHourAccurate = togo % 24 !== 0;
      const dayText = toGoDays >= -1 && toGoDays <= 1 ? 'day' : 'days';
      const hourText = remainderHours >= -1 && remainderHours <= 1 ? 'hour' : 'hours';

      if (!isHourAccurate) {
        return `${formattedToGoDays} ${dayText}`;
      }
      const days = formattedToGoDays !== 0 ? `${formattedToGoDays} ${dayText}` : '';
      const hours = `${remainderHours} ${hourText}`;
      return `${days} ${hours}`;
    }
    if (dimension === 'H') {
      if (isNegative) {
        return `${Math.ceil(togo / 60)} ${Math.ceil(togo / 60) < 0 ? 'hours' : 'hour'}`;
      }
      return `${Math.floor(togo / 60)} ${Math.floor(togo / 60) > 1 ? 'hours' : 'hour'}`;
    }
    if (dimension === 'AH') {
      if (isNegative) {
        return `${Math.ceil(togo)} ${Math.ceil(togo) < 0 ? 'hours' : 'hour'}`;
      }
      return `${Math.floor(togo)} ${Math.floor(togo) > 1 ? 'hours' : 'hour'}`;
    }
    if (dimension === 'C') {
      return `${togo} cycles`;
    }
    return togo;
  };

  const sortData = (value, sortFunctionType) => {
    // TODO: sort all data, apply filter, apply pagination - done
    // different compare functions
    // determine if already selected, direction
    let direction;

    const compareNumber = (a, b) => {
      if (direction === 'descending') {
        return a[value] - b[value];
      }
      return b[value] - a[value];
    };

    const compareString = (a, b) => {
      const aText = a[value] ? a[value].toUpperCase() : '';
      const bText = b[value] ? b[value].toUpperCase() : '';
      if (direction === 'descending') {
        if (bText < aText) {
          return -1;
        }
        if (bText > aText) {
          return 1;
        }

        return 0;
      }

      if (aText < bText) {
        return -1;
      }
      if (aText > bText) {
        return 1;
      }

      return 0;
    };

    const compareTime = (a, b) => {
      if (direction === 'descending') {
        return moment(a[value], 'MMM DD YYYY').unix() - moment(b[value], 'MMM DD YYYY').unix();
      }
      return moment(b[value], 'MMM DD YYYY').unix() - moment(a[value], 'MMM DD YYYY').unix();
    };

    const filteredDataClone = [...filteredData];
    if (currentColumn !== value) {
      // set value, sort by ascending
      setCurrentColumn(value);
      setCurrentDirection('ascending');
      direction = 'ascending';
      if (sortFunctionType === 'string') {
        filteredDataClone.sort(compareString);
      } else if (sortFunctionType === 'time') {
        filteredDataClone.sort(compareTime);
      } else {
        filteredDataClone.sort(compareNumber);
      }
    } else if (currentColumn === value && currentDirection === 'ascending') {
      // set value, sort by descending
      setCurrentDirection('descending');
      direction = 'descending';
      if (sortFunctionType === 'string') {
        filteredDataClone.sort(compareString);
      } else if (sortFunctionType === 'time') {
        filteredDataClone.sort(compareTime);
      } else {
        filteredDataClone.sort(compareNumber);
      }
    } else if (currentColumn === value && currentDirection === 'descending') {
      setCurrentColumn('');
      setCurrentDirection('');

      filteredDataClone.sort((a, b) => {
        return moment(a.issueDate, 'MMM DD YYYY').unix() - moment(b.issueDate, 'MMM DD YYYY').unix();
      });
    }

    setfilteredData([...filteredDataClone]);
  };
  const columns = [
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.description' })}
          dataIndex="event_description"
        />
      ),
      dataIndex: 'event_description',
      width: 450,
      sortName: 'event_description',
      className: tableStyles.tableHeader,
      render(val) {
        return (
          <div>
            <span className={tableStyles.statusText}>{val}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          dataType="number"
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          title={formatMessage({ id: 'title.togo' })}
          dataIndex="tg"
        />
      ),
      dataIndex: 'tg',
      className: tableStyles.tableHeader,
      sortName: 'tg',
      // width: 100,
      render(val, object) {
        return renderToGo(val, object.dimension);
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.interval' })}
          dataIndex="interval"
        />
      ),
      dataIndex: 'interval',
      className: tableStyles.dateColumn,
      // width: 100,
      render(val) {
        return (
          <div>
            <span className={tableStyles.numberText}>{val}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.expected' })}
          dataIndex="expected"
        />
      ),
      dataIndex: 'expected',
      className: tableStyles.tableHeader,

      render: (val) => val,
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.planned' })}
          dataIndex="planned"
        />
      ),
      dataIndex: 'planned',
      className: tableStyles.ataColumn,
      // width: 100,
      render(val) {
        return <span className={tableStyles.dateText}>{val}</span>;
      },
    },
    {
      title: (props) => (
        <TableTitle
          props={props}
          sortData={sortData}
          sortOrdered={currentDirection}
          currentColumn={currentColumn}
          dataType="string"
          title={formatMessage({ id: 'title.workpackage' })}
          dataIndex="workpackage"
        />
      ),
      dataIndex: 'workpackage',
      className: tableStyles.tableHeader,
      render: (val) => val,
    },
  ];

  if (loading) {
    return (
      <div className={styles.loadingDiv}>
        <Spin size="small" />
      </div>
    );
  }

  let filteredDataSubset = filteredData && filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  if (filteredDataSubset && filteredDataSubset.length === 0) filteredDataSubset = filteredData;

  return aircraft && data ? (
    <>
      <div className={styles.listFilterRow}>
        {data.length > 0 && (
          <SearchInput
            placeholder={formatMessage({ id: 'form.placeholder.searchMX' })}
            value={searchValue}
            visible={searchVisible}
            onToggle={() => setSearchVisible(!searchVisible)}
            onBlur={(value) => {
              if (!value) {
                setSearchVisible(false);
              }
            }}
            onChange={(value) => {
              setSearchValue(value);
              if (!value) {
                setfilteredData(data);
              } else {
                setfilteredData(
                  data.filter(
                    (mxItem) =>
                      mxItem.event_description.toString().indexOf(value) > -1 ||
                      mxItem.workpackage?.toString().indexOf(value) > -1,
                  ),
                );
              }
            }}
            onClear={() => {
              setSearchValue(null);
              setfilteredData(data);
            }}
            data-test="searchInput"
            inputRef="defects"
          />
        )}
      </div>
      {filteredDataSubset.length === 0 ? (
        <div className={tableStyles.emptyStateWrapper}>
          <img src={DefectsEmptyState} alt="no data" className={tableStyles.emptyState} />
          <span>{dataMessage}</span>
        </div>
      ) : (
        <div className={tableStyles.tableContainer}>
          <Table
            rowKey="index"
            columns={columns}
            dataSource={filteredDataSubset}
            bordered={false}
            rowClassName={tableStyles.tableRow__noLink}
            pagination={false}
            scroll={{ x: 915 }}
          />
          {filteredData.length > 15 && (
            <ModernPagination
              key="modernPagination"
              pageSize={pageSize}
              current={currentPage}
              total={filteredData.length}
              onPageNoChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(size) => setPageSize(size)}
              data-test="modernPagination"
            />
          )}
        </div>
      )}
    </>
  ) : null;
};

MaintenanceTable.propTypes = {
  aircraft: PropTypes.object.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

MaintenanceTable.defaultProps = {
  data: [],
  loading: false,
};

export default compose(
  withRouter,
  injectIntl,
  connect(({ userSettings }) => ({
    userSettings,
  })),
)(MaintenanceTable);
