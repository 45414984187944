import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Select } from '@arcflight/tf-component-library';
import { submitVendorSubmission } from '../../services/apiNew';
import countryCodes from '../../utils/countryCodesAlpha2.json';
import currencyCodes from '../../utils/currencyCodes.json';
import states from '../../utils/states.json';
import Dropdown from './Components/Dropdown';

const FormCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  padding: 28px;
  box-shadow: 0 0 10px 0 #dbe3ed;
  border: solid 2px #fff;
  background-color: #fafbfd;
  @media screen and (max-width: 1150px) {
    margin-top: 25px;
  }
`;

const Label = styled.div`
  color: #242d41;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
`;

const TextDiv = styled.div`
  margin-bottom: 32px;
  color: rgba(36, 45, 65, 0.7);
`;

const StyledInput = styled.input`
  height: 34px;
  width: ${({ width }): string => (width ? `${width}` : '400px')};
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  border-radius: 2px;
  border ${({ error }): string => (error ? 'solid 1px #ca2322' : 'solid 1px rgba(36, 45, 65, 0.1)')};
  background-color: #fff;
  color: rgba(36, 45, 65, 0.8);
  margin-bottom: 24px;
  margin-right: ${({ marginright }): string => (marginright ? `${marginright}` : '0')};
  padding-left: 10px;
  ::placeholder {
    color: rgba(36, 45, 65, 0.3);
  }
`;

const FlexDiv = styled.div`
  display: flex;
  @media screen and (max-width: 560px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledSubmit = styled.button`
  background-color: #1890ff;
  border: 'solid 1px #1890ff';
  color: #fff;
  height: 40px;
  border-radius: 20px;
  margin-top: 16px;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #027aff;
    border: 'solid 1px #027aff';
  }
  &:focus {
    border: solid 1px #7ebbef;
    background-color: #1890ff;
    background-color: #7ebbef;
    border: 'solid 1px #1890ff';
  }
  &:active {
    background-color: #1890ff;
    border: solid 1px #1890ff;
    background-color: #1890ff;
    border: 'solid 1px #1890ff';
  }
`;

const StyledSubmitWrapper = styled.div`
  margin-top: ${(props) => {
    if (props.status) {
      return '0px';
    }
    return '63px';
  }};
`;

const ErrorMessage = styled.p`
  color: #ca2322;
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '-24px')};
  margin-bottom: 6px;
  font-size: 12px;
`;

const AlertBox = styled.div`
  width: 100%;
  border-radius: 4px;
  border: ${({ type }): string => (type === 'success' ? '1px solid  #b7eb8f' : '1px solid  #ffa39e')};
  background-color: ${({ type }): string => (type === 'success' ? '#f6ffed' : '#fff1f0')};
  padding: 8px 12px;
`;

const ExpandTextBox = styled.textarea`
  resize: none;
  overflow: hidden;
  min-height: 34px;
  max-height: 88px;
  height: 34px;
  width: ${({ width }): string => (width ? `${width}` : '400px')};
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  border-radius: 2px;
  border ${({ error }): string => (error ? 'solid 1px #ca2322' : 'solid 1px rgba(36, 45, 65, 0.1)')};
  background-color: #fff;
  color: rgba(36, 45, 65, 0.8);
  margin-bottom: 24px;
  margin-right: ${({ marginright }): string => (marginright ? `${marginright}` : '0')};
  padding-left: 10px;
  padding-top: 4px;
  ::placeholder {
    color: rgba(36, 45, 65, 0.3);
  }
`;

const SelectWrapper = styled.div`
  width: 210px;
  position: relative;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
`;

const validateString = (fieldName, value, charLimit, required?) => {
  if (value.trim() === '' && required) {
    return `${fieldName} is required`;
  }
  if (value.trim().length > charLimit) {
    return `${fieldName} needs to be ${charLimit} characters or fewer`;
  }
  return null;
};

const NewVendorForm: React.FC = () => {
  const nameRef = useRef(null);
  const vendorCodeRef = useRef(null);
  const addressLine1Ref = useRef(null);
  const addressLine2Ref = useRef(null);
  const cityRef = useRef(null);
  const zipCodeRef = useRef(null);
  const [chosenCountry, setChosenCountry] = useState({ value: '', label: '' });
  const [chosenCountryCode, setChosenCountryCode] = useState(null);
  const [chosenCurrency, setChosenCurrency] = useState({ value: '', label: '' });
  const [chosenCurrencyCode, setChosenCurrencyCode] = useState(null);
  const [chosenState, setChosenState] = useState({ value: '', label: '' });
  const [chosenStateCode, setChosenStateCode] = useState('');

  const { formatMessage } = useIntl();

  const [errors, setErrors] = useState({
    name: null,
    vendorCode: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    currency: null,
    zipCode: null,
  });
  const [touched, setTouched] = useState({
    name: null,
    vendorCode: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zipCode: null,
  });
  const [status, setStatus] = useState(null);
  const resetStatus = () => {
    if (status !== null) {
      setStatus(null);
    }
  };

  const setCurrency = (option): void => {
    resetStatus();
    setChosenCurrency(option);
    setChosenCurrencyCode(option.value);
    const errorObj = { ...errors };
    errorObj.currency = null;
    setErrors(errorObj);
  };

  const setCountry = (option): void => {
    resetStatus();
    setChosenCountry(option);
    setChosenCountryCode(option.value);
  };

  const setState = (option): void => {
    resetStatus();
    setChosenState(option);
    setChosenStateCode(option.value);
  };

  const handleChange = (evt) => {
    const { name, value, id, required } = evt.target;
    resetStatus();
    if (id === 'name') {
      const target = evt.target as HTMLTextAreaElement;
      nameRef.current.style.height = '34px';
      nameRef.current.style.height = `${target.scrollHeight}px`;
    }

    setTouched({
      ...touched,
      [name]: true,
    });
    let charLim = 36;
    if (id === 'vendorCode') {
      charLim = 12;
    }
    if (id === 'city') {
      charLim = 30;
    }

    if (id === 'name') {
      charLim = 108;
    }
    if (id === 'zipCode') {
      charLim = 10;
    }
    const error = validateString(name, value, charLim, required);
    const errorObj = { ...errors };
    errorObj[id] = error;
    setErrors(errorObj);
  };

  const handleFormSubmit = async (): Promise<void> => {
    const payload = {
      name: nameRef.current.value,
      vendorCode: vendorCodeRef.current.value,
      addressLine1: addressLine1Ref.current.value,
      addressLine2: addressLine2Ref.current.value,
      city: cityRef.current.value,
      state: chosenStateCode,
      country: chosenCountryCode,
      currency: chosenCurrencyCode,
      zipCode: zipCodeRef.current.value,
    };

    setErrors({
      name: validateString('Name', payload.name, 108, true),
      vendorCode: validateString('Vendor Code', payload.vendorCode, 12, true),
      address1: validateString('Address Line 1', payload.addressLine1, 36),
      address2: validateString('Address Line 2', payload.addressLine2, 36),
      city: validateString('City', payload.city, 30),
      state: validateString('State', payload.state, 36),
      currency: payload.currency ? null : 'Currency is required',
      zipCode: validateString('Zip Code', payload.zipCode, 10),
    });

    const filtered = Object.values(errors).filter((el) => {
      return el != null;
    });
    const filteredTouched = Object.values(touched).filter((el) => {
      return el != null;
    });

    if (filtered.length < 1 && filteredTouched.length > 0) {
      // submit the form
      const reply = await submitVendorSubmission(payload);
      setStatus(reply && reply.status ? reply.status : reply);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      resetStatus();
    }, 4000);
  });
  return (
    <FormCard>
      <Label marginBottom="12px">{formatMessage({ id: 'title.addNewVendor' })}</Label>
      <TextDiv>{formatMessage({ id: 'text.toAddNewVendor' })}</TextDiv>

      <Label marginBottom="4px">{formatMessage({ id: 'title.name' })}</Label>
      <ExpandTextBox
        onChange={handleChange}
        placeholder="Name"
        name="Name"
        id="name"
        required
        error={errors.name}
        ref={nameRef}
      />
      {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}

      <Label marginBottom="4px">{formatMessage({ id: 'text.vendorCode' })}</Label>
      <StyledInput
        type="text"
        id="vendorCode"
        name="Vendor Code"
        required
        error={errors.vendorCode}
        placeholder="000-000-000"
        width="120px"
        ref={vendorCodeRef}
        onChange={handleChange}
      />
      {errors.vendorCode && <ErrorMessage>{errors.vendorCode}</ErrorMessage>}
      <Label marginBottom="4px">{formatMessage({ id: 'text.addressLine1' })}</Label>
      <StyledInput
        type="text"
        id="address1"
        name="Address Line 1"
        error={errors.address1}
        placeholder="Canada Building"
        ref={addressLine1Ref}
        onChange={handleChange}
      />
      {errors.address1 && <ErrorMessage>{errors.address1}</ErrorMessage>}
      <Label marginBottom="4px">{formatMessage({ id: 'text.addressLine2' })}</Label>
      <StyledInput
        type="text"
        id="address2"
        name="Address Line 2"
        error={errors.address2}
        placeholder="Suite 20"
        ref={addressLine2Ref}
        onChange={handleChange}
      />
      {errors.address2 && <ErrorMessage>{errors.address2}</ErrorMessage>}
      <Label marginBottom="4px">{formatMessage({ id: 'text.zipCode' })}</Label>
      <StyledInput
        type="text"
        id="zipCode"
        name="Zip Code"
        error={errors.zipCode}
        placeholder="ON L6A 1S6"
        ref={zipCodeRef}
        onChange={handleChange}
      />
      {errors.zipCode && <ErrorMessage>{errors.zipCode}</ErrorMessage>}
      <FlexDiv>
        <div>
          <Label marginBottom="4px">{formatMessage({ id: 'text.city' })}</Label>
          <StyledInput
            width="188px"
            type="text"
            id="city"
            name="City"
            error={errors.city}
            placeholder="Toronto"
            marginright="24px"
            ref={cityRef}
            onChange={handleChange}
          />
          {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
        </div>
        <div>
          <Label marginBottom="4px">{formatMessage({ id: 'text.state' })}</Label>
          <Select
            items={states}
            placeholder={formatMessage({ id: 'text.selectAState' })}
            isSearchable
            value={chosenState}
            onSelect={setState}
          />
        </div>
      </FlexDiv>
      <Label marginBottom="4px">{formatMessage({ id: 'text.country' })}</Label>
      <SelectWrapper marginBottom="24px">
        <Select
          items={countryCodes}
          placeholder={formatMessage({ id: 'text.selectACountry' })}
          isSearchable
          value={chosenCountry}
          onSelect={setCountry}
        />
      </SelectWrapper>
      <Label marginBottom="4px">{formatMessage({ id: 'text.currency' })}</Label>
      <SelectWrapper marginBottom="24px">
        <Select
          items={currencyCodes}
          placeholder={formatMessage({ id: 'text.selectACurrency' })}
          isSearchable
          value={chosenCurrency}
          onSelect={setCurrency}
        />
      </SelectWrapper>
      {errors.currency && <ErrorMessage marginTop="-20px">{errors.currency}</ErrorMessage>}
      <StyledSubmitWrapper status={status}>
        {status && (
          <>
            <AlertBox type={status === 200 ? 'success' : 'warning'}>
              {status && status === 200 ? 'Success: New vendor added' : `Warning: Vendor code already exists`}
            </AlertBox>
          </>
        )}
        <StyledSubmit onClick={handleFormSubmit}>{formatMessage({ id: 'form.button.addVendor' })}</StyledSubmit>
      </StyledSubmitWrapper>
    </FormCard>
  );
};

export default NewVendorForm;
