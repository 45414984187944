import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { UserPermission, CamoResource } from '../../models/userSettings';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import { queryPeople, linkGsuite, unlinkGsuite } from '../../services/apiNew';
import { PeopleActionTypes } from '../../models/people';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import UserManagementTable from '../../components/UserManagementTable';
import SearchInput from '../../components/SearchInput';
import LegendItem from '../../components/AircraftServiceOverview/LegendItem';
import { hasServiceDeskPermission } from '../../providers/AuthProvider';
import userSettingsIcon from '../../assets/pageHeaderIcons/title-user-settings.svg';
import styles from './UserManagement.module.less';

const UserManagement = ({ peopleMap, updatePeople, addUserButton, userSettings }) => {
  const [loading, setLoading] = useState(true);
  const [activeRoles, setActiveRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [activeRoleList, setActiveRoleList] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [activeCompanies, setActiveCompanies] = useState([]);
  const [companyTables, setCompanyTables] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { formatMessage } = useIntl();
  const [dataMessage, setDataMessage] = useState('');

  useEffect(() => {
    const getPeople = async () => {
      try {
        const response = await queryPeople();
        if (!response.statusText === 'OK') {
          throw new Error('Unable to retrieve users');
        }
        updatePeople(response.data);
      } catch (error) {
        console.error(error.message);
      }
    };
    getPeople();
  }, [updatePeople]);

  useEffect(() => {
    let organisations = [];
    const people = Array.from(peopleMap.values());
    people.forEach((person) => {
      const organisation = person.organisations.map((organisationObject) => ({
        id: organisationObject.id,
        gsuiteEnabled: organisationObject.gsuiteEnabled,
        organisation: organisationObject.name,
        users: [],
      }));
      organisations = [...organisations, ...organisation];
    });
    const organisationArray = Array.from(new Set(organisations.map(JSON.stringify))).map(JSON.parse);
    let users = [];
    people.forEach((person) => {
      const personWithRole = person.organisations.map((organisation) => ({
        ...person,
        role: organisation.role,
        organisation: organisation.name,
      }));
      users = [...users, ...personWithRole];
    });
    let roles = [];
    const role = users.map((user) => ({
      name: user.role,
    }));
    roles = [...roles, ...role];
    const userRoles = Array.from(new Set(roles.map(JSON.stringify))).map(JSON.parse);
    users.forEach((user) => {
      organisationArray.forEach((company) => {
        if (company.organisation === user.organisation) {
          company.users.push(user);
        }
      });
    });
    setLoading(false);
    setFilteredUsers([...organisationArray]);
    setCompanyTables([...organisationArray]);
    setRoleList([...userRoles]);
    setDataMessage(
      users.length > 0 ? formatMessage({ id: 'text.noSearchData' }) : formatMessage({ id: 'text.noData' }),
    );
  }, [peopleMap, formatMessage]);

  const filterTable = (table, filter) => {
    let data = [];
    data = [
      ...data,
      ...table.map((company) => ({
        organisation: company.organisation,
        users: company.users
          .filter((person) =>
            Object.keys(person).some((key) => {
              const val = key === 'id' || key === 'role' || key === 'lastActive' ? null : person[key];
              return typeof val === 'string' && val.toLowerCase().includes(filter);
            }),
          )
          .map((entry) => ({ ...entry })),
      })),
    ];
    return data;
  };

  const handleFilterTable = (value) => {
    const filteredBy = value.toLowerCase();
    let data = [];
    if (activeCompanies.length > 0) {
      if (filteredRoles.length > 0) {
        data = filterTable(filteredRoles, filteredBy);
      } else {
        data = filterTable(activeCompanies, filteredBy);
      }
    } else if (filteredRoles.length > 0) {
      data = filterTable(filteredRoles, filteredBy);
    } else {
      data = filterTable(companyTables, filteredBy);
    }
    setFilteredUsers(data);
  };

  const filterByRole = (array, table) => {
    const data = [];
    table.forEach((company) => {
      const roleArray = array.map((role) => ({
        role: company.users.filter((user) => {
          return user.role === role;
        }),
      }));
      let userList = [];
      roleArray.forEach((role) => {
        userList = [...userList, ...role.role];
      });
      data.push({ organisation: company.organisation, users: userList });
    });
    setFilteredRoles([...data]);
    setFilteredUsers([...data]);
  };

  const handleRoleFilterClick = (name, func, array) => {
    if (array.includes(name)) {
      const index = array.indexOf(name);
      array.splice(index, 1);
      func([...array]);
    } else {
      array.push(name);
      func([...array]);
    }

    if (array.length > 0) {
      if (activeCompanies.length > 0) {
        filterByRole(array, activeCompanies);
      } else {
        filterByRole(array, companyTables);
      }
    } else if (activeCompanies.length > 0) {
      setFilteredRoles([]);
      setFilteredUsers([...activeCompanies]);
    } else {
      setFilteredRoles([]);
      setFilteredUsers([...companyTables]);
    }
  };

  const filterByCompany = (array) => {
    let data = [];
    array.forEach((orgName) => {
      data = [
        ...data,
        ...companyTables
          .filter((company) => {
            return company.organisation === orgName;
          })
          .map((entry) => ({ ...entry })),
      ];
    });

    if (data.length > 0) {
      setActiveCompanies([...data]);
      if (activeRoleList.length > 0) {
        filterByRole(activeRoleList, data);
      } else {
        setFilteredUsers([...data]);
      }
    } else {
      setActiveCompanies(false);
      if (activeRoleList.length > 0) {
        filterByRole(activeRoleList, companyTables);
      } else {
        setFilteredUsers([...companyTables]);
      }
    }
  };

  const handleCompanyFilterClick = (name, func, array) => {
    if (array.includes(name)) {
      const index = array.indexOf(name);
      array.splice(index, 1);
      func([...array]);
    } else {
      array.push(name);
      func([...array]);
    }
    filterByCompany(array);
  };

  const onClickLink = async (orgId) => {
    try {
      const response = await linkGsuite(orgId);
      if (response.status === 200 && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('unable to generate gsuite oauth url');
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const onClickUnlink = async (orgId) => {
    try {
      const response = await unlinkGsuite(orgId);
      if (!response.statusText === 'OK') {
        throw new Error('unable to remove gsuite integration');
      }
      window.location.reload();
    } catch (err) {
      console.error(err.message);
    }
  };

  const renderGsuiteButtons = (company) => {
    const hasPermission = hasServiceDeskPermission(
      userSettings?.details?.service_desk_permissions,
      CamoResource.ORGANISATIONS,
      UserPermission.OWNER,
    );
    if (!hasPermission || company.organisation === 'TrustFlight') return null;
    return company.gsuiteEnabled ? (
      <Button className={`${styles.linkGsuiteButton}`} onClick={() => onClickUnlink(company.id)}>
        Remove GSuite
      </Button>
    ) : (
      <Button className={`${styles.linkGsuiteButton}`} onClick={() => onClickLink(company.id)}>
        Link to GSuite
      </Button>
    );
  };

  return (
    <PageHeaderWrapper>
      <PageHeaderWrapper
        secondaryHeader
        layout="modern"
        title={
          <span>
            <img src={userSettingsIcon} alt="reports" className={styles.headerIcon} />{' '}
            {formatMessage({ id: 'title.userSettings' })}
          </span>
        }
        content={formatMessage({ id: 'title.userSettingsHeader' })}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Card className={`${cardStyles.pageCard} ${cardStyles.padded} ${styles.userManagementCard}`}>
              {loading ? (
                <div className={styles.loadingDiv}>
                  <Spin size="small" />
                </div>
              ) : (
                <>
                  <Col span={19} className={styles.filtersCol}>
                    <Row>
                      <Col span={18} className={styles.filtersCol}>
                        <div className={styles.listFilterRow}>
                          <SearchInput
                            placeholder={formatMessage({ id: 'form.placeholder.searchByUser' })}
                            value={searchValue}
                            visible={searchVisible}
                            onToggle={() => setSearchVisible(!searchVisible)}
                            onBlur={(value) => {
                              if (!value) {
                                setSearchVisible(false);
                              }
                            }}
                            onChange={(value) => {
                              setSearchValue(value);
                              if (!value) {
                                if (activeCompanies.length > 0) {
                                  if (filteredRoles.length > 0) {
                                    setFilteredUsers([...filteredRoles]);
                                  } else {
                                    setFilteredUsers([...activeCompanies]);
                                  }
                                } else if (filteredRoles.length > 0) {
                                  setFilteredUsers([...filteredRoles]);
                                } else {
                                  setFilteredUsers(companyTables);
                                }
                              } else {
                                handleFilterTable(value);
                              }
                            }}
                            data-test="searchInput"
                            onClear={() => {
                              setSearchValue(null);
                              if (activeCompanies.length > 0) {
                                if (filteredRoles.length > 0) {
                                  setFilteredUsers([...filteredRoles]);
                                } else {
                                  setFilteredUsers([...activeCompanies]);
                                }
                              } else {
                                setFilteredUsers([...companyTables]);
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col span={3} />
                      {addUserButton && (
                        <Col span={3} className={styles.filtersCol}>
                          <div className="buttonContainer">
                            <Button type="primary" shape="round" className={styles.pointer}>
                              + Add user
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {filteredUsers &&
                        filteredUsers.map((company) => (
                          <div key={company.organisation}>
                            <div className={`${styles.headerWrapper}`}>
                              <div className={`${styles.tableHeader} ${styles.gap}`}>
                                {`${company.organisation} (${company.users.length})`}
                              </div>
                              {renderGsuiteButtons(company)}
                            </div>
                            <UserManagementTable
                              users={company.users}
                              organisation={company.organisation}
                              dataMessage={dataMessage}
                            />
                          </div>
                        ))}
                    </Row>
                  </Col>
                  <Col span={1} />
                  <Col lg={3} md={3} sm={3} className={styles.filtersCol}>
                    <div className={`${styles.filterHeader} ${styles.gap}`}>
                      {formatMessage({ id: 'text.company' })}
                    </div>
                    {companyTables &&
                      companyTables.map((company) => (
                        <LegendItem
                          key={company.organisation}
                          name={`${company.organisation} (${company.users.length})`}
                          id={company.organisation}
                          onClick={handleCompanyFilterClick}
                          func={setActiveRoles}
                          active={activeRoles}
                        />
                      ))}
                    <div className={`${styles.filterHeader} ${styles.gap} ${styles.filterHeaderLast}`}>
                      {formatMessage({ id: 'text.roles' })}
                    </div>
                    {roleList &&
                      roleList.map((role) => (
                        <LegendItem
                          key={role.name}
                          id={role.name}
                          name={role.name}
                          onClick={handleRoleFilterClick}
                          func={setActiveRoleList}
                          active={activeRoleList}
                        />
                      ))}
                  </Col>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </PageHeaderWrapper>
    </PageHeaderWrapper>
  );
};

UserManagement.propTypes = {
  peopleMap: PropTypes.instanceOf(Map),
  addUserButton: PropTypes.bool,
  userSettings: PropTypes.object,
  updatePeople: PropTypes.func.isRequired,
};

UserManagement.defaultProps = {
  peopleMap: new Map(),
  addUserButton: false,
  userSettings: {},
};

const UserManagementWithRedux = connect(
  ({ people, userSettings }) => ({
    peopleMap: people.peopleMap,
    userSettings,
  }),
  (dispatch) => ({
    updatePeople: async (payload) =>
      dispatch({
        type: PeopleActionTypes.SAVE_ALL,
        payload,
      }),
  }),
)(UserManagement);

export default UserManagementWithRedux;
