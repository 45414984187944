import { Card } from 'antd';
import React, { ReactElement } from 'react';
import chevron from '../../assets/icon-chevron-blue.svg';
import styles from './IssueCard.module.less';

const IssueCard = ({ icon, title, subTitle, showChevron }): ReactElement => {
  return (
    <Card className={styles.formTitleCard}>
      <div className={styles.cardLayoutWrapper}>
        <div className={styles.titleWrapper}>
          {icon && <img src={icon} alt="icon" className={styles.titleIcon} />}
          <div className={styles.textWrapper}>
            <div className={styles.cardTitle}>{title}</div>
            <div className={styles.subTitle}>{subTitle}</div>
          </div>
        </div>
        {showChevron && <img src={chevron} alt="chevron" className={styles.chevron} />}
      </div>
    </Card>
  );
};

export default IssueCard;
