import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import ContentLayout from '../layouts/ContentLayout';
import ExternalLayout from '../layouts/ExternalLayout';
import { CamoResource, UserPermission } from '../models/userSettings';
import Exception403 from '../pages/403';
import Exception404 from '../pages/404';
import Exception500 from '../pages/500';
import OauthCallback from '../pages/OauthCallback';
import FleetStatus from '../pages/Camo/FleetStatus';
import ReliabilityReports from '../pages/Camo/ReliabilityReports';
import HelpDesk from '../pages/Camo/HelpDesk';
import Performance from '../pages/Camo/Performance';
import Meetings from '../pages/Camo/Meetings';
import FleetDefects from '../pages/Camo/FleetDefects';
import FleetTechlog from '../pages/Camo/FleetTechlog';
import FleetWorkpack from '../pages/Camo/FleetWorkpack';
import DocumentPage from '../pages/Camo/DocumentPage';
import Logistics from '../pages/Camo/Logistics';
import DocumentPageApprovalState from '../pages/Camo/DocumentPageApprovalState';
import DocumentPageRevoked from '../pages/Camo/DocumentPageRevoked';
import UserManagement from '../pages/Camo/UserManagement';
import AircraftServiceOverview from '../pages/Camo/AircraftServiceOverview';
import AircraftMaintenanceForecast from '../pages/Camo/AircraftMaintenanceForecast';
import AircraftCostAnalysis from '../pages/Camo/AircraftCostAnalysis';
import AircraftDefects from '../pages/Camo/AircraftDefects';
import AuthProvider from './AuthProvider';

/**
 * React Context Provider for React Router DOM.
 *
 * Currently handles determines which pages require authentication,
 * which paths are available and the permissions required for each path.
 *
 * @returns {ReactFC} React Functional Component
 */
const RoutesWrapper: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Route path="/auth/callback">
        <OauthCallback />
      </Route>
      <Route path="/public/document/:approvalRequestId" key={`/public/document/approvalRequestId_${pathname}`} exact>
        <DocumentPage match={useRouteMatch} />
      </Route>
      <Route path="/public/document/:documentId/complete" key={`/public/document/documentId_${pathname}`} exact>
        <DocumentPageApprovalState match={useRouteMatch} />
      </Route>
      <Route path="/public/document/:documentId/revoked" key={`/public/document/documentId_${pathname}`} exact>
        <DocumentPageRevoked match={useRouteMatch} />
      </Route>
      <Route path="/public/exception/404" key={`/public/exception/404_${pathname}`} exact>
        <ExternalLayout>
          <Exception404 />
        </ExternalLayout>
      </Route>
      <Route path="/public/exception/500" key={`/public/exception/500_${pathname}`} exact>
        <ExternalLayout>
          <Exception500 />
        </ExternalLayout>
      </Route>
      <Route path="/" key={`/_${pathname}`} exact>
        {/* This path should never be used so we redirect to Dashboard instead :) */}
        <Redirect to="/camo/fleet" />
      </Route>
      <Route path="/exception/403" key={`/exception/403_${pathname}`} exact>
        <ContentLayout>
          <Exception403 />
        </ContentLayout>
      </Route>
      <Route path="/exception/404" key={`/exception/404_${pathname}`} exact>
        <ContentLayout>
          <Exception404 />
        </ContentLayout>
      </Route>
      <Route path="/exception/500" key={`/exception/500_${pathname}`} exact>
        <ContentLayout>
          <Exception500 />
        </ContentLayout>
      </Route>
      <Route path="/camo/fleet" key={`/camo/fleet_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MAINTENANCE} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <FleetStatus />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/fleetStatus" key={`/camo/fleetStatus_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.HELPDESK} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <HelpDesk />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/reliabilityReports" key={`/camo/reliabilityReports_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.REPORTS} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <ReliabilityReports />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/logistics" key={`/camo/logistics_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MATERIALS} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Logistics />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/aircraft/:id/overview" key={`/camo/aircraft_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MAINTENANCE} permissionRequired={UserPermission.READ}>
          <AircraftServiceOverview />
        </AuthProvider>
      </Route>
      <Route path="/camo/aircraft/:id/forecast" key={`/camo/aircraft_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MAINTENANCE} permissionRequired={UserPermission.READ}>
          <AircraftMaintenanceForecast />
        </AuthProvider>
      </Route>
      <Route path="/camo/aircraft/:id/cost" key={`/camo/aircraft_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.COST} permissionRequired={UserPermission.READ}>
          <AircraftCostAnalysis />
        </AuthProvider>
      </Route>
      <Route path="/camo/aircraft/:id/defects" key={`/camo/aircraft_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MAINTENANCE} permissionRequired={UserPermission.READ}>
          <AircraftDefects />
        </AuthProvider>
      </Route>
      <Route path="/camo/performance" key={`/camo/performance_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.PERFORMANCE} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Performance />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/meetings" key={`/camo/meetings_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MEETINGS} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Meetings />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/defects" key={`/camo/defects_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MAINTENANCE} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <FleetDefects />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/workpacks" key={`/camo/workpacks_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MAINTENANCE} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <FleetWorkpack />
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/camo/users" key={`/camo/performance_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.USERS} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <UserManagement />
          </ContentLayout>
        </AuthProvider>
      </Route>
      {/* <Route path="/camo/techlog" key={`/camo/techlog_${pathname}`} exact>
        <AuthProvider resourceRequired={CamoResource.MAINTENANCE} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <FleetTechlog />
          </ContentLayout>
        </AuthProvider>
      </Route> */}
      <Route path="*">
        <Redirect to="/exception/404" />
      </Route>
    </Switch>
  );
};

const RouterProvider: React.SFC = () => (
  <Router>
    <RoutesWrapper />
  </Router>
);

export default RouterProvider;
