import { IntlFormatters } from 'react-intl';
import { intersection } from 'lodash';
import { UserRoles, UserOrganisationPermissions, CamoResource, UserPermission } from '../models/userSettings';
import { hasServiceDeskPermission } from '../providers/AuthProvider';
import dashboard from '../assets/mainNav/icon-nav-main-fleet.svg';
import reports from '../assets/mainNav/reports-02.svg';
import operations from '../assets/mainNav/operations-02.svg';
import defects from '../assets/mainNav/defects-2.svg';
import crew from '../assets/mainNav/crew.svg';
import people from '../assets/mainNav/people.svg';
import meetings from '../assets/mainNav/meetings-white.svg';
import helpdesk from '../assets/mainNav/helpdesk.svg';
import settings from '../assets/mainNav/operations.svg';
import techlog from '../assets/mainNav/icon-tech-log.svg';
import logistics from '../assets/mainNav/icon-primary-navigation-parts.svg';
import workpacks from '../assets/mainNav/workpacks-icon.svg';

export interface MenuItem {
  exact?: boolean;
  icon?: string;
  name?: string;
  path: string;
  redirect?: string;
  authority?: UserRoles[];
  children?: MenuItem[];
  requiredResource?: CamoResource;
  requiredPermission?: UserPermission;
}

// Menu structure. For routes, still refer to ./config/router.config.js
export default (
  formatMessage: IntlFormatters['formatMessage'],
  dashboardCredentials: UserRoles[],
  serviceDeskPermissions: UserOrganisationPermissions[],
): MenuItem[] =>
  [
    {
      exact: true,
      path: '/',
      redirect: '/dashboard',
      authority: [UserRoles.ADMIN],
    },
    {
      icon: dashboard,
      name: formatMessage({ id: 'menu.dashboard' }),
      path: '/dashboard',
      authority: [UserRoles.ADMIN],
    },
    {
      icon: reports,
      name: formatMessage({ id: 'menu.reports' }),
      path: '/reports',
      authority: [UserRoles.ADMIN],
      children: [
        {
          exact: true,
          name: formatMessage({ id: 'menu.metrics' }),
          path: '/reports/metrics',
        },
        {
          exact: true,
          name: formatMessage({ id: 'menu.map' }),
          path: '/reports/map',
        },
        {
          exact: true,
          name: formatMessage({ id: 'menu.receipts' }),
          path: '/reports/receipts',
        },
      ],
    },
    {
      icon: operations,
      name: formatMessage({ id: 'menu.operations' }),
      path: '/operations',
      authority: [UserRoles.ADMIN],
      children: [
        {
          exact: true,
          name: formatMessage({ id: 'menu.flights' }),
          path: '/operations/flights',
        },
        {
          exact: true,
          name: formatMessage({ id: 'menu.documents' }),
          path: '/operations/documents',
        },
        {
          name: formatMessage({ id: 'title.mel' }),
          path: '/operations/mels',
        },
      ],
    },
    {
      icon: defects,
      name: formatMessage({ id: 'menu.defects' }),
      path: '/maintenance/defects',
      authority: [UserRoles.ADMIN],
    },
    {
      icon: crew,
      name: formatMessage({ id: 'menu.people' }),
      path: '/people',
      authority: [UserRoles.ADMIN],
      exact: true,
    },
    {
      icon: helpdesk,
      name: formatMessage({ id: 'menu.helpdesk' }),
      path: '/helpdesk/tickets',
      authority: [UserRoles.HELPDESK],
    },
    {
      icon: dashboard,
      name: formatMessage({ id: 'text.fleetStatus' }),
      path: '/camo/fleet',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.MAINTENANCE,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: helpdesk,
      name: formatMessage({ id: 'text.helpDesk' }),
      path: '/camo/fleetStatus',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.HELPDESK,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: reports,
      name: formatMessage({ id: 'text.reliabilityReports' }),
      path: '/camo/reliabilityReports',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.REPORTS,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: logistics,
      name: formatMessage({ id: 'text.logistics' }),
      path: '/camo/logistics',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.MATERIALS,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: reports,
      name: formatMessage({ id: 'text.performance' }),
      path: '/camo/performance',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.PERFORMANCE,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: meetings,
      name: formatMessage({ id: 'text.meetings' }),
      path: '/camo/meetings',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.MEETINGS,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: defects,
      name: formatMessage({ id: 'text.defects' }),
      path: '/camo/defects',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.MAINTENANCE,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: workpacks,
      name: formatMessage({ id: 'title.workpacks' }),
      path: '/camo/workpacks',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.MAINTENANCE,
      requiredPermission: UserPermission.READ,
    },
    // {
    //   icon: techlog,
    //   name: formatMessage({ id: 'text.techlog' }),
    //   path: '/camo/techlog',
    //   authority: [UserRoles.CAMO],
    //   requiredResource: CamoResource.MAINTENANCE,
    //   requiredPermission: UserPermission.READ,
    // },
    {
      icon: people,
      name: formatMessage({ id: 'text.userSettings' }),
      path: '/camo/users',
      authority: [UserRoles.CAMO],
      requiredResource: CamoResource.USERS,
      requiredPermission: UserPermission.READ,
    },
    {
      icon: settings,
      name: formatMessage({ id: 'menu.settings' }),
      path: '/settings',
      authority: [UserRoles.ADMIN],
      children: [
        {
          name: formatMessage({ id: 'menu.aircraft' }),
          path: '/settings/aircraft',
          exact: true,
        },
        {
          name: formatMessage({ id: 'menu.integrations' }),
          path: '/settings/integrations',
          exact: true,
        },
      ],
    },
  ].filter((entry) => {
    // use different permissions model if checking camo
    if (entry.authority.includes(UserRoles.CAMO)) {
      return hasServiceDeskPermission(serviceDeskPermissions, entry.requiredResource, entry.requiredPermission);
    }
    // Simple persmission checking to determine which pages of the dashboard the user is able to view.
    return intersection(entry.authority, dashboardCredentials).length > 0;
  });
