import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, Rectangle } from 'recharts';
import styles from './TicketTrendGraph.module.less';

const data = [
  { name: 'Mon', received: 17, resolved: 4, open: 2 },
  { name: 'Tue', received: 4, resolved: 8, open: 4 },
  { name: 'Wed', received: 6, resolved: 14, open: 16 },
  { name: 'Thu', received: 11, resolved: 8, open: 12 },
  { name: 'Fri', received: 26, resolved: 3, open: 8 },
  { name: 'Sat', received: 3, resolved: 11, open: 9 },
  { name: 'Sun', received: 18, resolved: 16, open: 0 },
];

const TicketTrendGraph = () => {
  const RoundedBar = ({ fill, x, y, width, height, radius }) => {
    return <Rectangle width={width} height={height + 4} x={x} y={y - 10} radius={radius} fill={fill} />;
  };

  RoundedBar.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string,
    radius: PropTypes.array,
  };

  RoundedBar.defaultProps = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    fill: '',
    radius: 0,
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#d5d7db" className={styles.yAxisLabel}>
          {payload.value}
        </text>
      </g>
    );
  };

  CustomizedAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object,
  };

  CustomizedAxisTick.defaultProps = {
    x: 0,
    y: 0,
    payload: {},
  };

  const CustomizedYAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-8} y={-20} dy={16} textAnchor="middle" fill="#d5d7db" className={styles.yAxisLabel}>
          {payload.value}
        </text>
      </g>
    );
  };

  CustomizedYAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object,
  };

  CustomizedYAxisTick.defaultProps = {
    x: 0,
    y: 0,
    payload: {},
  };

  return (
    <div className={styles.graphWrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Ticket Trends</div>
        <div className={styles.legendWrapper}>
          <div className={styles.legendItemWrapper}>
            <div className={`${styles.circle} ${styles.received}`} />
            <div>Received</div>
          </div>
          <div className={styles.legendItemWrapper}>
            <div className={`${styles.circle} ${styles.resolved}`} />
            <div>Resolved</div>
          </div>
          <div className={styles.legendItemWrapper}>
            <div className={`${styles.circle} ${styles.open}`} />
            <div>Open</div>
          </div>
        </div>
      </div>
      <ResponsiveContainer height={350} width="100%">
        <BarChart barGap={8} barSize={8} data={data} reverseStackOrder>
          <defs>
            <linearGradient id="received" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#72c9ff" />
              <stop offset="100%" stopColor="#3f98ff" />
            </linearGradient>
            <linearGradient id="resolved" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#024574" />
              <stop offset="100%" stopColor="#012140" />
            </linearGradient>
            <linearGradient id="openTicket" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#f4f5f7" />
              <stop offset="100%" stopColor="#e6e8ed" />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />} stroke="#d5d7db" tickLine={false} />
          <YAxis stroke="#d5d7db" tickLine={false} tick={<CustomizedYAxisTick />} />
          <Bar dataKey="open" stackId="a" fill="url(#openTicket)" shape={<RoundedBar radius={[10, 10, 0, 0]} />} />
          <Bar dataKey="resolved" stackId="a" fill="url(#resolved)" shape={<RoundedBar radius={[10, 10, 0, 0]} />} />
          <Bar dataKey="received" stackId="a" fill="url(#received)" shape={<RoundedBar radius={[10, 10, 10, 10]} />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TicketTrendGraph;
