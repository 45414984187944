import React, { useState, useEffect } from 'react';
import { useIntl, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Dropdown, Menu, Icon, Table, Button } from 'antd';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableTitle from '../TableTitle/TableTitle';
import TableEmptyState from '../../assets/empty-state-users.svg';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';

const UserManagementTable = ({ users, organisation, dataMessage }) => {
  const { formatMessage } = useIntl();
  const columns = [
    {
      dataIndex: 'icon',
      width: 20,
      className: tableStyles.tableHeader,
      render(val) {
        return (
          <div>
            <span> {val}</span>
          </div>
        );
      },
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.name' })} dataIndex="name" />,
      dataIndex: 'name',
      width: 130,
      sortName: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      className: tableStyles.tableHeader,
      render(val) {
        return (
          <div>
            <span className={tableStyles.nameText}> {val}</span>
          </div>
        );
      },
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.organisation' })} dataIndex="organisation" />
      ),
      dataIndex: 'organisation',
      className: tableStyles.tableHeader,
      sorter: (a, b) => a.organisation.localeCompare(b.organisation),
      sortDirections: ['descend', 'ascend'],
      render() {
        return (
          <div>
            <span className={tableStyles.companyText}>{organisation}</span>
          </div>
        );
      },
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.email' })} dataIndex="email" />,
      dataIndex: 'email',
      className: tableStyles.tableHeader,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return (
          <div>
            <span className={tableStyles.companyText}>{val}</span>
          </div>
        );
      },
    },
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.role' })} dataIndex="role" />,
      dataIndex: 'role',
      className: tableStyles.tableHeader,
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return <span className={tableStyles.companyText}>{val}</span>;
      },
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.lastActive' })} dataIndex="lastActive" />
      ),
      dataIndex: 'lastActive',
      className: tableStyles.tableHeader,
      render(val) {
        return <span className={tableStyles.lastActiveText}>{val}</span>;
      },
    },
  ];

  return (
    <>
      <div className={tableStyles.tableContainer}>
        {users.length === 0 ? (
          <div className={tableStyles.emptyStateWrapper}>
            <img src={TableEmptyState} alt="no data" className={tableStyles.emptyState} />
            <span>{dataMessage}</span>
          </div>
        ) : (
          <Table
            rowKey="id"
            columns={columns}
            dataSource={users}
            bordered={false}
            rowClassName={tableStyles.tableRow}
            pagination={false}
            scroll={{ x: 800 }}
          />
        )}
      </div>
    </>
  );
};

UserManagementTable.propTypes = {
  users: PropTypes.array,
  organisation: PropTypes.string,
  dataMessage: PropTypes.string.isRequired,
};

UserManagementTable.defaultProps = {
  users: [],
  organisation: undefined,
};

const UserManagementTableWithRedux = connect()(UserManagementTable);

export default UserManagementTableWithRedux;
