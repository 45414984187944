import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import React from 'react';
import * as Sentry from '@sentry/browser';
import * as Bowser from 'bowser';
import ReactDOM from 'react-dom';
import { TrustFlightThemeProvider } from '@arcflight/tf-component-library';
import NotValidBrowser from './layouts/notValidBrowser';
import './index.module.less';
import IntlProvider from './providers/IntlProvider';
import ReduxProvider from './providers/ReduxProvider';
import RouterProvider from './providers/RouterProvider';
import * as serviceWorker from './serviceWorker';
import config, { ConfigKey } from './utils/config';

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.getBrowserName() !== 'Internet Explorer';

if (!isValidBrowser) {
  ReactDOM.render(<NotValidBrowser browserName={browser.getBrowserName()} />, document.getElementById('root'));
} else {
  axios
    .get(`${process.env.PUBLIC_URL}/config.json`)
    .then((res) => {
      config.setConfig(res.data);
      Sentry.init({ dsn: config.get(ConfigKey.SENTRY), environment: config.get(ConfigKey.SENTRY_ENVIRONMENT) });
    })
    .catch(async (err) => {
      console.info('Failed to load config from server, will fallback to Production configuration', err);
    })
    // This should be a `.finally` but we still require our app to at least render for IE11.
    .then(() => {
      const App: React.SFC = () => {
        return (
          <ReduxProvider>
            <IntlProvider>
              <TrustFlightThemeProvider>
                <RouterProvider />
              </TrustFlightThemeProvider>
            </IntlProvider>
          </ReduxProvider>
        );
      };

      ReactDOM.render(<App />, document.getElementById('root'));
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
