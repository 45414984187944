import { EngineReportsActionTypes } from '.';

export const saveFleetEngine = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.FLEETENGINES,
    payload,
  });
};

export const saveFleetOil = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.FLEETOIL,
    payload,
  });
};

export const saveAircraftEngine = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.AIRCRAFTENGINES,
    payload,
  });
};

export const saveAircraftOil = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.AIRCRAFTOIL,
    payload,
  });
};

export const saveAircraftTypeEngine = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.AIRCRAFTTYPEENGINES,
    payload,
  });
};

export const saveAircraftTypeOil = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.AIRCRAFTTYPEOIL,
    payload,
  });
};

export const saveFleetUnscheduledEngineRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.FLEETUNSCHEDULEDENGINEREMOVALS,
    payload,
  });
};

export const saveAircraftUnscheduledEngineRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.AIRCRAFTUNSCHEDULEDENGINEREMOVALS,
    payload,
  });
};

export const saveAircraftTypeUnscheduledEngineRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: EngineReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS,
    payload,
  });
};
