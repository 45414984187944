import { BaseModel } from '..';

const initialState = {
  meetingMap: new Map(),
  lastFetched: 0,
  data: {
    list: [],
  },
};

export enum MeetingActionTypes {
  SAVE_ALL = 'meeting/saveAll',
}

export interface SaveAllMeetingsAction {
  type: MeetingActionTypes.SAVE_ALL;
  payload: Meeting;
}

type MeetingAction = SaveAllMeetingsAction;

export default function(state = initialState, action: MeetingAction): MeetingState {
  switch (action.type) {
    case MeetingActionTypes.SAVE_ALL: {
      const meetingMap = new Map(state.meetingMap.entries());
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((meeting) => {
          meetingMap.set(meeting.id, {
            ...meetingMap.get(meeting.id),
            ...meeting,
          });
        });
      }
      return {
        ...state,
        meetingMap,
      };
    }
    default:
      return state;
  }
}

export interface MeetingState {
  meetingMap: Map<string, Meeting>;
  lastFetched: number;
  data: {
    list: Meeting[];
  };
}

export interface Meeting extends BaseModel {
  id: string;
  start: {
    dateTime: string;
    updated: string;
  };
  end: {
    dateTime: string;
  };
  status: string;
  summary: string;
}
