import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Button } from 'antd';
import searchIcon from './assets/search.svg';
import styles from './index.module.less';

const SearchInput = ({ placeholder, value, visible, onToggle, onChange, onClear, inputRef, onBlur }) => {
  useEffect(() => {
    setTimeout(() => {
      const activeInputText = document.getElementsByClassName(
        `${styles.searchInput} ${inputRef} ${visible ? styles.searchInputActive : undefined}`,
      )[0];
      if (activeInputText) activeInputText.focus();
    }, 400);
  }, [inputRef, visible]);
  return (
    <div className={styles.searchHolder}>
      <Button type="link" className={styles.searchIconHolder} onClick={() => onToggle()}>
        <img
          src={searchIcon}
          alt="search"
          className={`${styles.searchIcon} ${visible ? styles.searchIconActive : undefined}`}
          data-test="searchIcon"
        />
      </Button>
      <div className={styles.searchInputHolder}>
        <Input
          className={`${styles.searchInput} ${inputRef} ${visible ? styles.searchInputActive : undefined}`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => onBlur(value)}
          data-test="searchInput"
        />
      </div>
      <Button
        type="link"
        className={`${styles.closeIconHolder} ${visible ? styles.closeIconHolderActive : undefined}`}
        onClick={() => {
          const activeInputText = document.getElementsByClassName(
            `${styles.searchInput} ${inputRef} ${visible ? styles.searchInputActive : undefined}`,
          )[0];
          activeInputText.focus();
          onClear();
        }}
        data-test="closeIcon"
      >
        <Icon type="close-circle" className={styles.closeIcon} />
      </Button>
    </div>
  );
};

SearchInput.defaultProps = {
  value: null,
  onClear: () => {
    // default func
  },
  inputRef: '',
};

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  inputRef: PropTypes.string,
};

export default SearchInput;
