import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Spin } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ModernPagination from '../ModernPagination';
import TableTitle from '../TableTitle/TableTitle';
import arrowIcon from '../../assets/icon-chevron-blue.svg';
import MeetingsEmptyState from '../../assets/empty-state-meetings.svg';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';
import styles from './MeetingsTable.module.less';

const MeetingsTable = ({ intl, title, meetingMap, calenderState, loading }) => {
  const { formatMessage } = intl;
  const [meetings, setMeetings] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataMessage, setDataMessage] = useState(formatMessage({ id: 'text.noData' }));

  useEffect(() => {
    const futureMeetingList = [];
    const pastMeetingList = [];
    const today = moment();

    if (meetingMap) {
      Array.from(meetingMap.values()).forEach((meeting) => {
        if (calenderState === 'future' && moment(meeting.start.dateTime).isAfter(today, 'day')) {
          futureMeetingList.push(meeting);
        }
        if (calenderState === 'past' && moment(meeting.start.dateTime).isBefore(today, 'day')) {
          pastMeetingList.push(meeting);
        }
      });
    }
    if (calenderState === 'future') {
      setMeetings(futureMeetingList);
      setDataMessage(
        Array.from(meetingMap.values()).length > 0
          ? formatMessage({ id: 'text.noScheduledMeetings' })
          : formatMessage({ id: 'text.noData' }),
      );
    }
    if (calenderState === 'past') {
      setMeetings(pastMeetingList);
      setDataMessage(
        Array.from(meetingMap.values()).length > 0
          ? formatMessage({ id: 'text.noPreviousMeetings' })
          : formatMessage({ id: 'text.noData' }),
      );
    }
  }, [meetingMap, calenderState, formatMessage]);

  const columns = [
    {
      title: (props) => <TableTitle props={props} title={formatMessage({ id: 'title.topic' })} dataIndex="summary" />,
      dataIndex: 'summary',
      className: tableStyles.dataBold,
      width: 150,
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.date' })} dataIndex="start.dateTime" />
      ),
      dataIndex: 'start.dateTime',
      className: tableStyles.dataGrey,
      width: 80,
      render(val) {
        return <span>{moment(val).format('DD/MM/YY')}</span>;
      },
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.time' })} dataIndex="start.dateTime" />
      ),
      dataIndex: 'start.dateTime',
      className: tableStyles.dataBold,
      width: 80,
      render(val) {
        return <span>{moment(val).format('HH:mm')}</span>;
      },
    },
    {
      title: (props) => (
        <TableTitle props={props} title={formatMessage({ id: 'title.frequency' })} dataIndex="frequency" />
      ),
      dataIndex: 'frequency',
      className: tableStyles.dataGrey,
      width: 130,
      render(val) {
        return (
          <span>
            <p className={tableStyles.sentenceCase}>{val}</p>
          </span>
        );
      },
    },
  ];

  if (calenderState === 'future') {
    columns.push({
      title: formatMessage({ id: 'title.conferenceLink' }),
      dataIndex: 'conferenceData.entryPoints[0].uri',
      className: styles.dataLink,
      width: 130,
      render(val) {
        return (
          <span>
            {val && (
              <a href={val} target="_blank" rel="noopener noreferrer">
                Join the meeting <img src={arrowIcon} alt="arrow" className={styles.arrowIcon} />
              </a>
            )}
          </span>
        );
      },
    });
  }

  let dataSubset = meetings && meetings.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  if (dataSubset && dataSubset.length === 0) dataSubset = meetings;
  if (loading) {
    return (
      <div className={styles.loadingDiv}>
        <Spin size="small" />
      </div>
    );
  }
  return (
    <div className={tableStyles.tableContainer}>
      <div className={styles.title}>{title}</div>
      {meetings && meetings.length === 0 ? (
        <div className={styles.emptyStateWrapper}>
          <img src={MeetingsEmptyState} alt="no data" className={styles.emptyState} />
          <span>{dataMessage}</span>
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={dataSubset}
            rowClassName={tableStyles.rowClassName}
            pagination={false}
            rowKey="id"
            scroll={{ x: 600 }}
          />
          {meetings.length > 15 && (
            <ModernPagination
              key="modernPagination"
              pageSize={pageSize}
              current={currentPage}
              total={meetings.length}
              onPageNoChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(size) => setPageSize(size)}
              data-test="modernPagination"
            />
          )}
        </>
      )}
    </div>
  );
};

MeetingsTable.propTypes = {
  intl: PropTypes.object.isRequired,
  title: PropTypes.string,
  meetingMap: PropTypes.instanceOf(Map).isRequired,
  calenderState: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

MeetingsTable.defaultProps = {
  loading: false,
  title: '',
};

const MeetingsTableWithRedux = connect(({ meetings }) => ({ meetingMap: meetings.meetingMap }))(MeetingsTable);

export default injectIntl(MeetingsTableWithRedux);
