const initialState: PerformanceReportsState = {
  fleet: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraft: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraftType: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  ttl: 60000,
};

export enum PerformanceReportsActionTypes {
  FLEETDEFECTTRENDS = 'performanceReports/fleetDefectTrends',
  FLEETDEFECTSUMMARY = 'performanceReports/fleetDefectSummary',
  FLEETUNSCHEDULEDREMOVALS = 'performanceReports/fleetUnscheduledRemovals',
  FLEETUNSCHEDULEDENGINEREMOVALS = 'performanceReports/fleetUnscheduledEngineRemovals',
  FLEETATADEFECTS = 'performanceReports/fleetATADefects',
  FLEETTIMEBETWEENDEFECTS = 'performanceReports/fleetTimeBetweenDefects',
  AIRCRAFTDEFECTTRENDS = 'performanceReports/aircraftDefectTrends',
  AIRCRAFTDEFECTSUMMARY = 'performanceReports/aircraftDefectSummary',
  AIRCRAFTUNSCHEDULEDREMOVALS = 'performanceReports/aircraftUnscheduledRemovals',
  AIRCRAFTUNSCHEDULEDENGINEREMOVALS = 'performanceReports/aircraftUnscheduledEngineRemovals',
  AIRCRAFTATADEFECTS = 'performanceReports/aircraftATADefects',
  AIRCRAFTTIMEBETWEENDEFECTS = 'performanceReports/aircraftTimeBetweenDefects',
  AIRCRAFTTYPEDEFECTTRENDS = 'performanceReports/aircraftTypeDefectTrends',
  AIRCRAFTTYPEDEFECTSUMMARY = 'performanceReports/aircraftTypeDefectSummary',
  AIRCRAFTTYPEUNSCHEDULEDREMOVALS = 'performanceReports/aircraftTypeUnscheduledRemovals',
  AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS = 'performanceReports/aircraftTypeUnscheduledEngineRemovals',
  AIRCRAFTTYPEATADEFECTS = 'performanceReports/aircraftTypeATADefects',
  AIRCRAFTTYPETIMEBETWEENDEFECTS = 'performanceReports/aircraftTypeTimeBetweenDefects',
}

interface SaveFleetDefectTrends {
  type: PerformanceReportsActionTypes.FLEETDEFECTTRENDS;
  payload: any;
}

interface SaveFleetDefectSummary {
  type: PerformanceReportsActionTypes.FLEETDEFECTSUMMARY;
  payload: any;
}

interface SaveFleetUnscheduledRemovals {
  type: PerformanceReportsActionTypes.FLEETUNSCHEDULEDREMOVALS;
  payload: any;
}

interface SaveFleetUnscheduledEngineRemovals {
  type: PerformanceReportsActionTypes.FLEETUNSCHEDULEDENGINEREMOVALS;
  payload: any;
}

interface SaveFleetATADefects {
  type: PerformanceReportsActionTypes.FLEETATADEFECTS;
  payload: any;
}

interface SaveFleetTimeBetweenDefects {
  type: PerformanceReportsActionTypes.FLEETTIMEBETWEENDEFECTS;
  payload: any;
}

interface SaveAircraftDefectTrends {
  type: PerformanceReportsActionTypes.AIRCRAFTDEFECTTRENDS;
  payload: any;
}

interface SaveAircraftDefectSummary {
  type: PerformanceReportsActionTypes.AIRCRAFTDEFECTSUMMARY;
  payload: any;
}

interface SaveAircraftUnscheduledRemovals {
  type: PerformanceReportsActionTypes.AIRCRAFTUNSCHEDULEDREMOVALS;
  payload: any;
}

interface SaveAircraftUnscheduledEngineRemovals {
  type: PerformanceReportsActionTypes.AIRCRAFTUNSCHEDULEDENGINEREMOVALS;
  payload: any;
}

interface SaveAircraftATADefects {
  type: PerformanceReportsActionTypes.AIRCRAFTATADEFECTS;
  payload: any;
}

interface SaveAircraftTimeBetweenDefects {
  type: PerformanceReportsActionTypes.AIRCRAFTTIMEBETWEENDEFECTS;
  payload: any;
}

interface SaveAircraftTypeDefectTrends {
  type: PerformanceReportsActionTypes.AIRCRAFTTYPEDEFECTTRENDS;
  payload: any;
}

interface SaveAircraftTypeDefectSummary {
  type: PerformanceReportsActionTypes.AIRCRAFTTYPEDEFECTSUMMARY;
  payload: any;
}

interface SaveAircraftTypeUnscheduledRemovals {
  type: PerformanceReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDREMOVALS;
  payload: any;
}

interface SaveAircraftTypeUnscheduledEngineRemovals {
  type: PerformanceReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS;
  payload: any;
}

interface SaveAircraftTypeATADefects {
  type: PerformanceReportsActionTypes.AIRCRAFTTYPEATADEFECTS;
  payload: any;
}

interface SaveAircraftTypeTimeBetweenDefects {
  type: PerformanceReportsActionTypes.AIRCRAFTTYPETIMEBETWEENDEFECTS;
  payload: any;
}

type performanceReportsAction =
  | SaveFleetDefectTrends
  | SaveFleetDefectSummary
  | SaveFleetUnscheduledRemovals
  | SaveFleetUnscheduledEngineRemovals
  | SaveFleetATADefects
  | SaveFleetTimeBetweenDefects
  | SaveAircraftDefectTrends
  | SaveAircraftDefectSummary
  | SaveAircraftUnscheduledRemovals
  | SaveAircraftUnscheduledEngineRemovals
  | SaveAircraftATADefects
  | SaveAircraftTimeBetweenDefects
  | SaveAircraftTypeDefectTrends
  | SaveAircraftTypeDefectSummary
  | SaveAircraftTypeUnscheduledRemovals
  | SaveAircraftTypeUnscheduledEngineRemovals
  | SaveAircraftTypeATADefects
  | SaveAircraftTypeTimeBetweenDefects;

export default function(state = initialState, action: performanceReportsAction): PerformanceReportsState {
  switch (action.type) {
    case PerformanceReportsActionTypes.FLEETDEFECTTRENDS: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('defectTrends', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.FLEETDEFECTSUMMARY: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('defectSummary', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.FLEETUNSCHEDULEDREMOVALS: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('unscheduledRemovals', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.FLEETUNSCHEDULEDENGINEREMOVALS: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('unscheduledEngineRemovals', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.FLEETATADEFECTS: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('ATADefects', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.FLEETTIMEBETWEENDEFECTS: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('timeBetweenDefects', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTDEFECTTRENDS: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('defectTrends', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTDEFECTSUMMARY: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('defectSummary', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTUNSCHEDULEDREMOVALS: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('unscheduledRemovals', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTUNSCHEDULEDENGINEREMOVALS: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('unscheduledEngineRemovals', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTATADEFECTS: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('ATADefects', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTTIMEBETWEENDEFECTS: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('timeBetweenDefects', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTTYPEDEFECTTRENDS: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('defectTrends', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTTYPEDEFECTSUMMARY: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('defectSummary', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDREMOVALS: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('unscheduledRemovals', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('unscheduledEngineRemovals', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTTYPEATADEFECTS: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('ATADefects', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case PerformanceReportsActionTypes.AIRCRAFTTYPETIMEBETWEENDEFECTS: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('timeBetweenDefects', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    default:
      return state;
  }
}

export interface PerformanceReportsState {
  fleet: FleetState;
  aircraft: AircraftState;
  aircraftType: AircraftTypeState;
  ttl: number;
}

export interface FleetState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftTypeState {
  dataMap: Map<string, any>;
  lastFetched: number;
}
