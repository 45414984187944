import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import React, { ReactElement } from 'react';
import { FormComponentProps } from 'antd/es/form';
import { createTicket } from '../../services/apiNew';
import IssueCard from './IssueCard';
import AttachmentUpload from './AttachmentUpload';
import aircraftSearchIcon from './assets/empty-state-aircraft-search.svg';
import documentsIcon from './assets/empty-state-documents-v1.svg';
import maintenanceIcon from './assets/empty-state-maintenance.svg';
import noDataIcon from './assets/empty-state-no-data.svg';
import styles from './QueryForm.module.less';

const { TextArea } = Input;
const { Option } = Select;

class QueryForm extends React.Component<QueryFormProps, QueryFormState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedIssue: '',
      departmentId: 1,
      issueDropdownActive: false,
      selectedArea: 'dashboard',
      selectedSection: 'operatorSettings',
    };
  }

  componentDidMount(): void {
    const { title } = this.props;
    this.updateState(title);
  }

  componentDidUpdate(prevProps): void {
    const { title } = this.props;
    if (title !== prevProps.title) this.updateState(title);
  }

  updateState = (title: string): void => {
    this.setState({ selectedIssue: title });
  };

  handleIssueChange = (value: string, departmentId: number): void => {
    this.setState({ selectedIssue: value, departmentId, issueDropdownActive: false });
  };

  handleAreaChange = (value: string): void => {
    this.setState({ selectedArea: value });
  };

  handleSectionChange = (value: string): void => {
    this.setState({ selectedSection: value });
  };

  handleSubmit = (e: React.FormEvent): void => {
    const { form, setVisibleForm, onUpdate } = this.props;
    const { departmentId } = this.state;
    let ticket;
    e.preventDefault();
    form.validateFields(async (err, values) => {
      ticket = values;
      ticket.departmentId = departmentId;
      if (err) return null;

      await createTicket(ticket);
      setVisibleForm(false);
      onUpdate(true);

      return null;
    });
  };

  displayingIssueCard = (input: string): ReactElement => {
    switch (input) {
      case 'Report a Technical Support':
        return (
          <IssueCard
            icon={maintenanceIcon}
            title="Report a Technical Support"
            subTitle="Something useful"
            showChevron
          />
        );
      case 'Service Request':
        return <IssueCard icon={documentsIcon} title="Service Request" subTitle="Something useful" showChevron />;
      case 'Question':
        return <IssueCard icon={aircraftSearchIcon} title="Question" subTitle="Something useful" showChevron />;
      case 'Other':
        return <IssueCard icon={noDataIcon} title="Other" subTitle="Something useful" showChevron />;
      default:
        return <IssueCard icon={null} title="" subTitle="" showChevron />;
    }
  };

  queryForm = (): ReactElement => {
    const { selectedArea, selectedSection } = this.state;
    const {
      setVisibleForm,
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form layout="vertical" hideRequiredMark className={styles.form} onSubmit={this.handleSubmit}>
        <Form.Item label="TITLE">
          {getFieldDecorator('title', {
            rules: [{ required: true }],
          })(<Input placeholder="Issue title" className={styles.titleInput} />)}
        </Form.Item>
        <Row className={styles.dropdownWrapper}>
          <Col sm={24} md={4}>
            <Form.Item label="AREA">
              {getFieldDecorator('area', { initialValue: selectedArea })(
                <Select onChange={this.handleAreaChange} className={styles.dropdown}>
                  <Option value="dashboard">Dashboard</Option>
                  <Option value="flightOps">Flight Ops</Option>
                  <Option value="techLog">Tech Log</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>

          <Col sm={24} md={4}>
            <Form.Item label="SECTION">
              {getFieldDecorator('section', { initialValue: selectedSection })(
                <Select onChange={this.handleSectionChange} className={styles.dropdown}>
                  <Option value="operatorSettings">Operator Settings</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="ISSUE">
          {getFieldDecorator('content', { rules: [{ required: true }] })(
            <TextArea placeholder="Describe the issue" autoSize={{ minRows: 10 }} className={styles.issueInput} />,
          )}
        </Form.Item>
        <Form.Item label="ATTACHMENTS">{getFieldDecorator('attachments')(<AttachmentUpload />)}</Form.Item>
        <Form.Item className={styles.buttonWrapper}>
          <Button type="link" className={styles.cancelButton} onClick={(): void => setVisibleForm('')}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className={styles.saveButton}>
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  };

  render(): ReactElement {
    const { issueDropdownActive, selectedIssue } = this.state;

    const issueOptions = (
      <>
        <Button
          type="link"
          onClick={(): void => this.handleIssueChange('Report a Technical Support', 1)}
          className={styles.issueButton}
        >
          <IssueCard
            icon={maintenanceIcon}
            title="Report a Technical Support"
            subTitle="Something useful"
            showChevron={false}
          />
        </Button>
        <Button
          type="link"
          onClick={(): void => this.handleIssueChange('Service Request', 2)}
          className={styles.issueButton}
        >
          <IssueCard icon={documentsIcon} title="Service Request" subTitle="Something useful" showChevron={false} />
        </Button>
        <Button type="link" onClick={(): void => this.handleIssueChange('Question', 3)} className={styles.issueButton}>
          <IssueCard icon={aircraftSearchIcon} title="Question" subTitle="Something useful" showChevron={false} />
        </Button>
        <Button type="link" onClick={(): void => this.handleIssueChange('Other', 4)} className={styles.issueButton}>
          <IssueCard icon={noDataIcon} title="Other" subTitle="Something useful" showChevron={false} />
        </Button>
      </>
    );

    return (
      <Card>
        <Button
          type="link"
          onClick={(): void => {
            this.setState({ issueDropdownActive: true, selectedIssue: '' });
          }}
          className={styles.issueButton}
        >
          {this.displayingIssueCard(selectedIssue)}
        </Button>
        {issueDropdownActive ? issueOptions : this.queryForm()}
      </Card>
    );
  }
}

const WrapperQueryForm = Form.create<QueryFormProps>()(QueryForm);

export default WrapperQueryForm;

export interface QueryFormProps extends FormComponentProps {
  title: string;
  setVisibleForm: Function;
  onUpdate: Function;
}

export type QueryFormState = {
  selectedIssue: string;
  departmentId: number;
  issueDropdownActive: boolean;
  selectedArea: string;
  selectedSection: string;
};
