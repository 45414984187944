import React, { ReactElement } from 'react';
import chevron from '../../assets/chevron-icon.svg';
import styles from './TableTitle.module.less';

const TableTitle = (props: TableTitleProps): ReactElement => {
  const {
    props: { sortOrder, sortColumn },
    title,
    dataIndex,
    sortData,
    dataType,
    sortOrdered,
    currentColumn,
  } = props;

  return (
    <div
      onClick={(): any => sortData(dataIndex, dataType)}
      onKeyPress={(): any => sortData(dataIndex, dataType)}
      className={styles.titleWrapper}
      role="button"
      tabIndex={0}
    >
      <span>{title}</span>
      {sortOrdered !== undefined && currentColumn && currentColumn === dataIndex ? (
        <img
          src={chevron}
          alt="chevron"
          className={sortOrdered === 'ascending' ? styles.chevronFlip : styles.chevron}
        />
      ) : (
        <div className={styles.emptyDiv} />
      )}
    </div>
  );
};

interface TableTitleProps {
  props: {
    sortColumn: {
      dataIndex: string;
    };
    sortOrder: string;
  };
  sortData: any;
  dataType: string;
  sortOrdered: string;
  title: string;
  dataIndex: string;
  currentColumn: string;
}

export default TableTitle;
