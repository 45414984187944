import React from 'react';
import { Row, Col, Progress } from 'antd';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styles from './MXWidget.module.less';

const MXWidget = ({ format, mxObj, title, showTotal, showNextDue, active, circleUnit, intl: { formatMessage } }) => {
  const emptyMxObj = {
    threshold: null,
    remaining: null,
    total: null,
    nextDue: null,
  };
  const { threshold } = mxObj || emptyMxObj;
  let { remaining, total, nextDue } = mxObj || emptyMxObj;

  // Red/Default Stroke Color
  const defaultStrokeColor = {
    '0%': '#ff7474',
    '100%': '#ff4040',
  };

  // Warning Stroke Color
  const warningStrokeColor = {
    '0%': '#fad288',
    '100%': '#f2a650',
  };

  // Green stroke Color
  const greenStrokeColor = {
    '0%': '#7bc88d',
    '100%': '#4aa91c',
  };

  let strokeColor = defaultStrokeColor;
  let percentValue = 100;
  let displayTextOverride = null;

  if (active) {
    percentValue = (Number(remaining) * 25) / Number(threshold);

    if (format === 'date') {
      const difference = moment(remaining).diff(moment(), 'days');
      remaining = difference;
      total = 'N/a';
    }

    if (percentValue <= 0) {
      percentValue = 100;
      strokeColor = defaultStrokeColor;
    } else if (percentValue <= 25) {
      strokeColor = warningStrokeColor;
    } else if (percentValue > 25) {
      strokeColor = greenStrokeColor;
    }

    if (format === 'seconds') {
      if (remaining < 0) {
        remaining = 0;
      }
      const durationRemaining = moment.duration(remaining, 'seconds');
      const hoursRemaining = durationRemaining.days() * 24 + durationRemaining.hours();
      let minutesRemaining = durationRemaining.minutes();
      if (minutesRemaining < 10) {
        minutesRemaining = `0${minutesRemaining}`;
      }
      remaining = `${hoursRemaining}:${minutesRemaining}`;

      const durationTotal = moment.duration(total, 'seconds');
      const hoursTotal = durationTotal.days() * 24 + durationTotal.hours();
      let minutesTotal = durationTotal.minutes();
      if (minutesTotal < 10) {
        minutesTotal = `0${minutesTotal}`;
      }
      total = `${hoursTotal}:${minutesTotal}`;
      if (!Number.isNaN(nextDue)) {
        if (mxObj.remaining && mxObj.remaining < 0) {
          nextDue = 0;
        }
        const durationNextDue = moment.duration(nextDue, 'seconds');
        const hoursNextDue = durationNextDue.days() * 24 + durationNextDue.hours();
        let minutesNextDue = durationNextDue.minutes();
        if (minutesNextDue < 10) {
          minutesNextDue = `0${minutesNextDue}`;
        }
        nextDue = `${hoursNextDue}:${minutesNextDue}`;
      }
    }
  } else {
    displayTextOverride = '-';
    remaining = -1;
    total = '-';
    nextDue = '-';
    percentValue = 0;
  }
  let formattedRemaining = Math.round(remaining);
  if (circleUnit === 'Days Rem.' && remaining % 1 !== 0) {
    formattedRemaining = Math.round(remaining * 10) / 10;
  }

  return mxObj ? (
    <Row gutter={16} className={!active ? styles.rowDisabled : undefined}>
      <Col md={24} className={styles.colCenter}>
        <svg className="progressStyle" width={0} height={0}>
          <linearGradient id="grey-gradient-0" x1="100%" y1="0%" x2="0%" y2="0%">
            <stop offset="0%" stopColor="#e6e8ed" />
            <stop offset="100%" stopColor="#f4f5f7" />
          </linearGradient>
          <filter id="shadow">
            <feDropShadow dx="1" dy="0" stdDeviation="1.5" floodColor="grey" floodOpacity=".1" />
          </filter>
        </svg>
        <Progress
          type="circle"
          strokeColor={greenStrokeColor}
          strokeWidth={5}
          percent={100}
          strokeLinecap="square"
          format={() => {
            return (
              <>
                <div
                  className={`${styles.progressValue} ${
                    remaining && remaining.length > 5 ? styles.progressValueSmall : undefined
                  }`}
                  data-test="progressValue"
                >
                  {displayTextOverride || (!remaining || remaining < 0 ? '0' : formattedRemaining)}
                </div>
                {circleUnit && (
                  <div className={styles.progressUnits} data-test="progressUnits">
                    {circleUnit}
                  </div>
                )}
              </>
            );
          }}
          className={styles.progressCircle}
          data-test="progressCircle"
        />
        {title && (
          <>
            <div className={styles.widgetTitle} data-test="widgetTitle">
              {title}
            </div>
            <div className={styles.spacer} />
          </>
        )}
        {showTotal && (
          <>
            <div className={styles.widgetTotals} data-test="widgetTotals">
              {formatMessage({ id: 'text.totals' })}: <span>{total || '-'}</span>
            </div>
          </>
        )}
        {showNextDue && (
          <>
            <div className={styles.widgetTotals} data-test="widgetNextDue">
              {`${formatMessage({ id: 'title.nextDue' })}: `}
              <span
                className={
                  Number.isNaN(nextDue) && !displayTextOverride && nextDue.indexOf(':') < 0 ? styles.nextDueDate : null
                }
              >
                {Number.isInteger(nextDue) ? `${nextDue}` : moment(nextDue).format('DD MMM')}
              </span>
            </div>
          </>
        )}
      </Col>
    </Row>
  ) : (
    formatMessage({ id: 'text.loading' })
  );
};

MXWidget.defaultProps = {
  title: '',
  showTotal: false,
  showNextDue: false,
  circleUnit: '',
  format: undefined,
  mxObj: undefined,
  active: false,
};

MXWidget.propTypes = {
  format: PropTypes.string,
  mxObj: PropTypes.object,
  title: PropTypes.string,
  showTotal: PropTypes.bool,
  showNextDue: PropTypes.bool,
  active: PropTypes.bool,
  circleUnit: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(MXWidget);
