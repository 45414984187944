import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import PerformanceTile from './PerformanceTile';
import styles from './DetailedSummaryCard.module.less';
import TicketTrendGraph from './TicketTrendGraph';

const data = [
  { name: 'resolved', number: 27, percentageChange: -24 },
  { name: 'received', number: 143, percentageChange: -40 },
  { name: 'open', number: 35, percentageChange: -45 },
  { name: 'waitTime', number: 20, percentageChange: -14 },
];

const DetailedSummaryCard = () => {
  return (
    <div className={styles.cardBody}>
      <Row>
        <Col span={10}>
          {data.map((item) => (
            <PerformanceTile tile={item} />
          ))}
        </Col>
        <Col span={14}>
          <TicketTrendGraph />
        </Col>
      </Row>
    </div>
  );
};

export default DetailedSummaryCard;
