import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import { queryAircraft, queryDefects } from '../../services/apiNew';
import { AircraftActionTypes } from '../../models/aircraft';
import CamoMenuLayout from '../../layouts/CamoMenuLayout';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import DefectsTable from '../../components/AircraftServiceOverview/DefectsTable';

const AircraftDefects = ({ aircraftMap, updateAircraft }) => {
  const { formatMessage } = useIntl();
  const {
    params: { id },
  } = useRouteMatch();
  const selectedAircraft = aircraftMap.get(id);
  const [loading, setLoading] = useState(false);
  const [defectsData, setDefectsData] = useState([]);

  useEffect(() => {
    const getAircraft = async () => {
      setLoading(true);
      const res = await queryAircraft(id);
      updateAircraft(res.data.data.aircraft);
      setLoading(false);
    };
    if (!selectedAircraft) {
      getAircraft();
    }
  }, [id, selectedAircraft, updateAircraft]);

  useEffect(() => {
    const getDefects = async () => {
      setLoading(true);
      const res = await queryDefects(id);
      if (!res.data.data.aircraft.defects) {
        res.data.data.aircraft.defects = [];
      }
      updateAircraft(res.data.data.aircraft);
      setLoading(false);
    };
    if (selectedAircraft && !selectedAircraft.defects) {
      getDefects();
    }
    if (selectedAircraft && selectedAircraft.defects) {
      const defectData = selectedAircraft.defects.map((defect) => ({
        ...defect,
        status: defect.togo > 0 ? 'open' : 'overdue',
        number: defect.id,
      }));
      setDefectsData(defectData);
    }
  }, [selectedAircraft, id, updateAircraft]);

  return (
    <CamoMenuLayout>
      <PageHeaderWrapper layout="modern" title={formatMessage({ id: 'title.defects' })}>
        <Row>
          <Col>
            <Card className={cardStyles.pageCard}>
              <DefectsTable aircraft={selectedAircraft} defectsData={defectsData} hideShowAll shortList={false} />
            </Card>
          </Col>
        </Row>
      </PageHeaderWrapper>
    </CamoMenuLayout>
  );
};

AircraftDefects.propTypes = {
  aircraftMap: PropTypes.instanceOf(Map).isRequired,
  updateAircraft: PropTypes.object,
};

AircraftDefects.defaultProps = {
  updateAircraft: {},
};

const AircraftDefectsWithRedux = connect(
  ({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  }),
  (dispatch) => ({
    updateAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.UPDATE,
        payload,
      }),
  }),
)(AircraftDefects);

export default AircraftDefectsWithRedux;
