import React, { ReactElement, useState, useEffect } from 'react';
import moment from 'moment';
import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { Loading } from '@arcflight/tf-component-library';
import config, { ConfigKey } from '../../utils/config';
import { queryDocumentApprovalRequest } from '../../services/apiNew';
import iconDocument from '../../assets/icon-documents.svg';
import logo from '../../assets/trustflight-logo-white.svg';
import pdfIcon from '../../assets/icon-pdf-blue.svg';
import bgOverlay from '../../assets/public-mx-bg-grid.svg';
import styles from './DocumentPage.module.less';
import DocumentPageForm from './DocumentPageForm';

const DocumentPage = ({ match }): ReactElement => {
  const { approvalRequestId } = match().params;
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    const getDocumentApproval = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await queryDocumentApprovalRequest(approvalRequestId);
        if (res.data.currentReleaseState === 'REV') {
          history.push(`/public/document/${res.data.documentId}/revoked`);
        }
        if (res.data.currentReleaseState === 'WAI' && (res.data.approved !== null || res.data.awaitingFeedback)) {
          history.push(`/public/document/${res.data.documentId}/complete`);
        }
        setDocument(res.data);
      } catch (err) {
        console.error(err);
        history.push(`/public/exception/404`);
      }

      setLoading(false);
    };
    getDocumentApproval();
  }, [approvalRequestId, history]);
  return (
    <div className={styles.backgroundWrapper}>
      <img src={bgOverlay} className={styles.bgOverlay} alt="bg" />
      <div className={styles.padding}>
        {loading ? (
          <Loading loading={loading} contain />
        ) : (
          <>
            <img src={logo} className={styles.logo} alt="bg" />
            <Card className={styles.document__card}>
              <h1>Please submit your review</h1>
              <Card className={styles.document__card__internal}>
                <h2>
                  <img src={iconDocument} className={styles.icon} alt="Document icon" /> Document Details
                </h2>
                <div className={`${styles.container} ${styles.paddingWrapper}`}>
                  <h3>
                    Document{' '}
                    <span className={styles.document}>
                      {document.pdf ? (
                        <a
                          href={`${config.get(ConfigKey.CAMOAPI)}/document/approval/attachment/${document.pdf}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {' '}
                          <img src={pdfIcon} className={styles.icon__pdf} alt="Document icon" />{' '}
                          {document.publicationDocumentType} {document.publicationDocumentNumber}
                        </a>
                      ) : (
                        <>
                          <img src={pdfIcon} className={styles.icon__pdf} alt="Document icon" />{' '}
                          {document.publicationDocumentType} {document.publicationDocumentNumber}
                        </>
                      )}
                    </span>
                  </h3>
                  <h3>
                    Responsible <span>{document.responsible}</span>
                  </h3>
                  <h3>
                    Due Date <span className={styles.time}>{moment(document.due).format('DD MMM YYYY')}</span>
                  </h3>
                  <h3>
                    Requested by <span>{document.requested}</span>
                  </h3>
                </div>
                <div className={styles.marginWrapper}>
                  <h3>Request remarks</h3>
                  <p>{document.remarks}</p>
                </div>
              </Card>

              <DocumentPageForm history={history} approvalRequestId={approvalRequestId} />
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentPage;
