import React from 'react';
import PropTypes from 'prop-types';
import RedArrow from '../../assets/RedArrow.svg';
import GreenArrow from '../../assets/GreenArrow.svg';
import styles from './PerformanceTile.module.less';

const PerformanceTile = ({ tile }) => {
  let title = '';
  let percent = 0;
  let colourStyle = '';
  let backgroundColour = '';
  let arrow = GreenArrow;
  let isInverted = false;
  switch (tile.name) {
    case 'resolved':
      title = 'Tickets Resolved';
      percent = tile.percentageChange;
      colourStyle = percent < 0 ? `${styles.colourRed}` : `${styles.colourGreen}`;
      backgroundColour = percent < 0 ? `${styles.backgroundRed}` : `${styles.backgroundGreen}`;
      arrow = percent < 0 ? RedArrow : GreenArrow;
      break;
    case 'received':
      title = 'Tickets Received';
      percent = tile.percentageChange;
      colourStyle = percent > 0 ? `${styles.colourRed}` : `${styles.colourGreen}`;
      backgroundColour = percent > 0 ? `${styles.backgroundRed}` : `${styles.backgroundGreen}`;
      arrow = percent > 0 ? RedArrow : GreenArrow;
      isInverted = true;
      break;
    case 'open':
      title = 'Tickets Open';
      percent = tile.percentageChange;
      colourStyle = percent > 0 ? `${styles.colourRed}` : `${styles.colourGreen}`;
      backgroundColour = percent > 0 ? `${styles.backgroundRed}` : `${styles.backgroundGreen}`;
      arrow = percent > 0 ? RedArrow : GreenArrow;
      isInverted = true;
      break;
    case 'waitTime':
      title = 'Average Time in Queue';
      percent = tile.percentageChange;
      colourStyle = percent > 0 ? styles.colourRed : styles.colourGreen;
      backgroundColour = percent > 0 ? `${styles.backgroundRed}` : `${styles.backgroundGreen}`;
      arrow = percent > 0 ? RedArrow : GreenArrow;
      isInverted = true;
      break;
    default:
      break;
  }

  return (
    <div className={styles.tileWrapper}>
      {percent === 0 ? (
        <>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={`${styles.percentage}`}>{`${Math.abs(percent)}%`}</div>
          </div>
          <div className={`${styles.count} ${styles.backgroundGreen}`}>{tile.number}</div>
        </>
      ) : (
        <>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={`${styles.percentage} ${colourStyle}`}>
              <img src={arrow} alt="arrow" className={styles.invertArrow} />
              {`${Math.abs(percent)}%`}
            </div>
          </div>
          <div className={`${styles.count} ${backgroundColour}`}>
            {tile.number}
            {tile.name === 'waitTime' ? <div className={styles.mins}>Mins</div> : null}
          </div>
        </>
      )}
    </div>
  );
};

PerformanceTile.propTypes = {
  tile: PropTypes.object.isRequired,
};

export default PerformanceTile;
