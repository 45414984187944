import React, { ReactElement } from 'react';
import styles from './CloseTicketModal.module.less';

const CloseTicketModal = ({ ticket }): ReactElement => {
  const { title, refNum, type, content } = ticket;
  return (
    <div className={styles.modalBody}>
      <div className={styles.infoSection}>
        <span>
          {title} <span className={styles.refNum}>{refNum}</span>
        </span>
        <div className={styles.section}>
          <div className={styles.label}>AREA:</div>
          <div className={styles.data}>{type}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.label}>DESCRIPTION:</div>
          <div className={styles.data}>{content}</div>
        </div>
      </div>
      <div className={styles.attachmentsSection}>
        <div className={styles.label}>ATTACHMENTS:</div>
      </div>
    </div>
  );
};

export default CloseTicketModal;
