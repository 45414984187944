import { Button, Input, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { ReactElement } from 'react';
import { replyTicket } from '../../services/apiNew';
import AttachmentUpload from './AttachmentUpload';
import styles from './ReplyForm.module.less';

const { TextArea } = Input;

class ReplyForm extends React.Component<ReplyFormProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (e: any): void => {
    const { form, ticketNumber, setReplyVisible } = this.props;
    e.preventDefault();
    form.validateFields(async (err, values) => {
      // ticket = values;
      // ticket.departmentId = departmentId;
      if (err) return null;
      const reply = await replyTicket(values, ticketNumber);
      setReplyVisible(false);
      return null;
    });
  };

  render(): ReactElement {
    const {
      form: { getFieldDecorator },
      setReplyVisible,
    } = this.props;
    return (
      <div className={styles.formBody}>
        <div className={styles.formHeader}>
          <span>Add an Update</span>
        </div>
        <Form layout="vertical" hideRequiredMark className={styles.form} onSubmit={this.onSubmit}>
          <Form.Item label="ISSUE UPDATE">
            {getFieldDecorator('content', { rules: [{ required: true }] })(
              <TextArea
                placeholder="Describe the review of the issue"
                autoSize={{ maxRows: 6, minRows: 6 }}
                className={styles.textArea}
              />,
            )}
          </Form.Item>
          <Form.Item label="ATTACHMENTS">{getFieldDecorator('attachments')(<AttachmentUpload />)}</Form.Item>
          <Form.Item className={styles.buttonWrapper}>
            <Button type="link" className={styles.cancelButton} onClick={(): void => setReplyVisible(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className={styles.saveButton}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrapperReplyForm = Form.create<ReplyFormProps>()(ReplyForm);

export default WrapperReplyForm;

export interface ReplyFormProps extends FormComponentProps {
  setReplyVisible: Function;
  ticketNumber: string;
}
