import { Button, Col, Drawer } from 'antd';
import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import { ConversationDataType } from '../../pages/Camo/HelpDesk';
import styles from './Ticket.module.less';
import TicketDrawer from './TicketDrawer';

const isMobile = window.innerWidth < 769;

const Ticket = ({ ticket, onTicketChange, activeTicketId }): ReactElement => {
  const {
    status,
    title,
    date,
    ticketNumber,
    type,
    content,
    conversation,
    attachments,
  }: {
    status: string;
    title: string;
    date: Date;
    ticketNumber: number;
    type: string;
    content: string;
    conversation: Array<ConversationDataType>;
    attachments: Array<any>;
  } = ticket;

  const [drawerVisible, setDrawerVisible] = useState(false);
  let statusStyling = styles.okStatus;
  if (status === 'warning') statusStyling = styles.warningStatus;
  if (status === 'overdue') statusStyling = styles.overdueStatus;
  return (
    <>
      {isMobile ? (
        <div className={styles.ticketBody}>
          <div className={styles.innerTicketBody}>
            <div className={styles.ticketHeader}>
              <div>
                <div className={`${styles.statusDiv} ${statusStyling}`} />
                <div>{title}</div>
              </div>
              <strong>{ticketNumber}</strong>
              <span>{moment(date, 'YYYYMMDDHHSS').format('DD/MM/YYYY HH:SS')}</span>
            </div>
            <div className={styles.type}>{type}</div>
            <div className={styles.content}>{content}</div>
            <Col span={12} className={styles.labelCol}>
              <span className={styles.label}>
                COMMENTARIES: <span>{ticket.conversation && ticket.conversation.length}</span>
              </span>{' '}
            </Col>
            <Col span={12} className={styles.labelCol}>
              <span className={styles.secondLabel}>
                ATTACHMENTS: <span>{attachments && attachments.length}</span>
              </span>
            </Col>
          </div>
          <Button type="link" className={styles.viewTicketButton} onClick={(): void => setDrawerVisible(true)}>
            View Ticket
          </Button>
        </div>
      ) : (
        <button
          type="button"
          className={activeTicketId === ticket.id ? `${styles.ticketBody} ${styles.activeTicket}` : styles.ticketBody}
          onClick={(): void => onTicketChange(ticket)}
        >
          <div className={styles.ticketHeader}>
            <div className={`${styles.statusDiv} ${statusStyling}`} />
            <div>{title}</div>
          </div>
          <div className={styles.secondRow}>
            <p>
              {moment(date, 'YYYYMMDDHHSS').format('DD/MM/YYYY HH:SS')} <strong>{ticketNumber}</strong>
            </p>
          </div>
          <div className={styles.type}>{type}</div>
          <div className={styles.content}>{content}</div>
        </button>
      )}
      {isMobile && (
        <Drawer
          placement="bottom"
          closable={false}
          onClose={(): void => setDrawerVisible(false)}
          visible={drawerVisible}
          height="80vh"
          className={styles.drawerComponent}
        >
          <TicketDrawer ticket={ticket} />
        </Drawer>
      )}
    </>
  );
};

export default Ticket;
