import { Button, Modal } from 'antd';
import React, { ReactElement, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import servers from '../../utils/servers';
import styles from './DisplayAttachments.module.less';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DisplayAttachments = ({ attachments }): ReactElement => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (file): Promise<void> => {
    setPreviewImage(file.url || file.preview);
  };

  const handleCancel = (): void => {
    setPreviewVisible(false);
    setTimeout(() => {
      setPreviewImage('');
    }, 200);
  };

  const displayPreviewImage = (image): ReactElement => {
    let displayImage;
    if (image) {
      if (image.startsWith('data:application/pdf')) {
        displayImage = (
          <embed src={image || `${servers.api}/${image}`} width="100%" height="650" type="application/pdf" />
        );
      } else {
        displayImage = (
          <div className={styles.fullsizeImagePreview}>
            <img
              src={image || `${servers.api}/${image}`}
              alt="preview"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </div>
        );
      }
    }
    return displayImage;
  };

  return (
    <>
      {attachments &&
        attachments.map((file, index) => {
          return (
            <div className={styles.attachmentWrapper} key={file.name}>
              <Button
                type="link"
                onClick={(): void => {
                  handlePreview(file);
                  setPreviewVisible(true);
                }}
                className={styles.imageButton}
              >
                {file.type === 'application/pdf' ? (
                  <Document renderMode="svg" className={styles.pdfImage} file={file.preview}>
                    <Page width={88} pageNumber={1} />
                  </Document>
                ) : (
                  <img
                    src={file.preview || `${servers.api}/${file.thumbnail_url}`}
                    alt="preview"
                    className={styles.attachmentPreviewImage}
                  />
                )}
              </Button>
              <div className={styles.fileName}>
                <span>{file.name}</span>
                {/* <Button
            type="default"
            className={styles.deleteAttachment}
            onClick={(): void => {
              handleAttachmentDelete(index);
            }}
          >
            <img src={deleteIcon} alt="delete" className={styles.deleteIcon} />
          </Button> */}
              </div>
            </div>
          );
        })}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel} closable={false}>
        {displayPreviewImage(previewImage)}
      </Modal>
    </>
  );
};

export default DisplayAttachments;
