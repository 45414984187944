import React, { ReactElement, useState } from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import LegendItem from '../../Charts/Legend/LegendItem';
import { CustomizedXAxisTick, CustomizedYAxisTick, filterDataByTimeScale, formatDate } from './utils';
import styles from './ReliabilityReportGraph.module.less';

const GenericBarGraph = ({
  data,
  title,
  axisKey,
  line1 = null,
  line2 = null,
  line3 = null,
  line4 = null,
  line5 = null,
  barSize,
  layout,
  background = false,
  stackId = null,
  format,
  selectedTimeScale,
  button,
  id,
}): ReactElement => {
  const [legend1, setLegend1] = useState(true);
  const [legend2, setLegend2] = useState(true);
  const [legend3, setLegend3] = useState(true);
  const [legend4, setLegend4] = useState(true);
  const [legend5, setLegend5] = useState(true);

  let newData = [];
  if (data && data[0] && Object.getOwnPropertyNames(data[0]).includes('date')) {
    const filterDataByTime = filterDataByTimeScale(data, selectedTimeScale, true);
    newData = formatDate(filterDataByTime, format);
  }

  const addRadiusToData =
    newData.length > 0 &&
    newData.map((item) => {
      return {
        ...item,
        radius: 10,
      };
    });

  const longestAxisLabel = (): number => {
    let length = 0;
    if (data)
      data.forEach((item) => {
        if (item[`${axisKey}`].length > length) length = item[`${axisKey}`].length;
      });
    return length;
  };

  return (
    <div className={styles.graphWrapper}>
      <div className={styles.headerRow}>
        <h2>{title}</h2>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          layout={layout}
          data={addRadiusToData}
          margin={{ top: 10, bottom: 20, right: 5, left: longestAxisLabel() * 6 }}
        >
          <CartesianGrid strokeDasharray="2 2" stroke="#8097b1" opacity={0.2} />
          {layout === 'vertical' ? (
            <XAxis
              type="number"
              axisLine={{ stroke: '#E0E7FF' }}
              tickLine={false}
              tick={<CustomizedXAxisTick />}
              domain={addRadiusToData.length > 0 ? [0, 'auto'] : [0, 1]}
            />
          ) : (
            <XAxis dataKey={axisKey} axisLine={{ stroke: '#E0E7FF' }} tickLine={false} tick={<CustomizedXAxisTick />} />
          )}
          {layout === 'vertical' ? (
            <YAxis
              type="category"
              dataKey={axisKey}
              axisLine={{ stroke: '#E0E7FF' }}
              tickLine={false}
              domain={addRadiusToData.length > 0 ? [0, 'auto'] : [0, 0]}
              tick={<CustomizedYAxisTick />}
            />
          ) : (
            <YAxis
              axisLine={{ stroke: '#E0E7FF' }}
              tickLine={false}
              tick={<CustomizedYAxisTick />}
              domain={addRadiusToData.length > 0 ? [0, 'auto'] : [0, 1]}
            />
          )}
          {legend1 && (
            <Bar
              dataKey={line1.dataKey}
              stackId={stackId}
              barSize={barSize}
              fill="url(#legend1)"
              background={background}
            />
          )}
          {line2 && legend2 ? (
            <Bar
              dataKey={line2.dataKey}
              stackId={stackId}
              barSize={barSize}
              fill="url(#legend2)"
              background={background}
            />
          ) : null}
          {line3 && legend3 ? (
            <Bar
              dataKey={line3.dataKey}
              stackId={stackId}
              barSize={barSize}
              fill="url(#legend3)"
              background={background}
            />
          ) : null}
          {line4 && legend4 ? (
            <Bar
              dataKey={line4.dataKey}
              stackId={stackId}
              barSize={barSize}
              fill="url(#legend4)"
              background={background}
            />
          ) : null}
          {line5 && legend5 ? (
            <Bar
              dataKey={line5.dataKey}
              stackId={stackId}
              barSize={barSize}
              fill="url(#legend5)"
              background={background}
            />
          ) : null}
        </BarChart>
      </ResponsiveContainer>
      <div className={styles.legend}>
        <LegendItem
          onButtonClick={(): void => (button ? setLegend1(!legend1) : null)}
          label={line1.dataKey}
          index={1}
          button={button}
          isActive={legend1}
        />
        {line2 && (
          <LegendItem
            onButtonClick={(): void => (button ? setLegend2(!legend2) : null)}
            label={line2.dataKey}
            index={2}
            button={button}
            isActive={legend2}
          />
        )}
        {line3 && (
          <LegendItem
            onButtonClick={(): void => (button ? setLegend3(!legend3) : null)}
            label={line3.dataKey}
            index={3}
            button={button}
            isActive={legend3}
          />
        )}
        {line4 && (
          <LegendItem
            onButtonClick={(): void => (button ? setLegend4(!legend4) : null)}
            label={line4.dataKey}
            index={4}
            button={button}
            isActive={legend4}
          />
        )}
        {line5 && (
          <LegendItem
            onButtonClick={(): void => (button ? setLegend5(!legend5) : null)}
            label={line5.dataKey}
            index={5}
            button={button}
            isActive={legend5}
          />
        )}
      </div>
    </div>
  );
};

export default GenericBarGraph;

export interface LineType {
  dataKey: string;
  startColour: string;
  stopColour: string;
}
