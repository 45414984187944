/* eslint-disable react/jsx-props-no-spreading */
import { Layout } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { pathToRegexp } from 'path-to-regexp';
import React from 'react';
import { ContainerQuery } from 'react-container-query';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import logo from '../assets/logo.svg';
import logoText from '../assets/logoText.svg';
import SiderMenu from '../components/SiderMenu';
import menuDataGenerator from '../utils/menuData';
import { fetch } from '../models/userSettings/actions';
import { changeLayoutCollapsed } from '../models/menu';
import Footer from './Footer';
import MyHeader from './Header';
import styles from './BasicLayout.module.less';

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
};

const mediaQueryList = window.matchMedia('(max-width: 767px)');

class BasicLayout extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    collapsed: PropTypes.bool.isRequired,
    menu: PropTypes.object,
    fixSiderbar: PropTypes.bool.isRequired,
    fixedHeader: PropTypes.bool.isRequired,
    layout: PropTypes.string.isRequired,
    navTheme: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    menu: undefined,
  };

  state = {
    isMobile: false,
    menuData: menuDataGenerator(
      this.props.intl.formatMessage,
      this.props.userSettings?.details?.dashboard_permissions,
      this.props.userSettings?.details?.service_desk_permissions,
    ),
  };

  constructor(props) {
    super(props);
    this.getPageTitle = memoizeOne(this.getPageTitle);
    this.matchParamsPath = memoizeOne(this.matchParamsPath, isEqual);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { isMobile } = this.state;
    dispatch(fetch());
    // Dispatching resize event to prevent DASH-1096 bug
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    window.addEventListener(
      'orientationchange',
      () => {
        if (isMobile) this.handleMenuCollapse(true);
      },
      false,
    );
    this.disabledAutoCorrect();
  }

  componentDidUpdate(prevProps, prevState) {
    // After changing to phone mode,
    // if collapsed is true, you need to click twice to display
    const { isMobile } = this.state;
    const { collapsed, menu } = this.props;
    if (isMobile && !prevState.isMobile && !collapsed) {
      this.handleMenuCollapse(false);
    }
    if (menu !== prevProps.menu) {
      this.setMenuData();
    }
    this.disabledAutoCorrect();
  }

  setMenuData() {
    const {
      userSettings,
      intl: { formatMessage },
    } = this.props;
    this.setState({
      menuData: menuDataGenerator(
        formatMessage,
        userSettings?.details?.dashboard_permissions,
        userSettings?.details?.service_desk_permissions,
      ),
    });
  }

  updateMediaScreenSize = () => {
    this.setState({ isMobile: mediaQueryList.matches });
  };

  getPageTitle = (pathname) => {
    const {
      intl: { messages },
      match: {
        params: { id },
      },
    } = this.props;
    const intlPath = pathname
      .split('/')
      .filter((path) => path !== id && path.length > 0)
      .map((path) => path.charAt(0).toUpperCase() + path.slice(1))
      .join('.');
    const message = messages[`menu.${intlPath}`];
    return message ? `${message} - TrustFlight` : 'TrustFlight';
  };

  getLayoutStyle = () => {
    const { isMobile } = this.state;
    const { fixSiderbar, collapsed, layout } = this.props;
    if (fixSiderbar && layout !== 'topmenu' && !isMobile) {
      return {
        paddingLeft: collapsed ? '80px' : '200px',
      };
    }
    return null;
  };

  getContentStyle = () => {
    const { fixedHeader } = this.props;
    return {
      margin: '24px 24px 0',
      paddingTop: fixedHeader ? 64 : 0,
    };
  };

  disabledAutoCorrect = () => {
    const inputs = document.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].setAttribute('spellcheck', 'false');
      inputs[i].setAttribute('autoComplete', 'off');
    }
  };

  matchParamsPath = (pathname) => {
    const pathKey = Object.keys(this.breadcrumbNameMap).find((key) => pathToRegexp(key).test(pathname));
    return this.breadcrumbNameMap[pathKey];
  };

  handleMenuCollapse = (collapsed, fromButton) => {
    if (fromButton !== 'responsive' || window.innerWidth < 1100) {
      const { dispatch } = this.props;
      dispatch(
        changeLayoutCollapsed({
          payload: collapsed,
        }),
      );
    }
  };

  render() {
    const {
      navTheme,
      children,
      location: { pathname },
    } = this.props;
    const { isMobile, menuData } = this.state;

    this.updateMediaScreenSize();

    matchMedia('(max-width: 767px)').addListener(this.updateMediaScreenSize);
    return (
      <>
        <DocumentTitle title={this.getPageTitle(pathname)}>
          <ContainerQuery query={query}>
            {(params) => (
              <div className={classNames(params)}>
                <Layout>
                  {isMobile ? null : (
                    <SiderMenu
                      logo={logo}
                      logoText={logoText}
                      theme={navTheme}
                      onCollapse={this.handleMenuCollapse}
                      menuData={menuData}
                      isMobile={isMobile}
                      {...this.props}
                    />
                  )}
                  <Layout
                    style={{
                      ...this.getLayoutStyle(),
                    }}
                    className={styles.contentLayout}
                  >
                    {isMobile ? (
                      <MyHeader
                        menuData={menuData}
                        handleMenuCollapse={this.handleMenuCollapse}
                        logo={logo}
                        isMobile={isMobile}
                        {...this.props}
                      />
                    ) : null}

                    {children}
                    <Footer />
                  </Layout>
                </Layout>
              </div>
            )}
          </ContainerQuery>
        </DocumentTitle>
      </>
    );
  }
}

export default injectIntl(
  withRouter(
    connect(({ menu, userSettings }) => ({
      collapsed: menu.collapsed,
      userSettings,
      ...userSettings.ui,
    }))(BasicLayout),
  ),
);
