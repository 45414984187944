import { Col } from 'antd';
import React, { ReactElement } from 'react';
import CurrentTicket from './CurrentTicket';
import ConversationHistory from './ConversationHistory';
import styles from './SelectTicket.module.less';

const SelectTicket = ({ activeTicket, onUpdate }): ReactElement => {
  return (
    <Col id="active-card" className={styles.viewCol} span={19}>
      <CurrentTicket activeTicket={activeTicket} onUpdate={onUpdate} />
      {activeTicket && activeTicket.conversation && (
        <ConversationHistory ticketNumber={activeTicket.ticketNumber} conversation={activeTicket.conversation} />
      )}
    </Col>
  );
};

export default SelectTicket;
