import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import styles from './TableFilter.module.less';

const TableFilter = ({ buttons, initialSelection, handleRadioSelection }) => {
  if (!buttons) return null;
  const buttonArray = buttons.map((item) => {
    return (
      <Radio.Button
        key={item}
        className={styles.filterButton}
        value={item}
        onClick={(e) => handleRadioSelection(e.target.value)}
      >
        {item}
      </Radio.Button>
    );
  });

  return (
    <Radio.Group className={styles.filterGroup} defaultValue={initialSelection}>
      {buttonArray}
    </Radio.Group>
  );
};

TableFilter.propTypes = {
  buttons: PropTypes.array,
  initialSelection: PropTypes.string.isRequired,
  handleRadioSelection: PropTypes.func.isRequired,
};

TableFilter.defaultProps = {
  buttons: [],
};

export default TableFilter;
