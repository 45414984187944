import React from 'react';
import PropTypes from 'prop-types';
import styles from './LegendItem.module.less';

const LegendItem = ({ id, name, colour, onClick, func, active }) => {
  const activeItem = id ? active.includes(id) : active.includes(name);
  return (
    <div className={activeItem ? styles.legendItemActive : styles.legendItem}>
      <button
        className={active.length > 0 ? styles.pointer : styles.noPointer}
        type="button"
        onClick={() => (id ? onClick(id, func, active) : onClick(name, func, active))}
      >
        {colour && <div className={styles.legendItemIndicator} style={{ background: colour }} />}
        <div className={styles.legendItemName}>{name}</div>
      </button>
    </div>
  );
};

LegendItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  colour: PropTypes.string,
  onClick: PropTypes.func,
  func: PropTypes.func,
  active: PropTypes.array,
};

LegendItem.defaultProps = {
  id: '',
  name: '',
  colour: '',
  onClick: () => {
    // default function
  },
  func: () => {
    // default function
  },
  active: [],
};

export default LegendItem;
