const initialState: BrakesTyresReportsState = {
  fleet: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraft: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  aircraftType: {
    dataMap: new Map(),
    lastFetched: 0,
  },
  ttl: 60000,
};

export enum BrakesTyresReportsActionTypes {
  FLEETBRAKE = 'brakesTyresReports/fleetBrake',
  AIRCRAFTBRAKE = 'brakesTyresReports/aircraftBrake',
  AIRCRAFTTYPEBRAKE = 'brakesTyresReports/aircraftTypeBrake',
}

interface SaveFleetBrake {
  type: BrakesTyresReportsActionTypes.FLEETBRAKE;
  payload: any;
}

interface SaveAircraftBrake {
  type: BrakesTyresReportsActionTypes.AIRCRAFTBRAKE;
  payload: any;
}

interface SaveAircraftTypeBrake {
  type: BrakesTyresReportsActionTypes.AIRCRAFTTYPEBRAKE;
  payload: any;
}

type brakesTyresReportsAction = SaveFleetBrake | SaveAircraftBrake | SaveAircraftTypeBrake;

export default function(state = initialState, action: brakesTyresReportsAction): BrakesTyresReportsState {
  switch (action.type) {
    case BrakesTyresReportsActionTypes.FLEETBRAKE: {
      const dataMap = new Map(state.fleet.dataMap.entries());
      dataMap.set('brake', action.payload);
      return {
        ...state,
        fleet: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case BrakesTyresReportsActionTypes.AIRCRAFTBRAKE: {
      const dataMap = new Map(state.aircraft.dataMap.entries());
      const existingAircraftMap = state.aircraft.dataMap.get(action.payload.type) || new Map();
      existingAircraftMap.set('brake', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftMap);
      return {
        ...state,
        aircraft: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    case BrakesTyresReportsActionTypes.AIRCRAFTTYPEBRAKE: {
      const dataMap = new Map(state.aircraftType.dataMap.entries());
      const existingAircraftTypeMap = state.aircraftType.dataMap.get(action.payload.type) || new Map();
      existingAircraftTypeMap.set('brake', action.payload.data);
      dataMap.set(action.payload.type, existingAircraftTypeMap);
      return {
        ...state,
        aircraftType: {
          dataMap,
          lastFetched: Date.now(),
        },
      };
    }
    default:
      return state;
  }
}

export interface BrakesTyresReportsState {
  fleet: FleetState;
  aircraft: AircraftState;
  aircraftType: AircraftTypeState;
  ttl: number;
}

export interface FleetState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftState {
  dataMap: Map<string, any>;
  lastFetched: number;
}

export interface AircraftTypeState {
  dataMap: Map<string, any>;
  lastFetched: number;
}
