const initialState: UserSettingsState = {
  details: undefined,
  id: null,
  avatar: 'init', // for cache busting
  dateFormat: 'YYYY-MM-DD',
  selectedView: 'table',
  tableAnimationClass: 'defaultTable',
  listAnimationClass: 'defaultList',
  lastFetched: Date.now(),
  ui: {
    colorWeak: false,
    navTheme: 'dark',
    primaryColor: '#1890FF',
    layout: 'sidemenu',
    contentWidth: 'Fluid',
    fixedHeader: false,
    autoHideHeader: false,
    fixSiderbar: false,
  },
  displayName: '',
};

export enum UserSettingsActionTypes {
  SAVE = 'userSettings/save',
  SET_SELECTED_VIEW = 'userSettings/setSelectedView',
  UPDATE_DETAILS = 'userSettings/updateDetails',
}

export enum UserRoles {
  ADMIN = 'admin',
  HELPDESK = 'helpdesk',
  PUBLIC = 'public',
  CAMO = 'camo',
}

export enum CamoResource {
  ORGANISATIONS = 'organisations',
  USERS = 'users',
  MAINTENANCE = 'maintenance',
  MEETINGS = 'meetings',
  PERFORMANCE = 'performance',
  COST = 'cost',
  HELPDESK = 'helpdesk',
  REPORTS = 'reliabilityReports',
  MATERIALS = 'materials',
}

export enum UserPermission {
  READ = 'read',
  WRITE = 'write',
  OWNER = 'owner',
}

interface SaveUserSettingsActionType {
  type: UserSettingsActionTypes.SAVE;
  payload: UserSettings;
  details: User;
}

interface SetSelectedViewUserSettingsActionType {
  type: UserSettingsActionTypes.SET_SELECTED_VIEW;
  payload: UserSettings;
}

interface UpdateDetailsUserSettingsActionType {
  type: UserSettingsActionTypes.UPDATE_DETAILS;
  payload: UserSettings;
  details: User;
}

type UserSettingsAction =
  | SaveUserSettingsActionType
  | SetSelectedViewUserSettingsActionType
  | UpdateDetailsUserSettingsActionType;

export default function(state = initialState, action: UserSettingsAction): typeof initialState {
  switch (action.type) {
    case UserSettingsActionTypes.SAVE: {
      action.payload.dashboard_permissions = [UserRoles.CAMO];
      return {
        ...state,
        details: action.payload,
        id: action.payload.id,
        avatar: action.payload.avatar || state.avatar,
        dateFormat: action.payload.date_format,
        lastFetched: Date.now(),
        displayName: `${action.payload.first_name} ${action.payload.last_name}`,
      };
    }
    case UserSettingsActionTypes.SET_SELECTED_VIEW: {
      return {
        ...state,
        selectedView: action.payload.selectedView,
        tableAnimationClass: action.payload.selectedView === 'table' ? 'animatedTable' : 'defaultTable',
        listAnimationClass: action.payload.selectedView === 'grid' ? 'animatedList' : 'defaultList',
      };
    }
    case UserSettingsActionTypes.UPDATE_DETAILS:
      action.details.dashboard_permissions = [UserRoles.CAMO];
      return {
        ...state,
        details: action.details,
      };
    default:
      return state;
  }
}

export interface Operator {
  id: string;
  name: string;
  address: string;
  aoc_number: string;
  date_format: string;
  ddl_template: null;
  logo_url: null;
  dark_logo_url: null;
}
export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  date_format: string;
  theme: string;
  menu_position: string;
  taskcards_theme: string;
  taskcards_role: string;
  avatar_url: string;
  dashboard_permissions: UserRoles[];
  service_desk_permissions: UserOrganisationPermissions[];
}

export interface UserSettingsState {
  details?: User;
  id: string;
  avatar: string;
  dateFormat: string;
  selectedView: string;
  tableAnimationClass: string;
  listAnimationClass: string;
  lastFetched: number;
  ui: AntUISettings;
  displayName: string;
}

export interface UserSettings extends User {
  avatar: string;
  selectedView: string;
}

export interface AntUISettings {
  colorWeak: boolean;
  /**
   * Theme for the nav menu
   * @type {string}
   * @memberof IAntUI
   */
  navTheme: string;
  /**
   * Primary color of ant design
   * @type {string}
   * @memberof IAntUI
   */
  primaryColor: string;
  /**
   * Nav menu position: sidemenu or topmenu
   * @type {string}
   * @memberof IAntUI
   */
  layout: string;
  /**
   * Layout of content: Fluid or Fixed, only works when layout is topmenu
   * @type {('Fluid' | 'Fixed')}
   * @memberof IAntUI
   */
  contentWidth: 'Fluid' | 'Fixed';
  /**
   * Sticky header
   * @type {boolean}
   * @memberof IAntUI
   */
  fixedHeader: boolean;
  /**
   * Auto hide header
   * @type {boolean}
   * @memberof IAntUI
   */
  autoHideHeader: boolean;
  /**
   * Sticky siderbar
   * @type {boolean}
   * @memberof IAntUI
   */
  fixSiderbar: boolean;
}

export interface Permission {
  resource: string;
  read: boolean;
  write: boolean;
  owner: boolean;
}

export interface UserOrganisationPermissions {
  organisationId: string;
  organisation: string;
  permissions: Permission[];
}
