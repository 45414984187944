import axios, { AxiosPromise } from 'axios';
import Cookies from 'universal-cookie';
import { User } from '../models/userSettings';
import { Aircraft } from '../models/aircraft';
import config, { ConfigKey } from '../utils/config';

const cookies = new Cookies();

const getDefaultHeaders = (): {} => ({
  Authorization: `Bearer ${cookies.get('auth')}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export const getMe = (): AxiosPromise<User> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/me`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryAircraft = (params: string): AxiosPromise<Aircraft> => {
  if (!params) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Couldn not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/${params}`,
    headers: getDefaultHeaders(),
  });
};

export const queryAircraftFilters = (): AxiosPromise<any> => {
  return axios({
    method: 'get',
    responseType: 'json',
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/aircraftFilters`,
    headers: getDefaultHeaders(),
  });
};

export const queryAircrafts = async (): Promise<Aircraft[]> => {
  const res = await axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });
  return res.data.data.allAircraft;
};

export const queryAircraftsWithUtilisation = (): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/utilisation`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportUtilisation = (params?: string): AxiosPromise<any> =>
  axios({
    method: 'get',
    responseType: 'json',
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/utilisation?filter=${params || ''}`,
    headers: getDefaultHeaders(),
  });

export const queryReportDelays = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/delays?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportAMP = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/AMP?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportDefectTrends = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/DefectTrends?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportDefectSummary = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/defectSummary?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportAtaDefects = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/AtaDefects?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportTimeBetweenDefects = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/timeBetweenDefects?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportUnscheduledRemovals = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/unscheduledRemovals?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportUnscheduledEngineRemovals = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/unscheduledEngineRemovals?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportEngines = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/engines?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportOil = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/oil?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryReportBreaks = (params?: string): AxiosPromise<any> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/reports/breaks?filter=${params || ''}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryAllWorkpacks = (): AxiosPromise<Aircraft[]> =>
  axios({
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/workpacks`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryUtilisation = (params: string): AxiosPromise<Aircraft> => {
  if (!params) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Could not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/${params}/utilisation`,
    headers: getDefaultHeaders(),
  });
};

export const queryForecast = (params: string): AxiosPromise<Aircraft> => {
  if (!params) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Could not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/${params}/forecast`,
    headers: getDefaultHeaders(),
  });
};

export const queryDefects = (params: string): AxiosPromise<Aircraft> => {
  if (!params) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Could not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/${params}/defects`,
    headers: getDefaultHeaders(),
  });
};

export const queryWorkpacks = (params: string): AxiosPromise<Aircraft> => {
  if (!params) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Could not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/${params}/workpacks`,
    headers: getDefaultHeaders(),
  });
};

export const queryCostAnalysis = (params: string): AxiosPromise<Aircraft> => {
  if (!params) {
    throw new Error('Could not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/aircraft/${params}/workpacks`,
    headers: getDefaultHeaders(),
  });
};

export const queryPeople = (): AxiosPromise<[Person]> => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/people`,
    headers: getDefaultHeaders(),
  });
};

export const linkGsuite = (organisationId: string): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/organisation/${organisationId}/gsuite`,
    headers: getDefaultHeaders(),
  });
};

export const unlinkGsuite = (organisationId: string): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${config.get(ConfigKey.CAMOAPI)}/organisation/${organisationId}/gsuite`,
    headers: getDefaultHeaders(),
  });
};

export const getMeetings = (): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/meetings`,
    headers: getDefaultHeaders(),
  });
};

export const getMeetingRecurrence = (params: string): AxiosPromise => {
  if (!params) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Could not determine recurringEventId');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/meetings/${params}`,
    headers: getDefaultHeaders(),
  });
};

export const getTickets = (page?: number): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/helpdesk/ticket/all${page ? `/page=${page}` : ''}`,
    headers: getDefaultHeaders(),
  });
};

export const createTicket = (formParams): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${config.get(ConfigKey.CAMOAPI)}/helpdesk/ticket`,
    responseType: 'json',
    headers: getDefaultHeaders(),
    data: {
      title: formParams.title,
      content: formParams.content,
      departmentId: formParams.departmentId,
      attachments: (formParams.attachments && formParams.attachments) || null,
    },
  });
};

export const replyTicket = (formParams, ticketNumber): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${config.get(ConfigKey.CAMOAPI)}/helpdesk/ticket/${ticketNumber}/reply`,
    responseType: 'json',
    headers: getDefaultHeaders(),
    data: {
      content: formParams.content,
      attachments: (formParams.attachments && formParams.attachments) || null,
    },
  });
};

export const closeTicket = (ticketNumber): AxiosPromise => {
  return axios({
    method: 'DELETE',
    url: `${config.get(ConfigKey.CAMOAPI)}/helpdesk/ticket/${ticketNumber}`,
    headers: getDefaultHeaders(),
  });
};

export const queryDocumentApproval = (documentId): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/document/${documentId}`,
    headers: getDefaultHeaders(),
  });
};

export const queryDocumentApprovalRequest = (documentId): AxiosPromise => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.CAMOAPI)}/document/approval/${documentId}`,
    headers: getDefaultHeaders(),
  });
};

export const submitDocumentApproval = async (approvalRequestId, approvalState, remarks): Promise<any> => {
  try {
    const response = await axios({
      url: `${config.get(ConfigKey.CAMOAPI)}/document/approval/${approvalRequestId}/response`,
      method: 'POST',
      headers: getDefaultHeaders(),
      data: {
        remarks,
        approvalState,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const submitPartSubmission = async (formParams): Promise<any> => {
  try {
    const response = await axios({
      url: `${config.get(ConfigKey.CAMOAPI)}/material/part`,
      method: 'post',
      responseType: 'json',
      headers: getDefaultHeaders(),
      data: {
        partNumber: formParams.partNumber,
        description: formParams.description,
        materialClass: formParams.class,
        measurement: formParams.measure,
        ACType: formParams.aircraftType,
        ATAChapter: formParams.ata,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const submitVendorSubmission = async (formParams): Promise<any> => {
  try {
    const response = await axios({
      url: `${config.get(ConfigKey.CAMOAPI)}/material/vendor`,
      method: 'post',
      responseType: 'json',
      headers: getDefaultHeaders(),
      data: {
        name: formParams.name,
        nameOne: formParams.name1,
        nameTwo: formParams.name2,
        vendorCode: formParams.vendorCode,
        addressOne: formParams.addressLine1,
        addressTwo: formParams.addressLine2,
        city: formParams.city,
        state: formParams.state,
        country: formParams.country,
        currency: formParams.currency,
        zipCode: formParams.zipCode,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

interface Organisation {
  name: string;
  role: string;
}

interface Person {
  name: string;
  email: string;
  lastActive: string;
  organisations: Organisation[];
}

export interface GetOilConsumptionParams {
  organisation_id: string;
  aircraft: string[];
  range: number;
  dateFormat: string;
}

export interface OilConsumption {
  oil_unit: string;
  engine_oil_consumption_threshold: number;
  apu_oil_consumption_threshold: number;
  data: Array<OilData>;
}

interface OilData {
  date: string;
  engine_1: number;
  engine_2: unknown;
  engine_3: unknown;
  engine_4: unknown;
  apu: unknown;
}

export interface GetRoutesParams {
  from: string;
  to: string;
  aircraft: string;
}

interface Document {
  id: string;
  responsible: string;
  due: string;
  pdf: string;
  remarks: string;
}
