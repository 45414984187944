import React, { ReactElement, useEffect, useState } from 'react';
import { Icon } from 'antd';
import { ConversationDataType } from '../../pages/Camo/HelpDesk';
import styles from './ConversationHistory.module.less';
import ConversationCard from './ConversationCard';
import ReplyForm from './ReplyForm';

const ConversationHistory = ({ ticketNumber, conversation }): ReactElement => {
  const [sortDirection, setSortDirection] = useState('Ascending');
  const [replyVisible, setReplyVisible] = useState(false);

  const handleSortChange = (): void => {
    if (sortDirection === 'Ascending') {
      setSortDirection('Descending');
    } else {
      setSortDirection('Ascending');
    }
  };

  let sortConversation;
  if (conversation.length > 1) {
    sortConversation = conversation.sort((a: ConversationDataType, b: ConversationDataType): number => {
      if (a.date < b.date) {
        if (sortDirection === 'Ascending') {
          return -1;
        }
        if (sortDirection !== 'Ascending') {
          return 1;
        }
      }
      if (a.date > b.date) {
        if (sortDirection === 'Ascending') {
          return 1;
        }
        if (sortDirection !== 'Ascending') {
          return -1;
        }
      }
      return 0;
    });
  } else {
    sortConversation = conversation;
  }

  useEffect(() => {
    setReplyVisible(false);
  }, [conversation]);

  return (
    <div className={styles.conversationWrapper}>
      <div className={styles.headerRow}>
        <span className={styles.conversationTitle}>
          Conversation History <span className={styles.conversationCount}>({conversation.length})</span>
        </span>
        <button type="button" className={styles.sortByButton} onClick={(): void => handleSortChange()}>
          <span className={styles.sortBy}>
            Sort by: <span className={styles.sortType}>{`Days ${sortDirection}`}</span>
            <Icon
              type="caret-down"
              className={sortDirection === 'Ascending' ? styles.sortArrow : styles.sortArrowDesc}
            />
          </span>
        </button>
      </div>
      {!replyVisible &&
        sortConversation.map((convo, index) => (
          <ConversationCard
            key={convo.id}
            author={convo.author}
            company={convo.company}
            date={convo.date}
            content={convo.content}
            attachments={convo.attachments}
            index={index}
            setReplyVisible={setReplyVisible}
          />
        ))}
      {replyVisible && <ReplyForm ticketNumber={ticketNumber} setReplyVisible={setReplyVisible} />}
    </div>
  );
};

export default ConversationHistory;
