import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import { AircraftActionTypes } from '../../models/aircraft';
import { queryAircraft, queryCostAnalysis } from '../../services/apiNew';
import CostAnalysisTable from '../../components/AircraftServiceOverview/CostAnalysisTable';
import CostGraph from '../../components/Graphs/CostGraph';
import LegendToggleButton from '../../components/Graphs/LegendToggleButton';
import CamoMenuLayout from '../../layouts/CamoMenuLayout';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import styles from './AircraftCostAnalysis.module.less';

const AircraftCostAnalysis = ({ aircraftMap, updateAircraft }) => {
  const [isLnfpForecastVisisble, setIsLnfpForecastVisible] = useState(true);
  const [isFleetAverageForecastVisible, setIsFleetAverageForecastVisible] = useState(true);
  const [isXForecastVisible, setIsXForecastVisible] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    params: { id },
  } = useRouteMatch();
  const selectedAircraft = aircraftMap.get(id);

  useEffect(() => {
    const getAircraft = async () => {
      const res = await queryAircraft(id);
      updateAircraft(res.data.data.aircraft);
    };
    if (!selectedAircraft) getAircraft();
  }, [id, selectedAircraft, updateAircraft]);

  useEffect(() => {
    const getCostGraphData = async () => {
      setLoading(true);
      const res = await queryCostAnalysis(id);
      updateAircraft(res.data.data.aircraft);
      setLoading(false);
    };
    // if (selectedAircraft && !selectedAircraft.costAnalysis) {
    //   getCostGraphData();
    // }
    // if (selectedAircraft && selectedAircraft.costAnalysis) {
    //   setGraphData(selectedAircraft.costAnalysis);
    // }
  }, [id, selectedAircraft, updateAircraft]);

  const { formatMessage } = useIntl();

  const lnfpForecast = {
    title: 'C-LNFP Forecast',
    colour: 'linear-gradient(to bottom, #72c9ff, #3f98ff)',
    visible: isLnfpForecastVisisble,
    func: setIsLnfpForecastVisible,
  };

  const fleetAverageForecast = {
    title: 'Fleet Average Forecast',
    colour: 'linear-gradient(to bottom, #024574, #012140)',
    visible: isFleetAverageForecastVisible,
    func: setIsFleetAverageForecastVisible,
  };

  const xForecast = {
    title: 'Estimated Forecast',
    colour: 'linear-gradient(to bottom, #f97d8a, #f5222d)',
    visible: isXForecastVisible,
    func: setIsXForecastVisible,
  };

  const handleLegendToggle = (func, visible) => {
    func(!visible);
  };

  const buttonsArray = [lnfpForecast, fleetAverageForecast, xForecast];
  const tableData = [
    {
      title: '12MO/600HR Airframe (cycle 1)',
      date: '06/04/2018',
      manHours: 22,
      estCost: '2660',
      actualCost: '4332',
    },
    {
      title: '12MO Airframe (cycle 1)',
      date: '06/04/2018',
      manHours: 10,
      estCost: '1210',
      actualCost: '2140',
    },
    {
      title: '12MO/600HR Engine (cycle 1)',
      date: '06/04/2018',
      manHours: 17.5,
      estCost: '2115',
      actualCost: '3890',
    },
    {
      title: '12MO Engine (cycle 1)',
      date: '06/04/2018',
      manHours: 2,
      estCost: '245',
      actualCost: '310',
    },
    {
      title: 'MLG Tyre change',
      date: '07/18/2018',
      manHours: 2,
      estCost: '2800',
      actualCost: '2600',
    },
    {
      title: 'Battery 1 & 2 replacement (Concorde)',
      date: '01/02/2019',
      manHours: 1,
      estCost: '8000',
      actualCost: '8000',
    },
    {
      title: 'Emergency Battery Functional Check',
      date: '01/02/2019',
      manHours: 1,
      estCost: '300',
      actualCost: '450',
    },
    {
      title: '12MO/600HR Airframe (cycle2)',
      date: '07/06/2019',
      manHours: 22,
      estCost: '2660',
      actualCost: '4500',
    },
    {
      title: '12MO Airframe (cycle 2)',
      date: '07/06/2019',
      manHours: 17.5,
      estCost: '2115',
      actualCost: '3700',
    },
    {
      title: '12MO Engine (cycle 2)',
      date: '07/06/2019',
      manHours: 2,
      estCost: '245',
      actualCost: '800',
    },
    {
      title: '24MO Airframe (cycle 1)',
      date: '07/06/2019',
      manHours: 24,
      estCost: '2904',
      actualCost: '3800',
    },
    {
      title: '24M/1200HR Airframe (cycle 1)',
      date: '07/06/2019',
      manHours: 20,
      estCost: '2420',
      actualCost: '2320',
    },
    {
      title: 'MLG Tyre change',
      date: '9/18/2019',
      manHours: 2,
      estCost: '2800',
      actualCost: '3900',
    },
    {
      title: 'Main Battery Cap Check',
      date: '10/25/2019',
      manHours: 2,
      estCost: '300',
      actualCost: '500',
    },
    {
      title: 'Emergency Battery Functional Check',
      date: '10/25/2019',
      manHours: 1,
      estCost: '300',
      actualCost: '310',
    },
    {
      title: '600HR Airframe (cycle 1)',
      date: '10/25/2019',
      manHours: 12.5,
      estCost: '1510',
      actualCost: '1850',
    },
    {
      title: '600HR Engine (cycle 1)',
      date: '10/25/2019',
      manHours: 3.5,
      estCost: '423',
      actualCost: '400',
    },
    {
      title: '12MO/600HR Airframe (cycle 3)',
      date: '06/11/2020',
      manHours: 22,
      estCost: '2660',
      actualCost: '3700',
    },
    {
      title: '12MO Airframe (cycle 3)',
      date: '06/11/2020',
      manHours: 10,
      estCost: '1210',
      actualCost: '1980',
    },
    {
      title: '12MO/600HR Engine (cycle 3)',
      date: '06/11/2020',
      manHours: 17.5,
      estCost: '2115',
      actualCost: '2340',
    },
    {
      title: '12MO Engine (cycle 3)',
      date: '06/11/2020',
      manHours: 2,
      estCost: '245',
      actualCost: '1770',
    },
    {
      title: '36MO/1800HR Airframe (cycle 1)',
      date: '06/11/2020',
      manHours: 6,
      estCost: '726',
      actualCost: '1020',
    },
    {
      title: '36MO/1800HR Engine (cycle 1)',
      date: '06/11/2020',
      manHours: 2.5,
      estCost: '302',
      actualCost: '880',
    },
  ];

  const renderToggles = buttonsArray.map((button) => {
    return (
      <div className={styles.legendWrapper} key={button.title}>
        <LegendToggleButton
          visible={button.visible}
          handleToggleClick={() => handleLegendToggle(button.func, button.visible)}
          badgeColour={button.colour}
          buttonText={button.title}
        />
      </div>
    );
  });

  const composeGraphData = (inputData) => {
    const newGraphData = inputData.reduce((outputArray, item) => {
      const newData = outputArray;
      const itemYear = moment(item.date, 'MM/DD/YYYY').year();
      if (newData.findIndex((object) => object.year === itemYear) !== -1) {
        const index = newData.findIndex((object) => object.year === itemYear);
        newData[index].lnfpForecast = parseInt(newData[index].lnfpForecast, 10) + parseInt(item.actualCost, 10);
        newData[index].estimatedForecast = parseInt(newData[index].estimatedForecast, 10) + parseInt(item.estCost, 10);
        newData[index].fleetForecast = parseInt(newData[index].fleetForecast, 10) + parseInt(item.actualCost, 10);
      } else {
        newData.push({
          year: itemYear,
          lnfpForecast: parseInt(item.actualCost, 10),
          estimatedForecast: parseInt(item.estCost, 10),
          fleetForecast: parseInt(item.actualCost, 10),
        });
      }
      return newData;
    }, []);
    newGraphData.push({
      year: 'Projected',
      estimatedForecast:
        (newGraphData[newGraphData.length - 1].estimatedForecast +
          newGraphData[newGraphData.length - 2].estimatedForecast) /
        2,
    });
    setGraphData(newGraphData);
  };

  useEffect(() => {
    composeGraphData(tableData);
  }, []);

  return (
    <CamoMenuLayout>
      <PageHeaderWrapper
        layout="modern"
        title={formatMessage({ id: 'title.costAnalysis' })}
        content={formatMessage({ id: 'title.costAnalysisSub' })}
      >
        <Card className={cardStyles.pageCard}>
          <div className={cardStyles.cardHeader}>{formatMessage({ id: 'title.maintenanceCost' })}</div>
          <div className={styles.toggleWrapper}>{renderToggles}</div>
          <CostGraph
            data={graphData}
            isLnfpForecastVisisble={isLnfpForecastVisisble}
            isFleetAverageForecastVisible={isFleetAverageForecastVisible}
            isXForecastVisible={isXForecastVisible}
          />
          <CostAnalysisTable data={tableData} />
        </Card>
      </PageHeaderWrapper>
    </CamoMenuLayout>
  );
};

AircraftCostAnalysis.propTypes = {
  aircraftMap: PropTypes.instanceOf(Map),
  updateAircraft: PropTypes.object,
};

AircraftCostAnalysis.defaultProps = {
  aircraftMap: new Map(),
  updateAircraft: {},
};

const AircraftCostAnalysisWithRedux = connect(
  ({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  }),
  (dispatch) => ({
    updateAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.UPDATE,
        payload,
      }),
  }),
)(AircraftCostAnalysis);

export default AircraftCostAnalysisWithRedux;
