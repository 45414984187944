import { Avatar, Dropdown, Icon, Menu, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import servers from '../../utils/servers';
import { truncateName } from '../../utils/utils';
import { fetch } from '../../models/userSettings/actions';
import OptionsIcon from '../../assets/more-options.svg';
import logout from '../../utils/logout';
import styles from './index.module.less';

const cookies = new Cookies();

class GlobalHeaderRight extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    theme: PropTypes.string,
    userSettings: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    theme: undefined,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetch());
  }

  handleMenuClick = ({ key }) => {
    const { history } = this.props;
    if (key === 'changePassword') {
      window.location.href = `${servers.api}/change_password`;
      return;
    }

    if (key === 'settings') {
      history.push('/settings/account');
    }
    if (key === 'logout') {
      logout();
    }
  };

  render() {
    const {
      theme,
      userSettings: { details = {}, displayName },
      intl: { formatMessage },
    } = this.props;
    const { avatar_url: avatarUrl, people } = details;
    const menu = (
      <Menu className={styles.menu} selectedKeys={[]} onClick={this.handleMenuClick}>
        <Menu.Item key="settings">
          <Icon type="setting" />
          {formatMessage({ id: 'menu.account.settings', defaultMessage: 'Profile Settings' })}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <Icon type="logout" />
          {formatMessage({ id: 'menu.account.logout' })}
        </Menu.Item>
      </Menu>
    );
    const className = [styles.right];
    if (theme === 'dark') {
      className.push(styles.dark);
    }
    const role = people ? people[0].type : '-';
    return (
      <div className={className}>
        {displayName ? (
          <div className={styles.wrapper}>
            <Dropdown overlay={menu} trigger={isMobile ? ['click'] : ['hover']}>
              <div className={`${styles.action} ${styles.account}`}>
                {avatarUrl ? (
                  <Avatar
                    data-test="image-avatar"
                    data-testid="RightContent--avatarImage"
                    size="small"
                    className={styles.avatar}
                    alt="avatar"
                    src={`${servers.api}/logins/${cookies.get('userId')}/avatar?${avatarUrl}`}
                  />
                ) : (
                  <Avatar
                    data-test="text-avatar"
                    data-testid="RightContent--avatarText"
                    size="small"
                    className={styles.avatar}
                    alt="avatar"
                  >
                    {displayName.charAt(0)}
                  </Avatar>
                )}
                <div data-test="name-span" data-testid="RightContent--nameSpan" className={styles.nameWrapper}>
                  <div className={styles.name}>{truncateName(displayName || '')}</div>
                  <div className={styles.role}>{role}</div>
                </div>
                <div className={styles.iconWrapper}>
                  <img src={OptionsIcon} alt="more" />
                </div>
              </div>
            </Dropdown>
          </div>
        ) : (
          <Spin size="small" className={styles.spinStyle} />
        )}
      </div>
    );
  }
}

export default injectIntl(
  withRouter(
    connect(({ userSettings }) => ({
      userSettings,
    }))(GlobalHeaderRight),
  ),
);
