import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import arrowIcon from '../../assets/icon-chevron-blue.svg';
import styles from './CalendarEntryTile.module.less';

const CalendarEntryTile = ({ entry, intl }) => {
  const { formatMessage } = intl;
  return (
    <div className={styles.tileWrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.meetingName}>{entry.summary}</div>
        {entry.conferenceData && (
          <div className={styles.joinLink}>
            <a href={entry.conferenceData.entryPoints[0].uri}>
              <span>{formatMessage({ id: 'text.joinTheMeeting' })}</span>
              <img src={arrowIcon} alt="arrow" className={styles.arrowIcon} />
            </a>
          </div>
        )}
      </div>
      <div className={styles.timeWrapper}>
        <div className={styles.meetingTime}>{moment(entry.start.dateTime).format('HH:mm')}</div>
        <div className={styles.meetingFrequency}>{entry.frequency}</div>
      </div>
    </div>
  );
};

CalendarEntryTile.propTypes = {
  entry: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

CalendarEntryTile.defaultProps = {
  entry: {},
};

export default injectIntl(CalendarEntryTile);
