import moment from 'moment';
import { stringify } from 'qs';
import rp from 'request-promise';
import Cookies from 'universal-cookie';
import { intl } from '../providers/IntlProvider';
import config, { ConfigKey } from '../utils/config';
import error from '../utils/requestError';

const cookies = new Cookies();

export async function getDefectUrl(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/defects/${params}/public_url`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateUserSettings(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/people/${params.id}`,
    resolveWithFullResponse: false,
    body: {
      params,
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getUserSettingsFromServer(params) {
  function autoParse(body) {
    const modifiedBody = body;
    modifiedBody.dateFormat = 'YYYY-DD-MM';
    return modifiedBody;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/people/${params.id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    transform: autoParse,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getFlight(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/${params}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTrip(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/trips/${params}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getDefect(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return {
        list: [body],
      };
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/defects/${params}`,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateDefect(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      const data = {};
      data.list = [body];
      return data;
    }
    return null;
  }

  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/defects/${params.id}`,
    resolveWithFullResponse: false,
    transform: autoParse,
    body: {
      grace_period_days: params.deferral,
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getRoutes(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return body.map(({ arrival, departure }) => ({
        departure,
        arrival,
        startLat: Number(departure.latitude),
        startLng: Number(departure.longitude),
        endLat: Number(arrival.latitude),
        endLng: Number(arrival.longitude),
      }));
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/routes?${params.from ? `from_date=${params.from}&` : ''}to_date=${
      params.to
    }`,
    qs: {
      aircraft_ids: params.aircraft,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftLogs(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const data = [];
      const { totals, entries } = body;
      const firstObj = {
        airframe_hours: totals.brought_forward.flight_seconds,
        cumulative_landings: totals.brought_forward.landings,
        apu_hours: totals.brought_forward.apu_seconds || 0,
        key: 0,
      };
      const lastObj = {
        flight_time: totals.carried_forward.flight_seconds - totals.brought_forward.flight_seconds,
        landings: totals.carried_forward.landings - totals.brought_forward.landings,
        airframe_hours: totals.carried_forward.flight_seconds,
        cumulative_landings: totals.carried_forward.landings,
        apu_hours: totals.carried_forward.apu_seconds || 0,
      };

      let engine1OilTotal = 0;
      let engine2OilTotal = 0;
      let engine3OilTotal = 0;
      let engine4OilTotal = 0;
      const { brought_forward: bfData } = totals;
      const { carried_forward: cfData } = totals;

      for (let i = 0; i < 4; i += 1) {
        if (body.columns.includes(`engine_${i + 1}_hours`)) {
          firstObj[`engine_${i + 1}_hours`] = bfData[`engine_${i + 1}_hours`];
          firstObj[`engine_${i + 1}_cycles`] = bfData[`engine_${i + 1}_cycles`];
          lastObj[`engine_${i + 1}_hours`] = cfData[`engine_${i + 1}_hours`];
          lastObj[`engine_${i + 1}_cycles`] = cfData[`engine_${i + 1}_cycles`];
        }
        if (
          body.columns.includes(`prop_${i + 1}_hours`) ||
          bfData[`prop_${i + 1}_hours`] ||
          cfData[`prop_${i + 1}_hours`]
        ) {
          firstObj[`prop_${i + 1}_hours`] = bfData[`prop_${i + 1}_hours`];
          lastObj[`prop_${i + 1}_hours`] = cfData[`prop_${i + 1}_hours`];
        }
      }

      data.push(firstObj);

      for (let i = 0; i < entries.length; i += 1) {
        const entry = {};
        if (body.columns.includes('engine_1_hours')) {
          engine1OilTotal += entries[i].engine_1_oil_uplift;
          entry.engine_1_hours = entries[i].engine_1_hours;
          entry.engine_1_cycles = entries[i].engine_1_cycles;
          entry.engine_1_oil = entries[i].engine_1_oil_uplift;
          if (body.columns.includes('prop_1_hours')) {
            entry.prop_1_hours = entries[i].prop_1_hours;
          }
        }
        if (body.columns.includes('engine_2_hours')) {
          engine2OilTotal += entries[i].engine_2_oil_uplift;
          entry.engine_2_hours = entries[i].engine_2_hours;
          entry.engine_2_cycles = entries[i].engine_2_cycles;
          entry.engine_2_oil = entries[i].engine_2_oil_uplift;
          if (body.columns.includes('prop_2_hours')) {
            entry.prop_2_hours = entries[i].prop_2_hours;
          }
        }
        if (body.columns.includes('engine_3_hours')) {
          engine3OilTotal += entries[i].engine_3_oil_uplift;
          entry.engine_3_hours = entries[i].engine_3_hours;
          entry.engine_3_cycles = entries[i].engine_3_cycles;
          entry.engine_3_oil = entries[i].engine_3_oil_uplift;
          if (body.columns.includes('prop_3_hours')) {
            entry.prop_3_hours = entries[i].prop_3_hours;
          }
        }
        if (body.columns.includes('engine_4_hours')) {
          engine4OilTotal += entries[i].engine_4_oil_uplift;
          entry.engine_4_hours = entries[i].engine_4_hours;
          entry.engine_4_cycles = entries[i].engine_4_cycles;
          entry.engine_4_oil = entries[i].engine_4_oil_uplift;
          if (body.columns.includes('prop_4_hours')) {
            entry.prop_4_hours = entries[i].prop_4_hours;
          }
        }
        entry.date = moment(entries[i].date);
        entry.takeoff = entries[i].takeoff;
        entry.landing = entries[i].landing;
        entry.from = entries[i].departure_airport;
        entry.to = entries[i].arrival_airport;
        entry.flight_time = moment.duration(entries[i].flight_time).as('seconds');
        entry.landings = entries[i].landings;
        entry.airframe_hours = moment.duration(entries[i].airframe_hours).as('seconds');
        entry.cumulative_landings = data[i].cumulative_landings + entries[i].landings;
        entry.apu_hours = 0;
        entry.srp = entries[i].srp;
        entry.key = i + 1;
        data[i + 1] = entry;
      }

      if (body.columns.includes('engine_1_hours')) {
        lastObj.engine_1_oil = engine1OilTotal;
      }
      if (body.columns.includes('engine_2_hours')) {
        lastObj.engine_2_oil = engine2OilTotal;
      }
      if (body.columns.includes('engine_3_hours')) {
        lastObj.engine_3_oil = engine3OilTotal;
      }
      if (body.columns.includes('engine_4_hours')) {
        lastObj.engine_4_oil = engine4OilTotal;
      }

      lastObj.key = data.length;
      data.push(lastObj);
      return data;
    }
    return null;
  }

  return rp({
    method: 'GET',
    // eslint-disable-next-line max-len
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/flights?from_date=${params.from}&to_date=${
      params.to
    }&columns=${params.columns}`,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMonthlySummary(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      const monthly = [];

      for (let i = 0; i < body.length; i += 1) {
        monthly[i] = {};
        monthly[i].period = moment(`${body[i].year}-${body[i].month}`, 'YYYY-MM');
        monthly[i].periodFormatted = moment(monthly[i].period).format('MMM YY');
        if (body[i].total_flights === null) {
          monthly[i].totalFlights = 0;
        } else {
          monthly[i].totalFlights = body[i].total_flights;
        }
        if (body[i].total_fuel_used_kg === null) {
          monthly[i].totalFuel = 0;
        } else {
          monthly[i].totalFuel = Number(Number(body[i].total_fuel_used_kg).toFixed(0));
        }
        if (body[i].total_fuel_uplifted_kg === null) {
          monthly[i].totalFuelUplifted = 0;
        } else {
          monthly[i].totalFuelUplifted = Number(Number(body[i].total_fuel_uplifted_kg).toFixed(0));
        }
        if (body[i].average_fuel_per_hour_kg === null) {
          monthly[i].fuelHour = 0;
        } else {
          monthly[i].fuelHour = Number(Number(body[i].average_fuel_per_hour_kg).toFixed(1));
        }
        if (body[i].total_duration === null) {
          monthly[i].totalHours = 0;
        } else {
          monthly[i].totalHours = Number((body[i].total_duration / 3600).toFixed(0));
        }
        if (body[i].average_hours_per_landing === null) {
          monthly[i].cycleHour = 0;
        } else {
          monthly[i].cycleHour = Number(body[i].average_hours_per_landing.toFixed(2));
        }
        if (i === 0) {
          monthly[i].fuelDeficit = monthly[i].totalFuel - monthly[i].totalFuelUplifted;
        } else {
          monthly[i].fuelDeficit = monthly[i].totalFuel - monthly[i].totalFuelUplifted + monthly[i - 1].fuelDeficit;
        }
      }

      monthly.sort((a, b) => {
        return a.period - b.period;
      });

      return monthly;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/monthly_summary`,
    resolveWithFullResponse: false,
    qs: {
      aircraft_ids: params.aircraft,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTopAirports(params) {
  function autoParse(body, res) {
    if (res.statusCode === 200) {
      const response = [];
      for (let i = 0; i < body.length; i += 1) {
        response[i] = {};
        response[i].x = body[i].ident;
        response[i].y = body[i].count;
      }
      return response.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/airports?from_date=${params.from}&to_date=${params.to}`,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getFigures(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/summary?from_date=${params.from}&to_date=${params.to}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTopAircraft(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      let hours = [];
      let flights = [];
      for (let i = 0; i < body.length; i += 1) {
        hours[i] = {};
        flights[i] = {};
        hours[i].x = body[i].registration;
        hours[i].y = body[i].total_duration / 3600;
        flights[i].x = body[i].registration;
        flights[i].y = body[i].flights_count;
      }
      hours = hours.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
      flights = flights.sort((a, b) => parseFloat(b.y) - parseFloat(a.y));
      return [flights, hours];
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/aircraft?from_date=${params.from}&to_date=${params.to}`,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftType(id) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft_types/${id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftTypes(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft_types/search?${stringify({ search: params })}&limit=20`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAirports(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/airports/search?${stringify({ search: params })}&limit=20`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
    return 'fail';
  });
}

export async function addTrip(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.id}/trips`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      trip: params.trip,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addFlight(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/trips/${params.id}/flights`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.flight,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addDefect(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.id}/defects`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.defect,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryTrips(params) {
  function autoParse(body) {
    const list = body;
    for (let i = 0; i < list.length; i += 1) {
      list[i].key = i + 1;
      if (list[i].cycles === null) {
        list[i].cycles = 0;
      }
      if (list[i].number === null) {
        list[i].number = '';
      }
      if (list[i].srp_number === null) {
        list[i].srp_number = '';
      }
      if (list[i].date === null) {
        list[i].date = '';
      }
      if (list[i].fuel_delta === null) {
        list[i].fuel_delta = 0;
      }
      if (list[i].flight_seconds === null) {
        list[i].flight_seconds = 0;
      }
      list[i].tripData = {
        flight_seconds: list[i].flight_seconds,
        landings: list[i].landings,
        fuel_delta: list[i].fuel_delta,
      };
      list[i].srpData = {
        srp_number: list[i].srp_number,
        srp_pages: list[i].srp_pages,
        srp_reference: list[i].srp_reference,
      };
      if (list[i].start_airport && list[i].end_airport) {
        list[i].route = `${list[i].start_airport} - ${list[i].end_airport}`;
      } else {
        list[i].route = '';
      }
    }
    return list;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(
      ConfigKey.API,
    )}/aircraft/${params}/trip_entries?sort_order=desc&limit=100000&sort_column=trip_number`,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryAllTrips(params) {
  function autoParse(body) {
    const list = body;
    for (let i = 0; i < list.length; i += 1) {
      list[i].key = i + 1;
      if (list[i].cycles === null) {
        list[i].cycles = 0;
      }
      if (list[i].number === null) {
        list[i].number = 'NEW';
      }
      if (list[i].srp_number === null) {
        list[i].srp_number = '';
      }
      if (list[i].date === null) {
        list[i].date = '';
      }
      if (list[i].fuel_delta === null) {
        list[i].fuel_delta = 0;
      }
      if (list[i].flight_seconds === null) {
        list[i].flight_seconds = 0;
      }
      list[i].tripData = {
        flight_seconds: list[i].flight_seconds,
        landings: list[i].landings,
        fuel_delta: list[i].fuel_delta,
      };
      list[i].srpData = {
        srp_number: list[i].srp_number,
        srp_pages: list[i].srp_pages,
        srp_reference: list[i].srp_reference,
      };
      if (list[i].start_airport && list[i].end_airport) {
        list[i].route = `${list[i].start_airport} - ${list[i].end_airport}`;
      } else {
        list[i].route = '';
      }
    }
    return list;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/trip_entries?sort_order=descend`,
    resolveWithFullResponse: false,
    transform: autoParse,
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets',
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryTripsOnly(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params}/trips?sort=desc`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getTripUpdate(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 4,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTripUpdate(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params.payload.tripId}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.payload.body.newTripData,
    json: true,
  });
}

export async function queryFlightsOnly(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(
      ConfigKey.API,
    )}/flights?aircraft_id[]=${params}&sort_order=desc&limit=100000&sort_column=trip_number`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addSubscription(params) {
  // function autoParse(body, response) {
  //   const data = {};
  //   data.list = [body];
  //   if (response.statusCode === 201) {
  //     data.list.statusCode = 201;
  //     return data;
  //   } else if (response.statusCode === 422) {
  //     data.list.statusCode = 422;
  //     return data;
  //   }
  // }

  return rp({
    simple: false,
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      model: params.model,
      event: params.event,
      enabled: params.enabled,
      filter_params: {
        aircraft_id: params.filters,
      },
      operator_id: params.operator,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateSubscription(params) {
  function autoParse(body, response) {
    return response;
  }

  return rp({
    simple: false,
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      model: params.model,
      event: params.event,
      enabled: params.enabled,
      filter_params: {
        aircraft_id: params.filters || params.filter_params.aircraft_id,
      },
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function removeSubscription(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscription(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscriptionEvents() {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      const bodyParsed = JSON.parse(body);
      const data = {};
      data.list = bodyParsed;
      return data;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/events`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscriptionEventModel() {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return {};
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions/events`,
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function querySubscriptions() {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/event_subscriptions`,
    resolveWithFullResponse: true,
    json: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryAircraft(params) {
  function autoParse(body, response) {
    if (response.statusCode >= 200 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params}`,
    transform: autoParse,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraftList() {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft`,
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryAircrafts() {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const aircraft = JSON.parse(body);
      for (let i = 0; i < aircraft.length; i += 1) {
        aircraft[i].key = i;
        aircraft[i].type = aircraft[i].aircraft_type.designator;
        // eslint-disable-next-line no-self-assign
        aircraft[i].year = aircraft[i].year;
        aircraft[i].serial = aircraft[i].serial_number;
      }
      const data = { list: aircraft };
      return data;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/aircraft`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function updateAircraft(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.aircraft,
    json: true,
  });
}

export async function updateTripEntry(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.trip,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteTripUpdate(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/trip_updates/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteTripEntry(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/trip_entries/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteFlight(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/flights/${params.id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTripNumber(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/trip_entries/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.trip,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateAircraftMaintenance(params) {
  return rp({
    method: 'PUT',
    // End point from aircraft obj for mx id
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_item/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: { aircraft: params.aircraft },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addAircraftTotals(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}/trip_updates`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.trip,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addAircraftMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}/scheduled_mx_items`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.item,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addAircraft(body) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addAircraftTrip(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/trip_update`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      trip_update: {
        number: params.srp || '',
        srp_number: params.srp_number || '',
        flight_seconds: params.acStartHours * 3600 + params.acStartMins * 60,
        apu_seconds: params.apuStartHours * 3600 + params.apuStartMins * 60,
        cycles: params.acStartCycles,
        landings: params.acStartLandings,
        aircraft_id: params.id,
      },
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updatePeople(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/people/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      first_name: params.first_name,
      last_name: params.last_name,
      email: params.email,
      type: params.type, // pilot, ops, camo, admin [tbd]
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateFuel(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/fuel_uplifts/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      paid: params.paid,
      price_value: params.price_value,
      price_currency: params.price_currency,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function removePeople(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/people/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function addPeople(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/organisations/${params.organisation_id}/people`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      first_name: params.first_name,
      last_name: params.last_name,
      email: params.email,
      type: params.role, // pilot, ops, camo, admin [tbd]
    },
    json: true,
  });
}

export async function download(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/${params}`,
    resolveWithFullResponse: true,
    encoding: null,
    headers: {
      'Content-type': 'application/pdf',
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function downloadCsv(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/${params}`,
    resolveWithFullResponse: true,
    encoding: null,
    headers: {
      'Content-type': 'application/json',
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryFuel(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      if (body.length > 0) {
        const fuel = body.map((d, i) => {
          const data = {};
          if (d.truck_actual_uplift && d.truck_unit) {
            data.truck_actual_uplift = d.truck_actual_uplift;
            data.truck_unit = d.truck_unit;
            data.formattedValue = `${parseInt(d.truck_actual_uplift, 10)} ${d.truck_unit.toUpperCase()}`;
          } else {
            data.formattedValue = '';
          }
          data.key = i;
          data.id = d.id;
          data.aircraft = d.flight.aircraft.registration;
          if (d.flight.trip.number) {
            data.tripSector = `${d.flight.trip.number}-${d.flight.sector_number}`;
          } else {
            data.tripSector = `${intl.formatMessage({ id: 'text.newCaps' })}-${d.flight.sector_number}`;
          }
          data.date = d.flight.trip.date;
          data.location = d.flight.departure_airport;
          if (d.attachments.length > 0) {
            data.attachment = d.attachments[0].pdf_url;
          } else {
            data.attachment = null;
          }
          data.aircraft_actual_uplift = d.aircraft_actual_uplift;
          data.aircraft_planned_uplift = d.aircraft_planned_uplift;
          data.aircraft_unit = d.aircraft_unit ? d.aircraft_unit : null;
          data.density = d.density ? d.density : null;
          data.flight = d.flight;
          data.paid = d.paid;
          data.price_currency = d.price_currency ? d.price_currency : null;
          data.price_value = d.price_value ? d.price_value : null;
          data.price_quote = d.price_quote ? d.price_quote : null;
          return data;
        });
        const total = response.headers['total-records'];
        return { fuel, total };
      }
      return [];
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/fuel_uplifts`,
    resolveWithFullResponse: true,
    transform: autoParse,
    qs: {
      page: params.page || '1',
      limit: params.limit || '10',
    },
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryPeople() {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return response.body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/people`,
    resolveWithFullResponse: true,
    json: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryMaintenance(params) {
  function autoParse(maintenance, response) {
    const data = maintenance;
    if (response.statusCode > 199 && response.statusCode < 400) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].key = i;
          for (let j = 0; j < data[i].mx_tasks.length; j += 1) {
            data[i].mx_tasks[j].key = j;
          }
        }
      }
      return maintenance;
    }
    return [];
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items`,
    qs: {
      aircraft_id: params.aircraft_id,
      from_date: params.from_date,
      to_date: params.to_date,
      mx_type: params.mx_type,
    },
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    resolveWithFullResponse: true,
    transform: autoParse,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 4,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function updateMaintenance(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.fieldsValue,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function approveMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/approve`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      ids: params.ids,
    },
    json: true,
  });
}

export async function rejectMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/reject`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      ids: params.ids,
    },
    json: true,
  });
}

// this might not be necessary but left it in just in case
export async function commitMaintenanceDiffs(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}/commit_diffs`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      commits: params.commits,
    },
    json: true,
  });
}

export async function removeMaintenance(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function addMaintenance(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/scheduled_mx_items`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.fieldsValue,
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addDamageMap(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/damage_maps`;
  const formData = params.form;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: formData,
  }).catch((err) => {
    error(err);
  });
}

export async function fetchIntegrations(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/integrations`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
    return { statusCode: null };
  });
}

export async function addRocketRoute(params) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/integrations/rocketroute`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.fieldsValue,
    json: true,
  }).catch((err) => {
    error(err);
    return { statusCode: null };
  });
}

export async function removeRocketRoute(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/integrations/rocketroute`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
    return { statusCode: null };
  });
}

export async function queryAllFlights(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      const total = parseInt(response.headers['total-records'], 10);
      const bodyParsed = JSON.parse(body);
      const flights = bodyParsed;
      for (let i = 0; i < flights.length; i += 1) {
        flights[i].key = i;
        if (flights[i].trip.number === null || flights[i].trip.number === '') {
          flights[i].trip.number = 'NEW';
        }
        flights[i].tripNo = `${flights[i].trip.number}-${flights[i].sector_number}`;
        flights[i].aircraft_ids = flights[i].aircraft_registration;
        flights[i].date = flights[i].trip.date;
        if (flights[i].time_landing != null) {
          flights[i].arrTime = moment(flights[i].time_landing)
            .utc()
            .format('HH:mm');
        } else {
          flights[i].arrTime = '';
        }
        if (flights[i].time_takeoff != null) {
          flights[i].depTime = moment(flights[i].time_takeoff)
            .utc()
            .format('HH:mm');
        } else {
          flights[i].depTime = '';
        }
      }
      const data = {};
      data.list = flights;
      data.pagination = { total };
      return data;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/flights/`,
    resolveWithFullResponse: true,
    transform: autoParse,
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryDefects(params) {
  function autoParse(body, response) {
    if (response.statusCode === 200) {
      return {
        list: JSON.parse(body).map((defect, i) => ({
          ...defect,
          key: i,
          aircraft_ids: defect.aircraft.registration,
        })),
        pagination: {
          total: parseInt(response.headers['total-records'], 10),
        },
      };
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/defects`,
    resolveWithFullResponse: true,
    transform: autoParse,
    qs: params,
    qsStringifyOptions: {
      encode: false,
      arrayFormat: 'brackets', // do not encode arrays within query strings
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Api-Version': 2,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function setUserDetails(params) {
  const url = `${config.get(ConfigKey.API)}/me`;
  const formData = params.form;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: formData,
  })
    .then((r) => {
      const response = r;
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function getUserAvatar() {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/logins/${cookies.get('userId')}/avatar`,
    resolveWithFullResponse: true,
    simple: false,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((response) => {
      if (response.statusCode !== 200) {
        return null;
      }
      return response.body;
    })
    .catch((err) => {
      error(err);
    });
}

export async function getUserDetails() {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.CAMOAPI)}/me`,
    resolveWithFullResponse: true,
    simple: false,
    json: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((response) => {
      if (response.statusCode !== 200) {
        return null;
      }
      return response.body;
    })
    .catch((err) => {
      error(err);
    });
}

export async function queryAircraftDocuments(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/documents`;
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const bodyData = body;
      for (let i = 0; i < bodyData.length; i += 1) {
        bodyData[i].key = i;
      }
      return bodyData;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function queryDocuments() {
  const url = `${config.get(ConfigKey.API)}/documents`;
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      const bodyData = body;
      for (let i = 0; i < bodyData.length; i += 1) {
        bodyData[i].key = i;
      }
      return bodyData;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addDocument(params) {
  const url = `${config.get(ConfigKey.API)}/documents`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.form,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function updateDocument(params) {
  const url = `${config.get(ConfigKey.API)}/documents/${params.id}`;
  const formData = params.form;

  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function removeDocument(params) {
  const url = `${config.get(ConfigKey.API)}/documents/${params.id}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function getDocumentTypes() {
  const url = `${config.get(ConfigKey.API)}/documents/types`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      error(err);
    });
}

export async function queryTickets(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets?limit=${params.limit}&page=${params.page}&group=${params.group}&state=${
      params.state
    }&aircraft=${params.aircraft}&search=${params.searchTerm}&sort_order=${params.sort_order}&sort_column=${
      params.sort_column
    }&my_tickets=${params.myTickets}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryTicketGroups() {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/groups`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryAttachment(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/attachments/${params.ticket_id}/${params.article_id}/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryTicketArticles(params) {
  function autoParse(body, response) {
    if (response.statusCode > 199 && response.statusCode < 400) {
      return body;
    }
    return null;
  }

  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/articles/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function queryTicket(params) {
  function autoParse(body, response) {
    if (response.statusCode >= 200 && response.statusCode < 400) {
      return body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/${params.id}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function searchTicket(params) {
  function autoParse(body, response) {
    if (response.statusCode >= 200 && response.statusCode < 400) {
      return body;
    }
    return null;
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/tickets/search/${params.globalFilter}`,
    resolveWithFullResponse: true,
    transform: autoParse,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function addTicket(params) {
  const attachments = [];
  params.attachments.forEach((a, index) => {
    const attachment = {
      filename: a.name,
      data: params.base64s[index],
      'mime-type': a.type,
    };
    attachments.push(attachment);
  });
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/tickets`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      title: params.title,
      group: params.group,
      aircraft: params.aircraft,
      article: {
        subject: 'some subject',
        body: params.note,
        type: 'note',
        internal: false,
        attachments,
      },
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function addArticle(params) {
  const attachments = [];
  params.attachments.forEach((a, index) => {
    const attachment = {
      filename: a.name,
      data: params.base64s[index],
      'mime-type': a.type,
    };
    attachments.push(attachment);
  });
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/tickets/article`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      ticket_id: params.ticket_id,
      to: params.to,
      cc: params.cc,
      subject: params.subject,
      body: params.body,
      content_type: params.content_type,
      type: params.type,
      internal: params.internal,
      time_unit: params.time_unit,
      attachments,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function updateTicket(params) {
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/tickets/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: {
      state: params.state,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function removeTicket(params) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/tickets/${params.id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function getAircraft(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function addDefectAttachment(params) {
  const url = `${config.get(ConfigKey.API)}/defects/${params.id}/upload`;
  const formData = new FormData();
  formData.append('attachment', params.attachment);
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: formData,
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function getAirport(params) {
  const url = `${config.get(ConfigKey.API)}/airports/${params}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function getAttachmentFile(url) {
  return fetch(url.startsWith('http') ? url : `${config.get(ConfigKey.API)}/${url}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((r) => r.blob())
    .catch((err) => {
      error(err);
    });
}

export async function addFlightAttachment(params) {
  const url = `${config.get(ConfigKey.API)}/flights/${params.id}/upload `;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.formData,
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function addTripAttachment(params) {
  const url = `${config.get(ConfigKey.API)}/trip_entries/${params.id}/upload`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    body: params.formData,
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function deleteAttachment(id) {
  const url = `${config.get(ConfigKey.API)}/attachments/${id}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function updateFlight(params) {
  const url = `${config.get(ConfigKey.API)}/flights/${params.id}`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params.flight),
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function getWorkpacks(params) {
  function autoParse(body) {
    return body.map((workpack) => {
      const itemCounts = {
        defects: 0,
        scheduled: 0,
        oop: 0,
        llp: 0,
      };

      workpack.workpack_items.forEach((item) => {
        if (item.mx_item.type === 'defect') {
          itemCounts.defects += 1;
        } else if (
          item.mx_item.type === 'scheduled_mx_item' &&
          (!item.mx_item.mx_type || item.mx_item.mx_type.includes('scheduled'))
        ) {
          itemCounts.scheduled += 1;
        } else if (item.mx_item.type === 'scheduled_mx_item' && item.mx_item.mx_type.includes('oop')) {
          itemCounts.oop += 1;
        } else if (item.mx_item.type === 'scheduled_mx_item' && item.mx_item.mx_type.includes('llp')) {
          itemCounts.llp += 1;
        }
      });

      return {
        ...workpack,
        itemCounts,
      };
    });
  }
  const url = `${config.get(ConfigKey.API)}/workpacks`;
  return rp(url, {
    method: 'GET',
    qs: params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
    transform: autoParse,
  }).catch((err) => {
    error(err);
  });
}

export async function getWorkpack(params) {
  function autoParse(body) {
    const itemCounts = {
      defects: 0,
      scheduled: 0,
      oop: 0,
      llp: 0,
    };
    body.workpack_items.forEach((item) => {
      if (item.mx_item.type === 'defect') {
        itemCounts.defects += 1;
      } else if (
        item.mx_item.type === 'scheduled_mx_item' &&
        (!item.mx_item.mx_type || item.mx_item.mx_type.includes('scheduled'))
      ) {
        itemCounts.scheduled += 1;
      } else if (item.mx_item.type === 'scheduled_mx_item' && item.mx_item.mx_type.includes('oop')) {
        itemCounts.oop += 1;
      } else if (item.mx_item.type === 'scheduled_mx_item' && item.mx_item.mx_type.includes('llp')) {
        itemCounts.llp += 1;
      }
    });

    return {
      ...body,
      itemCounts,
    };
  }
  const url = `${config.get(ConfigKey.API)}/workpacks/${params}`;
  return rp(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
    transform: autoParse,
  }).catch((err) => {
    error(err);
  });
}

export async function postWorkpack(params) {
  const url = `${config.get(ConfigKey.API)}/workpacks`;
  return rp(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    resolveWithFullResponse: true,
    body: params,
    json: true,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      error(err);
    });
}

export async function putWorkpack(params) {
  const url = `${config.get(ConfigKey.API)}/workpacks/${params.id}`;
  return rp(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    resolveWithFullResponse: true,
    body: params.body,
    json: true,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function getPublicWorkpackUrl(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/workpacks/${params}/public_url`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getDamageMaps(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/damage_maps`;
  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getDamageReports(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params.id}/damage_reports`;
  return rp({
    method: 'GET',
    uri: url,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMELs() {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/mels`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMEL(id, chapterNumber) {
  let qs = {};
  if (chapterNumber) {
    qs = {
      chapter_number: chapterNumber,
    };
  }
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/mels/${id}`,
    qs,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getMELItems(params) {
  const url = `${config.get(ConfigKey.API)}/aircraft/${params}/mel_items`;
  return fetch(url, {
    method: 'GET',
    qs: {
      chapter_number: params.chapterNumber,
    },
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
  })
    .then((r) => r.json())
    .catch((err) => {
      error(err);
    });
}

export async function getMELChapters(id) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/mels/${id}/chapters`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function postMEL(body) {
  return rp({
    method: 'POST',
    uri: `${config.get(ConfigKey.API)}/mels`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
    body,
  }).catch((err) => {
    error(err);
  });
}

export async function putMEL(body) {
  const bodyID = body.id;
  if (body.mel_items) {
    // eslint-disable-next-line no-param-reassign
    delete body.id;
    const newMelItems = body.mel_items
      .map((item) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!item._destroy && !item.attachments) {
          if (item.section_number !== undefined) {
            return item;
          }
          return null;
        }
        return item;
      })
      .filter((el) => {
        return el != null;
      });
    // eslint-disable-next-line no-param-reassign
    body.mel_items = newMelItems;
  }
  return rp({
    method: 'PUT',
    uri: `${config.get(ConfigKey.API)}/mels/${bodyID}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
    body,
  }).catch((err) => {
    error(err);
  });
}

export async function deleteMEL(id) {
  return rp({
    method: 'DELETE',
    uri: `${config.get(ConfigKey.API)}/mels/${id}`,
    resolveWithFullResponse: true,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}

export async function getWorkpackUrl(params) {
  return rp({
    method: 'GET',
    uri: `${config.get(ConfigKey.API)}/workpacks/${params}/public_url`,
    resolveWithFullResponse: false,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    json: true,
  }).catch((err) => {
    error(err);
  });
}
