import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import TodaysMeetings from '../../components/Meetings/TodaysMeetings';
import MeetingsTable from '../../components/Meetings/MeetingsTable';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import meetingsIcon from '../../assets/pageHeaderIcons/title-meetings.svg';
import { getMeetings, getMeetingRecurrence } from '../../services/apiNew';
import { MeetingActionTypes } from '../../models/meetings';
import styles from './Meetings.module.less';

class Meetings extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    saveAllMeetings: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    loading: true,
  };

  componentDidMount() {
    this.fetchMeetings();
  }

  compare = (a, b) => {
    if (a.start.dateTime < b.start.dateTime) {
      return -1;
    }
    if (a.start.dateTime > b.start.dateTime) {
      return 1;
    }
    return 0;
  };

  fetchRecurringMeetings = async (data) => {
    const { saveAllMeetings } = this.props;
    const meetingList = [];
    await Promise.all(
      data.map(async (meeting) => {
        if (meeting.recurringEventId) {
          const recurrence = await getMeetingRecurrence(meeting.recurringEventId);
          const reg = /=([^;]*)/.exec(recurrence.data.recurrence.toString().toLowerCase());
          return meetingList.push({ ...meeting, frequency: reg[1] });
        }
        return meetingList.push({ ...meeting });
      }),
    );
    this.setState({ loading: false });
    saveAllMeetings(meetingList.sort(this.compare));
  };

  fetchMeetings = async () => {
    this.setState({ loading: true });
    try {
      const response = await getMeetings();
      this.fetchRecurringMeetings(response.data.items);
    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <PageHeaderWrapper>
        <PageHeaderWrapper
          layout="modern"
          title={
            <span>
              <img src={meetingsIcon} alt="reports" className={styles.headerIcon} />{' '}
              {formatMessage({ id: 'title.meetings' })}
            </span>
          }
          content={formatMessage({ id: 'title.meetingsSub' })}
        >
          <Row className={styles.meetingRow}>
            <Col md={10} lg={9} xlg={6}>
              <Card className={`${cardStyles.pageCard} ${styles.meetingsCard}`}>
                <TodaysMeetings loading={loading} />
              </Card>
            </Col>

            <Col md={14} lg={15} xlg={18}>
              <Card className={`${cardStyles.pageCard} ${styles.scheduledCard}`}>
                <div>
                  <MeetingsTable
                    title={formatMessage({ id: 'title.scheduled' })}
                    calenderState="future"
                    loading={loading}
                  />
                </div>
              </Card>

              <Card className={`${cardStyles.pageCard} ${styles.pastCard}`}>
                <div>
                  <MeetingsTable title={formatMessage({ id: 'title.past' })} calenderState="past" loading={loading} />
                </div>
              </Card>
            </Col>
          </Row>
        </PageHeaderWrapper>
      </PageHeaderWrapper>
    );
  }
}

const MeetingsWithRedux = connect(
  ({ meetings }) => ({ meetingMap: meetings.meetingMap }),
  (dispatch) => ({
    saveAllMeetings: async (payload) =>
      dispatch({
        type: MeetingActionTypes.SAVE_ALL,
        payload,
      }),
  }),
)(Meetings);

export default injectIntl(MeetingsWithRedux);
