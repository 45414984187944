import { Spin } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { usePrevious } from '../../hooks/usePrevious';
import { ReliabilityReportsState } from '../../models/reliabilityReports';
import { ReliabilityReportsAircraftState } from '../../models/reliabilityReportsAircraft';
import {
  saveFleetAMP,
  saveFleetUtilisation,
  saveAircraftAMP,
  saveAircraftUtilisation,
  saveAircraftTypeAMP,
  saveAircraftTypeUtilisation,
} from '../../models/reliabilityReports/action';
import { ReportsFilter } from '../../pages/Camo/AircraftReliabilityReports';
import { queryReportAMP, queryReportUtilisation } from '../../services/apiNew';
import FiltersColumn from './FiltersColumn';
import GenericBarGraphDynamic from './Graphs/GenericBarGraphDynamic';
import GenericLineGraph from './Graphs/GenericLineGraph';
import TabHeader from './TabHeader';
import styles from './TabStyles.module.less';

const UtilisationTab = ({
  filter,
  selectedTimeScale,
  setSelectedTimeScale,
  handleFilterChange,
  activeTab,
  reliabilityReportsAircraft,
  reliabilityReports,
  saveFleetAMPLocal,
  saveFleetUtilisationLocal,
  saveAircraftAMPLocal,
  saveAircraftUtilisationLocal,
  saveAircraftTypeAMPLocal,
  saveAircraftTypeUtilisationLocal,
}): ReactElement => {
  const [AMPLoading, setAMPLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [UtilisationLoading, setUtilisationLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any>(new Map());
  const { registrations, types } = reliabilityReportsAircraft;
  const { fleet, aircraft, aircraftType, ttl } = reliabilityReports;
  const { formatMessage } = useIntl();
  const prevPropsFilter = usePrevious(filter);
  const { text, value } = selectedTimeScale;
  const prevPropsSelectedTimeScale = usePrevious(value);

  let fromDate = null;
  let toDate = null;
  if (text === 'Custom') {
    fromDate = moment()
      .add(value.from, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    toDate = moment()
      .add(value.to + 1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
  } else {
    fromDate = moment()
      .subtract(value, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    toDate = moment()
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
  }

  useEffect(() => {
    switch (filter.type) {
      case ReportsFilter.FLEET:
        setDataSource(fleet.dataMap);
        break;
      case ReportsFilter.AIRCRAFT:
        setDataSource(aircraft.dataMap.get(filter.value) || new Map());
        break;
      case ReportsFilter.AIRCRAFTTYPE:
        setDataSource(aircraftType.dataMap.get(filter.value) || new Map());
        break;
      default:
        setDataSource(fleet.dataMap);
    }
  }, [filter.type, filter.value, aircraft, aircraftType, fleet]);

  useEffect(() => {
    const getLastFetched = () => {
      switch (filter.type) {
        case ReportsFilter.FLEET:
          return fleet.lastFetched;
          break;
        case ReportsFilter.AIRCRAFT:
          return aircraft.lastFetched;
          break;
        case ReportsFilter.AIRCRAFTTYPE:
          return aircraftType.lastFetched;
          break;
        default:
          break;
      }
    };
    const saveToRedux = (data, type): any => {
      switch (filter.type) {
        case ReportsFilter.FLEET:
          if (type === 'amp') {
            saveFleetAMPLocal(data);
          } else {
            saveFleetUtilisationLocal(data);
          }
          break;
        case ReportsFilter.AIRCRAFT:
          if (type === 'amp') {
            saveAircraftAMPLocal({ data, type: filter.value });
          } else {
            saveAircraftUtilisationLocal({ data, type: filter.value });
          }
          break;
        case ReportsFilter.AIRCRAFTTYPE:
          if (type === 'amp') {
            saveAircraftTypeAMPLocal({ data, type: filter.value });
          } else {
            saveAircraftTypeUtilisationLocal({ data, type: filter.value });
          }
          break;
        default:
          break;
      }
    };

    const buildQueryString = (): string => {
      if (filter.type === ReportsFilter.AIRCRAFT) {
        return JSON.stringify({ registration: filter.value, from_date: fromDate, to_date: toDate });
      }
      if (filter.type === ReportsFilter.AIRCRAFTTYPE) {
        return JSON.stringify({ type: filter.value, from_date: fromDate, to_date: toDate });
      }
      return JSON.stringify({ from_date: fromDate, to_date: toDate });
    };

    if (
      activeTab === 'utilisation' &&
      !firstRun &&
      (Date.now() - getLastFetched() > ttl ||
        (prevPropsFilter && filter.value !== prevPropsFilter.value) ||
        (prevPropsSelectedTimeScale && selectedTimeScale.value !== prevPropsSelectedTimeScale))
    ) {
      const getAMPData = async (): Promise<any> => {
        setAMPLoading(true);
        const res = await queryReportAMP(buildQueryString());
        saveToRedux(res.data, 'amp');
        setAMPLoading(false);
      };
      const getUtilisationData = async (): Promise<any> => {
        setUtilisationLoading(true);
        const res = await queryReportUtilisation(buildQueryString());
        saveToRedux(res.data, 'utilisation');
        setUtilisationLoading(false);
      };
      getAMPData();
      getUtilisationData();
    }
    setFirstRun(false);
  }, [
    selectedTimeScale,
    fleet.lastFetched,
    ttl,
    fromDate,
    toDate,
    filter.type,
    filter.value,
    prevPropsFilter,
    activeTab,
    saveAircraftAMPLocal,
    saveAircraftTypeAMPLocal,
    saveAircraftTypeUtilisationLocal,
    saveAircraftUtilisationLocal,
    saveFleetAMPLocal,
    saveFleetUtilisationLocal,
    aircraft.lastFetched,
    aircraftType.lastFetched,
  ]);

  useEffect(() => {
    if (filter.type === ReportsFilter.AIRCRAFT && activeTab === 'utilisation') {
      const getAircraftAMPData = async (): Promise<any> => {
        setAMPLoading(true);
        const res = await queryReportAMP(JSON.stringify({ registration: filter.value }));
        saveAircraftAMPLocal(res.data);
        setAMPLoading(false);
      };
      const getAircraftUtilisationData = async (): Promise<any> => {
        setUtilisationLoading(true);
        const res = await queryReportUtilisation(JSON.stringify({ registration: filter.value }));
        saveAircraftUtilisationLocal(res.data);
        setUtilisationLoading(false);
      };
      getAircraftAMPData();
      getAircraftUtilisationData();
    }
  }, [activeTab, filter.type, filter.value, saveAircraftAMPLocal, saveAircraftUtilisationLocal]);

  useEffect(() => {
    if (filter.type === ReportsFilter.AIRCRAFTTYPE && activeTab === 'utilisation') {
      const getAircraftTypeAMPData = async (): Promise<any> => {
        setAMPLoading(true);
        const res = await queryReportAMP(JSON.stringify({ registration: filter.value }));
        saveAircraftTypeAMPLocal(res.data);
        setAMPLoading(false);
      };
      const getAircraftTypeUtilisationData = async (): Promise<any> => {
        setUtilisationLoading(true);
        const res = await queryReportUtilisation(JSON.stringify({ type: filter.value }));
        saveAircraftTypeUtilisationLocal(res.data);
        setUtilisationLoading(false);
      };
      getAircraftTypeAMPData();
      getAircraftTypeUtilisationData();
    }
  }, [activeTab, filter.type, filter.value, saveAircraftTypeAMPLocal, saveAircraftTypeUtilisationLocal]);

  return (
    <div className={styles.layoutDiv}>
      <div className={styles.graphsWrapper}>
        <TabHeader selectedTimeScale={selectedTimeScale} setSelectedTimeScale={setSelectedTimeScale} filter={filter} />
        <Spin spinning={UtilisationLoading}>
          <GenericLineGraph
            data={dataSource.get('utilisation')}
            title={formatMessage({ id: 'title.totals' })}
            selectedTimeScale={selectedTimeScale}
            referenceLine={[
              { value: 42, text: formatMessage({ id: 'text.cycles' }) },
              { value: 62.5, text: formatMessage({ id: 'text.hours' }) },
            ]}
            format="time"
            dataKeys={['cycles', 'flight_hours']}
            filter={filter}
          />
          <GenericLineGraph
            data={dataSource.get('utilisation')}
            title={formatMessage({ id: 'title.hoursPerCycle' })}
            selectedTimeScale={selectedTimeScale}
            referenceLine={[{ value: 1.5, text: formatMessage({ id: 'text.hours' }) }]}
            format="time"
            dataKeys={['duration']}
            filter={filter}
            button={false}
          />
        </Spin>
        <Spin spinning={AMPLoading}>
          <GenericLineGraph
            data={dataSource.get('AMP')}
            title={formatMessage({ id: 'title.operationalAvailability' })}
            selectedTimeScale={selectedTimeScale}
            referenceLine={[
              {
                value:
                  dataSource && dataSource.get('AMP') && dataSource.get('AMP')[0] && dataSource.get('AMP')[0].expected,
                text: formatMessage({ id: 'text.expected' }),
              },
            ]}
            format="time"
            dataKeys={['percentage']}
            filter={filter}
            // lineColourChange
            button={false}
            unit="%"
          />
        </Spin>
      </div>
      <div className={styles.filtersWrapper}>
        <FiltersColumn
          handleFilterChange={(value) => {
            handleFilterChange(value);
          }}
          registrations={registrations}
          types={types}
          filter={filter}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  reliabilityReports,
  reliabilityReportsAircraft,
}: {
  reliabilityReports: ReliabilityReportsState;
  reliabilityReportsAircraft: ReliabilityReportsAircraftState;
}): {
  reliabilityReports: {};
  reliabilityReportsAircraft: {};
} => {
  return {
    reliabilityReports,
    reliabilityReportsAircraft,
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  saveFleetAMPLocal: (payload: any) => Promise<void>;
  saveFleetUtilisationLocal: (payload: any) => Promise<void>;
  saveAircraftAMPLocal: (payload: any) => Promise<void>;
  saveAircraftUtilisationLocal: (payload: any) => Promise<void>;
  saveAircraftTypeAMPLocal: (payload: any) => Promise<void>;
  saveAircraftTypeUtilisationLocal: (payload: any) => Promise<void>;
} => ({
  saveFleetAMPLocal: (payload): Promise<void> => {
    return dispatch(saveFleetAMP({ payload }));
  },
  saveFleetUtilisationLocal: (payload): Promise<void> => {
    return dispatch(saveFleetUtilisation({ payload }));
  },
  saveAircraftAMPLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftAMP({ payload }));
  },
  saveAircraftUtilisationLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftUtilisation({ payload }));
  },
  saveAircraftTypeAMPLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeAMP({ payload }));
  },
  saveAircraftTypeUtilisationLocal: (payload): Promise<void> => {
    return dispatch(saveAircraftTypeUtilisation({ payload }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UtilisationTab);
