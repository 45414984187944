import React, { ReactElement } from 'react';
import { ConversationDataType } from '../../pages/Camo/HelpDesk';
import chevron from '../../assets/icon-chevron-blue.svg';
import infoIcon from './assets/information-icon.svg';
import styles from './AlertCard.module.less';

const isMobile = window.innerWidth < 769;

const AlertCard = ({ title, refNum, conversation, onReviewClick }: AlertCardProps): ReactElement => {
  const sortedConversations = conversation.sort((a: ConversationDataType, b: ConversationDataType) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });
  const lastReply: ConversationDataType = sortedConversations[0];

  const mobileLayout = (
    <div className={styles.alertCardWrapperMobile}>
      <div className={styles.cardLayout}>
        <div className={styles.infoWrapperMobile} />
        <div className={styles.textWrapperMobile}>
          <div className={styles.boldText}>{title}</div>
          {lastReply && (
            <div className={styles.textWrapperInner}>
              Reply:&nbsp;<span className={styles.boldTextInner}>{lastReply.author.name}</span>
            </div>
          )}
        </div>
      </div>
      <button type="button" className={styles.reviewButtonMobile} onClick={(): void => onReviewClick()}>
        Review
      </button>
    </div>
  );

  const desktopLayout = (
    <div className={styles.alertCardWrapper}>
      <div className={styles.cardLayout}>
        <div className={styles.infoWrapper}>
          <img src={infoIcon} alt="info" className={styles.infoIcon} />
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.boldText}>{title}</div>
          <div className={styles.spacing}>{refNum}</div>
          {lastReply && (
            <div className={styles.textWrapper}>
              New reply from:&nbsp;<span className={styles.boldText}>{lastReply.author.name}</span>
            </div>
          )}
        </div>
      </div>
      <button type="button" className={styles.reviewButton} onClick={(): void => onReviewClick()}>
        Go to ticket <img src={chevron} alt="chevron" className={styles.chevron} />
      </button>
    </div>
  );

  return <>{isMobile ? mobileLayout : desktopLayout}</>;
};

type AlertCardProps = {
  onReviewClick: Function;
  title: string;
  refNum: string;
  conversation: ConversationDataType[];
};

export default AlertCard;
