/* eslint-disable react/jsx-props-no-spreading */
import { Breadcrumb, Tabs } from 'antd';
import classNames from 'classnames';
import { enquireScreen, unenquireScreen } from 'enquire-js';
import pathMatch from 'path-match';
import { pathToRegexp } from 'path-to-regexp';
import PropTypes from 'prop-types';
import React, { createElement, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RightContent from '../GlobalHeader/RightContent';
import styles from './index.module.less';

const { TabPane } = Tabs;
export function getBreadcrumb(breadcrumbNameMap, url) {
  let breadcrumb = breadcrumbNameMap[url];
  if (!breadcrumb) {
    Object.keys(breadcrumbNameMap).forEach((item) => {
      if (pathToRegexp(item).test(url)) {
        breadcrumb = breadcrumbNameMap[item];
      }
    });
  }
  return breadcrumb || {};
}

// /userinfo/2144/id => ['/userinfo','/useinfo/2144,'/userindo/2144/id']
export function urlToList(url) {
  const urllist = url.split('/').filter((i) => i);
  return urllist.map((urlItem, index) => {
    return `/${urllist.slice(0, index + 1).join('/')}`;
  });
}

class PageHeader extends PureComponent {
  static propTypes = {
    routes: PropTypes.array,
    params: PropTypes.object,
    location: PropTypes.object.isRequired,
    breadcrumbNameMap: PropTypes.object,
    onTabChange: PropTypes.func,
    breadcrumbList: PropTypes.object,
    breadcrumbSeparator: PropTypes.object,
    linkElement: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    logo: PropTypes.object,
    action: PropTypes.object,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    extraContent: PropTypes.string,
    tabList: PropTypes.array,
    className: PropTypes.string,
    tabActiveKey: PropTypes.string,
    tabBarExtraContent: PropTypes.string,
    loading: PropTypes.bool,
  };

  static contextTypes = {
    routes: PropTypes.array,
    params: PropTypes.object,
    location: PropTypes.object,
    breadcrumbNameMap: PropTypes.object,
  };

  static defaultProps = {
    title: undefined,
    action: undefined,
    breadcrumbList: undefined,
    breadcrumbNameMap: undefined,
    breadcrumbSeparator: undefined,
    className: undefined,
    content: undefined,
    extraContent: undefined,
    loading: false,
    logo: undefined,
    onTabChange: undefined,
    params: undefined,
    routes: undefined,
    tabActiveKey: undefined,
    tabBarExtraContent: undefined,
    tabList: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.enquireHandler = enquireScreen((mobile) => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        });
      }
    });
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler);
  }

  onChange = (key) => {
    const { onTabChange } = this.props;
    if (onTabChange) {
      onTabChange(key);
    }
  };

  getBreadcrumbProps = () => {
    const { routes, location, breadcrumbNameMap, params } = this.props;
    return {
      routes: routes || this.context.routes,
      params: params || this.context.params,
      routerLocation: location || this.context.location,
      breadcrumbNameMap: breadcrumbNameMap || this.context.breadcrumbNameMap,
    };
  };

  // Generated according to props
  conversionFromProps = () => {
    const { breadcrumbList, breadcrumbSeparator, linkElement = 'a' } = this.props;
    return (
      <Breadcrumb className={styles.breadcrumb} separator={breadcrumbSeparator}>
        {breadcrumbList.map((item) => (
          <Breadcrumb.Item key={item.title}>
            {item.href
              ? createElement(
                  linkElement,
                  {
                    [linkElement === 'a' ? 'href' : 'to']: item.href,
                  },
                  item.title,
                )
              : item.title}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  conversionFromLocation = (routerLocation, breadcrumbNameMap) => {
    const { breadcrumbSeparator, linkElement = 'a' } = this.props;
    // Convert the url to an array
    let pathSnippets = [];
    if (routerLocation) {
      pathSnippets = urlToList(routerLocation.pathname);
    }
    // Loop data mosaic routing
    const extraBreadcrumbItems = pathSnippets.map((url, index) => {
      const currentBreadcrumb = getBreadcrumb(breadcrumbNameMap, url);
      const isLinkable =
        index !== pathSnippets.length - 1 && (currentBreadcrumb.component || currentBreadcrumb.breadcrumbLink);
      if (currentBreadcrumb.usePathParamInBreadcrumb) {
        const match = pathMatch()(currentBreadcrumb.path);
        const result = match(routerLocation.pathname);
        currentBreadcrumb.name = result[currentBreadcrumb.usePathParamInBreadcrumb];
      }
      return currentBreadcrumb.name && !currentBreadcrumb.hideInBreadcrumb ? (
        <Breadcrumb.Item key={url}>
          {createElement(
            isLinkable ? linkElement : 'span',
            { [linkElement === 'a' ? 'href' : 'to']: url },
            currentBreadcrumb.name,
          )}
        </Breadcrumb.Item>
      ) : null;
    });
    // Add home breadcrumbs to your head
    extraBreadcrumbItems.unshift(
      <Breadcrumb.Item key="home">
        {createElement(
          linkElement,
          {
            [linkElement === 'a' ? 'href' : 'to']: '/',
          },
          'Home',
        )}
      </Breadcrumb.Item>,
    );
    return (
      <Breadcrumb className={styles.breadcrumb} separator={breadcrumbSeparator}>
        {extraBreadcrumbItems}
      </Breadcrumb>
    );
  };

  /**
   * Convert parameters into breadcrumbs
   */
  conversionBreadcrumbList = () => {
    const { breadcrumbList, breadcrumbSeparator } = this.props;
    const { routes, params, routerLocation, breadcrumbNameMap } = this.getBreadcrumbProps();
    if (breadcrumbList && breadcrumbList.length) {
      return this.conversionFromProps();
    }
    // routes params
    // If pass routes and params attributes
    if (routes && params) {
      return (
        <Breadcrumb
          className={styles.breadcrumb}
          routes={routes.filter((route) => route.breadcrumbName)}
          params={params}
          itemRender={this.itemRender}
          separator={breadcrumbSeparator}
        />
      );
    }
    // location
    // Generate breadcrumbs based on location
    if (document.location && document.location.pathname) {
      return this.conversionFromLocation(routerLocation, breadcrumbNameMap);
    }
    return null;
  };

  // Breadcrumb
  // Render the Breadcrumb child node
  itemRender = (route, params, routes, paths) => {
    const { linkElement = 'a' } = this.props;
    const last = routes.indexOf(route) === routes.length - 1;
    return last || !route.component ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      createElement(
        linkElement,
        {
          href: paths.join('/') || '/',
          to: paths.join('/') || '/',
        },
        route.breadcrumbName,
      )
    );
  };

  render() {
    const {
      title,
      logo,
      action,
      content,
      extraContent,
      tabList,
      className,
      tabActiveKey,
      tabBarExtraContent,
      loading,
      match,
    } = this.props;
    const selectedAircraft = match.params.id;
    const { isMobile } = this.state;
    const clsString = classNames(styles.pageHeader, className);

    let tabDefaultValue;
    if (tabActiveKey !== undefined && tabList) {
      tabDefaultValue = tabList.find((item) => item.default) || tabList[0];
    }
    // const breadcrumb = selectedAircraft || loading.models.global ? null : this.conversionBreadcrumbList();
    const activeKeyProps = {
      defaultActiveKey: tabDefaultValue && tabDefaultValue.key,
    };
    if (tabActiveKey !== undefined) {
      activeKeyProps.activeKey = tabActiveKey;
    }

    return (
      <>
        {!selectedAircraft && !isMobile && !loading?.models?.global ? <RightContent /> : null}
        <div className={clsString}>
          {/* {breadcrumb} */}

          <div className={styles.detail}>
            {logo && <div className={styles.logo}>{logo}</div>}
            <div className={styles.main}>
              <div className={`${styles.row}` && !title ? `${styles.noTitle}` : ''}>
                {title && (
                  <h1 className={styles.title} data-test="pageTitle" data-testid="PageHeader--PageTitle">
                    {title}
                  </h1>
                )}
                {action && <div className={styles.action}>{action}</div>}
              </div>
              <div className={styles.row}>
                {content && <div className={styles.content}>{content}</div>}
                {extraContent && <div className={styles.extraContent}>{extraContent}</div>}
              </div>
            </div>
          </div>
          {tabList && tabList.length ? (
            <Tabs
              className={styles.tabs}
              {...activeKeyProps}
              onChange={this.onChange}
              tabBarExtraContent={tabBarExtraContent}
            >
              {tabList.map((item) => (
                <TabPane tab={item.tab} key={item.key} />
              ))}
            </Tabs>
          ) : null}
        </div>
      </>
    );
  }
}

const withReduxHOC = connect(({ loading }) => ({
  loading,
}))(PageHeader);

export default withRouter(withReduxHOC);
