import React, { ReactElement, useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import chevron from '../../assets/right-chevron.svg';
import Ticket from './Ticket';
import styles from './TicketList.module.less';

const isMobile = window.innerWidth < 769;

const TicketList = ({ ticketData, onTicketChange, activeTicketId }): ReactElement => {
  const [visibleTickets, setVisibleTickets] = useState('open');
  const [closedTickets, setClosedTickets] = useState(null);
  const [openTickets, setOpenTickets] = useState(null);

  useEffect(() => {
    let sortedTickets = [];
    let sortedTicketsPast = [];
    const closedTicketsArray = [];
    const openTicketsArray = [];

    ticketData.forEach((ticket) => {
      return ticket.closed ? closedTicketsArray.push(ticket) : openTicketsArray.push(ticket);
    });

    if (openTicketsArray.length > 1) {
      sortedTickets = openTicketsArray.sort((a: TicketDataType, b: TicketDataType) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      });
    } else {
      sortedTickets = openTicketsArray;
    }

    if (closedTicketsArray.length > 1) {
      sortedTicketsPast = closedTicketsArray.sort((a: TicketDataType, b: TicketDataType) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      });
    } else {
      sortedTicketsPast = closedTicketsArray;
    }

    if (openTickets === null) {
      setOpenTickets(sortedTickets);
    }
    if (closedTickets === null) {
      setClosedTickets(sortedTicketsPast);
    }
  }, [ticketData, closedTickets, openTickets]);

  const handleTicketChange = (): void => {
    if (visibleTickets === 'open') {
      setVisibleTickets('past');
    } else {
      setVisibleTickets('open');
    }
  };

  return (
    <Col className={styles.viewCol} sm={24} md={5} id="ticket-list">
      {isMobile ? (
        <div className={styles.listBody}>
          <Row>
            <Col span={12}>
              <button type="button" onClick={(): void => handleTicketChange()} className={styles.listHeader}>
                <span>
                  OPEN TICKETS <span className={styles.ticketCount}>({openTickets && openTickets.length})</span>
                </span>
                <img src={chevron} alt="chevron" className={visibleTickets === 'open' ? styles.chevron : ''} />
              </button>
            </Col>
            <Col span={12}>
              <button type="button" onClick={(): void => handleTicketChange()} className={styles.pastTickets}>
                <span>
                  PAST TICKETS <span className={styles.ticketCount}>({closedTickets && closedTickets.length})</span>
                </span>
                <img src={chevron} alt="chevron" className={visibleTickets === 'past' ? styles.chevron : ''} />
              </button>
            </Col>
          </Row>
          {visibleTickets === 'open' && openTickets
            ? openTickets.map((ticket) => (
                <Ticket
                  key={ticket.ticketNumber}
                  ticket={ticket}
                  onTicketChange={onTicketChange}
                  activeTicketId={activeTicketId}
                />
              ))
            : null}
          {visibleTickets === 'past' && closedTickets
            ? closedTickets.map((ticket) => (
                <Ticket
                  key={ticket.ticketNumber}
                  ticket={ticket}
                  onTicketChange={onTicketChange}
                  activeTicketId={activeTicketId}
                />
              ))
            : null}
        </div>
      ) : (
        <div className={styles.listBody}>
          <button type="button" onClick={(): void => handleTicketChange()} className={styles.listHeader}>
            <span>
              OPEN TICKETS <span className={styles.ticketCount}>({openTickets && openTickets.length})</span>
            </span>
            <img src={chevron} alt="chevron" className={visibleTickets === 'open' ? styles.chevron : ''} />
          </button>
          {visibleTickets === 'open' && openTickets
            ? openTickets.map((ticket) => (
                <Ticket
                  key={ticket.ticketNumber}
                  ticket={ticket}
                  onTicketChange={onTicketChange}
                  activeTicketId={activeTicketId}
                />
              ))
            : null}
          <button type="button" onClick={(): void => handleTicketChange()} className={styles.pastTickets}>
            <span>
              PAST TICKETS <span className={styles.ticketCount}>({closedTickets && closedTickets.length})</span>
            </span>
            <img src={chevron} alt="chevron" className={visibleTickets === 'past' ? styles.chevron : ''} />
          </button>
          {visibleTickets === 'past' && closedTickets
            ? closedTickets.map((ticket) => (
                <Ticket
                  key={ticket.ticketNumber}
                  ticket={ticket}
                  onTicketChange={onTicketChange}
                  activeTicketId={activeTicketId}
                />
              ))
            : null}
        </div>
      )}
    </Col>
  );
};

export default TicketList;

export interface TicketDataType {
  status: string;
  title: string;
  date: string;
  refNum: string;
  type: string;
  content: string;
  id: string;
  conversation: Array<any>;
}
