import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import { queryAircraftsWithUtilisation } from '../../services/apiNew';
import { AircraftActionTypes } from '../../models/aircraft';
import FleetStatusProgress from '../../components/FleetStatus/FleetStatusProgress';
import DocumentAssessmentTable from '../../components/FleetPerformance/DocumentAssessmentTable';
import fleetStatusIcon from '../../assets/pageHeaderIcons/title-fleet-status.svg';
import FlairAirport from '../../assets/flair-airport.png';
import GenericAirport from '../../assets/airport-commercial-default.svg';
import EmbraerAirport from '../../assets/embraer-airport.svg';
import FleetTable from '../../components/FleetStatus/FleetTable';
import styles from './FleetStatus.module.less';
import LongTermMxStatus from './LongTermMxStatus';

class FleetStatus extends PureComponent {
  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    intl: PropTypes.object.isRequired,
    saveAllAircraft: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      techlogVisible: false,
      searchVisible: false,
      searchValue: '',
    };
  }

  componentDidMount() {
    this.getAllAircraft();
  }

  setSearchValue = (value) => {
    this.setState({ searchValue: value });
  };

  getAllAircraft = async () => {
    const { saveAllAircraft } = this.props;
    const res = await queryAircraftsWithUtilisation();
    saveAllAircraft(res.data.data.allAircraft);
  };

  showSearchInput = () => {
    const { searchVisible, searchValue } = this.state;
    if (searchVisible && searchValue) {
      // input is open and has some filtered value - first remove value, than close
      this.setSearchValue(null);
    }
    this.setState({
      searchVisible: !searchVisible,
    });
  };

  getAirportImage = (permissions) => {
    let logo = GenericAirport;
    if (permissions[0]) {
      switch (permissions[0].organisation) {
        case 'Flair Airlines':
          logo = FlairAirport;
          break;
        case 'Trustflight Demo':
          logo = GenericAirport;
          break;
        default:
          logo = GenericAirport;
          break;
      }
    }
    return logo;
  };

  render() {
    const { techlogVisible } = this.state;
    const {
      intl: { formatMessage },
      aircraftMap,
      permissions,
    } = this.props;
    // Red/Default Stroke Color
    const defaultStrokeColor = {
      '0%': '#ff7474',
      '100%': '#ff4040',
    };

    // Warning Stroke Color
    const warningStrokeColor = {
      '0%': '#fad288',
      '100%': '#f2a650',
    };

    // Green stroke Color
    const greenStrokeColor = {
      '0%': '#7bc88d',
      '100%': '#4aa91c',
    };

    const fleetAircraftStatus = [
      {
        title: 'Zero Deferrals',
        count: Array.from(aircraftMap.values()).filter((a) => a && a.status === 'Serviceable').length,
        strokeColor: greenStrokeColor,
      },
      {
        title: 'Overdue',
        count: Array.from(aircraftMap.values()).filter((a) => a && a.status === 'Overdue').length,
        strokeColor: warningStrokeColor,
      },
      {
        title: 'MEL/CDL',
        count: Array.from(aircraftMap.values()).filter((a) => a && a.status === 'MEL/CDL').length,
        strokeColor: warningStrokeColor,
      },
      {
        title: 'Grounded',
        count: Array.from(aircraftMap.values()).filter((a) => a && a.status === 'AOG').length,
        strokeColor: defaultStrokeColor,
      },
    ];

    return (
      <PageHeaderWrapper>
        <PageHeaderWrapper
          layout="modern"
          title={
            <span>
              <img src={fleetStatusIcon} alt="reports" className={styles.headerIcon} />{' '}
              {formatMessage({ id: 'text.fleetStatus' })}
            </span>
          }
          content={formatMessage({ id: 'text.fleetStatusHeader' })}
        />
        <Row gutter={20} className={styles.statusOverviewRow}>
          <Col>
            <Card className={`${cardStyles.overviewCard} ${cardStyles.pageCard}`} bordered={false}>
              <Col span={16}>
                <div className={styles.fleetHeader}>Your Fleet</div>
                <div className={styles.aircraftCount}>{`${aircraftMap.size} Aircraft`}</div>
                <Row className={styles.summaryContent}>
                  {fleetAircraftStatus.map((status) => (
                    <FleetStatusProgress
                      key={status.title}
                      totalItems={aircraftMap.size}
                      itemsCount={status.count}
                      title={status.title}
                      strokeColor={status.strokeColor}
                    />
                  ))}
                </Row>
              </Col>
              <Col span={8} className={styles.dhlLogoContainer}>
                <img src={this.getAirportImage(permissions)} alt="logo" className={styles.airportImage} />
              </Col>
            </Card>
          </Col>
        </Row>
        <Row className={styles.fleetTableRow}>
          <Col>
            <FleetTable />
          </Col>
        </Row>
        <Row>
          <Col>
            <LongTermMxStatus aircraft={Array.from(aircraftMap.values())} />
          </Col>
        </Row>
        {techlogVisible && (
          <Row>
            <Col>
              <DocumentAssessmentTable />
            </Col>
          </Row>
        )}
      </PageHeaderWrapper>
    );
  }
}

const fleetStatusWithRedux = connect(
  ({ aircraft, userSettings }) => ({
    permissions: userSettings.details.service_desk_permissions,
    aircraftMap: aircraft.aircraftMap,
    lastFetched: aircraft.lastFetched,
  }),
  (dispatch) => ({
    saveAllAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.SAVE_ALL,
        payload,
      }),
  }),
)(FleetStatus);
export default withRouter(injectIntl(fleetStatusWithRedux));
