import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Progress } from 'antd';
import serviceableIcon from '../../assets/camo-serviceable.svg';
import groundedIcon from '../../assets/camo-grounded.svg';
import heavyMaintenance from '../../assets/camo-maintenance.svg';
import styles from './FleetStatusProgress.module.less';

const FleetStatusProgress = ({ totalItems, itemsCount, title, strokeColor }) => {
  const getPercentage = () => {
    return totalItems ? (itemsCount / totalItems) * 100 : 0;
  };
  let icon = null;
  switch (title) {
    case 'Zero Deferrals':
      icon = serviceableIcon;
      break;
    case 'Grounded':
      icon = groundedIcon;
      break;
    case 'MEL/CDL':
      icon = heavyMaintenance;
      break;
    case 'Overdue':
      icon = groundedIcon;
      break;
    default:
      break;
  }
  return (
    <>
      <Col className={styles.progressWrapper} data-test="progressWrapper">
        <Row
          gutter={8}
          className={`${styles.progressContent} ${itemsCount === 0 ? styles.progressWrapperDisabled : undefined}`}
        >
          <Col>
            <svg className="progressStyle" width={0} height={0}>
              <linearGradient id="grey-gradient-0" x1="100%" y1="0%" x2="0%" y2="0%">
                <stop offset="0%" stopColor="#e6e8ed" />
                <stop offset="100%" stopColor="#f4f5f7" />
              </linearGradient>
              <filter id="shadow">
                <feDropShadow dx="1" dy="0" stdDeviation="2" floodColor="#cbcbcb" floodOpacity=".1" />
              </filter>
            </svg>
            <Progress
              type="circle"
              percent={getPercentage()}
              width={90}
              strokeWidth={5}
              strokeColor={strokeColor}
              strokeLinecap="square"
              className={styles.progressCircle}
              data-test="progressCircle"
              format={() => <img src={icon} alt="serviceableIcon" width="54" height="41" />}
            />
          </Col>
          <Col className={styles.progressDescription}>
            <div className={styles.title} data-test="title">
              {title || '-'}
            </div>
            <div className={styles.count} data-test="count">
              {itemsCount || 0}
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

FleetStatusProgress.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  strokeColor: PropTypes.object.isRequired,
};

export default FleetStatusProgress;
