import React from 'react';
import { useIntl } from 'react-intl';
import logisticsIcon from '../../assets/mainNav/graphic-page-parts.svg';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import NewPartForm from '../../components/LogisticComponents/NewPartForm';
import NewVendorForm from '../../components/LogisticComponents/NewVendorForm';
import styles from './Logistics.module.less';

const Logistics: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <PageHeaderWrapper
      title={
        <span>
          <img src={logisticsIcon} alt="reports" /> {formatMessage({ id: 'text.logistics' })}
        </span>
      }
    >
      <div className={styles.formWrapper}>
        <NewPartForm />
        <NewVendorForm />
      </div>
    </PageHeaderWrapper>
  );
};

export default Logistics;
