import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import RedArrow from '../../assets/RedArrow.svg';
import GreenArrow from '../../assets/GreenArrow.svg';
import tableStyles from '../../styles/components/CamoTable/CamoTable.module.less';

const CostAnalysisTable = ({ data }) => {
  const columns = [
    {
      title: 'Item',
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: (a, b) => moment(a.date, 'DD-MM-YYYY') - moment(b.date, 'DD-MM-YYYY'),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Man Hours',
      dataIndex: 'manHours',
      sorter: (a, b) => a.manHours - b.manHours,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Est. Cost',
      dataIndex: 'estCost',
      sorter: (a, b) => a.estCost - b.estCost,
      sortDirections: ['descend', 'ascend'],
      render(val) {
        return `£${val}`;
      },
    },
    {
      title: 'Actual Cost',
      dataIndex: 'actualCost',
      sorter: (a, b) => a.actualCost - b.actualCost,
      sortDirections: ['descend', 'ascend'],
      render(val, record) {
        const percentage = Math.round((((val / record.estCost) * 100) / 100) * 100) - 100;
        let displayingPercentage = null;
        if (percentage > 0)
          displayingPercentage = (
            <span className={tableStyles.percentage}>
              {' '}
              <img src={RedArrow} alt="arrow" className={tableStyles.arrowFlip} /> {`${percentage}%`}
            </span>
          );
        if (percentage < 0)
          displayingPercentage = (
            <span className={tableStyles.greenPercentage}>
              {' '}
              <img src={GreenArrow} alt="arrow" className={tableStyles.arrowFlip} /> {`${percentage}%`}
            </span>
          );
        return (
          <div className={tableStyles.actualCost}>
            {`£${val}`}
            {displayingPercentage}
          </div>
        );
      },
    },
  ];
  return (
    <div className={tableStyles.tableContainer}>
      <Table
        columns={columns}
        dataSource={data}
        bordered={false}
        rowClassName={tableStyles.tableRow}
        pagination={false}
        scroll={{ x: 915 }}
      />
    </div>
  );
};

CostAnalysisTable.propTypes = {
  data: PropTypes.array,
};

CostAnalysisTable.defaultProps = {
  data: [],
};

export default CostAnalysisTable;
