import React, { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AircraftActionTypes } from '../../models/aircraft';
import { getAircraftTypes } from '../../services/api';
import { queryAircrafts } from '../../services/apiNew';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import ReportsCard from '../../components/ReliabilityReports/ReportsCard';
import SearchBox from '../../components/ReliabilityReports/SearchBox';
import reportsIcon from '../../assets/pageHeaderIcons/reliability-reporting.svg';
import styles from './ReliabilityReports.module.less';

const ReliabilityReports = ({ saveAllAircraft, aircraftMap }): ReactElement => {
  const { formatMessage } = useIntl();
  const [searchValue, setSearchValue] = useState('');
  const [aircraftRegs, setAircraftRegs] = useState([]);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState('');

  useEffect(() => {
    const getAllAircraft = async (): Promise<void> => {
      const allAircraft = await queryAircrafts();
      saveAllAircraft(allAircraft);
    };
    getAllAircraft();
  }, [saveAllAircraft]);

  useEffect(() => {
    if (searchValue.length > 0) {
      getAircraftTypes(searchValue).then((response) => {
        setAircraftTypes(response);
      });
      const aircraftRegArray = Array.from(aircraftMap.keys()).filter((reg: string) =>
        reg.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setAircraftRegs(aircraftRegArray);
    } else {
      setAircraftTypes([]);
      setAircraftRegs([]);
    }
  }, [aircraftMap, searchValue]);

  return (
    <PageHeaderWrapper
      title={
        <span>
          <img src={reportsIcon} alt="reports" className={styles.helpdeskIcon} />{' '}
          {formatMessage({ id: 'text.reliabilityReports' })}
        </span>
      }
    >
      {/* Comment out as will be coming back in, in the future
      <div className={styles.titleWrapper}>
        <h1 className={styles.searchHeading}>{formatMessage({ id: 'title.createYourmaintenanceManagementReport' })}</h1>
      </div>
      <SearchBox
        selectedAircraft={selectedAircraft}
        setSelectedAircraft={setSelectedAircraft}
        onInputChange={(value): void => setSearchValue(value)}
        aircraftTypes={aircraftTypes}
        aircraftRegs={aircraftRegs}
        searchInput={searchValue}
      /> */}
      <ReportsCard />
    </PageHeaderWrapper>
  );
};

const mapStateToProps = ({
  aircraft,
}): { aircraftMap: Map<string, { id: string; registration: string; lastFetched: number }> } => {
  return {
    aircraftMap: aircraft.aircraftMap,
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  saveAllAircraft: (payload) => Promise<void>;
} => ({
  saveAllAircraft: async (payload): Promise<void> =>
    dispatch({
      type: AircraftActionTypes.SAVE_ALL,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReliabilityReports);

export interface AircraftTypeType {
  manufacturer: string;
  model: string;
  designator: string;
}
