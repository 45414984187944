import React, { ReactElement, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ReliabilityReportsAircraftState } from '../../models/reliabilityReportsAircraft';
import UtilisationTab from '../../components/ReliabilityReports/UtilisationTab';
import BrakeTab from '../../components/ReliabilityReports/BrakeTab';
import EngineTab from '../../components/ReliabilityReports/EngineTab';
import PerformanceTab from '../../components/ReliabilityReports/PerformanceTab';
import { queryAircraftFilters } from '../../services/apiNew';
import { SaveAircraftType, SaveAircraftRegistrations } from '../../models/reliabilityReportsAircraft/action';

export enum ReportsFilter {
  FLEET,
  AIRCRAFTTYPE,
  AIRCRAFT,
}

const { TabPane } = Tabs;
const AircraftReliabilityReports = ({ saveAircraftTypes, saveAircraftRegistrations }): ReactElement => {
  const { formatMessage } = useIntl();
  const [selectedTimeScale, setSelectedTimeScale] = useState({ text: '6 months', value: 6 });
  const [filter, setFilter] = useState({ value: 'Fleet', type: ReportsFilter.FLEET });
  const [activeTab, setActiveTab] = useState('utilisation');

  useEffect(() => {
    const getAircraftFilters = async (): Promise<any> => {
      const res = await queryAircraftFilters();
      saveAircraftTypes(res.data.types);
      saveAircraftRegistrations(res.data.registrations);
    };
    getAircraftFilters();
  }, [saveAircraftRegistrations, saveAircraftTypes]);

  const handleFilterChange = (value): void => {
    let filterValue;
    if (value.value.registration) {
      filterValue = { value: value.value.registration, type: value.type };
    } else if (value.value.type) {
      filterValue = { value: value.value.type, type: value.type };
    } else {
      filterValue = value;
    }
    setFilter(filterValue);
  };

  const handleTabChange = (key: string): void => {
    setActiveTab(key);
  };
  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane key="utilisation" tab={formatMessage({ id: 'text.utilisation' })}>
        <UtilisationTab
          filter={filter}
          selectedTimeScale={selectedTimeScale}
          setSelectedTimeScale={setSelectedTimeScale}
          handleFilterChange={handleFilterChange}
          activeTab={activeTab}
        />
      </TabPane>
      <TabPane key="performance" tab={formatMessage({ id: 'text.performance' })}>
        <PerformanceTab
          filter={filter}
          selectedTimeScale={selectedTimeScale}
          setSelectedTimeScale={setSelectedTimeScale}
          handleFilterChange={handleFilterChange}
          activeTab={activeTab}
        />
      </TabPane>
      <TabPane key="engines" tab={formatMessage({ id: 'text.engines' })}>
        <EngineTab
          filter={filter}
          selectedTimeScale={selectedTimeScale}
          setSelectedTimeScale={setSelectedTimeScale}
          handleFilterChange={handleFilterChange}
          activeTab={activeTab}
        />
      </TabPane>
      {/* <TabPane key="brakes" tab={formatMessage({ id: 'text.brakesAndTyres' })}>
        <BrakeTab
          filter={filter}
          selectedTimeScale={selectedTimeScale}
          setSelectedTimeScale={setSelectedTimeScale}
          handleFilterChange={handleFilterChange}
          activeTab={activeTab}
        />
      </TabPane> */}
    </Tabs>
  );
};

const mapStateToProps = ({
  reliabilityReportsAircraft,
}: {
  reliabilityReportsAircraft: ReliabilityReportsAircraftState;
}): {
  reliabilityReportsAircraft: {};
} => {
  return {
    reliabilityReportsAircraft,
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  saveAircraftTypes: (payload: any) => Promise<void>;
  saveAircraftRegistrations: (payload: any) => Promise<void>;
} => ({
  saveAircraftTypes: (payload): Promise<void> => {
    return dispatch(SaveAircraftType({ payload }));
  },
  saveAircraftRegistrations: (payload): Promise<void> => {
    return dispatch(SaveAircraftRegistrations({ payload }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AircraftReliabilityReports);
