/* eslint-disable max-len */
export default {
  'navBar.lang': 'Languages',
  'lang.simplified-chinese': '简体中文',
  'lang.traditional-chinese': '繁体中文',
  'lang.english': 'English',
  'lang.portuguese': 'Portuguese',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'validation.email.required': 'Please enter your email!',
  'validation.email.wrong-format': 'The email address is in the wrong format!',
  'validation.password.required': 'Please enter your password!',
  'validation.password.twice': 'The passwords entered twice do not match!',
  'validation.password.strength.msg':
    "Please enter at least 6 characters and don't use passwords that are easy to guess.",
  'validation.password.strength.strong': 'Strength: strong',
  'validation.password.strength.medium': 'Strength: medium',
  'validation.password.strength.short': 'Strength: too short',
  'validation.confirm-password.required': 'Please confirm your password!',
  'validation.phone-number.required': 'Please enter your phone number!',
  'validation.phone-number.wrong-format': 'Malformed phone number!',
  'validation.verification-code.required': 'Please enter the verification code!',
  'validation.title.required': 'Please enter a title',
  'validation.date.required': 'Please select the start and end date',
  'validation.goal.required': 'Please enter a description of the goal',
  'validation.standard.required': 'Please enter a metric',
  'form.optional': ' (optional) ',
  'form.save': 'Save',
  'form.email.placeholder': 'Email',
  'form.password.placeholder': 'Password',
  'form.confirm-password.placeholder': 'Confirm password',
  'form.phone-number.placeholder': 'Phone number',
  'form.verification-code.placeholder': 'Verification code',
  'form.title.label': 'Title',
  'form.title.placeholder': 'Give the target a name',
  'form.date.label': 'Start and end date',
  'form.date.placeholder.start': 'Start date',
  'form.date.placeholder.end': 'End date',
  'form.goal.label': 'Goal description',
  'form.goal.placeholder': 'Please enter your work goals',
  'form.standard.label': 'Metrics',
  'form.standard.placeholder': 'Please enter a metric',
  'form.client.label': 'Client',
  'form.client.label.tooltip': 'Target service object',
  'form.client.placeholder': 'Please describe your customer service, internal customers directly @ Name / job number',
  'form.invites.label': 'Inviting critics',
  'form.invites.placeholder': 'Please direct @ Name / job number, you can invite up to 5 people',
  'form.weight.label': 'Weight',
  'form.weight.placeholder': 'Please enter weight',
  'form.public.label': 'Target disclosure',
  'form.public.label.help': 'Customers and invitees are shared by default',
  'form.public.radio.public': 'Public',
  'form.public.radio.partially-public': 'Partially public',
  'form.public.radio.private': 'Private',
  'form.publicUsers.placeholder': 'Open to',
  'form.publicUsers.option.A': 'Colleague A',
  'form.publicUsers.option.B': 'Colleague B',
  'form.publicUsers.option.C': 'Colleague C',
  'component.globalHeader.search': 'Search',
  'component.globalHeader.search.example1': 'Search example 1',
  'component.globalHeader.search.example2': 'Search example 2',
  'component.globalHeader.search.example3': 'Search example 3',
  'component.globalHeader.help': 'Help',
  'component.globalHeader.notification': 'Notification',
  'component.globalHeader.notification.empty': 'You have viewed all notifications.',
  'component.globalHeader.message': 'Message',
  'component.globalHeader.message.empty': 'You have viewed all messsages.',
  'component.globalHeader.event': 'Event',
  'component.globalHeader.event.empty': 'You have viewed all events.',
  'component.noticeIcon.clear': 'Clear',
  'component.noticeIcon.cleared': 'Cleared',
  'component.noticeIcon.empty': 'No notifications',
  menu: 'Trustflight',
  'menu.Aircraft.AircraftSettings': 'Aircraft Settings',
  'menu.Aircraft.Aircraft Settings': 'Aircraft Settings',
  'menu.Aircraft.DamageMap': 'Damage Maps',
  'menu.Aircraft.Damage Map.List View': 'List View',
  'menu.Aircraft.Damage_maps.List_view': 'Damage Maps',
  'menu.Aircraft.Damage_maps.Schematic_view': 'Damage Maps',
  'menu.Aircraft.Damage Map.Schematic View': 'Schematic View',
  'menu.Aircraft.Defects.Defect Profile': 'Defect Profile',
  'menu.Aircraft.Defects': 'Defects',
  'menu.Aircraft.Defects.Add Defect': 'Add Defect',
  'menu.Aircraft.Defects.Add Defect.Add Defect': 'Add Defect',
  'menu.Aircraft.Defects.Defects': 'Defects',
  'menu.Aircraft.Defects.Log': 'Defects',
  'menu.Aircraft.Documents': 'Documents',
  'menu.Aircraft.Logbook': 'Logbook',
  'menu.Aircraft.Maintenance.Maintenance': 'Maintenance',
  'menu.Aircraft.Maintenance.Airframe': 'Maintenance',
  'menu.Aircraft.Maintenance.Oop': 'Maintenance',
  'menu.Aircraft.Maintenance.Llp': 'Maintenance',
  'menu.Aircraft.Maintenance': 'Maintenance',
  'menu.Aircraft.Metrics': 'Metrics',
  'menu.Aircraft.Overview': 'Overview',
  'menu.Aircraft.Trips': 'Trips',
  'menu.Aircraft.Trips.Add Trip': 'Add Trip',
  'menu.Aircraft.Trips.Edit Flight': 'Edit Flight',
  'menu.Aircraft.Workpacks': 'Workpacks',
  'menu.Aircraft.Workpacks.Workpack': 'Workpack',
  'menu.Aircraft.Workpacks.Workpacks': 'Workpacks',
  'menu.Aircraft.Workpacks.Log': 'Workpacks',
  'menu.Aircraft.Workpacks.Add Workpack': 'Add Workpack',
  'menu.camo': 'Camo',
  'menu.camo.fleetStatus': 'Fleet Status',
  'menu.camo.overview': 'Overview',
  'menu.camo.maintenanceForecast': 'MX Forecast',
  'menu.camo.costAnalysis': 'Cost Analysis',
  'menu.camo.defects': 'Defects',
  'menu.home': 'Home',
  'menu.Dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.Maintenance.Defects': 'Defects',
  'menu.Maintenance.Defect Profile': 'Defect Profile',
  'menu.mels': 'MELs',
  'menu.Logbook': 'Flight Logbook',
  'menu.receipts': 'Receipts',
  'menu.Reports.Fuel': 'Fuel',
  'menu.Reports.Metrics': 'Metrics',
  'menu.Reports.Map': 'Map',
  'menu.Reports.Receipts': 'Receipts',
  'menu.Operations.Flights.Edit Flight': 'Edit Flight',
  'menu.Operations.Flights.Flight Profile': 'Flight Profile',
  'menu.Operations.Flights': 'Flights',
  'menu.people': 'Manage People',
  'menu.Settings.Account Settings': 'Account Settings',
  'menu.Settings.Aircraft': 'Manage Aircraft',
  'menu.Settings.Subscriptions': 'Manage Subscriptions',
  'menu.Operations.Documents': 'Manage Documents',
  'menu.Manage.trips': 'Manage Trips',
  'menu.Settings.Integrations': 'Manage Integrations',
  'menu.Settings.Mels': 'Manage MELs',
  'menu.Add.Trip': 'Add Trip',
  'menu.Add.Defect': 'Add Defect',
  'menu.Add.aircraft': 'Add Aircraft',
  'menu.form': 'Form',
  'menu.form.basicform': 'Basic Form',
  'menu.form.stepform': 'Step Form',
  'menu.form.stepform.info': 'Step Form(write transfer information)',
  'menu.form.stepform.confirm': 'Step Form(confirm transfer information)',
  'menu.form.stepform.result': 'Step Form(finished)',
  'menu.form.advancedform': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.searchtable': 'Search Table',
  'menu.list.basiclist': 'Basic List',
  'menu.list.cardlist': 'Card List',
  'menu.list.searchlist': 'Search List',
  'menu.list.searchlist.articles': 'Search List(articles)',
  'menu.list.searchlist.projects': 'Search List(projects)',
  'menu.list.searchlist.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.changepassword': 'Change Password',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Log out',
  'menu.account.signout': 'Sign out',
  'menu.registration': 'Registration',
  'menu.status': 'Status',
  'menu.helpdesk': 'Helpdesk',
  'app.login.message-invalid-credentials': 'Invalid username or password（admin/888888）',
  'app.login.message-invalid-verification-code': 'Invalid verification code',
  'app.login.tab-login-credentials': 'Credentials',
  'app.login.tab-login-mobile': 'Mobile number',
  'app.login.remember-me': 'Remember me',
  'app.login.forgot-password': 'Forgot your password?',
  'app.login.sign-in-with': 'Sign in with',
  'app.login.signup': 'Sign up',
  'app.login.login': 'Login',
  'app.register.register': 'Register',
  'app.register.get-verification-code': 'Get code',
  'app.register.sing-in': 'Already have an account?',
  'app.register-result.msg': 'Account：registered at {email}',
  'app.register-result.activation-email':
    'The activation email has been sent to your email address and is valid for 24 hours. Please log in to the email in time and click on the link in the email to activate the account.',
  'app.register-result.back-home': 'Back to home',
  'app.register-result.view-mailbox': 'View mailbox',
  'app.home.introduce': 'introduce',
  'app.analysis.test': 'Gongzhuan No.{no} shop',
  'app.analysis.introduce': 'Introduce',
  'app.analysis.total-sales': 'Total Sales',
  'app.analysis.day-sales': 'Day Sales',
  'app.analysis.visits': 'Visits',
  'app.analysis.visits-trend': 'Visits Trend',
  'app.analysis.visits-ranking': 'Visits Ranking',
  'app.analysis.day-visits': 'Day Visits',
  'app.analysis.week': 'Week Ratio',
  'app.analysis.day': 'Day Ratio',
  'app.analysis.payments': 'Payments',
  'app.analysis.conversion-rate': 'Conversion Rate',
  'app.analysis.operational-effect': 'Operational Effect',
  'app.analysis.sales-trend': 'Stores Sales Trend',
  'app.analysis.sales-ranking': 'Sales Ranking',
  'app.analysis.all-year': 'All Year',
  'app.analysis.all-month': 'All Month',
  'app.analysis.all-week': 'All Week',
  'app.analysis.all-day': 'All day',
  'app.analysis.search-users': 'Search Users',
  'app.analysis.per-capita-search': 'Per Capita Search',
  'app.analysis.online-top-search': 'Online Top Search',
  'app.analysis.the-proportion-of-sales': 'The Proportion Of Sales',
  'app.analysis.channel.all': 'ALL',
  'app.analysis.channel.online': 'Online',
  'app.analysis.channel.stores': 'Stores',
  'app.analysis.sales': 'Sales',
  'app.analysis.traffic': 'Traffic',
  'app.analysis.table.rank': 'Rank',
  'app.analysis.table.search-keyword': 'Keyword',
  'app.analysis.table.users': 'Users',
  'app.analysis.table.weekly-range': 'Weekly Range',
  'app.forms.basic.title': 'Basic form',
  'app.forms.basic.description':
    'Form pages are used to collect or verify information to users, and basic forms are common in scenarios where there are fewer data items.',
  'app.monitor.trading-activity': 'Real-Time Trading Activity',
  'app.monitor.total-transactions': 'Total transactions today',
  'app.monitor.sales-target': 'Sales target completion rate',
  'app.monitor.remaining-time': 'Remaining time of activity',
  'app.monitor.total-transactions-per-second': 'Total transactions per second',
  'app.monitor.activity-forecast': 'Activity forecast',
  'app.monitor.efficiency': 'Efficiency',
  'app.monitor.ratio': 'Ratio',
  'app.monitor.proportion-per-category': 'Proportion Per Category',
  'app.monitor.fast-food': 'Fast food',
  'app.monitor.western-food': 'Western food',
  'app.monitor.hot-pot': 'Hot pot',
  'app.monitor.waiting-for-implementation': 'Waiting for implementation',
  'app.monitor.popular-searches': 'Popular Searches',
  'app.monitor.resource-surplus': 'Resource Surplus',
  'app.monitor.fund-surplus': 'Fund Surplus',
  'app.settings.menuMap.basic': 'Basic Settings',
  'app.settings.menuMap.security': 'Security Settings',
  'app.settings.menuMap.binding': 'Account Binding',
  'app.settings.menuMap.notification': 'New Message Notification',
  'app.settings.basic.avatar': 'Change avatar',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Please input your email!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Please input your Nickname!',
  'app.settings.basic.profile': 'Personal profile',
  'app.settings.basic.profile-message': 'Please input your personal profile!',
  'app.settings.basic.profile-placeholder': 'Brief introduction to yourself',
  'app.settings.basic.country': 'Country/Region',
  'app.settings.basic.country-message': 'Please input your country!',
  'app.settings.basic.geographic': 'Province or city',
  'app.settings.basic.geographic-message': 'Please input your geographic info!',
  'app.settings.basic.address': 'Street Address',
  'app.settings.basic.address-message': 'Please input your address!',
  'app.settings.basic.phone': 'Phone Number',
  'app.settings.basic.phone-message': 'Please input your phone!',
  'app.settings.basic.update': 'Update Information',
  'app.settings.security.strong': 'Strong',
  'app.settings.security.medium': 'Medium',
  'app.settings.security.weak': 'Weak',
  'app.settings.security.password': 'Account Password',
  'app.settings.security.password-description': 'Current password strength：',
  'app.settings.security.phone': 'Security Phone',
  'app.settings.security.phone-description': 'Bound phone：',
  'app.settings.security.question': 'Security Question',
  'app.settings.security.question-description':
    'The security question is not set, and the security policy can effectively protect the account security',
  'app.settings.security.email': 'Backup Email',
  'app.settings.security.email-description': 'Bound Email：',
  'app.settings.security.mfa': 'MFA Device',
  'app.settings.security.mfa-description': 'Unbound MFA device, after binding, can be confirmed twice',
  'app.settings.security.modify': 'Modify',
  'app.settings.security.set': 'Set',
  'app.settings.security.bind': 'Bind',
  'app.settings.binding.taobao': 'Binding Taobao',
  'app.settings.binding.taobao-description': 'Currently unbound Taobao account',
  'app.settings.binding.alipay': 'Binding Alipay',
  'app.settings.binding.alipay-description': 'Currently unbound Alipay account',
  'app.settings.binding.dingding': 'Binding DingTalk',
  'app.settings.binding.dingding-description': 'Currently unbound DingTalk account',
  'app.settings.binding.bind': 'Bind',
  'app.settings.notification.password': 'Account Password',
  'app.settings.notification.password-description':
    'Messages from other users will be notified in the form of a station letter',
  'app.settings.notification.messages': 'System Messages',
  'app.settings.notification.messages-description': 'System messages will be notified in the form of a station letter',
  'app.settings.notification.todo': 'To-do Notification',
  'app.settings.notification.todo-description':
    'The to-do list will be notified in the form of a letter from the station',
  'app.settings.open': 'Open',
  'app.settings.close': 'Close',
  'app.exception.back': 'Back to home',
  'app.exception.description.403': "Sorry, you don't have access to this page",
  'app.exception.description.404': 'Sorry, the page you visited does not exist',
  'app.exception.description.500': 'Sorry, the server is reporting an error',
  'app.result.error.title': 'Submission Failed',
  'app.result.error.description': 'Please check and modify the following information before resubmitting.',
  'app.result.error.hint-title': 'The content you submitted has the following error:',
  'app.result.error.hint-text1': 'Your account has been frozen',
  'app.result.error.hint-btn1': 'Thaw immediately',
  'app.result.error.hint-text2': 'Your account is not yet eligible to apply',
  'app.result.error.hint-btn2': 'Upgrade immediately',
  'app.result.error.btn-text': 'Return to modify',
  'app.result.success.title': 'Submission Success',
  'app.result.success.description':
    'The submission results page is used to feed back the results of a series of operational tasks. If it is a simple operation, use the Message global prompt feedback. This text area can show a simple supplementary explanation. If there is a similar requirement for displaying “documents”, the following gray area can present more complicated content.',
  'app.result.success.operate-title': 'Project Name',
  'app.result.success.operate-id': 'Project ID：',
  'app.result.success.principal': 'Principal：',
  'app.result.success.operate-time': 'Effective time：',
  'app.result.success.step1-title': 'Create project',
  'app.result.success.step1-operator': 'Qu Lili',
  'app.result.success.step2-title': 'Departmental preliminary review',
  'app.result.success.step2-operator': 'Zhou Maomao',
  'app.result.success.step2-extra': 'Urge',
  'app.result.success.step3-title': 'Financial review',
  'app.result.success.step4-title': 'Finish',
  'app.result.success.btn-return': 'Back to list',
  'app.result.success.btn-project': 'View project',
  'app.result.success.btn-print': 'Print',
  'app.setting.pagestyle': 'Page style setting',
  'app.setting.pagestyle.dark': 'Dark style',
  'app.setting.pagestyle.light': 'Light style',
  'app.setting.content-width': 'Content Width',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.themecolor': 'Theme Color',
  'app.setting.themecolor.dust': 'Dust Red',
  'app.setting.themecolor.volcano': 'Volcano',
  'app.setting.themecolor.sunset': 'Sunset Orange',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Polar Green',
  'app.setting.themecolor.daybreak': 'Daybreak Blue (default)',
  'app.setting.themecolor.geekblue': 'Geek Glue',
  'app.setting.themecolor.purple': 'Golden Purple',
  'app.setting.navigationmode': 'Navigation Mode',
  'app.setting.sidemenu': 'Side Menu Layout',
  'app.setting.topmenu': 'Top Menu Layout',
  'app.setting.fixedheader': 'Fixed Header',
  'app.setting.fixedsidebar': 'Fixed Sidebar',
  'app.setting.fixedsidebar.hint': 'Works on Side Menu Layout',
  'app.setting.hideheader': 'Hidden Header when scrolling',
  'app.setting.hideheader.hint': 'Works when Hidden Header is enabled',
  'app.setting.othersettings': 'Other Settings',
  'app.setting.weakmode': 'Weak Mode',
  'app.setting.copy': 'Copy Setting',
  'app.setting.copyinfo': 'copy success，please replace defaultSettings in src/models/setting.js',
  'app.setting.production.hint': 'Setting panel shows in development environment only, please manually modify',
  'status.scheduled': 'Scheduled',
  'status.open': 'Open',
  'status.overdue': 'Overdue',
  'status.partial': 'Partial',
  'status.resolved': 'Resolved',
  'status.reviewed': 'Reviewed',
  'status.airworthy': 'Airworthy',
  'status.approved': 'Approved',
  'status.closed': 'Closed',
  'status.complete': 'Complete',
  'status.completed': 'Completed',
  'status.critical': 'Critical',
  'status.draft': 'Draft',
  'status.error': 'Incomplete',
  'status.openDefect': 'MEL/CDL',
  'status.flightStatus': 'Flight Status',
  'status.inFlight': 'In Flight',
  'status.inMaintenance': 'In Maintenance',
  'status.inProgress': 'In progress',
  'status.lastKnownLocation': 'Last Known Location',
  'status.location': 'Location',
  'status.new': 'New',
  'status.notAirworthy': 'Not Airworthy',
  'status.onGround': 'On Ground',
  'status.pendingClose': 'Pending close',
  'status.pendingReminder': 'Pending reminder',
  'status.preflight': 'Preflight done',
  'status.rejected': 'Rejected',
  'status.started': 'Started',
  'status.planned': 'Planned',
  'status.serviceable': 'Serviceable',
  'status.unknown': 'Unknown',
  'status.unserviceable': 'Unserviceable',
  'status.zeroDeferrals': 'Zero Deferrals',
  'menu.dashboard': 'Dashboard',
  'menu.reports': 'Reports',
  'menu.metrics': 'Metrics',
  'menu.map': 'Map',
  'menu.operations': 'Operations',
  'menu.flights': 'Flights',
  'menu.documents': 'Documents',
  'menu.defects': 'Defects',
  'menu.settings': 'Settings',
  'menu.aircraft': 'Aircraft',
  'menu.integrations': 'Integrations',
  'menu.subscriptions': 'Subscriptions',
  'menu.userSettings': 'User Settings',
  'message.addActualUplift': 'Please add the actual fuel uplift',
  'message.addAirframe': 'Please add in airframe hours for the next check',
  'message.addAirframeHours': 'Please add the current airframe hours',
  'message.addApuHours': 'Please add the current APU hours',
  'message.addArrivalFuel': 'Please add the arrival fuel',
  'message.addAssembly': 'Please add an assembly',
  'message.addCurrentLandings': 'Please add the current landings',
  'message.addDefectDetails': 'Please add details of the defect',
  'message.addDefectItem': 'Please add the item relating to the defect',
  'message.generateShareLinkFailed': 'Unable to generate a sharing link',
  'message.addDeferralPeriod': 'Please add a deferral period',
  'message.addDescription': 'Please add a description',
  'message.addItem': 'Please add related item',
  'message.addInCycles': 'Please add in cycles for the next check (if applicable)',
  'message.addNextCheckApu': 'Please add in hours for the next APU check',
  'message.addNextCheckDate': 'Please add in a date for the next check',
  'message.addNotes': 'Please add any notes',
  'message.addOilUplift': 'Please add oil uplift',
  'message.addOperator': 'Please add an operator',
  'message.addPassword': 'Please add password',
  'message.addPartNum': 'Please add a part no.',
  'message.addPlannedUplift': 'Please add the planned fuel uplift',
  'message.addRegistration': 'Please add a registration',
  'message.addRegistrationLength': 'The max length of a registration number is 8 characters',
  'message.addRegistrationCharacters': 'Only alphabetic, numerical and "-" characters are allowed',
  'message.addResolutionDate': 'Please add a resolution date',
  'message.resolutionSaveSuccess': 'Resolution saved successfully.',
  'message.resolutionSaveError': "Couldn't save resolution. Please try again later.",
  'message.addSource': 'Please add source',
  'message.addSrpNo': 'Please add an SRP number to start from',
  'message.addTaskRef': 'Please add a task',
  'message.addTrip': 'Please add a trip',
  'message.addTicket': 'Add a New Ticket',
  'message.addUsername': 'Please add username',
  'message.addedToSystem': 'has been added to the system',
  'message.addOperatorReference': 'Please add operator reference',
  'message.aircraftUpdated': 'Aircraft Updated',
  'message.aThumbnailNotComplete': 'A thumbnail is not complete',
  'message.approveItemsSuccess': 'Items successfully approved',
  'message.approveItemSuccess': 'Item successfully approved',
  'message.approveItemsError': 'An error occured, please try again',
  'message.canvasEmpty': 'Canvas is empty',
  'message.changesNotSaved': 'Changes have not been saved',
  'message.crewMemberAdded': 'Crew member added',
  'message.crewMemberRemoved': 'Crew member removed',
  'message.crewMembersRemoved': 'Crew members removed',
  'message.amountMustBePositive': 'Amount must be a positive number',
  'message.amountMustBeANumber': 'Amount must be a number',
  'message.dateRequired': 'Please select a date',
  'message.editTrip': 'Edit trip',
  'message.editFlights': 'Edit flights',
  'message.errorWhileLoadingMapScript': 'An error has occurred while loading map script. Please reload the page.',
  'message.emailHasBeenSent': 'An email has been sent to',
  'message.emailIsAlreadyUsed': 'This email is already in use. Please choose another',
  'message.filePreviewFailed': 'Failed to load file preview.',
  'message.hasApu': 'Please select if this aircraft has an APU',
  'message.hobbsField': 'Please select whether to show a Hobbs field',
  'message.invalidDate': 'Please enter a valid date',
  'message.linkCopied': 'Link copied to clipboard',
  'message.maximumFuel': 'Please add a maximum fuel value',
  'message.melIsRequired': 'MEL file is required',
  'message.newUserAdded': 'New user added successfully',
  'message.noAircraftSelected': 'No aircraft selected',
  'message.noCallsignSelected': 'No callsign selected',
  'message.noPilotInCommandSelected': 'No Pilot in Command selected',
  'message.noDateSelected': 'No date selected',
  'message.noHistoryFound': 'No history found for this flight',
  'message.noInformationProvided': 'No information provided',
  'message.nothingToSave': 'There is nothing to save',
  'message.oilUnit': 'Please choose the units used for oil',
  'message.onFileUpdate': 'File updated successfully',
  'message.personRemoved': 'Person removed',
  'message.onePersonRemoved': 'person removed',
  'message.peopleRemoved': 'people removed',
  'message.personUpdatedSuccess': 'Person updated successfully',
  'message.pleaseDescription': 'Please include a description',
  'message.pleaseSaveExistingWork': 'Please save existing work first and try again',
  'message.previousAirportNotMatch': "Previous arrival destination doesn't match selected departure airport",
  'message.referenceRequired': 'Please enter a reference',
  'message.poRequired': 'Please enter PO Number',
  'message.resolutionType': 'Please include a resolution type',
  'message.selectAircraft': 'Please select a flight to log the defect against',
  'message.selectApuTotal': 'Please enter the APU hours',
  'message.selectArea': 'Please select area',
  'message.selectATAChapter': 'Please select an ATA Chapter',
  'message.selectDeferred': 'Please select if the defect is to be deferred',
  'message.selectFlight': 'Please select a flight to log the defect against',
  'message.selectModel': 'Please select a model!',
  'message.selectTicketTitle': 'Please input ticket title',
  'message.selectTicketText': 'Please input ticket text',
  'message.selectTicketAircraft': 'Please select aircraft',
  'message.selectTicketGroup': 'Please select group',
  'message.selectUnit': 'Please choose the units used for fuel',
  'message.successfulSubmission': 'Successful Submission',
  'message.titleMustBeAdded': 'Title must be added',
  'message.sectorRemoved': 'Sector removed',
  'message.tripRemoved': 'Trip removed',
  'message.tripUpdateRemoved': 'Trip update removed',
  'message.tripNumberBlank': 'Please enter trip number',
  'message.tripSRPNumberBlank': 'Please enter SRP number',
  'message.tripNumberZero': 'Please enter number greater than zero',
  'message.tripUpdatedSuccess': 'Trip updated successfully',
  'message.operatorNotSelected': 'Please select an operator first',
  'message.unsupportedFileType': 'Unsupported file type, please select correct file and retry!',
  'message.upliftSuccess': 'Uplift updated successfully',
  'message.uploadMel': 'Please upload the MEL PDF file for the aircraft',
  'message.userAlreadyExists': 'User already exists.',
  'message.waitForUpload': 'Please wait for upload to complete',
  'message.warningDeicingTime': 'Time between start and end of deicing is',
  'message.warningDeicingTimeBefore': 'Time between deicing and takeoff is',
  'message.warningDeicingTimeAfter': 'Time is after takeoff.',
  'message.warningOnBlocks': 'Time between on blocks and landing is',
  'message.warningTakeOff': 'Time between take off and off blocks is',
  'message.workpackAlreadyExists': 'Workpack already exists',
  'message.workpackUpdated': 'Workpack updated',
  'message.workpackAdded': 'Workpack successfully added',
  'message.oneItemApproved': 'item approved',
  'message.itemApproved': 'Item approved',
  'message.itemsApproved': 'items approved',
  'message.oneItemDeleted': 'item deleted',
  'message.itemsDeleted': 'items deleted',
  'message.itemDeleted': 'Item deleted',
  'message.oneSubscriptionDeleted': 'subscription deleted',
  'message.subscriptionsDeleted': 'subscriptions deleted',
  'message.oneDamageReportDeleted': 'Damage Report deleted',
  'message.damageReportsDeleted': 'Damage Reports deleted',
  'message.subscriptionDeleted': 'Subscription deleted',
  'message.subscriptionExists': 'Subscription already exists',
  'message.subscriptionUsesFilter': 'Another subscription of this type already includes 1 or more aircraft ids',
  'message.subscriptionUpdated': 'Subscription updated',
  'message.titleNotUnique': 'The title must be unique',
  'message.imageMustBeAdded': 'An image must be added',
  'message.subscriptionCreated': 'Subscription created',
  'message.oneDocumentDeleted': 'document deleted',
  'message.documentsDeleted': 'documents deleted',
  'message.documentDeleted': 'Document deleted',
  'message.missingField': 'Please fill in {field}',
  'message.wrongEmail': 'Please enter a valid Email Address',
  'message.tooLongNumber': 'Please input a number with less than 10 digits',
  'message.noLimitations': 'No limitations on this aircraft.',
  'message.ticketClosed': 'Ticket closed',
  'message.ticketUpdateFailed': 'Unable to update ticket',
  'message.addArticleError': 'Unable to add comment',
  'message.addTicketError': 'Unable to create ticket',
  'message.fillComment': 'Please fill in comment text',
  'message.invalidFileType': 'Invalid file format, please retry.',
  'message.noAirports': 'No Airports Found',
  'message.noAircraft': 'No Aircraft Found',
  'message.emptyActiveWorkPack': 'Active Workpack must have at least 1 item',
  'message.pleaseWaitTillFinish': 'Please wait for form upload completion in order to close',
  'message.noAircraftFoundCriteria': 'No aircraft found for the specified criteria',
  'message.pleaseSaveResolution': 'Please save opened resolution(s) first',
  'message.pleaseSelectAtLeastOneItem': 'Please select at least one item to sign off',
  'message.melRemoved': 'MEL removed',
  'message.melCreated': 'MEL created',
  'message.melCantCreate': 'Could not create MEL or it already exists',
  'message.melSettingsSaved': 'MEL Settings saved',
  'message.melChapterSaved': 'MEL chapter saved',
  'message.melProblemSave': 'There was a problem saving MEL',
  'message.melSectionRemoved': 'Section removed',
  'message.newItems': 'New items',
  'message.cantCreateUser': "User can't be created",
  'message.deselectItems': 'Please deselect items before closing their category',
  'message.draftItemsImported':
    'Draft Items were imported. Review imported items to add them to the maintenance timeline.',
  'message.draftItemImported':
    'Draft Item was imported. Review imported items to add them to the maintenance timeline.',
  'message.duplicates': 'Duplicates',
  'message.deselectDrafts': 'Please deselect draft items to select items for workpack',
  'message.deselectActive': 'Please deselect active items to select drafts to import',
  'message.removedItems': 'Removed items',
  'message.startReviewing': 'Start reviewing',
  'message.workpackCreateSuccess': 'Workpack successfully created!',
  'message.workpackCreateError': "Couldn't create workpack",
  'message.workpackUpdateSuccess': 'Workpack successfully updated!',
  'message.workpackUpdateError': "Couldn't update workpack",
  'message.updatedItems': 'Updated items',
  'text.acType': 'A/C Type',
  'text.ago': 'ago',
  'text.attachments': 'Attachments',
  'text.account': 'Account',
  'text.accountSettings': 'Account Settings',
  'text.accountSettings.changePasswordInfo':
    'If you would like to change your password, please click on the button below for full instructions.',
  'text.accountSettings.changePassword': 'Change Password',
  'text.accountSettings.dateFormat': 'Date Format',
  'text.accountSettings.dateFormatUpdated': 'Date Format Successfully Updated',
  'text.accountSettings.DayMonthYear': 'DD-MM-YYYY',
  'text.accountSettings.display': 'Display',
  'text.accountSettings.MonthDayYear': 'MM-DD-YYYY',
  'text.accountSettings.navigation': 'Navigation',
  'text.accountSettings.passwordManagement': 'Password Management',
  'text.accountSettings.profilePicture': 'Profile Picture',
  'text.accountSettings.side': 'Side',
  'text.accountSettings.top': 'Top',
  'text.accountSettings.updateImage': 'Update Image',
  'text.accountSettings.uploadImage': 'Upload Image',
  'text.accountSettings.uploadProfileSuccessful': 'Profile image successfully uploaded',
  'text.accountSettings.YearMonthDay': 'YYYY-MM-DD',
  'text.active': 'Active',
  'text.activeLastUpdated': 'Active - last updated',
  'text.actualUplift': 'Actual Uplift',
  'text.additionalCrew': 'Additional Crew',
  'text.addNewTripUpdate': 'Add New',
  'text.addressLine1': 'Address Line 1',
  'text.addressLine2': 'Address Line 2',
  'text.aircraft': 'Aircraft',
  'text.aircraftExist': 'Aircraft already exists',
  'text.aircraftHours': 'Aircraft Hours',
  'text.aircraftOverviewHeader':
    'View real-time information about your selected aircraft including defects, work package status and forecast maintenance items',
  'text.aircraftSerial': 'Aircraft Serial',
  'text.allAircraft': 'All Aircraft',
  'text.apu': 'APU',
  'text.apuThreshold': 'APU Threshold',
  'text.airport': 'Airport',
  'text.apuHours': 'APU hrs',
  'text.apuHoursNotSet': 'APU hrs - not set',
  'text.approve': 'Approve',
  'text.area': 'Area',
  'text.arrival': 'Arrival',
  'text.ata': 'ATA',
  'text.attachmentProblem': 'There is a problem with this attachment',
  'text.aNew': 'a new',
  'text.anUpdated': 'an updated',
  'text.aRemoved': 'a removed',
  'text.basicAircraftInfo': 'Basic Aircraft Info',
  'text.baseMxOverHauls': 'Base mx / overhauls',
  'text.breaksAndTyre': 'Brakes and Tyres',
  'text.callsign': 'Callsign',
  'text.cancel': 'Cancel',
  'text.categoryNotSet': 'Category not set',
  'text.centrikIntegration': 'Document management and ASR Reporting.',
  'text.city': 'City',
  'text.close': 'Close',
  'text.draftItemsImported':
    'Draft items were imported. Please review the items below to add them to the maintenance timeline.',
  'text.draftItemImported':
    'Draft item was imported. Please review the item below to add it to the maintenance timeline.',
  'text.campIntegration': 'Automatically synchronise aircraft due list, discrepancies and actuals.',
  'text.carriedFromPrevious': 'Carried forward from previous sheet',
  'text.clickOrDragFile': 'Click or drag file to this area to upload.',
  'text.uploadSchematicImage':
    'Drag and drop your file and fill in the details. You can repeat the action for multiple views',
  'text.closeIcon': 'close icon',
  'text.clickingHere': 'clicking here',
  'text.closeTicket': 'Close ticket',
  'text.comments': 'Comments',
  'text.company': 'Company',
  'text.confirmationReportText':
    'I confirm that to the best of my knowledge the information submitted is complete and correct.',
  'text.confirmYes': 'Yes',
  'text.confirmNo': 'No',
  'text.consumptionPerHourPerEngine': 'Consumption per hour per engine',
  'text.contact': 'Contact',
  'text.country': 'Country',
  'text.createdBy': 'Created By',
  'text.criticalItems': 'Critical Items',
  'text.current': 'Current',
  'text.currency': 'Currency',
  'text.cycles': 'Cycles',
  'text.cyclesPerHour': 'Cycles per Hour',
  'text.cph': 'HpC',
  'text.damageMaps': 'Damage Maps',
  'text.date': 'Date',
  'text.dateClosed': 'Date Closed',
  'text.dateOpened': 'Date Opened',
  'text.dayAgo': 'day ago',
  'text.daysAgo': 'days ago',
  'text.days': 'Days',
  'text.defaultImage': 'default image',
  'text.defineSubscription': 'Define your subscription settings below to meet your specific needs.',
  'text.delete': 'Delete',
  'text.ddl': 'DDL',
  'text.disableLink': 'Disable',
  'text.displayedImage': 'displayedImage',
  'text.daysRemaining': 'Days Remaining',
  'text.defects': 'Defects',
  'text.defect': 'Defect',
  'text.deferralPeriod': 'Deferral Period',
  'text.departure': 'Departure',
  'text.description': 'Description',
  'text.details': 'Details',
  'text.documentCategory': 'Document category',
  'text.documentOperator': 'Operator name',
  'text.documents': 'Documents',
  'text.document': 'Document',
  'text.documentValidRange': 'Document valid range',
  'text.dropdownIcon': 'dropdown icon',
  'text.dragDropFile': 'Drag and drop file here or ',
  'text.duplicateItems': 'Duplicate Items',
  'text.draftRepeats': 'Draft Repeats',
  'text.brakesAndTyres': 'Brakes & Tyres',
  'text.browse': 'browse',
  'text.due': 'Due',
  'text.duration': 'Duration',
  'text.averageDuration': 'Avg Duration',
  'text.numberOfCycles': '# of Cycles',
  'text.edit': 'Edit',
  'text.editItem': 'Edit Item',
  'text.editIcon': 'edit icon',
  'text.editMel': 'Edit the settings of this MEL',
  'text.editWorkpack': 'Use this page to update this workpack, composed of all relevant defects and maintenance items.',
  'text.end': 'End',
  'text.utilisation': 'Utilisation',
  'text.dailyUtilisation': 'Daily Utilisation',
  'text.delaysCancellations': 'Delays/Cancellations',
  'text.ampRemaining': 'AMP Remaining',
  'text.engines': 'Engines',
  'text.engShort': 'Eng',
  'text.engine': 'Engine',
  'text.e1': 'E1',
  'text.engine1': 'Engine 1',
  'text.e2': 'E2',
  'text.engine2': 'Engine 2',
  'text.e3': 'E3',
  'text.engine3': 'Engine 3',
  'text.e4': 'E4',
  'text.engine4': 'Engine 4',
  'text.engineThreshold': 'Engine Threshold',
  'text.enterRocketrouteDetails': 'Enter your RocketRoute details below to link your account with the Tech Log',
  'text.errorUnableToConnect': 'Error - unable to connect',
  'text.estimatedShort': 'Est',
  'text.estimatedUpliftShort': 'Est. Uplift',
  'text.every': 'Every',
  'text.expected': 'Expected',
  'text.files': '.JPEG .JPG .PNG',
  'text.filledFlightPlans': 'Filed flight plans, crew details & aircraft details.',
  'text.fleetDefects': 'Fleet Defects',
  'text.fleetDefectsHeader': 'View defect information across your entire fleet.',
  'text.fleetStatus': 'Fleet Status',
  'text.fleetStatusHeader':
    'View real-time information about your fleet including service status, utilisation and upcoming maintenance items.',
  'text.flightCycles': 'Flight Cycles',
  'text.fleetWorkpack': 'Fleet Workpacks',
  'text.fleetWorkpackHeader': 'View workpack information and statuses for your entire fleet.',
  'text.flightCyclesNotSet': 'Flight Cycles: not set',
  'text.flightHoursNotSet': 'Flight Hrs: not set',
  'text.fluidType': 'Fluid Type',
  'text.from': 'From',
  'text.fuel': 'Fuel',
  'text.fuelDelta': 'Fuel Delta',
  'text.fuelOnBoard': 'FUEL ON BOARD',
  'text.fuelPerHour': 'Fuel per Hour',
  'text.fph': 'FBpH',
  'text.fuelUplift': 'Fuel Uplift',
  'text.fuelUsed': 'Fuel Used',
  'text.helpDesk': 'Help Desk',
  'text.hide': 'Hide',
  'text.hideAttachment': 'Hide Attachment',
  'text.hourAgo': 'hour ago',
  'text.hoursAgo': 'hours ago',
  'text.hideAircraftTotals': 'Hide Aircraft Totals',
  'text.hours': 'Hours',
  'text.iCertifyPart145':
    'I certify that the work specified, except as otherwise specified, was carried out in accordance with Part-145 and in respect to that work the aircraft/aircraft component is considered ready for release to service.',
  'text.iCertifyPart145Document1':
    'I certify that the uploaded document is authentic and carried out in accordance with Part-145.',
  'text.iCertifyPart145Document2':
    'In respect to that work the aircraft/aircraft component is considered ready for release to service.',
  'text.iConfirmCorrectness':
    'I confirm that to the best of my knowledge the information submitted is complete and correct.',
  'text.iConfirmFuel':
    'I confirm correct completion of pre-flight inspection, ground anti/de-icing, accpetance of aircraft/defect state and sufficient fuel and oil for the planned flight.',
  'text.iConfirmMessage':
    'I confirm that to the best of my knowledge the information submitted is complete and correct.',

  'text.imageUploader.upload': 'Drag and drop or click here to upload a profile picture. It should not exceed 1MB.',
  'text.integrationsDescriptionFailed':
    'Unfortunately we were unable to link this account. Please check your RocketRoute user details are correct, or contact us for support.',
  'text.integrationsDescriptionSuccess':
    'Your account has been successfully linked to RocketRoute. Within 24hrs your account should be fully configured to show filed flights from RocketRoute.',
  'text.integrationPageDescription': 'Enable and manage third party integrations with the Tech Log.',
  'text.joinTheMeeting': 'Join the meeting',
  'text.itemDetails': 'Item details',
  'text.itemType': 'Item Type',
  'text.itemStatus': 'Item Status',
  'text.importedItems': 'Imported Items',
  'text.jl': 'JL',
  'text.landing': 'Landing',
  'text.landings': 'Landings',
  'text.last': 'Last',
  'text.leaveBlank': 'leave blank if not required',
  'text.limitations': 'Limitations',
  'text.limitation': 'Limitation',
  'text.lineMaintenance': 'Line Maintenance',
  'text.lineMaintenanceArea': 'Line Maintenance Area',
  'text.linkSuccessful': 'Link successful',
  'text.llpItem': 'LLP Item',
  'text.uploadText': 'Upload schematic files to your Damage Maps library, then attach them to categories.',
  'text.lastSync': 'Last Sync',
  'text.loading': 'Loading...',
  'text.logistics': 'Logistics',
  'text.maintenance': 'Maintenance',
  'text.maintenanceForecastHeader': 'Maintenance Forecast Header',
  'text.editMelDescription': 'Modify the details of this MEL with the form below.',
  'text.mixRatio': 'Mix Ratio',
  'text.minuteAgo': 'minute ago',
  'text.minutesAgo': 'minutes ago',
  'text.modifySubscription': 'Modify your subscription settings below to meet your specific needs.',
  'text.months': 'Months',
  'text.monthsNotSet': 'Months: not set',
  'text.newCaps': 'NEW',
  'text.newWorkpack': 'Use this page to create a new workpack, composed of all relevant defects and maintenance items.',
  'text.nextDue': 'Next due',
  'text.noAircraftFound': 'No aircraft found',
  'text.noItemsFound': 'No items found in this workpack',
  'text.noItemsMatchMX': 'No matching items found',
  'text.fetching': 'Fetching...',
  'text.noCrew': 'Please add your first crew member.',
  'text.noData': 'No Data',
  'text.noDefects': 'No Defects',
  'text.noDefectsDetected': 'No defects recorded on this flight',
  'text.noItemsImported': 'There are no new items.',
  'text.noMeetingsToday': 'No meetings scheduled for today',
  'text.noScheduledMeetings': 'No meetings scheduled for the next two weeks',
  'text.noPreviousMeetings': 'No meetings found in the past year',
  'text.noAircraftSelected': 'No aircraft selected',
  'text.noScheduled': 'No Scheduled Items',
  'text.noSearchData': 'No results found for the search criteria',
  'text.noOop': 'No Out of Phase Items',
  'text.noLlp': 'No Life Limited Parts',
  'text.none': 'None',
  'text.noSearchMatching': 'No results found for your search criteria.',
  'text.noSignature': 'No signature found.',
  'text.notAvailableShort': 'N/a',
  'text.noteNoPasswordStored':
    'Please note for security reasons we do not store any of your third party username or password details.',
  'text.notIntegrated': 'Not Integrated',
  'text.notSet': 'Not Set',

  'text.oilAdded': 'Oil Added',
  'text.oilConsumption': 'Oil Consumption',
  'text.operator': 'Operator',
  'text.other': 'Other',
  'text.otherUnsavedFlights': 'There are still other unsaved flights.',
  'text.overdue': 'Overdue',
  'text.oopItem': 'OOP Item',
  'text.part145': 'Part 145',
  'text.part145Approval': 'Part 145 Approval',
  'text.partType': 'Part Type',
  'text.pdfFiles': '.PDF .JPG .PNG',
  'text.pending': 'Pending',
  'text.performance': 'Performance',
  'text.pilotInCommand': 'Pilot in Command',
  'text.pic': 'PIC',
  'text.pleaseAddSRP': 'Please add an SRP to complete the flight',
  'text.pleaseNoteChangingDeferral':
    'Please note changing the deferral period may alter the current status of the defect.',
  'text.pleaseSelectAircraft': 'Please select aircraft to view data',
  'text.previousPeriod': 'Previous Period',
  'text.quantity': 'Quantity',
  'text.question': 'Question',
  'text.receipt': 'Receipt',
  'text.reliabilityReports': 'Reliability Reports',
  'text.resolution': 'Resolution',
  'text.roles': 'Roles',
  'text.reviewDraftItemIndividually': 'Review draft item individually',
  'text.removedItems': 'Removed Items',
  'text.s': 's',
  'text.saveMyChanges': 'Save my changes',
  'text.scheduledItems': 'Scheduled Items',
  'text.scheduledFlightsText': 'Scheduled flights, crew details, aircraft details and maintenance items.',
  'text.schematics': 'schematics',
  'text.searchForAircraft': 'Search for individual aircraft or type of aircraft to find your reports',
  'text.secondAgo': 'second ago',
  'text.secondsAgo': 'seconds ago',
  'text.sectorNum': 'Sector #',
  'text.selected': 'selected',
  'text.selectACountry': 'Select a country',
  'text.selectACurrency': 'Select a currency',
  'text.selectAState': 'Select a state',
  'text.selectedAircraft': 'Selected Aircraft',
  'text.selectedItems': 'Selected Items',
  'text.show': 'Show',
  'text.showAll': 'Show All',
  'text.showAircraftTotals': 'Show Aircraft Totals',
  'text.showLess': 'Show Less',
  'text.showMore': 'Show More',
  'text.showAttachment': 'Show Attachment',
  'text.showAllDraftItems': 'Show All Draft Items',
  'text.showLessDraftItems': 'Show Less Items',
  'text.showOnlyMyTickets': 'Show Only My Tickets',
  'text.srp': 'SRP',
  'text.SRPWillBeUsed': 'The paper SRP will be used as evidence for flight completion and commander sign-off',
  'text.start': 'Start',
  'text.state': 'State',
  'text.status': 'Status',
  'text.statusCaps': 'STATUS',
  'text.subscriptionInfoText':
    'Subscriptions allow you to be notified about events that occur on your account. If you create a subscription, you will receive an email each time the event occurs.',
  'text.takeoff': 'Takeoff',
  'text.text': 'Text',
  'text.thisDefectNotResolved':
    'This Defect has not been resolved. Please share the following link to allow a Part 445 to update the defect status:',
  'text.to': 'To',
  'text.toAddNewPart': 'To add a part to AMOS please fill out the form below.',
  'text.toAddNewVendor': 'To add a vendor to AMOS please fill out the form below.',
  'text.today': 'Today',
  'text.tolerance': 'Tolerance',
  'text.tool': 'Tool',
  'text.totalHoursFlown': 'Total Hours Flown',
  'text.totalFuelUsed': 'Total Fuel Used',
  'text.totals': 'Totals',
  'text.trip': 'Trip',
  'text.tripNum': 'Trip #',
  'text.tripUpdate': 'Trip Update',
  'text.tripUpdateInfo': 'Use this page to edit trip/SRP numbers and create Trip Updates (for non-flight entries).',
  'text.tripUpdatesInfo':
    'Trip Updates allow recording aircraft state changes in the form of additional hours or cycles, or overriding the totals carried forward if, for example, part changes have occurred.',
  'text.tryAgain': 'Try again',
  'text.type': 'Type',
  'text.updates': 'Updates',
  'text.unknown': 'Unknown',
  'text.unableToLoadImage': 'Unable to load image file.',
  'text.uploadAttachmentToView': 'Upload an attachment to view while editing.',
  'text.uploadIcon': 'upload icon',
  'text.uploadSchematic': 'Upload schematic',
  'text.used': 'Used fuel',
  'text.user': 'User',
  'text.userSettings': 'User Settings',
  'text.updatedItems': 'Updated Items',
  'text.validFor30daysWorkpack':
    'This link (valid for 30 days) will allow a third party to view this workpack, without requiring a log in.',
  'text.vendorCode': 'Vendor Code',
  'text.view': 'View',
  'text.yearOnYear': 'Year on Year',
  'text.youAreViewing': 'You are viewing: ',
  'text.youCanAlsoUpload': 'You can also upload your file by',
  'text.zipCode': 'Zip Code',
  'text.repairCategory': 'Repair category',
  'text.threshold': 'Threshold',
  'text.noDetails': 'No details',
  'text.repeat': 'Repeat',
  'text.after': 'After',
  'text.thresholdShort': 'thr.',
  'text.reportATechincalSupport': 'Report a Technical Support',
  'text.scheduledMX': 'Scheduled MX',
  'text.workpack': 'Workpack',
  'text.measure': 'Measure',
  'text.meetings': 'Meetings',
  'text.melNoItems': 'There are no items in this chapter.',
  'text.melOneItemChapter': 'There is 1 item in this chapter.',
  'text.melManyItemsChapter': 'There are {field} items in this chapter.',
  'text.youreEditingChapter': "You're editing chapter",
  'text.sectionDetails': 'Section details',
  'text.serviceRequest': 'Service Request',
  'text.subSectionDetails': 'Sub-Section details',
  'text.subSubSectionDetails': 'Sub-Sub-Section details',
  'text.seeFile': 'See file',
  'text.browserNotSupported': 'Your browser ({browser}) is not supported',
  'text.browserUseFollowing': 'Please use one of the following:',
  'text.browserEdge': 'Microsoft Edge',
  'text.browserChrome': 'Google Chrome',
  'text.browserFirefox': 'Mozilla Firefox',
  'text.browserSafari': 'Apple Safari',
  'text.getInTouch': 'Get in touch with us at',
  'text.repeatedItems': 'Repeated Items',
  'text.expandAllItems': 'Expand All Items',
  'text.package': 'Package',
  'text.airframe': 'Airframe',
  'text.camp': 'CAMP',
  'text.trustflight': 'TrustFlight',
  'text.techlog': 'Tech Log',
  'text.techlogHeader': 'Tech Log Page Status',
  'text.techlogSubHeader': 'View the status of tech log pages across your fleet',
  'text.showing': 'Showing',
  'text.nextXDays': 'Next {days} days',
  'text.lastXDays': 'Last {days} days',
  'text.next24Hours': 'Next 24 hours',
  'text.nextYear': 'Next year',
  'text.nextYearMore': 'Next year & more',
  'text.lastYearMore': 'Last year & more',
  'text.allTime': 'All time',
  'text.class': 'Class',
  'text.clearAll': 'Clear All',
  'text.overdueItems': 'Overdue Items',
  'text.tommorow': 'Tommorow',
  'text.of': 'of',
  'text.viewYourFleet': 'View your fleet',
  'text.itemsPerPage': 'Items per page',
  'text.imported': 'Imported',
  'text.created': 'Created',
  'text.editedIn': 'Edited in',
  'text.manageItem': 'Manage Item',
  'text.lastCompleted': 'Last Completed',
  'text.viewWorkpack': 'View workpack',
  'text.itemInsidePackage': 'Items inside this package {number}',
  'text.poNotSpecified': 'PO Not Specified',
  'text.newWorkpackCaps': 'NEW WORKPACK',
  'text.addToExistingWorkpack': 'Add to existing workpack',
  'text.addToWorkpack': 'Add to workpack',
  'text.createNewWorkpack': 'Create a new workpack',
  'text.737': '737',
  'text.737NG': '737NG',
  'text.737MAX': '737MAX',
  'options.resetAll': 'Reset All',
  'options.deselectAll': 'Deselect All',
  'options.selectAll': 'Select All',
  'options.allTypes': 'All Types',
  'options.allOperators': 'All Operators',
  'period.all': 'All',
  'period.allTime': 'All time',
  'period.hourly': 'Hourly',
  'period.hrs': 'hrs',
  'period.mins': 'mins',
  'period.cycles': 'cycles',
  'period.thisWeek': 'This Week',
  'period.thisMonth': 'This Month',
  'period.lastMonth': 'Last Month',
  'period.thisYear': 'This Year',
  'period.lastYear': 'Last Year',
  'period.custom': 'Custom',
  'tooltip.addNewDefect': 'This form allows a defect to be added to an Aircraft',
  'tooltip.addNewFlight': 'This form allows a paper-logged flight/sector to be uploaded into the system',
  'tooltip.addingPaperRecord': 'For adding a paper record',
  'tooltip.servicing': 'These values keep track of fuel & oil added to the aircraft',
  'tooltip.aircraftSettings':
    'These are settings used to configure the app specifically for this aircraft, for example fuel capacity allows us to check if too much fuel is being uplifted!',
  'tooltip.basicAircraftInfo': 'This information is used to create the basic aircraft profile',
  'tooltip.createNewAircraft': 'This form will guide you through creating a new aircraft for use in the Tech Log app',
  'tooltip.fuelDelta': 'Fuel Delta',
  'tooltip.fuelDeltaExplain': ' = Cumulative [Fuel Used - Fuel Uplifted] ',
  'tooltip.fuelDeltaPositive': 'Positive',
  'tooltip.fuelDeltaPositiveExplain': ' = more used than uplifted',
  'tooltip.generateLink3rdParty': 'Generates a link for a third party (Part 145) to update the record',
  'tooltip.nextChecks': 'These values are used to calculate show the time remaining until next checks within the app',
  'tooltip.resolveDefect': 'Opens a new tab for a third party (Part 145) to add a resolution',
  'tooltip.scannedSRPCopy':
    'A scanned copy of the paper SRP is used to confirm the flight. Uploading the SRP will mark the flight status as complete',
  'tooltip.startingTotals':
    'Additional flight time logged using the Tech Log will added to the starting totals created here. Starting totals can also be modified once the aircraft has been created',
  'units.apuHoursLeft': 'APU hrs left',
  'units.apuHourLeft': 'APU hr left',
  'units.apuHours': 'APU hrs',
  'units.apuHour': 'APU hr',
  'units.cycles': 'cycles',
  'units.cyclesCap': 'CYCLES',
  'units.cycle': 'cycle',
  'units.cyclesLeft': 'cycles left',
  'units.cycleLeft': 'cycle left',
  'units.daysLeft': 'days left',
  'units.dayLeft': 'day left',
  'units.day': 'day',
  'units.days': 'days',
  'units.fuelPerHour': 'Fuel burn per hour',
  'units.hoursLeft': 'hrs left',
  'units.hourLeft': 'hr left',
  'units.hours': 'hours',
  'units.hoursShort': 'hrs',
  'units.hour': 'hour',
  'units.hourShort': 'hr',
  'units.hoursPerCycle': 'Hours per cycle',
  'units.litres': 'Litres',
  'units.minute': 'minute',
  'units.minutes': 'minutes',
  'units.months': 'months',
  'units.monthsLeft': 'months left',
  'units.monthLeft': 'month left',
  'units.monthsLeftShort': 'mos left',
  'units.monthLeftShort': 'mo left',
  'units.monthShort': 'mo',
  'units.monthsShort': 'mos',
  'units.totalHoursFlown': 'Total Hours Flown',
  'units.totalFuelUsed': 'Total Fuel Used',
  'units.daysAgo': 'days ago',
  'user.logout': 'Logout',
  'user.changePassword': 'Change Password',
  'form.button.add': 'Add',
  'form.button.applyDates': 'Apply Dates',
  'form.button.applyTolerance': 'Apply Tolerance',
  'form.button.addAircraftFilter': 'Add an Aircraft',
  'form.button.addAircraft': 'Add a New Aircraft',
  'form.button.addCrewMember': 'Add Crew Member',
  'form.button.addCRS': 'Add CRS',
  'form.button.addDamageMap': 'Add a new schematic',
  'form.button.addDefect': 'Add a Defect',
  'form.button.addFlight': 'Add a Flight',
  'form.button.addMEL': 'Add MEL',
  'form.button.addNew': 'Add New',
  'form.button.addNewDocument': 'Add a New Document',
  'form.button.addNewItem': 'Add a New Item',
  'form.button.addNewUser': 'Add a New User',
  'form.button.addNewPart': 'Add Part',
  'form.button.addSection': 'Add Section',
  'form.button.approve': 'Approve',
  'form.button.reject': 'Reject',
  'form.button.addSignature': 'Add a Signature',
  'form.button.addSubscription': 'Add a New Subscription',
  'form.button.addNextSubSubSection': 'Add Next Sub-Sub-Section',
  'form.button.addTrip': 'Add a New Trip',
  'form.button.addVendor': 'Add Vendor',
  'form.button.addWorkpack': 'Add a New Workpack',
  'form.button.all': 'All',
  'form.button.back': 'Back',
  'form.button.Category not set': 'Category not set',
  'form.button.cancel': 'Cancel',
  'form.labels.cannotBeUndone': 'Warning! This action cannot be undone.',
  'form.button.choseFile': 'Choose File',
  'form.button.clear': 'Clear',
  'form.button.clickToAddFile': 'Click to Add',
  'form.button.clickHere': 'clicking here',
  'form.button.close': 'Close',
  'form.button.clearAll': 'Clear All',
  'form.button.clickToUpload': 'Click to Upload',
  'form.button.continue': 'Continue',
  'form.button.copyToClipboard': 'Copy to clipboard',
  'form.button.delete': 'Delete',
  'form.button.selectFile': 'Select file',
  'form.button.deleteTrip': 'Delete trip',
  'form.button.deleteFile': 'Delete',
  'form.button.details': 'Details',
  'form.button.done': 'Done',
  'form.button.download': 'Download',
  'form.button.downloadCSV': 'Download CSV',
  'form.button.edit': 'Edit',
  'form.button.editDetails': 'Edit Details',
  'form.button.editSettings': 'Edit Settings',
  'form.button.editThresholds': 'Edit MX Thresholds',
  'form.button.empennage': 'Empennage',
  'form.button.fuselage': 'Fuselage',
  'form.button.general': 'General',
  'form.button.generate': 'Generate',
  'form.button.grid': 'Grid',
  'form.button.link': 'Link',
  'form.button.list': 'List',
  'form.button.melSettings': 'MEL Settings',
  'form.button.no': 'No',
  'form.button.openInNewTab': 'Open In New Tab',
  'form.button.overrideValues': 'Override values',
  'form.button.remove': 'Remove',
  'form.button.removeCrew': 'Remove Crew Member',
  'form.button.removeSection': 'Remove Section',
  'form.button.removeSelected': 'Remove Selected',
  'form.button.removeItem': 'Remove Item',
  'form.button.removeSubSection': 'Remove Sub-Section',
  'form.button.removeSubSubSection': 'Remove Sub-Sub-Section',
  'form.button.powerplant': 'Powerplant',
  'form.button.propeller': 'Propeller',
  'form.button.resetPassword': 'Reset Password',
  'form.button.save': 'Save',
  'form.button.seeMoreUpdates': 'See More Updates',
  'form.button.moreDetails': 'More Details',
  'form.button.lessDetails': 'Less Details',
  'form.button.saveChapter': 'Save Chapter',
  'form.button.selectAll': 'Select all',
  'form.button.mxThreshold': 'MX Threshold',
  'form.button.submit': 'Submit',
  'form.button.submitCRS': 'Submit CRS',
  'form.button.submitDetails': 'Submit Details',
  'form.button.table': 'Table',
  'form.button.tryAgain': 'Try Again',
  'form.button.update': 'Update',
  'form.button.uploadNewSchematic': 'Upload a New Schematic',
  'form.button.viewCRS': 'View CRS',
  'form.button.viewSignature': 'View Signature',
  'form.button.windows': 'Windows',
  'form.button.wings': 'Wings',
  'form.button.yes': 'Yes',
  'form.button.youCan': 'You can upload your file by',
  'form.button.airworthy': 'Airworthy',
  'form.button.serviceable': 'Serviceable',
  'form.button.inMaintenance': 'In Maintenance',
  'form.button.onGround': 'On Ground',
  'form.button.inFlight': 'In Flight',
  'form.button.unserviceable': 'Unserviceable',
  'form.button.critical': 'Critical',
  'form.button.fillInCRS': 'Fill in Engineer Details',
  'form.button.uploadCRS': 'Upload CRS Document',
  'form.helper.fillCRS': 'Fill in engineer details required to sign CRS',
  'form.helper.uploadCRS': 'Upload previously signed CRS form and confirm it with your details & signature',
  'form.labels.acHours': 'A/C Hours',
  'form.labels.acLandings': 'A/C Landings',
  'form.labels.aircraftType': 'Aircraft Type',
  'form.labels.apuHours': 'APU Hours',
  'form.labels.assembly': 'Assembly',
  'form.labels.ataChapter': 'ATA Chapter',
  'form.labels.attachments': 'Attachments',
  'form.labels.callsign': 'Callsign',
  'form.labels.pic': 'PIC',
  'form.labels.cyclesDue': 'Cycles Due',
  'form.labels.cyclesLimit': 'Cycles Limit',
  'form.labels.date': 'Date',
  'form.labels.dateDue': 'Date Due',
  'form.labels.days': 'Days',
  'form.labels.defectDetails': 'Defect Details',
  'form.labels.deferralPeriod': 'Deferral Period',
  'form.labels.deferred': 'Deferred?',
  'form.labels.description': 'Description',
  'form.labels.enabled': 'Enabled?',
  'form.labels.endTime': 'End Time',
  'form.labels.enterTitle': 'Title',
  'form.labels.event': 'Event',
  'form.labels.fields': 'Fields',
  'form.labels.filterByAircraft': 'Filter by aircraft',
  'form.labels.sic': 'SIC',
  'form.labels.flight': 'Flight',
  'form.labels.flightHours': 'Flight Hours',
  'form.labels.flightHourLimit': 'Flight Hours Limit',
  'form.labels.flightHoursDue': 'Flight Hours Due',
  'form.labels.hours': 'Hours',
  'form.labels.item': 'Item',
  'form.labels.lifeMonths': 'Life (Months)',
  'form.labels.maintenanceProcedure': 'Maintenance Procedures',
  'form.labels.masterTitle': 'Master Title',
  'form.labels.mel': 'MEL PDF',
  'form.labels.newDeferralPeriod': 'New deferral period:',
  'form.labels.nextCheckApu': 'Next APU Check',
  'form.labels.nextCheckCycles': 'Next Check (cycles)',
  'form.labels.nextCheckDate': 'Next Check (date)',
  'form.labels.nextCheckHours': 'Next Check (hours)',
  'form.labels.no': 'No.',
  'form.labels.operationalLimitations': 'Operational Limitations',
  'form.labels.operationalProcedure': 'Operational Procedures',
  'form.labels.operatorName': 'Operator name',
  'form.labels.other': 'Other',
  'form.labels.pageNo': 'Page No.',
  'form.labels.partNum': 'Part No.',
  'form.labels.password': 'Password',
  'form.labels.placards': 'Placards',
  'form.labels.qtyInstalled': 'Qty Installed',
  'form.labels.qtyRequired': 'Qty Required',
  'form.labels.reporter': 'Reporter',
  'form.labels.recordType': 'Record Type',
  'form.labels.registration': 'Registration',
  'form.labels.remarks': 'Remarks',
  'form.labels.section': 'Section',
  'form.labels.selectedAircraft': 'Selected Aircraft',
  'form.labels.serialNum': 'Serial No.',
  'form.labels.selectCategory': 'Category',
  'form.labels.srpNo': 'Start SRP No.',
  'form.labels.source': 'Source',
  'form.labels.subSectionTitle': 'Subsection Title',
  'form.labels.taskRef': 'Task Reference',
  'form.labels.timeLimit': 'Time Limit',
  'form.labels.title': 'Title',
  'form.labels.trip': 'Trip',
  'form.labels.username': 'Username',
  'form.labels.validRange': 'Document valid range',
  'form.labels.details': 'Details',
  'form.labels.operatorReference': 'Operator Ref.',
  'form.labels.partNumber': 'Part Number',
  'form.label.firstName': 'First Name',
  'form.label.lastName': 'Last Name',
  'form.label.email': 'Email',
  'form.labels.crsDocument': 'CRS Document',
  'form.labels.signedBy': 'Signed by',
  'form.labels.flightsLimit': 'Flights Limit',
  'form.labels.hoursLimit': 'Hours Limit',
  'form.labels.calendarDaysLimit': 'Calendar Days Limit',
  'form.labels.flightDaysLimit': 'Flight Days Limit',
  'form.labels.workpackRefNo': 'Workpack Ref. No.',
  'form.labels.name': 'Name',
  'form.option.addNewTrip': '+ Add a new Trip',
  'form.option.convert': 'Convert',
  'form.option.delete': 'Delete',
  'form.option.edit': 'Edit',
  'form.option.noFault': 'No fault found',
  'form.option.rectified': 'Rectified',
  'form.option.replaced': 'Replaced',
  'form.option.draft': 'Draft',
  'form.option.active': 'Active',
  'form.option.complete': 'Complete',
  'form.placeholder.enterTitle': 'Enter a title',
  'form.placeholder.filterData': 'Filter table data...',
  'form.placeholder.mainLandingGear': 'eg. Main Landing Gear',
  'form.placeholder.noFiltersApplied': 'No filters applied',
  'form.placeholder.selectCategory': 'Select a category',
  'form.placeholder.registration': 'eg. G-ABCD',
  'form.placeholder.searchATAChapter': 'Search for ATA chapter',
  'form.placeholder.searchByAircraftReg': 'Search by aircraft registration',
  'form.placeholder.searchByUser': 'Search by e.g. name, email, role',
  'form.placeholder.searchByTitle': 'Search by title',
  'form.placeholder.searchDamageMaps': 'Search Damage Maps ...',
  'form.placeholder.searchHelpdesk': 'Search Helpdesk',
  'form.placeholder.searchMX': 'Search by ref number...',
  'form.placeholder.searchMXType': 'Search by maintenance type',
  'form.placeholder.searchRegistration': 'Search registration',
  'form.placeholder.selectAircraft': 'Select an aircraft',
  'form.placeholder.selectAirport': 'Select an airport',
  'form.placeholder.selectPilotInCommand': 'Select Pilot in Command',
  'form.placeholder.selectSecondInCommand': 'Select Second in Command',
  'form.placeholder.serialNo': 'eg. 560-001',
  'form.placeholder.washer': 'eg. Washer',
  'form.placeholder.searchAircraft': 'Search aircraft...',
  'form.placeholder.selectFlight': 'Select flight',
  'form.placeholder.selectMELItem': 'Select MEL Item',
  'form.placeholder.search': 'Search...',
  'form.placeholder.selectWorkpack': 'Select workpack...',
  'form.radio.active': 'Active',
  'form.radio.paused': 'Paused',
  'form.question.areYouSureDelete': 'Are you sure you want to delete this item?',
  'form.question.areYouSureDeleteTrip': 'Are you sure you want to delete this Trip?',
  'form.question.areYouSureDeleteSector': 'Are you sure you want to remove this Sector from the Trip?',
  'form.question.areYouSureMultiApprove': 'Are you sure you want to approve these items?',
  'form.question.areYouSureMultiDelete': 'Are you sure you want to delete these items?',
  'form.question.areYouSureDisable': 'Are you sure you want to disable this integration?',
  'form.question.areYouSureRemoveCrew': 'Are you sure you want to remove this crew member from aircraft?',
  'form.question.areYouSureRemoveCrewMultiple': 'Are you sure you want to delete these crew members from aircraft?',
  'form.question.confirmMELSectionDelete': 'Are you sure you want to remove this item?',
  'form.question.settings': 'What are Log Settings?',
  'form.question.areYouSureReset': 'Are you sure you want to reset this password?',
  'form.tooltip.startTypingAirports': 'Start typing to display airports',
  'form.title.nextMaintenance': 'Next Scheduled Maintenance Due',
  'form.title.nextChecks': 'Next Checks',
  'form.title.startingTotals': 'Starting Totals',
  'title.actions': 'Actions',
  'title.addNewDefect': 'Add a new Defect',
  'title.addNewPart': 'Add a new part',
  'title.addNewTicket': 'Add New Ticket',
  'title.addNewTrip': 'Add a new Trip',
  'title.addNewVendor': 'Add a new vendor',
  'title.agenda': 'Agenda',
  'title.aircraft': 'Aircraft',
  'title.aircraftAndFlight': 'Aircraft & Flight',
  'title.aircraftCreated': 'Aircraft successfully created',
  'title.aircraftCrew': 'Aircraft Crew',
  'title.aircraftDocuments': 'Aircraft Documents',
  'title.aircraftFlightLog': 'Aircraft Flight Log',
  'title.aircraftSettings': 'Aircraft Settings',
  'title.airframeHours': 'Airframe Hours',
  'title.airport': 'Airport',
  'title.amount': 'Amount',
  'title.approvedBy': 'Approved By',
  'title.apuHours': 'APU Hrs',
  'title.approvedItems': 'Approved Items',
  'title.arrival': 'Arrival',
  'title.ata': 'ATA',
  'title.attachment': 'Attachments',
  'title.attachmentsLower': 'attachments',
  'title.attachmentLower': 'attachments',
  'title.approveItem': 'Approve Item',
  'title.approveItems': 'Approve Items',
  'title.area': 'Area',
  'title.assembly': 'Assembly',
  'title.avgEngineShutdown': 'Avg Engine Shutdown',
  'title.brake&TyreWear': 'Brake & Tyre Wear',
  'title.callsign': 'Callsign',
  'title.pic': 'PIC',
  'title.category': 'Category',
  'title.certificateOfRelease': 'Certificate of Release to Service',
  'title.chapter': 'Chapter',
  'title.ataChapter': 'ATA Chapter',
  'title.changeDeferralPeriod': 'Change the deferral period',
  'title.changesNotSaved': 'Changes have not been saved',
  'title.confirmCancel': 'You have made changes, are you sure to cancel?',
  'title.confirmDelete': 'Confirm delete?',
  'title.conferenceLink': 'Conference Link',
  'title.costAnalysis': 'Cost Analysis',
  'title.costAnalysisSub': 'View breakdowns of cost estimates for your fleet.',
  'title.createdAt': 'Created at',
  'title.createdBy': 'Created by',
  'title.createNewAircraft': 'Create new aircraft',
  'title.createNewSubscription': 'Create a new subscription',
  'title.createYourmaintenanceManagementReport': 'Create your engineering & maintenance management report',
  'title.crew': 'Crew',
  'title.CRSSignature': 'CRS Signature',
  'title.currency': 'Currency',
  'title.currentStatus': 'Current Status',
  'title.cycles': 'Cycles',
  'title.cyclesCaps': 'CYCLES',
  'title.damageType': 'Damage Type',
  'title.date': 'Date',
  'title.dateIssued': 'Date Issued',
  'title.dateClosed': 'Date Closed',
  'title.dateCreated': 'Date Created',
  'title.daysRemaining': 'Days Remaining',
  'title.daysRemainingShort': 'Days Rem.',
  'title.defectId': 'Defect ID',
  'title.defectNum': 'Defect #',
  'title.defectReport': 'Defect Report',
  'title.defectReportSignature': 'Defect Report Signature',
  'title.defectReportTrends': 'Defect Report Trends',
  'title.defectReportTimeBetween': 'Time between defect reports',
  'title.defectReportCyclesBetween': 'Cycles between defect reports',
  'title.marepSummary': 'MAREP / PIREP Summary',
  'title.totalReportedDefects': 'Total Reported Defects',
  'title.marepDefects': 'MAREP Reported Defects',
  'title.pirepDefects': 'PIREP Reported Defects',
  'title.reportedDefects': 'MAREP / PIREP Reported Defects',
  'title.ataDefects': 'ATA Defects',
  'title.ataDefectsOverTime': 'ATA Defect Totals Over Time',
  'title.deferredDefects': 'Deferred Defects by 1000 Flight Hours',
  'title.defects': 'Defects',
  'title.unscheduledRemovals': 'Unscheduled Removals',
  'title.unscheduledEngineRemovals': 'Unscheduled Engine Removals',
  'title.topRemovalsByPart': 'Top Removals by Part Number',
  'title.defectsSub': 'View defect data on your currently selected aircraft.',
  'title.deleteItem': 'Delete Item',
  'title.deleteItems': 'Delete Items',
  'title.departure': 'Departure',
  'title.deferralReportSignature': 'Defect Report Signature',
  'title.delays': 'Delays',
  'title.description': 'Description',
  'title.documentAssessment': 'Document Assessment',
  'title.due': 'due',
  'title.draftItems': 'Draft Items',
  'title.edit': 'Edit',
  'title.editDeferral': 'Edit deferral period',
  'title.editWorkpack': 'Edit Workpack',
  'title.edittingFlightOnTrip': 'Editing Flights on Trip',
  'title.email': 'Email',
  'title.enable': 'Enable',
  'title.engShort': 'Eng',
  'title.engineShutdowns': 'Engine Shutdowns',
  'title.errorDetails': 'Error Details',
  'title.errorLinking': 'Unable to link account',
  'title.estimatedDue': 'Est. Due',
  'title.estDueCaps': 'EST DUE',
  'title.estimatedShort': 'Est',
  'title.event': 'Event',
  'title.expected': 'Expected',
  'title.fileName': 'File Name',
  'title.filePreview': 'File Preview',
  'title.firstName': 'First Name',
  'title.sic': 'SIC',
  'title.flightHrs30d': 'Flight hours in the last 30 days',
  'title.flight': 'Flight',
  'title.flightAttachments': 'Flight Attachments',
  'title.flights': 'Flights',
  'title.flightsTop30': 'Flights (Top 30)',
  'title.flightDetails': 'Flight Details',
  'title.flightHrs': 'Flight Hrs',
  'title.flightLandings': 'Flight Landings',
  'title.flightTime': 'Flight Time',
  'title.flightTimeCaps': 'FLIGHT TIME',
  'title.flights30d': 'Flights (last 30 days)',
  'title.flightsTooltip30d': 'Flights in the last 30 days',
  'title.fo': 'FO',
  'title.frequency': 'Frequency',
  'title.from': 'From',
  'title.fuel': 'Fuel',
  'title.fuelReceiptManagement': 'Fuel Receipt Management',
  'title.fuelUsage': 'Fuel Usage',
  'title.generalSettings': 'General Settings',
  'title.group': 'Group',
  'title.helpdesk': 'Helpdesk',
  'title.history': 'History',
  'title.hoursPerCycle': 'Hours per cycle',
  'title.hours': 'Hours',
  'title.hoursCaps': 'HOURS',
  'title.hours&flights': 'Hours & Flights (last 30 days)',
  'title.hoursTop30': 'Hours (Top 30)',
  'title.hours30d': 'Hours (last 30 days)',
  'title.howCanWeHelp': 'How can we help you today?',
  'title.id': 'ID',
  'title.integrations': 'Integrations',
  'title.interval': 'Interval',
  'title.lifespan': 'Lifespan',
  'title.item': 'Item',
  'title.itemRef': 'Item Ref.',
  'title.landing': 'Land',
  'title.lanDing': 'Landing',
  'title.landings': 'Landings',
  'title.lastActive': 'Last Active',
  'title.lastFlown': 'Last Flown',
  'title.lastFlownCaps': 'LAST FLOWN',
  'title.lastName': 'Last Name',
  'title.lastUpdated': 'Last Updated',
  'title.lifeLimitedParts': 'Life Limited Parts',
  'title.location': 'Location',
  'title.logSettings': 'Log Settings',
  'title.maintenance': 'Maintenance',
  'title.maintenanceCost': 'Maintenance Cost',
  'title.maintenanceDescription':
    "View and manage all of {registration}'s maintenance and defect items. Create and schedule workpacks.",
  'title.maintenanceForecast': 'Maintenance Forecast',
  'title.manageTrips': 'Manage Trips',
  'title.masterMels': 'Master MELs',
  'title.meetings': 'Meetings',
  'title.meetingsSub': 'View your upcoming and previous meetings and access agendas and minutes.',
  'title.mel': 'MEL',
  'title.melCode': 'MEL Code',
  'title.mels': 'MELs',
  'title.myMels': 'My MELs',
  'title.metrics': 'Metrics',
  'title.minutes': 'Minutes',
  'title.months': 'Months',
  'title.mostFlights': 'Most Flights',
  'title.mostHours': 'Most Hours',
  'title.mostVisited': 'Most Visited',
  'title.mostVisitedAirports': 'Most Visited Airports',
  'title.mxThresholdsFor': 'Maintenance warnings for ({field})',
  'title.pageEditMel': 'Editing {melType} MEL for {melAircraft}',
  'title.name': 'Name',
  'title.name1': 'Name 1',
  'title.name2': 'Name 2',
  'title.nextDue': 'Next Due',
  'title.new': 'New',
  'title.newWorkpack': 'New Workpack',
  'title.notAvailableShort': 'N/A',
  'title.notes': 'Notes',
  'title.number': 'Number',
  'title.numberShort': 'No.',
  'title.oilConsumption': 'Oil Consumption',
  'title.oilUplift': 'Oil Uplift',
  'title.operator': 'Operator',
  'title.operationalAvailability': 'Operational Availability',
  'title.organisation': 'Organisation',
  'title.organisationDocuments': 'Organisation Documents',
  'title.outOfPhaseItems': 'Out of Phase Items',
  'title.paid': 'Paid',
  'title.part145Signature': 'Part 145 Signature',
  'title.partNum': 'Part No.',
  'title.part': 'Part',
  'title.past': 'Past',
  'title.performance': 'Performance',
  'title.planned': 'Planned',
  'title.preview': 'Preview',
  'title.quantity': 'Quantity',
  'title.receipt': 'Receipt',
  'title.recordType': 'Record Type',
  'title.reg': 'Reg',
  'title.ref': 'Ref.',
  'title.reference': 'Reference',
  'title.registration': 'Registration',
  'title.remaining': 'Remaining',
  'title.removeCrew': 'Remove Crew Member',
  'title.reportBy': 'Reported By',
  'title.reportedBy': 'Reported By:',
  'title.resetPasswordEmail': 'Send password reset email?',
  'title.resolution': 'Resolution',
  'title.resolve': 'RESOLUTION',
  'title.role': 'Role',
  'title.routeMap': 'Route Map',
  'title.routes': 'Routes',
  'title.scheduled': 'Scheduled',
  'title.scheduledWorkDate': 'Date Scheduled',
  'title.sector': 'Sector',
  'title.serial': 'Serial',
  'title.serialNum': 'Serial No.',
  'title.servicing': 'Servicing',
  'title.sharingLinkCaps': 'SHARING LINK',
  'title.srp': 'SRP',
  'title.srpDate': 'SRP Date',
  'title.startingTotals': 'Starting Totals',
  'title.status': 'Status',
  'title.subscriptions': 'Subscriptions',
  'title.sureToCancel': 'Sure to cancel?',
  'title.takeOff': 'T/O',
  'title.takeoff': 'Takeoff',
  'title.taskRef': 'Task Ref.',
  'title.ticket': 'Ticket',
  'title.ticketDetails': 'Ticket Details',
  'title.time': 'Time',
  'title.timestamp': 'Timestamp',
  'title.title': 'Title',
  'title.threshold': 'Threshold',
  'title.to': 'To',
  'title.togo': 'To go',
  'title.todaysMeetings': 'Todays Meetings',
  'title.topic': 'Topic',
  'title.totalLandings': 'Total Ldgs',
  'title.totalCycles': 'Total Cycles',
  'title.totalDays': 'Total Days',
  'title.totalEngineShutdowns': 'Total Engine Shutdowns',
  'title.totalHours': 'Total Hours',
  'title.totals': 'Totals',
  'title.tripAttachments': 'Trip Attachments',
  'title.triplog': 'Trip Log',
  'title.trip': 'Trip',
  'title.tripNum': 'Trip Number',
  'title.trips': 'Trips',
  'title.recentTrips': 'Recent Trips',
  'title.tripSector': 'Trip-Sector',
  'title.tripSectorNum': 'Trip/Sector #',
  'title.timeline': 'Timeline',
  'title.type': 'Type',
  'title.update': 'Update',
  'title.updateSubscription': 'Update a subscription',
  'title.editSubscription': 'Edit subscription',
  'title.uploadImage': 'Upload Image',
  'title.uploadSchematics': 'Upload schematics',
  'title.user': 'User',
  'title.userSettings': 'User Settings',
  'title.userSettingsHeader': 'Manage users linked to your organisations',
  'title.userManagement': 'User Management',
  'title.workpackage': 'Workpackage',
  'title.workpacks': 'Workpacks',
  'title.workpackReference': 'Workpack Ref.',
  'title.workpackTitle': 'Workpack Title',
  'title.viewAll': 'View all',
  'title.visits': 'Visits',
  'title.visitsTop30': 'Visits (Top 30)',
  'title.year': 'Year',
  'title.statusCaps': 'STATUS',
  'title.estimatedDueCaps': 'EST. DUE',
  'title.none': 'None',
  'title.dashboard': 'Dashboard',
  'title.defect': 'Defect',
  'title.tolerance': 'Tolerance',
  'title.remarks': 'Remarks',
  'title.placard': 'Placards',
  'title.maintenanceProcedure': 'Maintenance Procedure',
  'title.operationalProcedure': 'Operational Procedure',
  'title.editAircraft': 'Edit Aircraft ({field})',
  'title.sureToClose': 'Are you sure to close this ticket?',
  'title.nextFlight': 'Next Flight',
  'title.partNumber': 'Part Number',
  'title.aircraftOverview': 'Aircraft Overview',
  'title.selectDateRange': 'Select Date Range',
  'title.selectRepairedDefects': 'Select repaired defects',
  'title.selectCompletedMXItems': 'Select completed maintenance items',
  'title.selectACatagory': 'Select a catagory to create a ticket. Our team will reply to your query within 72 hours.',
  'title.selectAdditionalTasks': 'Select additional tasks',
  'title.entryDate': 'Entry Date',
  'title.signForCRS': 'Sign to create a Certificate of Release to Service',
  'title.poNumber': 'PO Number',
  'subtitle.step15': 'Step 1/5',
  'subtitle.step25': 'Step 2/5',
  'subtitle.step35': 'Step 3/5',
  'subtitle.step45': 'Step 4/5',
  'subtitle.step55': 'Step 5/5',
  'subtitle.step': 'Step {current}/{total}',
  'subtitle.showResolved': 'Show resolved',
  'subtitle.hideResolved': 'Hide resolved',
  'title.cyclesRem': 'Cycles Rem.',
  'title.daysRem': 'Days Rem.',
  'title.hoursRem': 'Hours Rem.',
  'title.age': 'Age',
  'title.apuRem': 'APU Rem.',
  'title.items': 'Items',
  'title.showResolved': 'Show resolved {type}',
  'title.hideResolved': 'Hide resolved {type}',
  'title.allResolved': 'All {type} resolved',
  'tab.busiestAircraftFlights': 'Busiest Aircraft (by flights)',
  'tab.busiestAircraftHours': 'Busiest Aircraft (by hours)',
  'tab.flights': 'Flights',
  'tab.fuelDelta': 'Fuel Delta',
  'tab.llps': 'LLPs',
  'tab.oilConsumption': 'Oil Consumption',
  'tab.outOfPhase': 'Out of Phase',
  'tab.scheduled': 'Scheduled',
  'tab.topAirports': 'Top Airports',
  'tab.totals': 'Totals',
  'tab.listView': 'List View',
  'tab.schematicView': 'Schematic View',
  'tab.tableView': 'Table View',
  'label.sortBy': 'Sort By',
  'currency.CAD': 'CAD',
  'currency.EUR': 'EUR',
  'currency.GBP': 'GBP',
  'currency.USD': 'USD',
};
