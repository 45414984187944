import { Layout, Spin, Button, Affix } from 'antd';
import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Sticky from 'react-stickynode';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import isScreenMobile from '../utils/isScreenMobile';
import planeIcon from '../assets/plane_icon--blue.svg';
import RightContent from '../components/GlobalHeader/RightContent';
import { changeLayoutCollapsed } from '../models/menu';
import { getAllAircraft } from '../models/aircraft/actions';
import SwipeableDrawer from '../components/SwipeableDrawer';
import BasicLayout from './BasicLayout';
import styles from './InnerMenuLayout.module.less';
import CamoContextMenu from './menus/CamoContextMenu';
import AircraftDropdown from './menus/AircraftDropdown';

const { Header, Sider } = Layout;

class InnerMenuLayout extends React.PureComponent {
  handleContentOnClick = throttle(() => {
    this.setState({
      searchFocussed: false,
    });
  }, 600);

  handleTopMenuOnClick = throttle((data) => {
    const { searchFocussed } = this.state;
    const aircraft = this.getAircraft();
    let path = this.props.location.pathname.replace(`/camo/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('/');
    path = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    if (data.key === 'changeContext') {
      this.setState({ searchFocussed: !searchFocussed });
    } else if (data.key === 'more') {
      this.setState({
        drawerOpen: true,
        drawerContent: <CamoContextMenu selectedKeys={[path]} onClick={this.handleTopMenuOnClick} />,
      });
    } else if (aircraft) {
      this.props.history.push(`/camo/aircraft/${aircraft.id}/${data.key}`);
    }
  }, 800);

  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    children: PropTypes.object.isRequired,
    collapsed: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    lastFetched: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    ttl: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    const { selectedAircraft, collapsed } = this.props;
    this.state = {
      aircraftMenuStuck: false,
      filter: '',
      loading: !!selectedAircraft.id,
      isSmallScreen: false,
      searchFocussed: false,
      drawerOpen: false,
      drawerContent: null,
    };
    this.filterInput = React.createRef();
    if (!collapsed) {
      this.handleMenuCollapse(true);
    }
  }

  componentDidMount() {
    const {
      dispatch,
      lastFetched,
      match: {
        params: { id },
      },
      ttl,
    } = this.props;
    if (id && Date.now() - lastFetched >= ttl) {
      dispatch(getAllAircraft()).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentDidUpdate() {
    const {
      lastFetched,
      match: {
        params: { id },
      },
      ttl,
    } = this.props;
    if (id && Date.now() - lastFetched < ttl) {
      this.setUpLoading();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(
      changeLayoutCollapsed({
        payload: false,
      }),
    );
    window.scrollTo(0, 0);
  }

  setUpLoading = () => {
    this.setState({ loading: false });
  };

  getAircraft = () => {
    const { selectedAircraft } = this.props;
    return selectedAircraft.id ? selectedAircraft : null;
  };

  getSideNavLayoutStyle() {
    const { aircraftMenuStuck } = this.state;
    let paddingLeft = '';
    let paddingTop = '';
    if (aircraftMenuStuck) {
      paddingLeft = '128px';
      paddingTop = '64px';
    }
    return {
      minHeight: '0',
      maxWidth: '100vw',
      paddingLeft,
      paddingTop,
      position: 'relative',
    };
  }

  getTopMenuStyle() {
    const { collapsed } = this.props;
    const menuStyles = [styles.topMenu];
    if (!collapsed) {
      menuStyles.push(styles.topMenuWhenSideCollapsed);
    }
    return menuStyles;
  }

  getAircraftList() {
    const {
      aircraftMap,
      match: {
        params: { id },
      },
      location: { pathname },
    } = this.props;
    const { filter } = this.state;
    const aircraftList = Array.from(aircraftMap.values());
    const path = pathname.replace(`/camo/aircraft/${id}/`, '');

    const slashIndex = path.indexOf('/');
    let commonPath = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    let selectedAircraftObj = {};
    const commonPathRedirects = ['settings'];
    if (commonPathRedirects.includes(commonPath)) {
      commonPath = 'overview';
    }
    const aircraftListArray = aircraftList.reduce((arr, item) => {
      const acItem = { ...item };
      const aircraftMatchesFilter = item.registration.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      const selectedAircraft = acItem.id === id;
      if (selectedAircraft) {
        selectedAircraftObj = acItem;
      }
      if (aircraftMatchesFilter) {
        acItem.path = `/camo/aircraft/${item.id}/${commonPath}`;
        arr.push(acItem);
      }
      return arr;
    }, []);
    return {
      aircraftList: aircraftListArray,
      selectedAircraft: selectedAircraftObj,
    };
  }

  toggle = () => {
    const { collapsed } = this.props;
    this.handleMenuCollapse(!collapsed);
    for (let i = 1; i < 6; i += 1) {
      setTimeout(() => {
        this.triggerResizeEvent();
      }, i * 50);
    }
  };

  handleMenuCollapse = (collapsed) => {
    const { dispatch } = this.props;
    dispatch(
      changeLayoutCollapsed({
        payload: collapsed,
      }),
    );
  };

  handleFilterChange = (e) => {
    this.setState(
      {
        filter: e.target.value,
      },
      () => {
        const { drawerOpen, filter } = this.state;
        if (drawerOpen) {
          this.setState({
            drawerContent: (
              <AircraftDropdown
                aircraftList={this.getAircraftList().aircraftList}
                selectedAircraft={this.getAircraftList().selectedAircraft}
                onSearchChange={this.handleFilterChange}
                searchValue={filter}
                menuOnly
              />
            ),
          });
        }
      },
    );
  };

  handleStateChange = (status) => {
    this.setState({
      aircraftMenuStuck: status.status === Sticky.STATUS_FIXED,
    });
  };

  triggerResizeEvent = () => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  };

  updateMediaScreenSize = () => {
    this.setState({ isSmallScreen: isScreenMobile() });
  };

  renderSideNavBar = () => {
    let path = this.props.location.pathname.replace(`/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('o');
    path = path.substring(slashIndex + 1, path.length);
    return (
      <Sider width={200} className={styles.scrollableSider} collapsed={false}>
        <AircraftDropdown
          aircraftList={this.getAircraftList().aircraftList}
          selectedAircraft={this.getAircraftList().selectedAircraft}
          onSearchChange={this.handleFilterChange}
          searchValue={this.state.filter}
        />
        <CamoContextMenu selectedKeys={[path]} />
      </Sider>
    );
  };

  renderTopNavBar = () => {
    const smallScreen = this.state.isSmallScreen;
    let path = this.props.location.pathname.replace(`/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('/');
    path = path.substring(0, slashIndex !== -1 ? slashIndex : path.length);
    const aircraft = this.getAircraft();
    return !smallScreen ? (
      <Header className={styles.headerLayout} data-test="headerLayout">
        <>
          <span className={styles.planeContext}>
            <img src={planeIcon} alt="plane" width="24" data-test="aircraftIcon" />
            <span className={styles.planeRegistration} data-test="aircraftRegistration">
              {aircraft ? aircraft.registration.substring(0, 8) : ''}
            </span>
          </span>
        </>
        <span className={styles.fixedRightMenu}>
          <RightContent />
        </span>
      </Header>
    ) : null;
  };

  renderSecondNavBar = () => {
    let path = this.props.location.pathname.replace(`/aircraft/${this.props.match.params.id}/`, '');
    const slashIndex = path.indexOf('o');
    path = path.substring(slashIndex + 1, path.length);
    return (
      <Header className={`${styles.headerLayout} ${styles.secondaryLayout}`} data-test="headerLayout">
        <AircraftDropdown
          aircraftList={this.getAircraftList().aircraftList}
          selectedAircraft={this.getAircraftList().selectedAircraft}
          onSearchChange={this.handleFilterChange}
          searchValue={this.state.filter}
          forMobile
          toggleDrawer={() =>
            this.drawerToggle({
              drawerContent: (
                <AircraftDropdown
                  aircraftList={this.getAircraftList().aircraftList}
                  selectedAircraft={this.getAircraftList().selectedAircraft}
                  onSearchChange={this.handleFilterChange}
                  searchValue={this.state.filter}
                  menuOnly
                />
              ),
            })
          }
        />
        <CamoContextMenu selectedKeys={[path]} onClick={this.handleTopMenuOnClick} isMobile />
      </Header>
    );
  };

  drawerToggle = (additionalState) => {
    const { drawerOpen } = this.state;
    this.setState({
      drawerOpen: !drawerOpen,
      ...additionalState,
    });
  };

  render() {
    const { children, loading } = this.props;
    const { searchFocussed, isSmallScreen, drawerOpen, drawerContent } = this.state;
    const { loading: stateLoading } = this.state;
    const combinedLoadingState = loading || stateLoading;

    this.updateMediaScreenSize();

    matchMedia('(max-width: 767px)').addListener(this.updateMediaScreenSize);
    return !isSmallScreen ? (
      <BasicLayout>
        <Layout className={styles.innerMenuLayout}>
          <Spin spinning={combinedLoadingState} data-test="globalSpinBig">
            <Layout style={this.getSideNavLayoutStyle()}>
              <div aria-hidden className={styles.affixSpacer} />
              <div className={styles.contextAffix}>{this.renderSideNavBar()}</div>
              <Layout>
                <Sticky innerZ={200} className={styles.topNavBar}>
                  {this.renderTopNavBar()}
                </Sticky>
                <Layout className={styles.innerContent}>
                  <div className={styles.contentWrapper} data-test="contentWrapper">
                    {children}
                  </div>
                </Layout>
              </Layout>
            </Layout>
          </Spin>
        </Layout>
      </BasicLayout>
    ) : (
      <BasicLayout>
        <Layout className={styles.innerMenuLayout}>
          <Spin spinning={combinedLoadingState} data-test="globalSpinSmall">
            <Sticky innerZ={200} top={64} className={styles.topNavBarMobile}>
              {this.renderTopNavBar()}
              {this.renderSecondNavBar()}
            </Sticky>

            <SwipeableDrawer
              open={drawerOpen}
              onToggle={() => this.drawerToggle()}
              data-test="swipeableDrawer"
              swipeDrawerContent={drawerContent}
            />
            <Layout style={this.getSideNavLayoutStyle()}>
              <Layout>
                <Layout className={searchFocussed ? [styles.innerContent, styles.searchFocussed] : styles.innerContent}>
                  <Button type="link" className={styles.contentWrapper} onClick={this.handleContentOnClick}>
                    {children}
                  </Button>
                </Layout>
              </Layout>
            </Layout>
          </Spin>
        </Layout>
      </BasicLayout>
    );
  }
}

export default withRouter(
  injectIntl(
    connect(({ aircraft, menu, userSettings }, { match: { params: { id } } }) => ({
      aircraftMap: aircraft.aircraftMap,
      selectedAircraft: aircraft.aircraftMap.get(id) || {},
      collapsed: menu.collapsed,
      layout: userSettings.ui.layout,
      lastFetched: aircraft.lastFetched,
      menu,
      ttl: aircraft.ttl,
      ...userSettings.ui,
    }))(InnerMenuLayout),
  ),
);
