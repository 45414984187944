/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import SiderMenu from './SiderMenu';
import styles from './index.module.less';

/**
 * Recursively flatten the data
 * [{path:string},{path:string}] => {path,path2}
 * @param   menus
 */
const getFlatMenuKeys = (menuData) => {
  let keys = [];
  menuData.forEach((item) => {
    if (item.children) {
      keys = keys.concat(getFlatMenuKeys(item.children));
    }
    keys.push(item.path);
  });
  return keys;
};

const SiderMenuWrapper = (props) => {
  const { isMobile, menuData, collapsed, onCollapse } = props;
  return isMobile ? (
    <Drawer
      visible={!collapsed}
      placement="left"
      onClose={() => onCollapse(true)}
      className={styles.siderMenuWrapperDrawer}
    >
      <SiderMenu {...props} flatMenuKeys={getFlatMenuKeys(menuData)} />
    </Drawer>
  ) : (
    <SiderMenu {...props} flatMenuKeys={getFlatMenuKeys(menuData)} />
  );
};

SiderMenuWrapper.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  menuData: PropTypes.array.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

export default SiderMenuWrapper;
