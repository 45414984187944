import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import Calendar from 'react-calendar';
import MeetingsEmptyState from '../../assets/empty-state-meetings.svg';
import CalendarEntryTile from './CalendarEntryTile';
import styles from './TodaysMeetings.module.less';
import 'react-calendar/dist/Calendar.css';

const formatDay = (date) => {
  const day = moment(date).format('ddd');
  return day.charAt(0);
};

const TodaysMeetings = ({ intl, meetingMap, loading }) => {
  const { formatMessage } = intl;
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [dataMessage, setDataMessage] = useState('');
  let dateHeader = moment(calendarDate).format('dddd Do');
  if (moment(calendarDate).format('DD MM YYYY') === moment().format('DD MM YYYY')) dateHeader = 'Today';

  useEffect(() => {
    const meetingList = [];
    if (meetingMap) {
      const map = Array.from(meetingMap.values());
      map.forEach((meeting) => {
        if (meeting.start && moment(meeting.start.dateTime).isSame(calendarDate, 'day')) {
          meetingList.push(meeting);
        }
      });
    }
    setFilteredData(meetingList);
    setDataMessage(
      Array.from(meetingMap.values()).length > 0
        ? formatMessage({ id: 'text.noMeetingsToday' })
        : formatMessage({ id: 'text.noData' }),
    );
  }, [meetingMap, calendarDate, formatMessage]);

  return (
    <div>
      <div className={styles.header}>
        {`${dateHeader}'s `}
        {formatMessage({ id: 'text.meetings' })}
      </div>
      <div className={styles.calendarWrapper}>
        <Calendar
          className={styles.calendar}
          value={calendarDate}
          onChange={(chosenDate) => setCalendarDate(chosenDate)}
          formatShortWeekday={(locale, date) => formatDay(date, 'd')}
          next2Label={null}
          prev2Label={null}
          tileClassName={styles.calendarDate}
          showNeighboringMonth={false}
        />
      </div>
      {loading === true ? (
        <div className={styles.loadingDiv}>
          <Spin size="small" />
        </div>
      ) : (
        <>
          {filteredData.length === 0 ? (
            <div className={styles.emptyStateWrapper}>
              <img src={MeetingsEmptyState} alt={dataMessage} className={styles.emptyState} />
              <span>{dataMessage}</span>
            </div>
          ) : (
            <>
              {filteredData.map((entry) => (
                <CalendarEntryTile key={entry.id} entry={entry} />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

TodaysMeetings.propTypes = {
  intl: PropTypes.object.isRequired,
  meetingMap: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool,
};

TodaysMeetings.defaultProps = {
  loading: false,
};

const TodaysMeetingsWithRedux = connect(({ meetings }) => ({ meetingMap: meetings.meetingMap }))(TodaysMeetings);

export default injectIntl(TodaysMeetingsWithRedux);
