import React from 'react';
import PropTypes from 'prop-types';
import styles from './LegendToggleButton.module.less';

const LegendToggleButton = ({ visible, handleToggleClick, badgeColour, buttonText }) => {
  return (
    <button
      className={visible ? styles.buttonToggle : styles.buttonToggleOff}
      type="button"
      onClick={() => handleToggleClick(buttonText)}
    >
      {badgeColour ? <div className={styles.badge} style={{ backgroundImage: badgeColour }} /> : null}
      <span>{buttonText}</span>
    </button>
  );
};

LegendToggleButton.propTypes = {
  visible: PropTypes.bool,
  handleToggleClick: PropTypes.func.isRequired,
  badgeColour: PropTypes.string,
  buttonText: PropTypes.string,
};

LegendToggleButton.defaultProps = {
  visible: true,
  badgeColour: '',
  buttonText: '',
};

export default LegendToggleButton;
