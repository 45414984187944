import { combineReducers } from 'redux';
import aircraft from './aircraft';
import maintenance from './maintenance';
import people from './people';
import brakesTyresReports from './brakesTyresReports';
import engineReports from './engineReports';
import meetings from './meetings';
import menu from './menu';
import performanceReports from './performanceReports';
import reliabilityReports from './reliabilityReports';
import reliabilityReportsAircraft from './reliabilityReportsAircraft';
import userSettings from './userSettings';

const reducers = {
  aircraft,
  brakesTyresReports,
  engineReports,
  maintenance,
  meetings,
  menu,
  people,
  performanceReports,
  reliabilityReports,
  reliabilityReportsAircraft,
  userSettings,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;

export type DashboardState = ReturnType<typeof rootReducer>;

export interface BaseModel {
  lastFetched: number;
}
