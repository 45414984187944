import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { queryAircraft, queryDefects } from '../../services/apiNew';
import { AircraftActionTypes } from '../../models/aircraft';
import CamoMenuLayout from '../../layouts/CamoMenuLayout';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import AircraftSummary from '../../components/AircraftServiceOverview/AircraftSummary';
import DefectsSummary from '../../components/AircraftServiceOverview/DefectsSummary';
import cardStyles from '../../styles/components/CamoCard/CamoCard.module.less';
import MaintenanceTable from '../../components/AircraftServiceOverview/MaintenanceTable';
import DefectsTable from '../../components/AircraftServiceOverview/DefectsTable';
import WorkpackStatusTable from '../../components/AircraftServiceOverview/WorkpackStatusTable';
import TechLogPageTable from '../../components/AircraftServiceOverview/TechLogPageTable';
import styles from './AircraftServiceOverview.module.less';

const AircraftServiceOverview = ({ aircraftMap, updateAircraft }) => {
  const [loading, setLoading] = useState(false);
  const [techlogVisible, settechlogVisible] = useState(false);
  const [defectsData, setDefectsData] = useState([]);
  const {
    params: { id },
  } = useRouteMatch();
  const selectedAircraft = aircraftMap.get(id);
  useEffect(() => {
    const getAircraft = async () => {
      setLoading(true);
      const res = await queryAircraft(id);
      updateAircraft(res.data.data.aircraft);
      setLoading(false);
    };
    if (!selectedAircraft) {
      getAircraft();
    }
  }, [id, selectedAircraft, updateAircraft]);

  useEffect(() => {
    const getDefects = async () => {
      setLoading(true);
      const res = await queryDefects(id);
      if (!res.data.data.aircraft.defects) {
        res.data.data.aircraft.defects = [];
      }
      updateAircraft(res.data.data.aircraft);
      setLoading(false);
    };
    if (selectedAircraft && !selectedAircraft.defects) {
      getDefects();
    }
    if (selectedAircraft && selectedAircraft.defects) {
      const defectData = selectedAircraft.defects.map((defect) => ({
        ...defect,
        status: defect.togo > 0 ? 'open' : 'overdue',
        number: defect.id,
      }));
      setDefectsData(defectData);
    }
  }, [selectedAircraft, id, updateAircraft]);

  const { formatMessage } = useIntl();

  return (
    <CamoMenuLayout>
      <PageHeaderWrapper
        layout="modern"
        title={formatMessage({ id: 'title.aircraftOverview' })}
        content={formatMessage({ id: 'text.aircraftOverviewHeader' })}
      >
        {!selectedAircraft ? (
          'loading'
        ) : (
          <>
            <Row gutter={20}>
              <Col span={24}>
                <Card className={`${cardStyles.pageCard} ${cardStyles.padded}`}>
                  <div className={styles.cardHeader}>Aircraft Summary</div>
                  <AircraftSummary aircraft={selectedAircraft} />
                </Card>
              </Col>
            </Row>
            <Row gutter={30} className={styles.cardRow}>
              <Col lg={15} md={24} sm={24}>
                <Card className={cardStyles.pageCard}>
                  <div className={styles.cardHeader}>Workpack Closing Status</div>
                  <WorkpackStatusTable aircraft={selectedAircraft} />
                </Card>
              </Col>
              <Col lg={9} md={24} sm={24}>
                <Card className={`${cardStyles.pageCard} ${cardStyles.padded} ${styles.defectsCard}`}>
                  <div className={styles.cardHeader}>Defects Summary</div>
                  <DefectsSummary aircraft={selectedAircraft} defectsData={defectsData} />
                </Card>
              </Col>
            </Row>
            <Row className={styles.cardRow}>
              <Col span={24}>
                <Card className={`${cardStyles.pageCard} ${cardStyles.padded} ${styles.extraHeight}`}>
                  <div className={styles.headerRow}>
                    <div className={`${styles.cardHeader} ${styles.maintenanceHeader}`}>Maintenance Forecast</div>
                  </div>
                  <MaintenanceTable
                    aircraft={selectedAircraft}
                    data={selectedAircraft.maintenanceChecks}
                    loading={loading}
                  />
                </Card>
              </Col>
            </Row>
            <Row className={styles.cardRow}>
              <Col span={24}>
                <Card className={`${cardStyles.pageCard} ${cardStyles.padded}`}>
                  <div className={styles.cardHeader}>Defects</div>
                  <DefectsTable
                    aircraft={selectedAircraft}
                    shortList
                    defectsData={defectsData}
                    hideShowAll={defectsData.length < 1}
                  />
                </Card>
              </Col>
            </Row>
            {techlogVisible && (
              <Row className={styles.cardRow}>
                <Col span={24}>
                  <Card className={`${cardStyles.pageCard} ${cardStyles.padded}`}>
                    <div className={styles.cardHeader}>Tech Log Page Status</div>
                    <TechLogPageTable aircraft={selectedAircraft} />
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
      </PageHeaderWrapper>
    </CamoMenuLayout>
  );
};

AircraftServiceOverview.propTypes = {
  aircraftMap: PropTypes.instanceOf(Map),
  updateAircraft: PropTypes.object,
};

AircraftServiceOverview.defaultProps = {
  aircraftMap: new Map(),
  updateAircraft: {},
};

const AircraftServiceOverviewWithRedux = connect(
  ({ aircraft }) => ({
    aircraftMap: aircraft.aircraftMap,
  }),
  (dispatch) => ({
    updateAircraft: async (payload) =>
      dispatch({
        type: AircraftActionTypes.UPDATE,
        payload,
      }),
  }),
)(AircraftServiceOverview);

export default AircraftServiceOverviewWithRedux;
