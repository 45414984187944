import { PerformanceReportsActionTypes } from '.';

export const saveFleetDefectTrends = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.FLEETDEFECTTRENDS,
    payload,
  });
};

export const saveFleetDefectSummary = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.FLEETDEFECTSUMMARY,
    payload,
  });
};

export const saveFleetUnscheduledRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.FLEETUNSCHEDULEDREMOVALS,
    payload,
  });
};

export const saveFleetUnscheduledEngineRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.FLEETUNSCHEDULEDENGINEREMOVALS,
    payload,
  });
};

export const saveFleetTimeBetweenDefects = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.FLEETTIMEBETWEENDEFECTS,
    payload,
  });
};

export const saveFleetATADefects = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.FLEETATADEFECTS,
    payload,
  });
};

export const saveAircraftDefectTrends = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTDEFECTTRENDS,
    payload,
  });
};

export const saveAircraftDefectSummary = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTDEFECTSUMMARY,
    payload,
  });
};

export const saveAircraftUnscheduledRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTUNSCHEDULEDREMOVALS,
    payload,
  });
};

export const saveAircraftUnscheduledEngineRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTUNSCHEDULEDENGINEREMOVALS,
    payload,
  });
};

export const saveAircraftATADefects = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTATADEFECTS,
    payload,
  });
};

export const saveAircraftTimeBetweenDefects = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTTIMEBETWEENDEFECTS,
    payload,
  });
};

export const saveAircraftTypeDefectTrends = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTTYPEDEFECTTRENDS,
    payload,
  });
};

export const saveAircraftTypeDefectSummary = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTTYPEDEFECTSUMMARY,
    payload,
  });
};

export const saveAircraftTypeUnscheduledRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDREMOVALS,
    payload,
  });
};

export const saveAircraftTypeUnscheduledEngineRemovals = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTTYPEUNSCHEDULEDENGINEREMOVALS,
    payload,
  });
};

export const saveAircraftTypeATADefects = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTTYPEATADEFECTS,
    payload,
  });
};

export const saveAircraftTypeTimeBetweenDefects = ({ payload }) => (dispatch): any => {
  dispatch({
    type: PerformanceReportsActionTypes.AIRCRAFTTYPETIMEBETWEENDEFECTS,
    payload,
  });
};
