import React from 'react';
import { Row, Col, Carousel } from 'antd';
import PropTypes from 'prop-types';
import AircraftDetails from './AircraftDetails';
import MXWidget from './MXWidget';
import styles from './index.module.less';

const AircraftOverview = ({ aircraft, widgets }) => {
  return (
    <>
      <Row gutter={16}>
        <Col md={8} className={styles.aircraftDetailsCol}>
          <AircraftDetails aircraft={aircraft} data-test="aircraftDetails" />
        </Col>
        <Col md={16} className={styles.aircraftWidgetsCol}>
          <Row gutter={16} className={styles.aircraftWidgets}>
            {widgets &&
              widgets.length &&
              widgets.map((item) => (
                <Col className={styles.aircraftWidgetStandard} key={`mxWidget-${item.circleUnit}`}>
                  <MXWidget
                    mxObj={item.mxObj}
                    title={item.title}
                    circleUnit={item.circleUnit}
                    format={item.format}
                    showTotal={item.showTotal}
                    showNextDue={item.showNextDue}
                    active={item.active}
                    data-test="mxWidget"
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

AircraftOverview.propTypes = {
  aircraft: PropTypes.object.isRequired,
  widgets: PropTypes.array,
};

AircraftOverview.defaultProps = {
  widgets: [],
};

export default AircraftOverview;
