import { DatePicker, Dropdown, Icon, Menu, Modal } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import React, { ReactElement, useEffect, useState } from 'react';
import planeIcon from '../../assets/plane-icon.svg';
import styles from './TabHeader.module.less';

const { MonthPicker } = DatePicker;

const TabHeader = ({ selectedTimeScale, setSelectedTimeScale, showFilter = true, filter }): ReactElement => {
  const [headerText, setHeaderText] = useState('Fleet');
  const [rangePickerVisible, setRangePickerVisible] = useState(false);
  const [fromDate, setFromDate] = useState(0);
  const [toDate, setToDate] = useState(0);
  const { formatMessage } = useIntl();

  const disabledDate = (current): boolean => {
    const today = moment().endOf('month');
    const tooLate = today && current.diff(today, 'days') > 1;
    return tooLate;
  };

  useEffect(() => {
    setHeaderText(filter.value);
  }, [filter.value]);

  const timeOptions = [
    { text: '3 months', value: 3 },
    { text: '6 months', value: 6 },
    { text: '1 year', value: 12 },
    { text: 'Custom', value: { from: fromDate, to: toDate } },
  ];

  const menu = (
    <Menu>
      {timeOptions.map((item) => (
        <Menu.Item
          key={item.text}
          onClick={(): void => (item.text === 'Custom' ? setRangePickerVisible(true) : setSelectedTimeScale(item))}
        >
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleModalClose = (): void => {
    setRangePickerVisible(false);
  };

  const handleFromDateChange = (date): void => {
    if (date) {
      const newDate = Math.round(date.diff(moment(), 'months', true));
      setFromDate(newDate);
    }
  };

  const handleToDateChange = (date): void => {
    if (date) {
      const newDate = Math.round(date.diff(moment(), 'months', true));
      setToDate(newDate);
    }
  };

  const handleModalOk = (): void => {
    setRangePickerVisible(false);
    setSelectedTimeScale(timeOptions[3]);
  };

  const getMonth = (value): string => {
    const newDate = moment().add(value, 'months');
    let showYear = false;
    if (!newDate.isSame(moment(), 'year')) showYear = true;
    if (showYear) {
      return newDate.format('MMM YY');
    }
    return newDate.format('MMM');
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.infoWrapper}>
        <img src={planeIcon} alt="plane" className={styles.planeIcon} />
        <span className={styles.infoText}>{headerText}</span>
      </div>
      {showFilter && (
        <Dropdown overlay={menu}>
          <div className={styles.filterWrapper}>
            <span>Showing: </span>
            <span className={styles.value}>
              {selectedTimeScale.text === 'Custom'
                ? `${getMonth(selectedTimeScale.value.from)} - ${getMonth(selectedTimeScale.value.to)}`
                : selectedTimeScale.text}{' '}
              <Icon type="caret-down" />
            </span>
          </div>
        </Dropdown>
      )}
      <Modal
        title={formatMessage({ id: 'title.selectDateRange' })}
        visible={rangePickerVisible}
        onCancel={handleModalClose}
        onOk={handleModalOk}
        okText={formatMessage({ id: 'form.button.applyDates' })}
        width={465}
      >
        <div className={styles.datePickerWrapper}>
          <div className={styles.pickerWrapper}>
            <span className={styles.pickerHeader}>From date</span>
            <MonthPicker onChange={handleFromDateChange} disabledDate={disabledDate} />
          </div>
          <div className={styles.pickerWrapper}>
            <span className={styles.pickerHeader}>To date</span>
            <MonthPicker onChange={handleToDateChange} disabledDate={disabledDate} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TabHeader;
